import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ManageLayout } from '../../../../layouts';
import { ManageField, ManageInput, ManageSelectbox } from '../../../../elements';
import trans from '../../../../localization';
import { Api, AuthService } from '../../../../services';
import { CarouselPane, StepCarousel } from '../../../../components';
import definitions from '../../../../definitions';
import CancelAndSaveFooter from '../../../../components/helpdesk/carousel/CancelAndSaveFooter';

class CreateOrEditPage extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;

        this.state = {
            isLoading: true,
            user: AuthService.user(),
            companyId: location.state && location.state.companyId,
            company: null,
            responseTypes: [
                {
                    title: trans('personal'),
                    value: '',
                },
            ],
            channels: [
                {
                    title: trans('for_all_channels'),
                    value: '',
                },
            ],
            isUpdate: !!(location.state && location.state.data),
            data: (location.state && location.state.data) || {
                company_id: '',
                title: '',
                description: '',
                body: '',
                source_id: '',
            },
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchCompanies()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        if (!companyId) return Promise.resolve();

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
            const { location } = this.props;

            if (location.state && location.state.responseType) {
                this.getChannels(response.data.data);
            }
        });
    }

    fetchCompanies() {
        const { data } = this.state;
        const { location } = this.props;

        return Api.request({
            method: 'GET',
            url: 'companies?role=admin',
        }).then(response => {
            this.setState({
                responseTypes: [
                    {
                        title: trans('personal'),
                        value: '',
                    },
                    ...response.data.data.map(x => ({
                        ...x,
                        title: x.name,
                        value: x.id,
                    })),
                ],
                data: {
                    ...data,
                    company_id: (location.state && location.state.responseType) || '',
                },
            });
        });
    }

    getChannels(company) {
        return Api.request({
            method: 'GET',
            url: `channels?companyId=${company.slug}`,
        }).then(response => {
            this.setState({
                channels: [
                    {
                        title: trans('for_all_channels'),
                        value: '',
                    },
                    ...response.data.data.map(x => ({
                        ...x,
                        title: x.name,
                        value: x.id,
                    })),
                ],
            });
        });
    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    onChange = (field, value) => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                [field]: value,
            },
        });
    };

    onChangeCompany = value => {
        const { data } = this.state;
        this.setState(
            {
                data: {
                    ...data,
                    company_id: value,
                    source_id: '',
                },
                isLoading: true,
            },
            () => {
                const { responseTypes } = this.state;
                const selectedResponseType = responseTypes.find(x => x.value.toString() === value);

                if (!selectedResponseType || value === '') {
                    this.setState({
                        channels: [
                            {
                                title: trans('for_all_channels'),
                                value: '',
                            },
                        ],
                        isLoading: false,
                    });
                    return;
                }

                this.getChannels(selectedResponseType).then(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
            }
        );
    };

    createCannedResponse = () => {
        const { data } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: 'cannedResponses/create',
            data,
        })
            .then(() => {
                const { history } = this.props;
                history.goBack();
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    updateCannedResponse = () => {
        const { data } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'PUT',
            url: `cannedResponses/${data.id}/edit`,
            data: {
                title: data.title,
                body: data.body,
                description: data.description,
                company_id: data.company_id,
                source_id: data.source_id,
            },
        })
            .then(() => {
                const { history } = this.props;
                history.goBack();
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    static availableVariables() {
        return definitions.AVAILABLE_VARIABLES.map(key => {
            const description = trans(`available_variables.${key}`);
            return `{${key}}: ${description}`;
        });
    }

    render() {
        const { isLoading, user, company, data, responseTypes, channels, isUpdate } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="orange"
                user={user}
                company={company}
                hideLBImg
            >
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-4"
                        scroll
                        fluid
                    >
                        <div className="d-flex flex-column pb-5">
                            <span className="font-weight-bold text-3xlarge pb-4">
                                {isUpdate
                                    ? trans('update_canned_response')
                                    : trans('create_canned_response')}
                            </span>
                            <span className="font-weight-light text-darker-gray">
                                {isUpdate
                                    ? trans('update_canned_response_text')
                                    : trans('create_canned_response_text')}
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-lg-5 pt-4">
                                <ManageField title={trans('response_name')}>
                                    <ManageInput
                                        value={data.title}
                                        placeholder={trans('placeholders.reply_to_simple_ticket')}
                                        onChange={e => this.onChange('title', e.target.value)}
                                        type="text"
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-2 pt-4">
                                <ManageField title={trans('response_type')}>
                                    <ManageSelectbox
                                        value={data.company_id}
                                        items={responseTypes}
                                        onChange={e => this.onChangeCompany(e.target.value)}
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-2 pt-4">
                                <ManageField title={trans('response_channel')}>
                                    <ManageSelectbox
                                        value={data.source_id}
                                        items={channels}
                                        onChange={e => this.onChange('source_id', e.target.value)}
                                    />
                                </ManageField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9 pt-4">
                                <ManageField title={trans('response_description')}>
                                    <ManageInput
                                        value={data.description}
                                        placeholder={trans(
                                            'placeholders.this_is_a_reply_to_a_simple_ticket'
                                        )}
                                        onChange={e => this.onChange('description', e.target.value)}
                                        type="text"
                                    />
                                </ManageField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9 pt-5">
                                <div className="d-flex flex-column">
                                    <span className="font-weight-bold text-medium pb-3">
                                        {trans('response_content')}
                                    </span>
                                    <span className="font-weight-light text-darker-gray pb-4">
                                        {trans('response_content_text')}
                                    </span>
                                    <div className="manage-textarea-container">
                                        <textarea
                                            value={data.body}
                                            placeholder={trans(
                                                'placeholders.type_response_message_here'
                                            )}
                                            onChange={e => this.onChange('body', e.target.value)}
                                        />
                                    </div>
                                    <span className="mt-2 small text-muted">
                                        {trans('available_variables_are_x', {
                                            x: CreateOrEditPage.availableVariables().join(', '),
                                        })}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>

                <CancelAndSaveFooter
                    submitTitle={isUpdate ? trans('save_changes') : trans('create')}
                    onSubmit={isUpdate ? this.updateCannedResponse : this.createCannedResponse}
                    onCancel={this.goBack}
                />
            </ManageLayout>
        );
    }
}

CreateOrEditPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(CreateOrEditPage);
