import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Navbar from '../../components/helpdesk/Navbar';
import { AppLayout } from '../../layouts';
import { Api, AuthService } from '../../services';
import CreateTicketBox from '../../components/helpdesk/CreateTicketBox';
import SidebarCreateTicket from '../../components/helpdesk/SidebarCreateTicket';
import NavigationMenu from '../../components/helpdesk/NavigationMenu';
import CompanyService from '../../services/CompanyService';
import router from '../../utils/router';
import helpers from '../../helpers';

class CreateTicketPage extends Component {
    constructor(props) {
        super(props);
        const { match } = props;

        this.state = {
            isLoading: false,
            companyId: match.params.companyId,
            company: null,
            companies: [],
            sources: [],
            data: {
                subject: '',
                body: '',
                customer_name: '',
                customer_email: '',
                customer_id: '',
                status: 'open',
                priority: 'medium',
                files: [],
                tags: [],
                users: [AuthService.user()],
                cc: [],
                bcc: [],
                fwd: [],
                source_id: 0,
            },
            showMenu: false,
            showCustomerNameField: false,
            showSources: false,
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchCompanies(), this.fetchChannels()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    componentDidUpdate() {
        const { sources, showSources, data } = this.state;

        if (!showSources && data.customer_email && helpers.isEmail(data.customer_email)) {
            if (!data.source_id && sources.length) {
                data.source_id = sources[0].id;
            }

            // eslint-disable-next-line
            this.setState({
                data,
                showSources: true,
            });
        } else if (showSources && (!data.customer_email || !helpers.isEmail(data.customer_email))) {
            // eslint-disable-next-line
            this.setState({
                showSources: false,
            });
        }
    }

    toggleMenu = () => {
        const { showMenu } = this.state;

        this.setState({
            showMenu: !showMenu,
        });
    };

    createTicket = e => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { data, company } = this.state;

        const {
            subject,
            body,
            customer_name: customerName,
            customer_email: customerEmail,
            customer_id: customerId,
            status,
            priority,
            files,
            tags,
            users,
            cc,
            bcc,
            fwd,
            source_id: sourceId,
        } = data;

        const formData = new FormData();

        formData.append('company_id', company.id);
        formData.append('subject', subject);
        formData.append('body', body);
        formData.append('status', status);
        formData.append('priority', priority);

        if (customerId) {
            formData.append('customer_id', customerId);
        } else {
            formData.append('customer_name', customerName);
            formData.append('customer_email', customerEmail);
        }

        if (customerEmail && helpers.isEmail(customerEmail) && sourceId) {
            formData.append('source_id', sourceId);
        }

        files.forEach(x => formData.append('files[]', x, x.name));
        users.forEach(x => formData.append('user_ids[]', x.id));

        tags.forEach(x => formData.append(x.id ? 'tag_ids[]' : 'tags[]', x.id || x.value));

        const appendValues = (arr, name) => arr.forEach(x => formData.append(`${name}[]`, x.value));

        appendValues(fwd, 'fwd');
        appendValues(cc, 'cc');
        appendValues(bcc, 'bcc');

        return Api.request({
            method: 'POST',
            url: 'tickets/create',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            const { history } = this.props;
            router.toCompany(history, company, `tickets/${response.data.data.id}`);
        });
    };

    goToSettingPage = settingItem => {
        const { company } = this.state;
        const { history } = this.props;

        CompanyService.goToSettingsPage(history, company, settingItem);
    };

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    fetchCompanies = () => {
        return Api.request({
            method: 'GET',
            url: 'companies',
            params: {
                counts: true,
            },
        }).then(response => {
            this.setState({
                companies: response.data.data,
            });
        });
    };

    fetchChannels = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: 'channels',
            params: {
                companyId,
                create_ticket: true,
            },
        }).then(response => {
            this.setState({
                sources: response.data.data,
            });
        });
    };

    searchUser = (query, setItems) => {
        const { company, data } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${company.slug}/users`,
            params: {
                search: query,
                user_id: data.users ? data.users.map(x => x.id) : [],
            },
        })
            .then(res => {
                setItems(res.data.data);
            })
            .catch(() => []);
    };

    searchPerson = (query, setItems) => {
        const { company } = this.state;
        return Api.request({
            method: 'GET',
            url: `search/${company.slug}/persons?search=${query}`,
        })
            .then(res => setItems(res.data.data))
            .catch(() => setItems([]));
    };

    searchTag = (query, setItems) => {
        const { company } = this.state;
        return CompanyService.tags(company, query)
            .then(res => {
                setItems(res.data.data);
                return res.data.data;
            })
            .catch(() => []);
    };

    searchCustomer = (query, setItems) => {
        const { data, company } = this.state;

        this.setState({
            data: {
                ...data,
                customer_email: query,
            },
        });

        return Api.request({
            method: 'GET',
            url: `companies/${company.slug}/customers?search=${query}`,
        })
            .then(res => {
                if (!res.data.data.length) {
                    this.setState({
                        showCustomerNameField: true,
                    });
                } else {
                    this.setState({
                        showCustomerNameField: false,
                    });
                }
                setItems(res.data.data);
            })
            .catch(() => []);
    };

    onChange = (field, value, isAdd) => {
        const { data } = this.state;

        if (data[field] instanceof Array) {
            const index = data[field].indexOf(value);

            if (isAdd) {
                if (index < 0) {
                    data[field].push(value);
                }
            } else if (index > -1) {
                data[field].splice(index, 1);
            }
            this.setState({
                data,
            });
        } else {
            this.setState({
                data: {
                    ...data,
                    [field]: value,
                },
            });
        }
    };

    onChangeFiles = e => {
        const { data } = this.state;

        data.files.push(...e.target.files);

        this.setState({
            data,
        });
    };

    onChangeCustomer = customer => {
        const { data } = this.state;

        const changedData = {};

        Object.keys(customer).forEach(key => {
            changedData[`customer_${key}`] = customer[key];
        });

        this.setState({
            data: {
                ...data,
                ...changedData,
            },
        });
    };

    onClear = field => {
        const { data } = this.state;
        let value;

        if (data[field] instanceof Array) {
            value = [];
        } else if (data[field] instanceof String) {
            value = '';
        } else {
            value = null;
        }

        this.setState({
            data: {
                ...data,
                [field]: value,
            },
        });
    };

    onChangeSource = sourceId => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                source_id: sourceId,
            },
        });
    };

    renderTopbar = () => {
        const { company, companies, viewType } = this.state;

        return (
            <Navbar
                viewType={viewType}
                role={company && company.role}
                companies={companies}
                showSearch={false}
                showViewTypes={false}
                onMenuClick={this.toggleMenu}
                onLogoClick={this.goBack}
            />
        );
    };

    goBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    renderTicket = () => {
        const { sources, data, company, showCustomerNameField, showSources } = this.state;

        return (
            <div>
                <div className="platform-wrapper fullmessage">
                    <div className="main-content container-fluid message-page">
                        <div className="container-fluid messages">
                            <CreateTicketBox
                                ticket={data}
                                company={company}
                                onCancelCreate={this.goBack}
                                onChangeSubject={e => this.onChange('subject', e.target.value)}
                                onChangeBody={e => this.onChange('body', e.target.value)}
                                onSubmit={this.createTicket}
                                onSearchPerson={this.searchPerson}
                                onChangeCustomer={e =>
                                    this.onChangeCustomer({
                                        name: e.target.value,
                                    })
                                }
                                clearCustomer={() => {
                                    this.onChangeCustomer({
                                        name: '',
                                        email: '',
                                        id: '',
                                    });
                                }}
                                onChangeReceiver={this.onChange}
                                onAttachFile={this.onChangeFiles}
                                onDeleteAttachment={file => this.onChange('files', file)}
                                onClear={this.onClear}
                                onSearchCustomer={this.searchCustomer}
                                onSelectCustomer={customer =>
                                    this.onChangeCustomer({
                                        ...customer,
                                        name: customer.name || customer.email,
                                        email: customer.email || customer.name,
                                    })
                                }
                                sources={sources}
                                showCustomerNameField={showCustomerNameField}
                                showSources={showSources}
                                onChangeSource={this.onChangeSource}
                            />
                        </div>
                    </div>
                </div>
                <SidebarCreateTicket
                    ticket={data}
                    onSearchUser={this.searchUser}
                    onSearchTag={this.searchTag}
                    onAddTag={tag => this.onChange('tags', tag, true)}
                    onDeleteTag={tag => this.onChange('tags', tag)}
                    onAddAssignment={user => this.onChange('users', user, true)}
                    onDeleteAssignment={user => this.onChange('users', user)}
                    onChangePriority={e => this.onChange('priority', e.target.value)}
                    onChangeStatus={e => this.onChange('status', e.target.value)}
                />
            </div>
        );
    };

    render() {
        const { company, isLoading, showMenu } = this.state;
        const { history } = this.props;

        return (
            <AppLayout isLoading={isLoading}>
                {company && this.renderTopbar()}
                {this.renderTicket()}
                {company && (
                    <NavigationMenu
                        active={showMenu}
                        company={company}
                        role={company.role}
                        closeMenu={this.toggleMenu}
                        goToSettingPage={this.goToSettingPage}
                        history={history}
                    />
                )}
            </AppLayout>
        );
    }
}

export default withRouter(CreateTicketPage);

CreateTicketPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
