import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ResizableBox } from 'react-resizable';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import Navbar from '../../components/helpdesk/Navbar';
import { AppLayout } from '../../layouts';
import { Api } from '../../services';
import Sidebar from '../../components/helpdesk/Sidebar';
import TicketList from '../../components/helpdesk/list/TicketList';
import QuickRespond from '../../components/helpdesk/QuickRespond';
import QuickRespondPopup from '../../components/helpdesk/QuickRespondPopup';
import TicketControl from '../../components/helpdesk/TicketControl';
import NavigationMenu from '../../components/helpdesk/NavigationMenu';
import { CustomerOrdersSidebar } from '../../components';
import definitions from '../../definitions';
import helpers from '../../helpers';
import trans from '../../localization';
import { emptyMessage } from '../../assets/images';
import TicketService from '../../services/TicketService';
import eventService from '../../services/EventService';
import ticketGrouper from '../../utils/ticketGrouper';
import storage from '../../utils/storage';
import router from '../../utils/router';
import CompanyService from '../../services/CompanyService';
import globals from '../../utils/globals';

class HomePage extends Component {
    constructor(props) {
        super(props);

        const { match, location } = props;
        const { companyId } = match.params;
        const filters = storage.getId(definitions.STORAGE_KEY_FILTERS, companyId, {});
        const sortOptions = storage.getId(definitions.STORAGE_KEY_SORT, companyId, {});
        const viewType = helpers.isLarge()
            ? HomePage.getFromState(
                  'viewType',
                  location,
                  storage.get(definitions.STORAGE_KEY_VIEW_TYPE, definitions.VIEW_TYPE_PILLS)
              )
            : definitions.VIEW_TYPE_TABLE;

        const resizeStates = HomePage.calculateResizeStates();
        resizeStates.canResize =
            viewType === definitions.VIEW_TYPE_TABLE ? false : resizeStates.canResize;

        this.state = {
            viewType,
            sortOptions: {
                sort: definitions.SORT_TYPE_LAST_MESSAGE,
                order: definitions.ORDER_BY_TYPES.DESC,
                ...sortOptions,
            },
            startDate: null,
            endDate: null,
            isLoading: true,
            isFetching: false,
            company: null,
            counts: {},
            totalCount: null,
            tickets: {},
            companyId: match.params.companyId,
            companies: [],
            filters: {
                users: [],
                sources: [],
                ticketTypes: [],
                statuses: HomePage.getFromState('statuses', location, [definitions.STATUS_OPEN]),
                priorities: HomePage.getFromState('priorities', location, []),
                tags: HomePage.getFromState('tags', location, []),
                customers: HomePage.getFromState('customers', location, []),
                ...filters,
            },
            openedTicket: null,
            openedTicketOrders: null,
            openedTicketMessages: [],
            openedTicketMimeTypes: [],
            openedTicketCanFetchOrders: false,
            openedTicketHasSignature: false,
            openedTicketLastAIResponseId: null,
            openedTicketLastAIResponse: '',
            selectedTicketIds: [],
            currentPage: 1,
            lastPage: null,
            currentMessagesPage: 1,
            lastMessagesPage: 1,
            showMenu: false,
            customerOrdersSidebarActive: false,
            showSidebar: true,
            ...resizeStates,
            search: null,
            quickRespondPopup: {
                active: HomePage.shouldUseQuickRespondPopup(
                    resizeStates && resizeStates.resizeWidth
                ),
                type: null,
                show: false,
            },
        };

        this.selectTicket = this.selectTicket.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeSortType = this.changeSortType.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.goToSettingPage = this.goToSettingPage.bind(this);
        this.renderBulkAction = this.renderBulkAction.bind(this);
        this.renderEmptyMessageForTickets = this.renderEmptyMessageForTickets.bind(this);
        this.setQuickRespondPopup = this.setQuickRespondPopup.bind(this);
        this.sendMessageFromPopup = this.sendMessageFromPopup.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchTickets(), this.fetchCompanies()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState(
                {
                    isLoading: false,
                },
                () => {
                    this.startListeningEvents();
                }
            );
        });

        this.fetchCounts();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        const { company } = this.state;
        if (company) {
            eventService.removeListener(definitions.EVENT_TICKET_MESSAGE_SENT);
            eventService.removeListener(definitions.EVENT_TICKET_UPDATED);
            eventService.removeListener(definitions.EVENT_TICKET_UPDATED_WITHOUT_LISTENER);
            eventService.removeListener(definitions.EVENT_TICKET_RECEIVED);
            eventService.removeListener(definitions.EVENT_TICKET_MESSAGE_RECEIVED);
            eventService.removeListener(definitions.EVENT_TICKET_MESSAGE_DELETED);
        }

        window.removeEventListener('resize', this.updateDimensions);
    }

    // eslint-disable-next-line
    startListeningEvents() {
        eventService.on(definitions.EVENT_TICKET_RECEIVED, ticket => {
            const { tickets, totalCount, ...others } = this.state;

            if (!TicketService.ticketIsExists(ticket, tickets)) {
                const newTickets = TicketService.addTicket(ticket, tickets);

                this.setState(
                    {
                        tickets: ticketGrouper(newTickets, others),
                        totalCount: totalCount ? totalCount + 1 : 1,
                    },
                    () => {
                        this.fetchCounts();
                    }
                );
            }
        });

        eventService.on(definitions.EVENT_TICKET_MESSAGE_SENT, ticketMessage => {
            const { openedTicket, openedTicketMessages } = this.state;

            // noinspection JSUnresolvedVariable
            if (
                openedTicket &&
                openedTicket.id === ticketMessage.ticket_id &&
                openedTicketMessages
            ) {
                this.setState({
                    openedTicketMessages: TicketService.updateMessage(
                        ticketMessage,
                        openedTicketMessages
                    ),
                });
            }
        });

        eventService.on(definitions.EVENT_TICKET_UPDATED, ticket => {
            this.processTicketUpdate(ticket);
        });

        eventService.on(definitions.EVENT_TICKET_UPDATED_WITHOUT_LISTENER, ticket => {
            this.processTicketUpdate(ticket, false);
        });

        eventService.on(definitions.EVENT_TICKET_MESSAGE_RECEIVED, ticketMessage => {
            const { openedTicket, openedTicketMessages } = this.state;

            // noinspection JSUnresolvedVariable
            if (
                openedTicket &&
                openedTicket.id === ticketMessage.ticket_id &&
                openedTicketMessages
            ) {
                this.setState({
                    openedTicketMessages: TicketService.addMessage(
                        ticketMessage,
                        openedTicketMessages
                    ),
                });
            }
        });

        eventService.on(definitions.EVENT_TICKET_MESSAGE_DELETED, ticketMessage => {
            const { openedTicket, openedTicketMessages } = this.state;

            if (
                openedTicket &&
                openedTicket.id === ticketMessage.ticket_id &&
                openedTicketMessages
            ) {
                this.setState({
                    openedTicketMessages: TicketService.deleteMessage(
                        ticketMessage,
                        openedTicketMessages
                    ),
                });
            }
        });
    }

    processTicketUpdate(ticket, fetchCounts = true) {
        const { tickets, openedTicket, ...others } = this.state;

        // noinspection JSUnresolvedVariable
        if (openedTicket && openedTicket.id === ticket.id) {
            this.setState({
                openedTicket: ticket,
            });
        }

        const newTickets = TicketService.ticketIsExists(ticket, tickets)
            ? TicketService.updateTicket(ticket, tickets)
            : TicketService.addTicket(ticket, tickets);

        this.setState({
            tickets: ticketGrouper(newTickets, others),
        });

        if (fetchCounts) {
            this.fetchCounts();
        }
    }

    static getFromState(key, location, value) {
        if (location.state && location.state[key] && location.state[key] !== undefined) {
            return location.state[key];
        }

        return value;
    }

    openLastTicket = () => {
        const { viewType } = this.state;
        if (globals.openedTicket && viewType !== definitions.VIEW_TYPE_TABLE) {
            this.openTicket(globals.openedTicket);
        }
    };

    getViewClass() {
        const { viewType } = this.state;

        switch (viewType) {
            case definitions.VIEW_TYPE_POPOUT:
                return 'medium-view';

            case definitions.VIEW_TYPE_TABLE:
                return 'small-view';

            default:
                return 'large-view';
        }
    }

    // eslint-disable-next-line react/sort-comp
    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
            params: {
                sources: true,
                users: true,
                ticketTypes: true,
            },
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
            this.openLastTicket();
        });
    }

    fetchCompanies() {
        return Api.request({
            method: 'GET',
            url: 'companies',
            params: {
                counts: true,
            },
        }).then(response => {
            this.setState({
                companies: response.data.data,
            });
        });
    }

    fetchCounts() {
        const { companyId, isFetching } = this.state;
        if (!isFetching) {
            this.setState({
                isFetching: true,
            });

            const filterParams = this.filterParams();
            return Api.request({
                method: 'GET',
                url: `companies/${companyId}/counts`,
                params: filterParams,
            })
                .then(response => {
                    this.setState({
                        counts: response.data.data,
                    });
                })
                .finally(() => {
                    this.setState({
                        isFetching: false,
                    });
                });
        }

        return null;
    }

    fetchCannedResponses() {
        const { openedTicket } = this.state;

        this.setState({
            isLoading: true,
        });

        return Api.request({
            method: 'GET',
            url: `cannedResponses/tickets/${openedTicket.id}`,
        })
            .then(response => {
                this.setState({
                    isLoading: false,
                });

                return response;
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    filterParams() {
        const { search, sortOptions, filters } = this.state;
        const { tags, users, sources, customers, ticketTypes, statuses, priorities } = filters;

        return {
            statuses,
            priorities,
            search,
            sortOptions,
            user_ids: users,
            source_ids: sources,
            tag_ids: tags.map(x => x.id || x),
            customer_ids: customers.map(x => x.id || x),
            ticket_type_ids: ticketTypes,
        };
    }

    static mapParams(params) {
        return {
            // tags: params.tag_ids,
            users: params.user_ids,
            sources: params.source_ids,
            // customers: params.customer_ids,
            ticketTypes: params.ticket_type_ids,
            statuses: params.statuses,
            priorities: params.priorities,
        };
    }

    getTickets() {
        return new Promise((resolve, reject) => {
            const { companyId, sortOptions, startDate, endDate, currentPage } = this.state;
            const filterParams = this.filterParams();

            let ticketCountPerPage = 15;

            if (currentPage === 1 && globals.lastScrolledTicketPage > 1) {
                ticketCountPerPage *= globals.lastScrolledTicketPage;
            }

            return Api.request({
                method: 'GET',
                url: `companies/${companyId}/tickets`,
                params: {
                    end_date: endDate,
                    start_date: startDate,
                    page: currentPage,
                    per_page: ticketCountPerPage,
                    ...filterParams,
                    ...sortOptions,
                },
            })
                .then(response => {
                    // noinspection JSUnresolvedVariable
                    this.setState(
                        {
                            currentPage:
                                ticketCountPerPage === 15
                                    ? response.data.meta.current_page
                                    : globals.lastScrolledTicketPage,
                            lastPage:
                                ticketCountPerPage === 15
                                    ? response.data.meta.last_page
                                    : Math.ceil(response.data.meta.total / 15),
                            totalCount: response.data.meta.total,
                        },
                        () => {
                            resolve(response);
                        }
                    );

                    storage.setId(
                        definitions.STORAGE_KEY_FILTERS,
                        companyId,
                        HomePage.mapParams(filterParams)
                    );

                    storage.setId(definitions.STORAGE_KEY_SORT, companyId, sortOptions);
                })
                .catch(reject);
        });
    }

    async fetchTickets() {
        return this.getTickets().then(response => {
            const currentState = this.state;

            this.setState({
                tickets: ticketGrouper(response.data.data, currentState),
            });
        });
    }

    updateTickets() {
        return new Promise((resolve, reject) => {
            this.setState(
                {
                    isLoading: true,
                    currentPage: 1,
                    lastPage: 1,
                },
                () => {
                    this.fetchTickets()
                        .then(response => {
                            this.fetchCounts();
                            resolve(response);
                        })
                        .catch(reject)
                        .finally(() => {
                            this.setState({
                                isLoading: false,
                            });
                        });
                }
            );
        });
    }

    updateTicket(ticketId, key, value) {
        let request = Promise.resolve();

        switch (key) {
            case definitions.KEY_STATUS:
                request = TicketService.updateStatus(ticketId, value);
                break;

            case definitions.KEY_PRIORITY:
                request = TicketService.updatePriority(ticketId, value);
                break;

            case definitions.KEY_TICKET_TYPE_ID:
                request = TicketService.updateTicketType(ticketId, value);
                break;

            default:
                break;
        }

        this.setState({
            isLoading: true,
        });

        return request
            .then(response => {
                if (response && response.data && response.data.data) {
                    const { tickets, ...others } = this.state;
                    const updatedTickets = TicketService.updateTicket(response.data.data, tickets);

                    this.setState({
                        openedTicket: response.data.data,
                        tickets: ticketGrouper(updatedTickets, others),
                    });

                    if (
                        document.activeElement &&
                        [...document.activeElement.classList].indexOf('dc-popover') > -1
                    ) {
                        document.activeElement.blur();
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    changeViewType(name) {
        this.setState(
            {
                viewType: name,
                canResize:
                    name === definitions.VIEW_TYPE_TABLE
                        ? false
                        : HomePage.calculateResizeStates().canResize,
            },
            () => {
                storage.set(definitions.STORAGE_KEY_VIEW_TYPE, name);
            }
        );
    }

    onFilter(key, value) {
        const { filters } = this.state;
        this.setState(
            {
                filters: {
                    ...filters,
                    [key]: value,
                },
            },
            this.updateTickets
        );
    }

    search(query) {
        this.setState(
            {
                search: query,
            },
            () => this.updateTickets()
        );
    }

    searchCustomer(customer) {
        const { filters } = this.state;
        const { customers } = filters;
        const index = customers.indexOf(customer);

        if (index > -1) {
            customers.splice(index, 1);
        } else {
            customers.push(customer);
        }

        this.setState(
            {
                filters: {
                    ...filters,
                    customers,
                },
            },
            () => this.updateTickets()
        );
    }

    openTicket(ticket, e) {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        const { viewType } = this.state;

        // eslint-disable-next-line
        if (this.state.openedTicket && this.state.openedTicket.id === ticket.id) {
            this.closeTicket();
            return;
        }

        // setTimeout(() => {
        this.setState({
            isLoading: true,
            lastMessagesPage: 1,
            currentMessagesPage: 1,
        });
        // }, 200);

        globals.openedTicket = ticket;

        if (viewType === definitions.VIEW_TYPE_TABLE || !helpers.isLarge()) {
            this.openFullScreen(ticket);
        } else {
            this.setState(
                {
                    openedTicket: ticket,
                },
                () => {
                    Api.request({
                        method: 'POST',
                        url: 'ticketViewers',
                        data: {
                            ticket_id: ticket.id,
                        },
                    });

                    this.getMessages()
                        .then(response => {
                            this.setState(
                                {
                                    openedTicketMessages: response.data.data,
                                },
                                () => {
                                    const { openedTicket } = this.state;
                                    if (!openedTicket) return;

                                    Api.request(`tickets/${openedTicket.id}/detail`, true).then(
                                        res => {
                                            const {
                                                mimeTypes: openedTicketMimeTypes,
                                                canFetchOrders: openedTicketCanFetchOrders,
                                                hasSignature: openedTicketHasSignature,
                                                lastAIResponse: openedTicketLastAIResponse,
                                            } = res.data.data;

                                            this.setState({
                                                openedTicketMimeTypes,
                                                openedTicketCanFetchOrders,
                                                openedTicketHasSignature,
                                                openedTicketLastAIResponseId: openedTicket.id,
                                                openedTicketLastAIResponse,
                                            });
                                        }
                                    );
                                }
                            );
                        })
                        .catch(() => this.closeTicket())
                        .finally(() => {
                            this.setState({
                                isLoading: false,
                            });
                        });
                }
            );
        }
    }

    onFetchOrders() {
        this.setState({
            isLoading: true,
        });

        const { isLoading, openedTicket, company } = this.state;
        if (isLoading || !openedTicket) {
            return null;
        }

        return Api.request({
            url: `companies/${company.slug}/orders/${openedTicket.customer.id}`,
            method: 'GET',
        })
            .then(res => {
                this.setState({
                    openedTicketOrders: res.data.data,
                    customerOrdersSidebarActive: true,
                    showSidebar: false,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    getMessages() {
        return new Promise((resolve, reject) => {
            const { openedTicket, currentMessagesPage } = this.state;

            return Api.request({
                method: 'GET',
                url: `tickets/${openedTicket.id}/messages`,
                params: {
                    page: currentMessagesPage,
                },
            })
                .then(response => {
                    // noinspection JSUnresolvedVariable
                    this.setState(
                        {
                            lastMessagesPage: response.data.meta.last_page,
                            currentMessagesPage: response.data.meta.current_page,
                        },
                        () => {
                            resolve(response);
                        }
                    );
                })
                .catch(reject);
        });
    }

    openFullScreen(ticket) {
        const { company, viewType, filters } = this.state;
        const { statuses, priorities } = filters;
        const { history } = this.props;

        router.toCompany(history, company, `tickets/${ticket.id}`, {
            viewType,
            statuses,
            priorities,
        });
    }

    setQuickRespondPopup(values = {}) {
        const { quickRespondPopup } = this.state;

        this.setState({
            quickRespondPopup: { ...quickRespondPopup, ...values },
        });
    }

    closeTicket() {
        globals.openedTicket = null;
        this.setState({
            isLoading: false,
            openedTicket: null,
            openedTicketMessages: [],
            openedTicketMimeTypes: [],
            openedTicketCanFetchOrders: false,
            customerOrdersSidebarActive: false,
            showSidebar: true,
        });
    }

    updateOpenedTicket(ticket) {
        const { openedTicket } = this.state;

        if (openedTicket) {
            this.setState({
                openedTicket: ticket,
            });
        }
    }

    updateOpenedTicketMessages(messages) {
        return new Promise(resolve => {
            this.setState(
                {
                    openedTicketMessages: messages,
                },
                () => {
                    resolve();
                }
            );
        });
    }

    sendMessage(ticket, formData) {
        return new Promise((resolve, reject) => {
            this.setState({
                isLoading: true,
                openedTicketLastAIResponse: '',
                openedTicketLastAIResponseId: null,
            });

            TicketService.sendMessage(ticket.id, formData)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        });
    }

    sendMessageFromPopup(ticket, formData) {
        const { quickRespondPopup } = this.state;
        return this.sendMessage(ticket, formData).then(() => {
            this.setState({
                quickRespondPopup: {
                    ...quickRespondPopup,
                    show: false,
                },
            });
        });
    }

    goToNextPage() {
        return new Promise((resolve, reject) => {
            const { currentPage, lastPage, isLoading } = this.state;
            if (currentPage >= lastPage || isLoading) {
                resolve();
                return;
            }

            const nextPage = currentPage + 1;

            this.setState(
                {
                    isLoading: true,
                    currentPage: nextPage,
                },
                () => {
                    this.getTickets()
                        .then(response => {
                            const newTickets = response.data.data;
                            globals.lastScrolledTicketPage = response.data.meta.current_page;

                            if (helpers.isNotEmpty(newTickets)) {
                                const { tickets: prevTicketGroups, ...others } = this.state;
                                const prevTickets = TicketService.getTickets(prevTicketGroups);
                                const tickets = [...prevTickets, ...newTickets];

                                this.setState(
                                    {
                                        tickets: ticketGrouper(tickets, others),
                                    },
                                    resolve
                                );
                            } else {
                                resolve();
                            }
                        })
                        .catch(reject)
                        .finally(() => {
                            this.setState({
                                isLoading: false,
                            });
                        });
                }
            );
        });
    }

    goToNextMessagesPage() {
        return new Promise((resolve, reject) => {
            const { currentMessagesPage, lastMessagesPage, isLoading } = this.state;

            if (currentMessagesPage >= lastMessagesPage || isLoading) {
                resolve();
                return;
            }

            const nextPage = currentMessagesPage + 1;

            this.setState(
                {
                    isLoading: true,
                    currentMessagesPage: nextPage,
                },
                () => {
                    this.getMessages()
                        .then(response => {
                            const newMessages = response.data.data;

                            if (newMessages.length > 0) {
                                const { openedTicketMessages: currentMessages } = this.state;

                                const messages = [...currentMessages, ...newMessages];

                                this.setState(
                                    {
                                        openedTicketMessages: messages,
                                    },
                                    resolve
                                );
                            }
                        })
                        .catch(reject)
                        .finally(() => {
                            this.setState({
                                isLoading: false,
                            });
                        });
                }
            );
        });
    }

    goToSettingPage(settingItem) {
        const { company } = this.state;
        const { history } = this.props;

        CompanyService.goToSettingsPage(history, company, settingItem);
    }

    selectTicket(ticketId) {
        const { selectedTicketIds } = this.state;

        const index = selectedTicketIds.indexOf(ticketId);

        if (index > -1) {
            selectedTicketIds.splice(index, 1);
        } else {
            selectedTicketIds.push(ticketId);
        }

        this.setState({
            selectedTicketIds,
        });

        // TODO: show the panel to update ticket
    }

    // eslint-disable-next-line react/sort-comp
    onSelectAll(select) {
        const { tickets } = this.state;

        const flatTickets = helpers.toFlatArray(tickets);

        this.setState({
            selectedTicketIds: select === true ? flatTickets.map(x => x.id) : [],
        });
    }

    changeSortType(sortOptions) {
        this.setState(
            {
                sortOptions,
            },
            () => this.updateTickets()
        );
    }

    changeDate(startDate, endDate) {
        this.setState(
            {
                startDate,
                endDate,
            },
            () => this.updateTickets()
        );
    }

    toggleMenu() {
        const { showMenu } = this.state;

        this.setState({
            showMenu: !showMenu,
        });
    }

    onBulkAction(value) {
        const { selectedTicketIds } = this.state;

        const formData = new FormData();

        formData.append('status', value);

        selectedTicketIds.forEach(x => formData.append('tickets[]', x));

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: 'tickets/status/bulk',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                this.setState(
                    {
                        selectedTicketIds: [],
                    },
                    () => {
                        this.fetchTickets();
                    }
                );
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    static calculateResizeStates() {
        const windowWidth = window.innerWidth;
        const resizeMinWidth = HomePage.resizeMinWidth(windowWidth);
        const resizeMaxWidth = HomePage.resizeMaxWidth(windowWidth);
        const resizeWidth = storage.get(definitions.STORAGE_KEY_RESIZE_WIDTH, resizeMinWidth);
        const resizeHeight = window.innerHeight - 62;

        const fixedResizeWidth = resizeWidth < resizeMinWidth ? resizeMinWidth : resizeWidth;

        const resizeClass = HomePage.getClass(resizeWidth);

        return {
            windowWidth,
            resizeMinWidth,
            resizeMaxWidth,
            resizeWidth: fixedResizeWidth,
            resizeHeight,
            resizeClass,
            canResize: helpers.isXLarge() && resizeMaxWidth > resizeMinWidth,
        };
    }

    updateDimensions = () => {
        const resizeStates = HomePage.calculateResizeStates();
        const { viewType } = this.state;
        resizeStates.canResize =
            viewType === definitions.VIEW_TYPE_TABLE ? false : resizeStates.canResize;

        this.setState(resizeStates);
    };

    onResize = (e, data) => {
        if (data && data.size && data.size.width) {
            storage.set(definitions.STORAGE_KEY_RESIZE_WIDTH, data.size.width);

            const { resizeWidth, quickRespondPopup } = this.state;

            if (
                HomePage.shouldUseQuickRespondPopup(resizeWidth) !==
                    HomePage.shouldUseQuickRespondPopup(data.size.width) ||
                HomePage.getClass(resizeWidth) !== HomePage.getClass(data.size.width)
            ) {
                this.setState({
                    resizeWidth: data.size.width,
                    resizeClass: HomePage.getClass(data.size.width),
                    quickRespondPopup: {
                        ...quickRespondPopup,
                        active: HomePage.shouldUseQuickRespondPopup(data.size.width),
                    },
                });
            }
        }
    };

    static shouldUseQuickRespondPopup(width) {
        if (window.innerWidth - 258 - width > 613) {
            return false;
        }
        return true;
    }

    static getClass(width) {
        if (width < 495) return 'homepage-xs';

        if (width < 515) return 'homepage-sm';

        if (width < 645) return 'homepage-md';

        if (width < 835) return 'homepage-lg';

        if (width < 915) return 'homepage-xl';

        return '';
    }

    static resizeMinWidth() {
        return 380;
    }

    static resizeMaxWidth(windowWidth) {
        return windowWidth - 669;
    }

    renderEmptyMessageForTickets() {
        const { search } = this.state;
        return (
            <div className="d-flex empty justify-content-center text-center w-100">
                <div className="d-flex empty-graphic flex-column justify-content-center">
                    <h2 className="text-center">
                        {search ? trans('no_tickets_found') : trans('its_empty_over_here')}
                    </h2>
                    <img src={emptyMessage} alt="Empty message" className="mt-3" />
                </div>
            </div>
        );
    }

    static renderEmptyMessage() {
        return (
            <div className="d-flex empty justify-content-center text-center w-100">
                <div className="d-flex empty-graphic flex-column justify-content-center">
                    <h2 className="text-center">{trans('its_empty_over_here')}</h2>
                    <img src={emptyMessage} alt="Empty message" className="mt-3" />
                </div>
            </div>
        );
    }

    static getQuickrespondWidth() {
        const quickrespond = document.querySelector('.quickrespond');
        return quickrespond && quickrespond.clientWidth;
    }

    renderBulkAction(x, i) {
        return (
            <button
                key={`bulk-action-${i}`}
                onClick={() => this.onBulkAction(x.value)}
                type="button"
                className="btn btn-link item"
                value={x.value}
            >
                {x.title}
            </button>
        );
    }

    renderResizableContent(children) {
        const { canResize } = this.state;
        if (canResize) {
            const {
                resizeWidth,
                resizeHeight,
                resizeMinWidth,
                resizeMaxWidth,
                resizeClass,
            } = this.state;

            return (
                <ResizableBox
                    className={`homepage pr-0 col-auto ${resizeClass}`}
                    width={resizeWidth}
                    height={resizeHeight}
                    onResize={this.onResize}
                    minConstraints={[resizeMinWidth, resizeHeight]}
                    maxConstraints={[resizeMaxWidth, resizeHeight]}
                    axis="x"
                >
                    {children}
                </ResizableBox>
            );
        }

        const { customerOrdersSidebarActive } = this.state;
        return (
            <div
                className={cx(
                    'col homepage pr-0',
                    customerOrdersSidebarActive && 'col-lg-2 col-xl'
                )}
            >
                {children}
            </div>
        );
    }

    renderQuickResponse(children) {
        const { viewType, canResize } = this.state;
        if (viewType === definitions.VIEW_TYPE_TABLE) {
            return <></>;
        }

        return (
            <div
                className={cx(
                    'px-0 d-lg-flex d-none',
                    canResize ? 'col-auto col-xl flex-grow-1' : 'col'
                )}
            >
                {children}
            </div>
        );
    }

    renderTickets() {
        const {
            tickets,
            company,
            openedTicket,
            openedTicketOrders,
            openedTicketMessages,
            openedTicketMimeTypes,
            openedTicketCanFetchOrders,
            openedTicketHasSignature,
            openedTicketLastAIResponse,
            openedTicketLastAIResponseId,
            selectedTicketIds,
            lastPage,
            currentPage,
            currentMessagesPage,
            lastMessagesPage,
            sortOptions,
            customerOrdersSidebarActive,
            canResize,
            showSidebar,
            quickRespondPopup,
        } = this.state;

        const viewClass = this.getViewClass();
        const statuses = helpers.getStatuses();
        const noSidebar = canResize ? false : !showSidebar;
        const { history } = this.props;

        return (
            <div className={cx('platform-wrapper', { 'ml-0': noSidebar })}>
                <div
                    className={cx(
                        'main-content container-fluid main-menu',
                        viewClass,
                        customerOrdersSidebarActive && 'has-customer-orders-sidebar'
                    )}
                >
                    <div className="container-fluid messages px-0">
                        <div className="d-flex">
                            {this.renderResizableContent(
                                <>
                                    <TicketControl
                                        onSelectAll={this.onSelectAll}
                                        isAllSelected={
                                            selectedTicketIds.length ===
                                                helpers.toFlatArray(tickets).length &&
                                            helpers.toFlatArray(tickets).length > 0
                                        }
                                        sortOptions={sortOptions}
                                        onChangeSortBy={this.changeSortType}
                                        onChangeDate={this.changeDate}
                                    />
                                    <div
                                        className={cx('tickets mb-2', {
                                            'tickets-bulk-actions': selectedTicketIds.length > 0,
                                        })}
                                        role="button"
                                        tabIndex="-1"
                                        onClick={() => this.closeTicket()}
                                    >
                                        {helpers.isEmpty(tickets) ? (
                                            this.renderEmptyMessageForTickets()
                                        ) : (
                                            <TicketList
                                                tickets={tickets}
                                                company={company}
                                                openedTicket={openedTicket}
                                                onOpenTicket={(t, e) => this.openTicket(t, e)}
                                                selectedTicketIds={selectedTicketIds}
                                                onSelectTicket={this.selectTicket}
                                                onNextPage={p => this.goToNextPage(p)}
                                                isLastPage={currentPage >= lastPage}
                                            />
                                        )}
                                    </div>
                                    <PerfectScrollbar
                                        className={cx('bulk-actions-container slim-gray', {
                                            show: selectedTicketIds.length > 0,
                                        })}
                                    >
                                        <div className="bulk-actions">
                                            <div className="item">{trans('bulk_action')}</div>
                                            {statuses.map(this.renderBulkAction)}
                                        </div>
                                    </PerfectScrollbar>
                                </>
                            )}
                            {this.renderQuickResponse(
                                <>
                                    <QuickRespond
                                        ticket={openedTicket}
                                        mimeTypes={openedTicket ? openedTicketMimeTypes : []}
                                        company={company}
                                        messages={openedTicketMessages}
                                        sendMessage={(ticket, message) =>
                                            this.sendMessage(ticket, message)
                                        }
                                        updateTicket={ticket => this.updateOpenedTicket(ticket)}
                                        updateMessages={m => this.updateOpenedTicketMessages(m)}
                                        getCannedResponses={() => this.fetchCannedResponses()}
                                        onOpenFullScreen={ticket => this.openFullScreen(ticket)}
                                        onClickCustomer={customer => this.searchCustomer(customer)}
                                        isLastPage={currentMessagesPage >= lastMessagesPage}
                                        onNextPage={() => this.goToNextMessagesPage()}
                                        canFetchOrders={openedTicketCanFetchOrders}
                                        hasSignature={openedTicketHasSignature}
                                        lastAIResponseId={openedTicketLastAIResponseId}
                                        lastAIResponse={openedTicketLastAIResponse}
                                        onFetchOrders={() => this.onFetchOrders()}
                                        changeStatus={status =>
                                            this.updateTicket(
                                                openedTicket.id,
                                                definitions.KEY_STATUS,
                                                status.value
                                            )
                                        }
                                        changePriority={priority =>
                                            this.updateTicket(
                                                openedTicket.id,
                                                definitions.KEY_PRIORITY,
                                                priority.value
                                            )
                                        }
                                        changeTicketType={ticketType =>
                                            this.updateTicket(
                                                openedTicket.id,
                                                definitions.KEY_TICKET_TYPE_ID,
                                                ticketType.id
                                            )
                                        }
                                        useQuickRespondPopup={quickRespondPopup.active}
                                        openQuickRespondPopup={type => {
                                            this.setQuickRespondPopup({ type, show: true });
                                        }}
                                        isQuickRespondPopupOpen={quickRespondPopup.show}
                                        history={history}
                                    />

                                    <CustomerOrdersSidebar
                                        absolute
                                        ticket={openedTicket}
                                        orders={openedTicketOrders}
                                        active={customerOrdersSidebarActive}
                                        onClose={() =>
                                            this.setState({
                                                customerOrdersSidebarActive: false,
                                                showSidebar: true,
                                            })
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            company,
            filters,
            companies,
            viewType,
            isLoading,
            counts,
            showMenu,
            totalCount,
            showSidebar,
            canResize,
            quickRespondPopup,
            openedTicketMimeTypes,
            openedTicket,
        } = this.state;

        const { history } = this.props;

        return (
            <AppLayout isLoading={isLoading}>
                <Navbar
                    companyId={company && company.slug}
                    companies={companies}
                    viewType={viewType}
                    role={company && company.role}
                    onSearch={query => this.search(query)}
                    onChangeViewType={name => this.changeViewType(name)}
                    onMenuClick={this.toggleMenu}
                />
                <Sidebar
                    company={company}
                    counts={counts}
                    totalCount={totalCount}
                    filters={filters}
                    onFilter={this.onFilter}
                    onSearch={query => this.search(query)}
                    show={canResize ? true : showSidebar}
                />

                {this.renderTickets()}

                {company && (
                    <NavigationMenu
                        active={showMenu}
                        company={company}
                        role={company.role}
                        closeMenu={this.toggleMenu}
                        goToSettingPage={this.goToSettingPage}
                        history={history}
                    />
                )}

                {quickRespondPopup.active && (
                    <QuickRespondPopup
                        ticket={openedTicket}
                        onSendMessage={this.sendMessageFromPopup}
                        show={quickRespondPopup.show}
                        type={quickRespondPopup.type}
                        onClose={() => this.setQuickRespondPopup({ show: false })}
                        mimeTypes={openedTicketMimeTypes}
                        offsetRight={HomePage.getQuickrespondWidth()}
                    />
                )}
            </AppLayout>
        );
    }
}

export default withRouter(HomePage);

HomePage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
