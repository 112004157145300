import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import Information from './Information';
import trans from '../../../localization';
import { activityInvite } from '../../../assets/images';

const ActivityInvitationReceived = props => {
    const { activity, acceptInvitation, containerClassName, onMoreClick } = props;

    const { user, invitation } = activity;

    const activityText = trans('x_invited_you_to', { name: user && user.name });
    const buttonText = invitation.accepted ? trans('accepted') : trans('accept');

    return (
        <Container activity={activity} className={containerClassName} onMoreClick={onMoreClick}>
            <Information
                activity={activity}
                onClick={acceptInvitation}
                buttonText={buttonText}
                activityImage={activityInvite}
                activityText={activityText}
                buttonDisabled={invitation.accepted}
                activityTextClassName="mb-2"
            />
        </Container>
    );
};

export default ActivityInvitationReceived;

ActivityInvitationReceived.propTypes = {
    activity: PropTypes.object.isRequired,
    acceptInvitation: PropTypes.func,
    containerClassName: PropTypes.string,
    onMoreClick: PropTypes.func,
};

ActivityInvitationReceived.defaultProps = {
    acceptInvitation: () => {},
    containerClassName: null,
    onMoreClick: null,
};
