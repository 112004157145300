import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Api } from '../../services';
import trans from '../../localization';
import { DateTime } from '../index';
import MainHomePage from '../../pages/helpdesk/HomePage';

export default class ChannelLogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logs: [],
            collapsed: true,
            isLoading: false,
        };

        this.refresh = this.refresh.bind(this);
        this.collapse = this.collapse.bind(this);
    }

    componentDidMount() {
        this.fetchChannelLogs();
    }

    fetchChannelLogs() {
        const { channelId } = this.props;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'GET',
            url: `channels/${channelId}/logs`,
        })
            .then(response => {
                this.setState({
                    logs: response.data.data,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    collapse() {
        const { collapsed } = this.state;

        this.setState({
            collapsed: !collapsed,
        });
    }

    refresh() {
        this.fetchChannelLogs();
    }

    render() {
        const { collapsed, logs, isLoading } = this.state;

        return (
            <div className="bg-lightest-gray p-4 mb-4 border-radius-4 mt-5 border-radius-10">
                <div className="font-weight-bold text-medium d-flex justify-content-between">
                    <span>{trans('channel_logs')}</span>
                    <div>
                        {!collapsed && (
                            <button
                                type="button"
                                className="btn btn-collapse mr-3"
                                onClick={this.refresh}
                                disabled={isLoading}
                            >
                                <i className={`icon fa fa-redo-alt ${isLoading && 'fa-spin'}`} />
                            </button>
                        )}

                        <button
                            type="button"
                            className={`btn btn-collapse ${collapsed ? 'collapsed' : ''}`}
                            onClick={this.collapse}
                        >
                            <i className="icon fa fa-chevron-down" />
                        </button>
                    </div>
                </div>
                {!collapsed &&
                    (logs.length ? (
                        <PerfectScrollbar className="list-group black max-height-280 z-index-1 mt-4">
                            {logs.map(log => (
                                <div key={`log-${log.id}`} className="list-group-item">
                                    <div className="row">
                                        <div className="align-items-center col-6 col-md-3 d-flex justify-content-start">
                                            <DateTime
                                                datetime={log.date}
                                                format="DD MMMM YYYY hh:mm:ss A"
                                            />
                                        </div>
                                        <div className="align-items-center col-6 col-md-2 d-flex justify-content-center">
                                            <span className={`text-${log.color}`}>
                                                {trans(`log_types.${log.type}`)}
                                            </span>
                                        </div>
                                        <div className="col-12 col-md-7">{log.content}</div>
                                    </div>
                                </div>
                            ))}
                        </PerfectScrollbar>
                    ) : (
                        MainHomePage.renderEmptyMessage()
                    ))}
            </div>
        );
    }
}

ChannelLogs.propTypes = {
    channelId: PropTypes.any.isRequired,
};
