import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import { brandLogo, menu } from '../../assets/images';
import { AuthService } from '../../services';
import trans from '../../localization';
import UserIcon from './UserIcon';
import definitions from '../../definitions';
import Popover from '../Popover';
import router from '../../utils/router';

class Navbar extends Component {
    constructor(props, context) {
        super(props, context);

        const { viewType } = this.props;

        this.viewTypes = [
            {
                name: definitions.VIEW_TYPE_PILLS,
                icon: 'fas fa-columns',
            },
            {
                name: definitions.VIEW_TYPE_TABLE,
                icon: 'fad fa-stream',
            },
        ];

        this.state = {
            viewType,
            query: '',
            user: AuthService.user(),
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        const { query } = this.state;
        const { onSearch } = this.props;

        onSearch(query);
    }

    onChange(event) {
        this.setState({
            query: event.target.value,
        });
    }

    clearFilters = () => {
        this.setState(
            {
                query: '',
            },
            () => {
                const { onSearch } = this.props;
                onSearch('');
            }
        );
    };

    changeViewType(name) {
        this.setState({
            viewType: name,
        });

        const { onChangeViewType } = this.props;
        onChangeViewType(name);
    }

    renderViewType(viewType, key) {
        // eslint-disable-next-line
        const stateViewType = this.state.viewType;
        return (
            // eslint-disable-next-line
            <li
                onClick={() => this.changeViewType(viewType.name)}
                className={cx('nav-item viewswitch', { active: stateViewType === viewType.name })}
                key={key}
            >
                <i className={viewType.icon} />
                <span className="text-uppercase">{trans(`view_types.${viewType.name}`)}</span>
            </li>
        );
    }

    render() {
        const {
            companyId,
            companies,
            role,
            showSearch,
            showViewTypes,
            isAway,
            onMenuClick,
            onLogoClick,
        } = this.props;
        const { user, query } = this.state;

        return (
            <nav className="navbar navbar-expand-lg navbar-light search-bar px-4">
                <button className="btn mr-4 px-0" type="button" onClick={onMenuClick}>
                    <img src={menu} alt="Menu" />
                </button>

                {onLogoClick ? (
                    <button
                        type="button"
                        onClick={() => onLogoClick()}
                        className="bg-white border-0 logo p-0"
                    >
                        <img src={brandLogo} alt="Despatch Cloud" />
                    </button>
                ) : (
                    <Link to="/" className="logo">
                        <img src={brandLogo} alt="Despatch Cloud" />
                    </Link>
                )}

                {showSearch && (
                    <div className="search mx-4">
                        <form onSubmit={this.onSubmit} className="form-inline">
                            <button
                                className="btn d-flex align-items-center"
                                onClick={this.clearFilters}
                                type="button"
                            >
                                <i className="fas fa-times" hidden={query === ''} />
                                <i className="fas fa-search" hidden={query !== ''} />
                            </button>
                            <input
                                className="form-control mr-sm-2 search-input"
                                onChange={this.onChange}
                                placeholder={trans('placeholders.search_for_ticket')}
                                aria-label={trans('search')}
                                value={query}
                            />
                            <button className="btn btn-outline-success" hidden type="submit">
                                {trans('search')}
                            </button>
                        </form>
                    </div>
                )}

                <div className="options">
                    <ul className="navbar-nav mr-auto">
                        {showViewTypes &&
                            this.viewTypes.map((viewType, key) =>
                                this.renderViewType(viewType, key)
                            )}
                        <li className="nav-item active-user">
                            <Popover
                                align="right"
                                className="company-popover"
                                label={
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#test"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span className="thisuser">
                                            {user.name}
                                            {role && <span>{trans(`roles.${role}`)}</span>}
                                        </span>
                                        <UserIcon user={user} isAway={isAway} />
                                    </a>
                                }
                            >
                                <div className="d-flex flex-column pt-2 px-1">
                                    <div className="pb-2 border-bottom-control">
                                        <PerfectScrollbar className="slim-gray companies">
                                            {companies.map(x => (
                                                <Link
                                                    key={`my-company-${x.id}`}
                                                    className="company"
                                                    onClick={() => {
                                                        window.location.href = router.pathCompany(
                                                            x
                                                        );
                                                    }}
                                                    to={router.pathCompany(x)}
                                                >
                                                    <div className="d-flex flex-column justify-content-center align-items-start ml-2">
                                                        <span className="name font-weight-bold">
                                                            {x.name}
                                                        </span>
                                                        <span className="text-xsmall font-weight-light">
                                                            {trans(`roles.${x.role}`)}
                                                        </span>
                                                    </div>
                                                    {x.count && (
                                                        <>
                                                            <div className="text-xsmall font-weight-light">
                                                                <span className="border-radius-4 bg-light-gray p-1 mr-2">
                                                                    {x.count}
                                                                </span>
                                                                <span>{trans('new')}</span>
                                                            </div>
                                                        </>
                                                    )}
                                                </Link>
                                            ))}
                                        </PerfectScrollbar>
                                    </div>
                                    <div className="d-flex text-xsmall justify-content-between align-items-center">
                                        <Link
                                            className="link-account w-50"
                                            to={{
                                                pathname: '/myAccount',
                                                state: {
                                                    companyId,
                                                },
                                            }}
                                        >
                                            {trans('my_account')}
                                        </Link>
                                        <span className="text-border-color">{'|'}</span>
                                        <Link className="link-account red w-50" to="/auth/signOut">
                                            {trans('logout')}
                                        </Link>
                                    </div>
                                </div>
                            </Popover>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default withRouter(Navbar);

Navbar.propTypes = {
    companyId: PropTypes.string,
    companies: PropTypes.array,
    role: PropTypes.string,
    showSearch: PropTypes.bool,
    showViewTypes: PropTypes.bool,
    viewType: PropTypes.string,
    onChangeViewType: PropTypes.func,
    onSearch: PropTypes.func,
    isAway: PropTypes.bool,
    onMenuClick: PropTypes.func,
    onLogoClick: PropTypes.func,
};

Navbar.defaultProps = {
    companyId: null,
    companies: [],
    role: null,
    showSearch: true,
    showViewTypes: true,
    viewType: 'type-2',
    isAway: false,
    onChangeViewType: () => {},
    onSearch: () => {},
    onMenuClick: () => {},
    onLogoClick: null,
};
