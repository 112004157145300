import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CarouselPane, StepCarousel } from '../../../components';
import { FormButton } from '../../../elements';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';
import trans from '../../../localization';
import router from '../../../utils/router';

class FinishSetupEmailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            user: AuthService.user(),
            company: null,
            channel: null,
        };
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchChannel()]).finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany = () => {
        const { match } = this.props;

        return Api.request({
            method: 'GET',
            url: `companies/${match.params.companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    fetchChannel = () => {
        const { match } = this.props;

        return Api.request({
            method: 'GET',
            url: `channels/${match.params.channelId}`,
        }).then(response => {
            this.setState({
                channel: response.data.data,
            });
        });
    };

    goToTickets = () => {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company);
    };

    render() {
        const { user, isLoading, company, channel } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane
                        className="d-flex flex-column justify-content-center"
                        scroll
                        middle
                        center
                    >
                        {channel && (
                            <div className="text-center">
                                <div className={`verification-sign bg-color-${channel.color}`}>
                                    <i className={`fa fas fa-${channel.icon}`} />
                                </div>
                                <div className="mt-4">
                                    <h4 className="m-0">
                                        <strong>{channel.name}</strong>
                                    </h4>
                                    <div className="mt-4">
                                        <div>{trans('youre_all_setup')}</div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <FormButton
                                        type="button"
                                        className="manage-btn"
                                        text={trans('go_to_tickets')}
                                        onClick={this.goToTickets}
                                    />
                                </div>
                                <div className="mt-2 text-light-gray">{trans('or')}</div>
                                <div className="mt-2">
                                    <Link
                                        to={router.pathCompany(company, 'channels/create')}
                                        className="text-dark"
                                    >
                                        {trans('setup_another_source')}
                                    </Link>
                                </div>
                            </div>
                        )}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(FinishSetupEmailPage);

FinishSetupEmailPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
