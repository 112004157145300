// Fix for content sizing with vh
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
// Use calculation below for 100vh
// 100vh = calc(var(--vh, 1vh) * 100);

function setViewportUnit() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function iPadControl() {
    const ua = navigator.userAgent;
    return (
        /iPad/i.test(ua) ||
        /iPhone OS 3_1_2/i.test(ua) ||
        /iPhone OS 3_2_2/i.test(ua) ||
        /Macintosh/i.test(ua)
    );
}

if (iPadControl()) {
    setViewportUnit();
}
