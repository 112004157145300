import React from 'react';
import PropTypes from 'prop-types';

const MasonryContainer = props => {
    const { items, maxColumn, containerClassName, messyOrder } = props;

    const itemGroups = [];

    const showMultiColumns = window.innerWidth > 1100 && messyOrder;

    if (showMultiColumns) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < maxColumn; i++) {
            itemGroups.push([]);
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < items.length; i++) {
            itemGroups[i % maxColumn].push(items[i]);
        }
    } else {
        let tmpItemGroup = [];
        // eslint-disable-next-line radix
        const itemGroupLength = parseInt(
            items.length / maxColumn + (items.length % 2 === 0 ? 0 : 1)
        );

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < items.length; i++) {
            tmpItemGroup.push(items[i]);
            if (tmpItemGroup.length === itemGroupLength || i === items.length - 1) {
                itemGroups.push(tmpItemGroup);
                tmpItemGroup = [];
            }
        }
    }

    const renderItem = (item, index) => (
        <div key={`mg-item${index}`} className="masonry-item">
            {item}
        </div>
    );

    const renderGroup = (group, index) => (
        <div key={`mg-${index}`} className="masonry-column d-flex flex-column flex-grow-1 mw-100">
            {group.map(renderItem)}
        </div>
    );

    return (
        <div className={`masonry d-flex flex-wrap flex-grow-1 ${containerClassName}`}>
            {itemGroups.map(renderGroup)}
        </div>
    );
};

export default MasonryContainer;

MasonryContainer.propTypes = {
    items: PropTypes.array,
    maxColumn: PropTypes.number,
    containerClassName: PropTypes.string,
    messyOrder: PropTypes.bool,
};

MasonryContainer.defaultProps = {
    items: [],
    maxColumn: 2,
    containerClassName: '',
    messyOrder: true,
};
