import React from 'react';
import PropTypes from 'prop-types';
import UserIcon from './UserIcon';
import HoverTooltip from '../HoverTooltip';

const MultipleUserIcon = ({ users, count }) => {
    const more = users.length - count < 0 ? 0 : users.length - count;

    if (users.length === 0) {
        return <UserIcon />;
    }

    return (
        <div className="d-flex align-items-center justify-content-end">
            <div className="user-icon-group">
                {users
                    .slice(0, count)
                    .reverse()
                    .map(user => (
                        <HoverTooltip key={users.indexOf(user)} title={user.name}>
                            <UserIcon user={user} className="user-icon-group-item" />
                        </HoverTooltip>
                    ))}
            </div>
            {more > 0 && (
                <div className="text-light-gray mx-2 text-xsmall text-nowrap">
                    <HoverTooltip
                        title={
                            <>
                                {users
                                    .slice(count, users.length)
                                    .reverse()
                                    .map(x => x.name)
                                    .join(', ')}
                            </>
                        }
                    >
                        <div>+{more}</div>
                    </HoverTooltip>
                </div>
            )}
        </div>
    );
};

MultipleUserIcon.propTypes = {
    users: PropTypes.array,
    count: PropTypes.number,
};

MultipleUserIcon.defaultProps = {
    users: [],
    count: 3,
};

export default MultipleUserIcon;
