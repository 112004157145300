import * as moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';
import HoverTooltip from '../../HoverTooltip';

const TicketResolution = ({ datetime, expanded, format }) => {
    const instance = datetime ? moment(datetime) : moment();
    const diff = instance.diff(new Date(), 'hours');

    const fullText = format ? instance.format(format) : instance.toISOString();

    const text = trans('resolution_due_x', { diff: instance.fromNow() });

    let color = 'secondary';

    if (diff < 0) {
        color = 'danger';
    } else if (diff <= 1) {
        color = 'warning';
    } else {
        color = 'success';
    }

    if (expanded) {
        return (
            <HoverTooltip title={fullText}>
                <div className="d-flex align-items-center labeled-icon cursor-default">
                    <span className={`labeled-icon-icon ${`bg-${color} text-white`}`}>
                        <i className="fa fa-calendar-check" />
                    </span>
                    <span className={`p-2 text-${color} flex-all-1`}>{text}</span>
                </div>
            </HoverTooltip>
        );
    }

    return (
        <HoverTooltip title={text}>
            <span className={`text-${color}`}>
                <i className="fa fa-exclamation-triangle" />
            </span>
        </HoverTooltip>
    );
};

export default TicketResolution;

TicketResolution.propTypes = {
    datetime: PropTypes.string,
    expanded: PropTypes.bool,
    format: PropTypes.string,
};

TicketResolution.defaultProps = {
    datetime: null,
    expanded: false,
    format: null,
};
