import React from 'react';
import PropTypes from 'prop-types';
import UserIcon from '../UserIcon';
import { DateTime } from '../..';

const Heading = props => {
    const { user, date, onMoreClick } = props;
    return (
        <div className="header d-flex justify-content-between align-items-center pl-4 pt-4 pr-2 pb-2 mw-100">
            <div className="d-flex">
                <UserIcon user={user} />
                <div className="d-flex flex-column justify-content-end flex-grow-1 pl-2">
                    <span className="text-small text-dark font-weight-bold">{user.name}</span>
                    <DateTime format="MMMM DD YYYY" datetime={date} className="date text-small" />
                </div>
            </div>
            {!!onMoreClick && (
                <button
                    type="button"
                    onClick={onMoreClick}
                    className="btn more-btn align-self-start"
                >
                    <i className="fa fa-ellipsis-h text-light-gray more-icon" />
                </button>
            )}
        </div>
    );
};

export default Heading;

Heading.propTypes = {
    user: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    onMoreClick: PropTypes.func,
};

Heading.defaultProps = {
    onMoreClick: null,
};
