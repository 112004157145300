import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Api, AuthService} from '../../../services';
import {ManageLayout} from '../../../layouts';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import trans from '../../../localization';
import {CarouselPane, StepCarousel} from '../../../components';
import {ManageField, ManageInput} from "../../../elements";
import moment from "moment";
import CancelAndSaveFooter from "../../../components/helpdesk/carousel/CancelAndSaveFooter";
import definitions from "../../../definitions"
import router from "../../../utils/router";

class ReportPage extends Component {
    constructor(props) {
        super(props);

        const {match} = this.props;

        this.state = {
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            report: match.params.report,
            data: {
                start_at: moment().subtract(1, 'months').format(definitions.DATE_INPUT_FORMAT),
                end_at: moment().format(definitions.DATE_INPUT_FORMAT)
            }
        };

        this.goBack = this.goBack.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.generate = this.generate.bind(this);
    }

    componentDidMount() {
        const promises = [this.fetchCompany()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany = () => {
        const {companyId} = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    goBack() {
        const {history} = this.props;
        history.goBack();
    }

    onChangeDate(value, date) {
        const { data } = this.state
        data[date] = moment(value).format(definitions.DATE_INPUT_FORMAT)

        this.setState({
            data
        })
    }

    generate() {
        const { company, data, report } = this.state;
        const { history } = this.props;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: 'statistics/download',
            responseType: 'blob',
            data: {
                company_id: company.id,
                type: report,
                ...data
            },
        })
            .then((response) => {
                const filename = `${company.slug}-${report}-report-${data.start_at}-${data.end_at}.csv`
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();

                setTimeout(() => {
                    router.toCompany(history, company, `statistics`);
                }, 1000)
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    render() {
        const {isLoading, user, company, report, data} = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane onPrev={this.goBack} scroll center middle>
                        <div style={{minWidth: 600}}>
                            <ManageHeading
                                title={trans(report)}
                            />
                            <div className="pt-5">
                                <ManageField title={trans('start_date')}>
                                    <ManageInput
                                        placeholder={trans('start_date')}
                                        max={moment().subtract('1', 'days').format(definitions.DATE_INPUT_FORMAT)}
                                        value={data.start_at}
                                        onChange={e =>
                                            this.onChangeDate(e.target.value, 'start_at')
                                        }
                                        type="date"
                                    />
                                </ManageField>
                            </div>
                            <div className="pt-4">
                                <ManageField title={trans('end_date')}>
                                    <ManageInput
                                        placeholder={trans('end_date')}
                                        max={moment().format(definitions.DATE_INPUT_FORMAT)}
                                        value={data.end_at}
                                        onChange={e =>
                                            this.onChangeDate(e.target.value, 'end_date')
                                        }
                                        type="date"
                                    />
                                </ManageField>
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>

                <CancelAndSaveFooter submitTitle={trans('generate_and_download_as_csv')} onSubmit={this.generate} onCancel={this.goBack} />
            </ManageLayout>
        );
    }
}

export default withRouter(ReportPage);

ReportPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
