import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import trans from '../../../localization';
import TicketInfo from './TicketInfo';

const ActivityTicketAssign = props => {
    const { activity, containerClassName, seeTicket, assigned, onMoreClick } = props;
    const { user, customer, company, ticket } = activity;

    const key = assigned ? 'assigned' : 'unassigned';
    const activityTextKey = `x_has_${key}_you_to_a_ticket_on`;

    return (
        <Container
            activity={activity}
            className={containerClassName}
            onButtonClick={seeTicket}
            buttonText={trans('see_ticket')}
            onMoreClick={onMoreClick}
        >
            <p>
                <span className="text-dark text-small">
                    {trans(activityTextKey, {
                        name: ((customer && customer.name) || (user && user.name) || '').split(
                            ' '
                        )[0],
                    })}
                </span>{' '}
                <span className="text-dark font-weight-bold text-small">
                    {company && company.name}
                </span>
            </p>
            <TicketInfo ticket={ticket} company={company} status={ticket.status} />
        </Container>
    );
};

export default ActivityTicketAssign;

ActivityTicketAssign.propTypes = {
    activity: PropTypes.object.isRequired,
    containerClassName: PropTypes.string,
    seeTicket: PropTypes.func,
    assigned: PropTypes.bool,
    onMoreClick: PropTypes.func,
};

ActivityTicketAssign.defaultProps = {
    containerClassName: null,
    seeTicket: () => {},
    assigned: false,
    onMoreClick: null,
};
