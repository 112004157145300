// eslint-disable-next-line import/no-cycle
import Api from './Api';
import router from '../utils/router';
import definitions from '../definitions';
import storage from '../utils/storage';

const AUTH_STORAGE_KEY = '_authToken';

class AuthService {
    async init() {
        return new Promise(resolve => {
            this.loadToken();

            if (this.hasToken()) {
                this.fetchUser()
                    .then(() => {
                        resolve(this.currentUser);
                    })
                    .catch(() => {
                        resolve(false);
                    });
            } else {
                resolve(false);
            }
        });
    }

    guest() {
        return !this.user();
    }

    user() {
        return this.currentUser;
    }

    check() {
        // noinspection JSUnresolvedVariable
        return this.user() && this.user().has_password && this.user().email_verified;
    }

    logout() {
        return new Promise(resolve => {
            AuthService.storage().removeItem(AUTH_STORAGE_KEY);
            this.token = null;
            this.currentUser = null;

            resolve();
        });
    }

    getToken() {
        return this.token;
    }

    setToken(token) {
        AuthService.storage().setItem(AUTH_STORAGE_KEY, token);
        this.token = token;
    }

    hasToken() {
        return !!this.token;
    }

    setUser(user) {
        this.currentUser = user;
    }

    async fetchUser() {
        return new Promise((resolve, reject) => {
            Api.request({
                method: 'GET',
                url: 'users/me',
            })
                .then(response => {
                    this.setUser(response.data.data);
                    resolve(this.currentUser);
                })
                .catch(error => {
                    this.logout().then(() => {
                        reject(error);
                    });
                });
        });
    }

    loadToken() {
        this.token = AuthService.storage().getItem(AUTH_STORAGE_KEY);
    }

    setTokenAndFetch(token) {
        this.setToken(token);
        return this.fetchUser();
    }

    hasPassword() {
        // noinspection JSUnresolvedVariable
        return this.currentUser && this.currentUser.has_password;
    }

    emailVerified() {
        return this.currentUser && this.currentUser.email_verified;
    }

    redirectToNext(history) {
        const acceptUrl = storage.get(definitions.STORAGE_KEY_ACCEPT_INVITATION);
        if (this.guest()) {
            router.replace(history, '/auth/signIn');
        } else if (!this.hasPassword()) {
            router.replace(history, '/auth/setPassword');
        } else if (!this.emailVerified()) {
            router.replace(history, '/auth/verifyEmail');
        } else if (acceptUrl) {
            storage.remove(definitions.STORAGE_KEY_ACCEPT_INVITATION);
            window.location.href = acceptUrl;
        } else {
            router.replace(history, '/');
        }
    }

    setTokenAndFetchThenRedirect(token, history) {
        this.setTokenAndFetch(token).then(() => {
            this.redirectToNext(history);
        });
    }

    static storage() {
        // eslint-disable-next-line no-undef
        return localStorage;
    }
}

const authService = new AuthService();
export default authService;
