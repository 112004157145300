import { Api } from './index';
import router from '../utils/router';

export default class CompanyService {
    static show(company, extra = {}) {
        return Api.request({
            method: 'GET',
            url: `companies/${CompanyService.slug(company)}`,
            ...extra,
        });
    }

    static tags(company, search, extra = {}) {
        const options = extra;
        if (!options.params) {
            options.params = {};
        }

        options.params.search = search;

        return Api.request({
            method: 'GET',
            url: `companies/${CompanyService.slug(company)}/tags`,
            ...options,
        });
    }

    static users(company, search, extra = {}) {
        const options = extra;
        options.params.search = search;

        return Api.request({
            method: 'GET',
            url: `companies/${CompanyService.slug(company)}/users`,
            ...options,
        });
    }

    static customers(company, search, extra = {}) {
        return Api.request({
            method: 'GET',
            url: `companies/${CompanyService.slug(company)}/customers`,
            params: {
                search,
            },
            ...extra,
        });
    }

    static username(company) {
        return company.slug ? company.slug : company;
    }

    static slug(company) {
        return router.companySlug(company);
    }

    static goToSettingsPage(history, company, settingItem) {
        switch (settingItem.name) {
            case 'agents':
                router.toCompany(history, company, 'agents');
                break;
            case 'channels':
                router.toCompany(history, company, 'channels');
                break;
            case 'responses':
            case 'canned_responses':
                router.to(history, '/myAccount/cannedResponses', {
                    companyId: company.slug,
                });
                break;
            case 'sla_targets':
                router.toCompany(history, company, 'SLATargets');
                break;
            case 'worktime_calendar':
                router.toCompany(history, company, 'worktimeCalendar');
                break;
            case 'integrations':
                router.toCompany(history, company, 'integrations');
                break;
            case 'ticket_types':
                router.toCompany(history, company, 'ticketTypes');
                break;
            case 'ticket_rules':
                router.toCompany(history, company, 'ticketRules');
                break;
            default:
                break;
        }
    }
}
