import React from 'react';
import PropTypes from 'prop-types';

const ManageTimeInput = props => {
    const { onTimeChange, onUnitChange, timeInputProps, unitSelectProps, units } = props;

    return (
        <div className="manage-time-input">
            <input
                className="manage-time-input-input"
                type="number"
                onChange={onTimeChange}
                {...timeInputProps}
            />
            <div className="manage-time-input-selectbox">
                <select
                    className="manage-time-input-selectbox-select"
                    onChange={onUnitChange}
                    {...unitSelectProps}
                >
                    {units.map(x => (
                        <option key={(x && x.value) || x} value={(x && x.value) || x}>
                            {(x && x.title) || x}
                        </option>
                    ))}
                </select>
                <i className="manage-time-input-selectbox-icon fas fa-chevron-down" />
            </div>
        </div>
    );
};

export default ManageTimeInput;

ManageTimeInput.propTypes = {
    onTimeChange: PropTypes.func,
    onUnitChange: PropTypes.func,
    timeInputProps: PropTypes.object,
    unitSelectProps: PropTypes.object,
    units: PropTypes.array,
};

ManageTimeInput.defaultProps = {
    onTimeChange: () => {},
    onUnitChange: () => {},
    timeInputProps: {},
    unitSelectProps: {},
    units: [],
};
