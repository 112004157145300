import React, { useState } from 'react';
import cx from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import trans from '../localization';
import DateTime from './DateTime';

const CustomerOrdersSidebar = ({ orders, ticket, active, onClose, absolute }) => {
    const [toggles, setToggles] = useState({ 0: true });

    if (!orders || !orders.length || !ticket) {
        return null;
    }

    const dateFormat = 'MMMM DD, YYYY';

    const toggleCollapse = index => () => {
        setToggles({
            ...toggles,
            [index]: !toggles[index],
        });
    };

    return (
        <div
            className={cx(`customer-orders-sidebar`, {
                'customer-orders-sidebar-active': !!orders && active,
                'customer-orders-sidebar-absolute': absolute,
            })}
        >
            <div className="customer-orders-sidebar-inner">
                <PerfectScrollbar>
                    <div className="customer-orders-sidebar-customer">
                        <button
                            type="button"
                            className="customer-orders-sidebar-close"
                            onClick={onClose}
                        >
                            <i className="fad fa-times-circle" />
                        </button>
                        <div className="customer-orders-sidebar-customer-name">
                            {ticket.customer.name}
                        </div>
                        <div className="customer-orders-sidebar-customer-email">
                            {ticket.customer.email}
                        </div>
                    </div>
                    <div className="customer-orders-sidebar-content">
                        <div className="customer-orders-sidebar-count">
                            {trans('x_orders_found', { count: orders.length })}
                        </div>
                        {orders.map(order => (
                            <div className="cos-order" key={orders.indexOf(order)}>
                                <div className="cos-order-header">
                                    <div className="cos-order-meta">
                                        <div className="cos-order-date">
                                            <DateTime
                                                datetime={order.dateReceived}
                                                format={dateFormat}
                                            />
                                        </div>
                                        <div className="cos-order-id">{order.orderId}</div>

                                        <div className="cos-order-ref">
                                            {order.orderPacks.length > 0 && (
                                                <a
                                                    href={order.url}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    {order.orderPacks[0].printRef}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                    <div className="cos-order-options">
                                        <button
                                            type="button"
                                            className="cos-order-collapse-button"
                                            onClick={toggleCollapse(orders.indexOf(order))}
                                        >
                                            <i className="fa fa-chevron-down" />
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className={cx('cos-order-content', {
                                        'cos-order-content-active': !!toggles[
                                            orders.indexOf(order)
                                        ],
                                    })}
                                >
                                    <div className="cos-order-row bg-light-gray">
                                        <div className="cos-order-row-label">{trans('status')}</div>
                                        <div className="cos-order-row-value">{order.status}</div>
                                    </div>

                                    <div className="cos-order-row">
                                        <div className="cos-order-row-icon">
                                            <i className="fad fa-shopping-cart" />
                                        </div>
                                        <div className="cos-order-row-label">{trans('placed')}</div>
                                        <div className="cos-order-row-value">
                                            <DateTime
                                                datetime={order.dateReceived}
                                                format={dateFormat}
                                            />
                                        </div>
                                    </div>

                                    <div className="cos-order-row">
                                        <div className="cos-order-row-icon">
                                            <i className="fad fa-coins" />
                                        </div>
                                        <div className="cos-order-row-label text-color-resolved">
                                            {trans('paid_currency_value', {
                                                currency: order.paymentCurrency || '£',
                                                value: order.totalPaid,
                                            })}
                                        </div>
                                        <div className="cos-order-row-value">
                                            <DateTime
                                                datetime={order.dateReceived}
                                                format={dateFormat}
                                            />
                                        </div>
                                    </div>

                                    <div className="cos-order-row">
                                        <div className="cos-order-row-icon">
                                            <i className="fad fa-route" />
                                        </div>
                                        <div className="cos-order-row-label">
                                            {trans('shipped')}
                                        </div>
                                        <div className="cos-order-row-value">
                                            <DateTime
                                                datetime={order.dateDispatched}
                                                format={dateFormat}
                                            />
                                        </div>
                                    </div>

                                    <div className="cos-order-row">
                                        <div className="cos-order-row-icon">
                                            <i className="fad fa-truck" />
                                        </div>
                                        <div className="cos-order-row-label">
                                            <div>{order.shippingMethodRequested}</div>
                                            <a
                                                href={order.shippingTrackingUrl}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="text-color-primary"
                                            >
                                                {order.shippingTrackingCode}
                                            </a>
                                        </div>
                                        <div className="cos-order-row-value">
                                            {!!order.shippingTrackingUrl && (
                                                <a
                                                    href={order.shippingTrackingUrl}
                                                    className="text-color-primary"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    <i className="fad fa-external-link-alt" />
                                                </a>
                                            )}
                                        </div>
                                    </div>

                                    <hr />

                                    {order.orderInventory.map(product => (
                                        <div
                                            className="cos-order-row"
                                            key={order.orderInventory.indexOf(product)}
                                        >
                                            <div className="cos-order-row-quantity">
                                                <div className="cos-order-badge">
                                                    {product.quantity}
                                                </div>
                                            </div>
                                            <div className="cos-order-row-label">
                                                <div
                                                    title={product.name}
                                                    className="text-truncate"
                                                    style={{ width: 252 }}
                                                >
                                                    {product.name}
                                                </div>
                                                <div className="text-color-primary">
                                                    {product.stockCode}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <hr />

                                    <div className="cos-order-row">
                                        <div className="cos-order-row-label">
                                            {trans('shipping_cost')}
                                        </div>
                                        <div className="cos-order-row-value">
                                            {order.shippingPaid}
                                        </div>
                                    </div>
                                    <div className="cos-order-row">
                                        <div className="cos-order-row-label">
                                            {trans('tax_paid')}
                                        </div>
                                        <div className="cos-order-row-value">{order.taxPaid}</div>
                                    </div>
                                    <div className="cos-order-row">
                                        <div className="cos-order-row-label">
                                            <strong>{trans('total_paid')}</strong>
                                        </div>
                                        <div className="cos-order-row-value">{order.totalPaid}</div>
                                    </div>

                                    {order.customerComments !== '' && (
                                        <>
                                            <hr />
                                            <div>
                                                <div>
                                                    <strong>Customer Comments</strong>
                                                </div>
                                                <p>{order.customerComments}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    );
};

CustomerOrdersSidebar.propTypes = {
    orders: PropTypes.array,
    ticket: PropTypes.object,
    active: PropTypes.bool,
    onClose: PropTypes.func,
    absolute: PropTypes.bool,
};
CustomerOrdersSidebar.defaultProps = {
    orders: [],
    active: false,
    ticket: null,
    absolute: false,
    onClose: () => {},
};

export default CustomerOrdersSidebar;
