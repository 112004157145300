import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserIcon from './UserIcon';
import LabeledCheckbox from './LabeledCheckbox';
import ManageSelectbox from '../../elements/ManageSelectbox';
import ModalActionBar from './ModalActionBar';
import trans from '../../localization';

export default class EditAgentModal extends Component {
    constructor(props) {
        super(props);
        const { user } = props;

        this.state = {
            user,
        };
    }

    changeUserRole(value) {
        const { user } = this.state;
        const { refreshPermissions } = this.props;

        user.role = value;

        if (refreshPermissions instanceof Function) {
            refreshPermissions();
        }
    }

    render() {
        const { user } = this.state;
        const { onSaveChanges, onUnlinkUser, onClose, permissions, myUser } = this.props;

        return (
            <>
                <div className="user-icon-header d-flex flex-column justify-content-center align-items-center position-relative">
                    <UserIcon user={user} className="position-absolute" />
                </div>
                <div className="edit-agent-modal inner-modal-content d-flex justify-content-center align-items-center flex-column">
                    <div className="p-3 d-flex justify-content-center align-items-center flex-column w-100">
                        <div className="user-icon-header d-flex flex-column justify-content-center align-items-center">
                            <p className="text-dark name mb-0">{user.name}</p>
                        </div>
                        <PerfectScrollbar className="d-flex justify-content-center align-items-center w-100">
                            <div className="content mt-3">
                                <div className="modal-field">
                                    <p className="title text-color-dark">
                                        {trans('permission_level')}
                                    </p>
                                    <ManageSelectbox
                                        id="permissions-level"
                                        items={[
                                            {
                                                title: 'Support Administrator',
                                                value: 'admin',
                                            },
                                            { title: 'Agent', value: 'user' },
                                        ]}
                                        defaultValue={user.role === 'admin' ? 'admin' : 'user'}
                                        onChange={e => this.changeUserRole(e.target.value)}
                                        disabled={user.id === myUser.id}
                                    />
                                </div>
                                <div className="modal-field mt-3">
                                    <p className="title text-color-dark">{trans('permissions')}</p>
                                    <div className="d-flex flex-wrap">
                                        {permissions.map(x => (
                                            <LabeledCheckbox
                                                key={`permission-${x.name}`}
                                                containerClassName="mr-3 my-1"
                                                checked={x.value}
                                                readOnly
                                                item={{
                                                    id: `permission-${x.name}`,
                                                    name: x.name,
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                    <ModalActionBar
                        onCancel={onClose}
                        onSave={() => onSaveChanges(user)}
                        onUnlink={() => onUnlinkUser(user)}
                        canUnlink={user.id !== myUser.id}
                        canSave={user.id !== myUser.id}
                    />
                </div>
            </>
        );
    }
}

EditAgentModal.propTypes = {
    user: PropTypes.object.isRequired,
    myUser: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onSaveChanges: PropTypes.func,
    onUnlinkUser: PropTypes.func,
    refreshPermissions: PropTypes.func,
    permissions: PropTypes.array,
};

EditAgentModal.defaultProps = {
    onClose: () => {},
    onSaveChanges: () => {},
    onUnlinkUser: () => {},
    refreshPermissions: () => {},
    permissions: [],
};
