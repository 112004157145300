import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { DateTime, PaginationScrollbar } from '../..';
import TicketListItem from './TicketListItem';
import trans from '../../../localization';

export default class TicketList extends PureComponent {
    renderTicket(ticket) {
        const {
            openedTicket,
            onOpenTicket,
            selectedTicketIds,
            onSelectTicket,
            company,
        } = this.props;
        const active = openedTicket ? openedTicket.id === ticket.id : null;
        const selected = selectedTicketIds && selectedTicketIds.indexOf(ticket.id) > -1;

        return (
            <div key={ticket.id} className="py-2">
                <TicketListItem
                    ticket={ticket}
                    company={company}
                    active={active}
                    selected={selected}
                    onSelectTicket={onSelectTicket}
                    onOpenTicket={e => onOpenTicket(ticket, e)}
                />
            </div>
        );
    }

    static renderDate(date) {
        const isToday = moment().diff(date, 'days') === 0;
        if (isToday) {
            return trans('today');
        }

        return <DateTime datetime={date} format="Do MMMM YYYY" />;
    }

    renderTicketGroup(tickets, date) {
        return (
            <div key={`ticket-group-${date}`}>
                <h2 className="ticket-title mt-3">{TicketList.renderDate(date)}</h2>
                {tickets.map(ticket => this.renderTicket(ticket))}
            </div>
        );
    }

    render() {
        const { tickets, title, onNextPage, isLastPage } = this.props;

        return (
            <div className="ticket-list pr-0">
                <PaginationScrollbar
                    onNextPage={p => onNextPage(p)}
                    isLastPage={isLastPage}
                    className="pr-3"
                >
                    {title && <h2 className="ticket-title d-none">{title}</h2>}
                    {Object.keys(tickets).map(date => this.renderTicketGroup(tickets[date], date))}
                </PaginationScrollbar>
            </div>
        );
    }
}

TicketList.propTypes = {
    tickets: PropTypes.object.isRequired,
    company: PropTypes.object,
    title: PropTypes.string,
    onOpenTicket: PropTypes.func,
    onNextPage: PropTypes.func,
    openedTicket: PropTypes.object,
    onSelectTicket: PropTypes.func,
    selectedTicketIds: PropTypes.array,
    isLastPage: PropTypes.bool,
};

TicketList.defaultProps = {
    company: null,
    title: null,
    onOpenTicket: () => {},
    onNextPage: () => Promise.resolve(),
    openedTicket: null,
    selectedTicketIds: [],
    onSelectTicket: () => {},
    isLastPage: false,
};
