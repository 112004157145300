import LocalizedStrings from 'react-localization';
import en from './lang/en';

const strings = new LocalizedStrings({
    en,
});

const trans = (key, ...values) => {
    const explode = key.split('.');
    let string = strings[key];

    if (explode.length > 1) {
        string = strings;
        explode.forEach(value => {
            string = string[value];
        });
    }

    return strings.formatString(string, ...values);
};

export default trans;
