import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';

const TicketStatus = props => {
    const { ticket, className, priority } = props;
    const name = priority ? ticket.priority : ticket.status;

    return (
        <div className={`status ticket-status-${name} ${className}`}>
            {priority ? trans(`priorities.${name}`) : trans(`statuses.${name}`)}
        </div>
    );
};

export default TicketStatus;

TicketStatus.propTypes = {
    ticket: PropTypes.object,
    className: PropTypes.string,
    priority: PropTypes.bool,
};

TicketStatus.defaultProps = {
    ticket: null,
    className: '',
    priority: false,
};
