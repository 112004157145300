import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as moment from 'moment';
import Datepicker from './Datepicker';
// import trans from '../../localization';

export default class DateFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: null,
            endDate: null,
            active: false,
            onlyFromSelected: false,
        };

        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(fd, dates) {
        let startDate = (dates && dates[0]) || null;
        let endDate = (dates && dates[1]) || null;

        this.setState({
            startDate,
            endDate,
            onlyFromSelected: dates.length === 1,
            active: dates.length === 2,
        });

        if (dates.length === 0 || dates.length === 2) {
            const { onChange } = this.props;

            // add hours to end date to include it
            if (endDate) {
                endDate.setHours(23, 59, 59);
            }

            endDate = moment(endDate).toISOString();
            startDate = moment(startDate).toISOString();

            onChange(startDate, endDate);
        }
    }

    static renderFilterDate(date) {
        return (
            <span className="date">
                {date instanceof Date ? moment(date).format('MMM D') : date}
            </span>
        );
    }

    static renderCell(date, cellType) {
        const currentDate = date.getDate();

        if (cellType === 'day') {
            return {
                html: `<div class="-custom- day-${date.getDay()}">${currentDate}</div>`,
            };
        }

        return null;
    }

    render() {
        const { startDate, endDate, active, onlyFromSelected } = this.state;

        const start = startDate || null;
        const end = endDate || '∞';

        return (
            <label className={cx('datepicker-preview', { active })}>
                <Datepicker
                    range="true"
                    maxDate={new Date()}
                    language="en"
                    position="bottom right"
                    toggleSelected={false}
                    onSelect={this.onSelect}
                    onRenderCell={DateFilter.renderCell}
                    clearButton
                    nextHtml={`<i class="fa fa-chevron-right text-2xsmall text-dark"></i>`}
                    prevHtml={`<i class="fa fa-chevron-left text-2xsmall text-dark"></i>`}
                    navTitles={{
                        days: 'MM yyyy',
                        months: 'yyyy',
                        years: 'yyyy1 - yyyy2',
                    }}
                    classes={cx({ 'only-from-selected': onlyFromSelected })}
                />
                <div
                    className={cx('datepicker-preview-days', {
                        selected: !!startDate,
                    })}
                >
                    <div className="datepicker-preview-day">
                        <div className="datepicker-preview-day-number">
                            {(startDate && startDate.getDate()) || (
                                <i className="fa fa-calendar-alt" />
                            )}
                        </div>
                        <div className="datepicker-preview-day-text">
                            {DateFilter.renderFilterDate(start)}
                        </div>
                    </div>
                    {endDate && (
                        <div className="datepicker-preview-day">
                            <div className="datepicker-preview-day-number">
                                {(endDate && endDate.getDate()) || ''}
                            </div>
                            <div className="datepicker-preview-day-text">
                                {DateFilter.renderFilterDate(end)}
                            </div>
                        </div>
                    )}
                </div>
            </label>
        );
    }
}

DateFilter.propTypes = {
    onChange: PropTypes.func,
};

DateFilter.defaultProps = {
    onChange: () => {},
};
