import React from 'react';
import PropTypes from 'prop-types';

const FilterGroupItem = props => {
    const { text, color, count, isSelected, isDeleted, onClick } = props;

    let className = isSelected
        ? `list-group-item list-group-item-action filter-option-${color} active`
        : `list-group-item list-group-item-action filter-option-${color}`;

    if (isDeleted) {
        className += ' list-group-item-deleted';
    }

    return (
        <button type="button" onClick={onClick} className={className}>
            <div className="d-flex flex-wrap w-100 justify-content-between">
                <p className={isDeleted ? 'text-light-gray' : 'text-truncate'}>{text}</p>
            </div>
            <span className="badge badge-pill badge-secondary py-1 px-2 blank">{count}</span>
        </button>
    );
};

export default FilterGroupItem;

FilterGroupItem.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    count: PropTypes.number,
    isSelected: PropTypes.bool,
    isDeleted: PropTypes.bool,
    onClick: PropTypes.func,
};

FilterGroupItem.defaultProps = {
    text: null,
    color: null,
    count: null,
    isSelected: false,
    isDeleted: false,
    onClick: () => {},
};
