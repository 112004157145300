import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CarouselPane, Spinner, StepCarousel } from '../../../components';
import trans from '../../../localization';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';
import { FormButton, ManageField, ManageSelectbox } from '../../../elements';
import definitions from '../../../definitions';
import router from '../../../utils/router';

class SetupEbayPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            channelId: match.params.channelId,
            channel: null,
            companyId: match.params.companyId,
            company: null,
            siteIds: [],
            user: AuthService.user(),
            isLoading: true,
            isRedirecting: false,
            redirectingUrl: '#',
            step: 0,
            siteId: 0,
        };
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchChannel(), this.fetchSiteIds()]).finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchChannel = () => {
        const { channelId } = this.state;

        return Api.request({
            method: 'GET',
            url: `channels/${channelId}`,
        }).then(response => {
            const channel = response.data.data;
            // noinspection JSUnresolvedVariable
            if (channel.type !== definitions.PROVIDER_EBAY) {
                const { history } = this.props;
                const { companyId } = this.state;
                router.toCompany(history, companyId, 'channels');
            }

            this.setState({
                channel,
            });
        });
    };

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    fetchSiteIds = () => {
        const { channelId } = this.state;

        return Api.request({
            method: 'GET',
            url: `channels/${channelId}/ebay/sites`,
        }).then(response => {
            this.setState({
                siteIds: response.data.data,
            });
        });
    };

    handleSelectSiteId = e => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { channel, isLoading, siteId } = this.state;
        if (isLoading) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: `channels/${channel.id}/ebay/redirectUrl`,
            data: {
                site_id: siteId,
            },
        })
            .then(response => {
                this.setState({
                    isRedirecting: true,
                    redirectingUrl: response.data.data,
                });

                localStorage.setItem(definitions.CALLBACK_EBAY_CHANNEL_ID, channel.id);
                window.location.href = response.data.data;
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    goToBack = () => {
        const { history } = this.props;
        const { company, channel } = this.state;

        router.toCompany(history, company, `channels/${channel.id}/edit/ebay`);
    };

    onChangeSiteId = event => {
        const { target } = event;

        this.setState({
            siteId: target.value,
        });
    };

    renderSelectSiteIdStep = () => {
        const { siteIds, channel, isRedirecting, redirectingUrl } = this.state;

        return (
            <form onSubmit={this.handleSelectSiteId}>
                <ManageHeading
                    title={trans('connect_your_x', {
                        name: <strong>{channel.name}</strong>,
                    })}
                    subtitle={!isRedirecting && trans('set_up_ebay_page_text')}
                />

                {isRedirecting ? (
                    <div className="my-4 text-center">
                        <div>
                            <strong>{trans('redirecting_to_ebay')}</strong>
                        </div>
                        <Spinner className="my-50" />
                        <div>
                            {trans('a_new_window_does_not_open')},
                            <a href={redirectingUrl}>{trans('click_here')}</a>.
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                        <div className="mt-4 d-flex flex-column">
                            <PerfectScrollbar className="px-4 manage-form-scroll overflow-hidden d-flex flex-column">
                                <ManageField title={trans('which_ebay_site_you_want_to_use')}>
                                    <ManageSelectbox
                                        className="min-width-293px"
                                        onChange={this.onChangeSiteId}
                                        items={siteIds}
                                    />
                                </ManageField>
                            </PerfectScrollbar>

                            <div className="d-flex justify-content-end mt-4 px-4">
                                <FormButton
                                    onClick={this.handleSubmitName}
                                    type="submit"
                                    className="manage-btn"
                                    style={{ minWidth: 150 }}
                                    text={trans('next_step')}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </form>
        );
    };

    render() {
        const { step, user, company, isLoading, channel } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel step={step}>
                    <CarouselPane
                        onNext={this.handleSelectSiteId}
                        onPrev={this.goToBack}
                        middle
                        center
                        scroll={!!channel}
                    >
                        {channel && this.renderSelectSiteIdStep()}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(SetupEbayPage);

SetupEbayPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
