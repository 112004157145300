import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';
import TicketIcon from '../ticket/TicketIcon';
import TicketId from '../ticket/TicketId';

const TicketInfo = props => {
    const { ticket, company, status, priority, reopen, resolve } = props;

    const renderStatus = () => {
        if (reopen || resolve) {
            return (
                <span className={`text-nowrap text-xsmall text-color-${status}`}>
                    <i className={reopen ? 'fa fa-check' : 'fa fa-eye'} />
                    <span className="ml-2">{trans(`statuses.${status}`)}</span>
                </span>
            );
        }

        return (
            <p className="color-circle-cnt">
                <span className={`color-circle background-color-${status}`} />
                <span className="text-xsmall ml-2">{trans(`statuses.${status}`)}</span>
            </p>
        );
    };

    return (
        <div className="activity-ticket-info d-flex justify-content-between align-items-center">
            <div className="info d-flex align-items-center mb-2">
                <TicketIcon ticket={ticket} />
                <div className="d-flex pl-2">
                    <div className="sender d-flex flex-column">
                        <span className="text-dark font-weight-bold text-capitalize">
                            {ticket.source && ticket.source.name}
                        </span>
                        <span>
                            <span className="text-dark text-small">{ticket.subject}</span>
                            <TicketId ticket={ticket} company={company} />
                        </span>
                    </div>
                </div>
            </div>
            {status && renderStatus()}
            {priority && (
                <p className="color-circle-cnt">
                    <span className={`color-circle background-color-${priority}`} />
                    <span className="text-xsmall ml-2">{trans(`priorities.${priority}`)}</span>
                </p>
            )}
        </div>
    );
};

export default TicketInfo;

TicketInfo.propTypes = {
    ticket: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    status: PropTypes.string,
    reopen: PropTypes.bool,
    resolve: PropTypes.bool,
    priority: PropTypes.string,
};

TicketInfo.defaultProps = {
    status: null,
    reopen: false,
    resolve: false,
    priority: null,
};
