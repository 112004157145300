import parameters from '../../../routes/parameters';
import ListPage from './ListPage';
import CreateAndEditPage from './CreateAndEditPage';

export default [
    {
        path: '/companies/:companyId/ticketRules',
        middleware: ['auth'],
        component: ListPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/ticketRules/create',
        middleware: ['auth'],
        component: CreateAndEditPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/ticketRules/:ruleId',
        middleware: ['auth'],
        component: CreateAndEditPage,
        ...parameters.companyIdParameters,
    },
];
