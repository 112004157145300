import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CarouselPane, StepCarousel } from '../../../components';
import trans from '../../../localization';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';
import definitions from '../../../definitions';
import router from '../../../utils/router';

class ErrorEbayPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            channelId: match.params.channelId,
            channel: null,
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            step: 0,
        };
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchChannel()]).finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchChannel = () => {
        const { channelId } = this.state;

        return Api.request({
            method: 'GET',
            url: `channels/${channelId}`,
        }).then(response => {
            const channel = response.data.data;
            // noinspection JSUnresolvedVariable
            if (channel.type !== definitions.PROVIDER_EBAY) {
                const { history } = this.props;
                const { companyId } = this.state;
                router.toCompany(history, companyId, 'channels');
            }

            this.setState({
                channel,
            });
        });
    };

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    goToBack = () => {
        const { history } = this.props;
        const { company, channel } = this.state;

        router.toCompany(history, company, `channels/${channel.id}/edit/ebay`);
    };

    goToSetupAgain = () => {
        const { history } = this.props;
        const { company, channel } = this.state;

        router.toCompany(history, company, `channels/${channel.id}/setup/ebay`);
    };

    renderSelectSiteIdStep = () => {
        return (
            <form onSubmit={this.handleSelectSiteId}>
                <ManageHeading
                    title={trans('something_went_wrong')}
                    subtitle={trans('ebay_connection_error')}
                />

                <div className="text-center mt-4">
                    <div className="mb-4 mt-4">{trans('ready_to_try_again')}</div>
                    <button
                        className="manage-btn btn btn-primary"
                        type="button"
                        onClick={this.goToSetupAgain}
                    >
                        Retry
                    </button>
                </div>
            </form>
        );
    };

    render() {
        const { step, user, company, isLoading, channel } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel step={step}>
                    <CarouselPane onPrev={this.goToBack} middle center scroll>
                        {channel && this.renderSelectSiteIdStep()}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(ErrorEbayPage);

ErrorEbayPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
