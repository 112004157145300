import HomePage from './HomePage';
import EditIconPage from './EditIconPage';
import ChangePassword from './ChangePassword';
import ChangeMyDetails from './ChangeMyDetails';
import cannedResponseRoutes from './cannedResponses';
import signatureRoutes from './signatures';
import userTokenRoutes from './userTokens';
import ChangeTimezone from './ChangeTimezone';

export default [
    {
        path: '/myAccount',
        middleware: ['auth'],
        component: HomePage,
    },
    {
        path: '/myAccount/editIcon',
        middleware: ['auth'],
        component: EditIconPage,
    },
    {
        path: '/myAccount/changePassword',
        middleware: ['auth'],
        component: ChangePassword,
    },
    {
        path: '/myAccount/changeMyDetails',
        middleware: ['auth'],
        component: ChangeMyDetails,
    },
    {
        path: '/myAccount/changeTimezone',
        middleware: ['auth'],
        component: ChangeTimezone,
    },
    ...cannedResponseRoutes,
    ...signatureRoutes,
    ...userTokenRoutes,
];
