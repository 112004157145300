import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api } from '../../services';
import { AuthCardView } from '../../components/auth';
import AutoLoadCompleteInput from '../../components/helpdesk/AutoLoadCompleteInput';
import { FormButton, FormInput, FormTextButton } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';
import definitions from '../../definitions';

class EditCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company: null,
            isLoading: true,
        };
    }

    componentDidMount() {
        const fetchPromises = Promise.all([this.fetchCompany()]);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { history, match } = this.props;
        const { company } = match.params;

        Api.request(`companies/${company}`, true)
            .then(res => {
                const response = res.data.data;
                if (response.role !== definitions.ROLE_ADMIN) {
                    history.push(`/`);
                }

                this.setState({
                    company: response,
                });
            })
            .catch(() => history.push(`/`))
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    onSubmit = event => {
        event.preventDefault();

        const { isSending, company } = this.state;
        const { history } = this.props;

        if (isSending) {
            return false;
        }

        this.setState({
            isLoading: true,
        });

        return Api.request({
            method: 'PUT',
            url: `companies/${company.slug}/edit`,
            data: {
                // don't send whole company!
                name: company.name,
                timezone: company.timezone,
                api_domain: company.api_domain,
                api_key: company.api_key,
            },
        })
            .then(() => {
                history.push(`/`);
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    searchTimezone = query => {
        if (!query) return Promise.resolve([]);

        return Api.request({
            method: 'GET',
            url: `timezones?name=${query}`,
        })
            .then(response => {
                return response.data.data;
            })
            .catch(() => {
                return [];
            });
    };

    selectTimezone = timezone => {
        const { company } = this.state;

        this.setState({
            company: {
                ...company,
                timezone: timezone.timezone,
            },
        });

        return Promise.resolve();
    };

    onChange = event => {
        const { company } = this.state;

        this.setState({
            company: {
                ...company,
                [event.target.name]: event.target.value,
            },
        });
    };

    render() {
        const { isLoading, company } = this.state;

        if (!company) {
            // wait till load whole company data
            return null;
        }

        return (
            <AuthLayout isLoading={isLoading}>
                {company && (
                    <AuthCardView centerHeading title={trans('edit_x', { x: company.name })}>
                        <form onSubmit={this.onSubmit}>
                            <FormInput
                                labelText={trans('attributes.company_name')}
                                onChange={this.onChange}
                                type="text"
                                name="name"
                                value={company.name}
                                placeholder={trans('placeholders.company_name')}
                            />
                            <div className="form-group pt-1">
                                <label className="dc-form-label">{trans('timezone')}</label>
                                <div className="auth-autoload-theme">
                                    <AutoLoadCompleteInput
                                        value={(company && company.timezone) || ''}
                                        canCreate={false}
                                        onSearch={this.searchTimezone}
                                        onClick={this.selectTimezone}
                                        itemMapper={x => ({
                                            ...x,
                                            value: x.timezone,
                                            label: (
                                                <div className="font-weight-normal p-1 cursor-pointer hover-effect">
                                                    {x.name}
                                                </div>
                                            ),
                                        })}
                                        placeholder={trans('placeholders.type_to_search_timezone')}
                                        scrollbarClassnames="timezone-scrollbar slim-gray"
                                        scroll
                                        showSelectedValue
                                        suggestion
                                        fullWidth
                                    />
                                </div>
                            </div>

                            <div className="mt-4 pt-1 d-flex align-items-center">
                                <FormTextButton
                                    type="link"
                                    to="/"
                                    text={trans('back_to_company_list')}
                                />

                                <div className="ml-auto">
                                    <FormButton
                                        text={trans('save_changes')}
                                        disabled={isLoading}
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </form>
                    </AuthCardView>
                )}
            </AuthLayout>
        );
    }
}

export default withRouter(EditCompany);

EditCompany.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
