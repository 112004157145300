import HomePage from './HomePage';
import myAccountRoutes from './myAccount';

export default [
    {
        path: '/',
        middleware: ['auth'],
        exact: true,
        component: HomePage,
    },
    ...myAccountRoutes,
];
