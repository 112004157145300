import React from 'react';
import PropTypes from 'prop-types';
import { DateTime, HoverTooltip } from '../../index';
import trans from '../../../localization';
import definitions from '../../../definitions';
import { AuthService } from '../../../services';

const MessageTime = ({ message, tryAgain, cancelSending }) => {
    if (message.user && message.type === definitions.TYPE_MESSAGE) {
        // noinspection JSUnresolvedVariable
        if (message.is_failed) {
            return (
                <div>
                    <span>{trans('we_could_not_send_the_message')}</span>

                    <button
                        type="button"
                        onClick={() => tryAgain(message)}
                        className="btn btn-link btn-sm"
                    >
                        {trans('try_again')}
                    </button>
                </div>
            );
        }

        // noinspection JSUnresolvedVariable
        if (message.sent_at) {
            // noinspection JSUnresolvedVariable
            return (
                <div>
                    <DateTime datetime={message.sent_at} /> -
                    <DateTime datetime={message.sent_at} diff />
                </div>
            );
        }

        return (
            <div className="d-flex justify-content-end align-items-center">
                {trans('sending')}...
                {message.user.id === AuthService.user().id && message.can_cancel && (
                    <HoverTooltip title={trans('click_to_cancel_sending_message')}>
                        <div
                            role="button"
                            tabIndex="0"
                            onClick={cancelSending}
                            className="d-flex justify-content-center align-items-center p-2 cursor-pointer"
                        >
                            <i className="fa fa-times" />
                        </div>
                    </HoverTooltip>
                )}
            </div>
        );
    }

    // noinspection JSUnresolvedVariable
    return (
        <div>
            <DateTime datetime={message.created_at} /> -{' '}
            <DateTime datetime={message.created_at} diff />
        </div>
    );
};

export default MessageTime;

MessageTime.propTypes = {
    message: PropTypes.object.isRequired,
    tryAgain: PropTypes.func.isRequired,
    cancelSending: PropTypes.func.isRequired,
};
