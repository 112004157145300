import React from 'react';
import PropTypes from 'prop-types';

const SwitchCheckbox = ({ label, type, checked, ...attrs }) => {
    return (
        <div
            className={`switch-checkbox ${checked ? 'switch-checkbox--checked' : ''} ${
                type ? `switch-checkbox--${type}` : ''
            }`}
        >
            <label className="d-flex align-items-center">
                <div className="switch-checkbox__switch">
                    <input
                        type="checkbox"
                        checked={checked}
                        className="switch-checkbox__checkbox"
                        {...attrs}
                    />
                    <div className="switch-checkbox__check" />
                </div>
                {label && (
                    <div
                        className={`text-xsmall pl-2 ${
                            checked || type !== 'manage' ? 'text-dark' : 'text-light-gray'
                        }`}
                    >
                        {label}
                    </div>
                )}
            </label>
        </div>
    );
};

export default SwitchCheckbox;

SwitchCheckbox.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    checked: PropTypes.bool,
};

SwitchCheckbox.defaultProps = {
    label: null,
    type: null,
    checked: false,
};
