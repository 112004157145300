import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CarouselPane, Spinner, StepCarousel } from '../../../components';
import trans from '../../../localization';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';
import definitions from '../../../definitions';
import router from '../../../utils/router';

class ConnectEbayPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            channelId: match.params.channelId,
            channel: null,
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            step: 0,
        };
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchChannel()]).finally(() => {
            this.setState({
                isLoading: false,
            });

            this.tryToConnect();
        });
    }

    fetchChannel = () => {
        const { channelId } = this.state;

        return Api.request({
            method: 'GET',
            url: `channels/${channelId}`,
        }).then(response => {
            const channel = response.data.data;
            // noinspection JSUnresolvedVariable
            if (channel.type !== definitions.PROVIDER_EBAY) {
                const { history } = this.props;
                const { companyId } = this.state;
                router.toCompany(history, companyId, 'channels');
            }

            this.setState({
                channel,
            });
        });
    };

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    tryToConnect = () => {
        const { company, channel } = this.state;
        const { history } = this.props;

        return Api.request({
            method: 'POST',
            url: `channels/${channel.id}/ebay/connect`,
        })
            .then(() => {
                router.toCompany(history, company, `channels/${channel.id}/setup/ebay/success`);
            })
            .catch(() => {
                router.toCompany(history, company, `channels/${channel.id}/setup/ebay/error`);
            });
    };

    goToBack = () => {
        const { history } = this.props;
        const { company, channel } = this.state;

        router.toCompany(history, company, `channels/${channel.id}/setup/ebay`);
    };

    renderSelectSiteIdStep = () => {
        const { channel } = this.state;

        return (
            <form onSubmit={this.handleSelectSiteId}>
                <ManageHeading
                    title={trans('connect_your_x', {
                        name: <strong>{channel.name}</strong>,
                    })}
                />

                <div className="my-4 text-center">
                    <Spinner />
                </div>
            </form>
        );
    };

    render() {
        const { step, user, company, isLoading, channel } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel step={step}>
                    <CarouselPane onPrev={this.goToBack} middle>
                        {channel && this.renderSelectSiteIdStep()}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(ConnectEbayPage);

ConnectEbayPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
