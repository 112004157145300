import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import trans from '../../localization';
import FilterOption from './FilterOption';
import definitions from '../../definitions';
import helpers from '../../helpers';
import UserIcon from './UserIcon';
import TicketAttachments from './ticket/attachments/TicketAttachments';
import TicketOverdue from './ticket/TicketOverdue';
// import TicketResolution from './ticket/TicketResolution';
import ListGroupContent from './ListGroupContent';
import CompanyService from '../../services/CompanyService';
import AutoLoadCompleteInput from './AutoLoadCompleteInput';
import MapperService from '../../services/MapperService';
import router from '../../utils/router';

class SidebarTicket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleAttachments: true,
        };

        this.onPriorityChange = this.onPriorityChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onSearchTags = this.onSearchTags.bind(this);
        this.onSearchUsers = this.onSearchUsers.bind(this);
        this.onTicketTypeChange = this.onTicketTypeChange.bind(this);
        this.onTicketSourceChange = this.onTicketSourceChange.bind(this);
    }

    onStatusChange(event) {
        const { ticket, updateTicket } = this.props;
        const { value } = event.target;

        updateTicket(ticket.id, definitions.KEY_STATUS, value);
    }

    onPriorityChange(event) {
        const { ticket, updateTicket } = this.props;
        const { value } = event.target;

        updateTicket(ticket.id, definitions.KEY_PRIORITY, value);
    }

    onTicketTypeChange(event) {
        const { ticket, updateTicket } = this.props;
        const { value } = event.target;

        updateTicket(ticket.id, definitions.KEY_TICKET_TYPE_ID, value);
    }

    onTicketSourceChange(event) {
        const { ticket, updateTicket } = this.props;
        const { value } = event.target;

        updateTicket(ticket.id, definitions.KEY_SOURCE_ID, value);
    }

    toggleAttachments() {
        const { toggleAttachments } = this.state;
        this.setState({ toggleAttachments: !toggleAttachments });
    }

    onSearchTags(query, options) {
        const { company, ticket } = this.props;
        return new Promise((resolve, reject) => {
            CompanyService.tags(company, query, {
                params: {
                    ticket_id: ticket.id,
                },
                ...options,
            })
                .then(response => resolve(response.data.data))
                .catch(reject);
        });
    }

    onSearchUsers(query, options) {
        const { company, ticket } = this.props;
        return new Promise((resolve, reject) => {
            CompanyService.users(company, query, {
                params: {
                    ticket_id: ticket.id,
                },
                ...options,
            })
                .then(response => resolve(response.data.data))
                .catch(reject);
        });
    }

    render() {
        const { toggleAttachments } = this.state;
        const {
            ticket,
            addTag,
            deleteTag,
            assignUser,
            removeAssignedUser,
            company,
            canFetchOrders,
            onFetchOrders,
            ticketTypes,
            showSources,
            sources,
        } = this.props;
        const statuses = helpers.getStatuses();
        const priorities = helpers.getPriorities();

        return (
            <div className="sidebar-navigation sit-right">
                <div className="filters">
                    <PerfectScrollbar>
                        {canFetchOrders && (
                            <div className="filter-option mx-4">
                                <button
                                    type="button"
                                    onClick={onFetchOrders}
                                    className="btn-related-orders align-items-center bg-transparent border-0 d-flex p-0 text-dark-gray text-decoration-none text-hover-light-gray"
                                >
                                    <div className="circle-icon-button">
                                        <i className="fas fa-shopping-cart" />
                                    </div>
                                    <span className="text-small pl-2">
                                        {trans('related_orders')}
                                    </span>
                                </button>
                            </div>
                        )}

                        <FilterOption
                            name="status"
                            title={trans('status')}
                            value={ticket.status}
                            options={statuses}
                            onChange={this.onStatusChange}
                        />

                        <FilterOption
                            name="priority"
                            title={trans('priority')}
                            value={ticket.priority}
                            options={priorities}
                            onChange={this.onPriorityChange}
                        />

                        {showSources && (
                            <FilterOption
                                name="source_id"
                                title={trans('channel')}
                                value={ticket.source && ticket.source.id}
                                options={sources}
                                onChange={this.onTicketSourceChange}
                                valueKey="id"
                                colorKey="color"
                                labelKey="name"
                                useColor
                            />
                        )}

                        {ticketTypes.length > 0 && (
                            <FilterOption
                                name="ticket_type_id"
                                title={trans('ticket_type')}
                                value={ticket.ticketType && ticket.ticketType.id}
                                options={ticketTypes}
                                onChange={this.onTicketTypeChange}
                                valueKey="id"
                                colorKey="color"
                                labelKey="name"
                                useColor
                            />
                        )}

                        <div className="filter-option mx-4">
                            <div className="list-group">
                                <div className="list-group-title">
                                    <h5>{trans('assigned_agent')}</h5>
                                </div>

                                {!!ticket.users.length &&
                                    ticket.users.map(x => (
                                        <div className="d-flex align-items-center mb-1" key={x.id}>
                                            <UserIcon user={x} className="agent-icon" />
                                            <div className="ml-2 text-truncate">{x.name}</div>
                                            <a
                                                href="#remove-assigned-user"
                                                role="button"
                                                tabIndex={0}
                                                onClick={() => removeAssignedUser(x)}
                                                className="tag ml-auto flex-shrink-0 cursor-pointer text-dark-gray"
                                            >
                                                <i className="fad fa-times-circle" />
                                            </a>
                                        </div>
                                    ))}

                                <AutoLoadCompleteInput
                                    canCreate={false}
                                    onSearch={this.onSearchUsers}
                                    onClick={assignUser}
                                    itemMapper={x => ({
                                        id: x.id,
                                        label: (
                                            <div
                                                className={cx(
                                                    `list-group-item list-group-item-action active font-weight-normal py-1 pl-4 cursor-pointer`
                                                )}
                                            >
                                                {x.name}
                                            </div>
                                        ),
                                        value: x.name,
                                    })}
                                    suggestion
                                />
                            </div>
                        </div>

                        <div className="filter-option mx-4">
                            <div className="list-group">
                                <div className="list-group-title">
                                    <h5>{trans('add_tags')}</h5>
                                </div>

                                <AutoLoadCompleteInput
                                    onCreate={addTag}
                                    onClick={addTag}
                                    onSearch={this.onSearchTags}
                                    itemMapper={MapperService.mapAutoComplete}
                                    suggestion
                                />

                                <div className="tags">
                                    {ticket.tags.length > 0 &&
                                        ticket.tags.map(x => (
                                            <button
                                                key={x.id}
                                                type="button"
                                                className="tag"
                                                onClick={() => deleteTag(x)}
                                            >
                                                {x.name}
                                                <span className="ml-1">
                                                    <i className="fad fa-times-circle" />
                                                </span>
                                            </button>
                                        ))}
                                </div>
                            </div>
                        </div>

                        {ticket.attachments.length ? (
                            <div className="filter-option mx-4">
                                <div className="list-group">
                                    <div className="list-group-title">
                                        <h5>{trans('attachments')}</h5>
                                        <div
                                            className="text-off-black mb-2 toggle-button"
                                            role="button"
                                            tabIndex="0"
                                            onClick={() => this.toggleAttachments()}
                                        >
                                            <i
                                                className={cx('icon fa fa-chevron-up', {
                                                    down: toggleAttachments,
                                                })}
                                            />
                                        </div>
                                    </div>
                                    {toggleAttachments && (
                                        <ListGroupContent show={toggleAttachments}>
                                            <TicketAttachments attachments={ticket.attachments} />
                                        </ListGroupContent>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}

                        {!!ticket.due_at && (
                            <div className="filter-option mx-4">
                                <TicketOverdue
                                    datetime={ticket.due_at}
                                    isOverdue={ticket.is_overdue}
                                    format="DD MMMM YYYY, hh:mm"
                                    expanded
                                />
                            </div>
                        )}

                        {/*
                            !!ticket.resolution_at && (
                            <div className="filter-option mx-4">
                                <TicketResolution
                                    datetime={ticket.resolution_at}
                                    isOverdue={ticket.is_resolution}
                                    format="DD MMMM YYYY, hh:mm"
                                    expanded
                                />
                            </div>
                        ) */}

                        {company.role === 'admin' && (
                            <div className="filter-option mx-4">
                                <Link
                                    to={router.pathCompany(company, `tickets/${ticket.id}/logs`)}
                                    className="text-dark-gray text-hover-light-gray d-flex align-items-center text-decoration-none"
                                >
                                    <div className="circle-icon-button">
                                        <i className="fa fa-history" />
                                    </div>
                                    <span className="text-small pl-2">
                                        {trans('open_ticket_logs')}
                                    </span>
                                </Link>
                            </div>
                        )}
                    </PerfectScrollbar>
                </div>
            </div>
        );
    }
}

SidebarTicket.propTypes = {
    ticket: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    updateTicket: PropTypes.func,
    assignUser: PropTypes.func,
    removeAssignedUser: PropTypes.func,
    addTag: PropTypes.func,
    deleteTag: PropTypes.func,
    canFetchOrders: PropTypes.bool,
    onFetchOrders: PropTypes.func,
    ticketTypes: PropTypes.array,
    showSources: PropTypes.bool,
    sources: PropTypes.array,
};

SidebarTicket.defaultProps = {
    updateTicket: () => {},
    assignUser: () => {},
    removeAssignedUser: () => {},
    addTag: () => {},
    deleteTag: () => {},
    canFetchOrders: false,
    onFetchOrders: null,
    ticketTypes: [],
    showSources: false,
    sources: [],
};

export default SidebarTicket;
