import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import { withRouter } from 'react-router-dom';
import { ProgressOverlay } from '../components';
import User from '../components/helpdesk/User';
import { ManageLayoutIllustrationBL, ManageLayoutIllustrationTR } from '../elements/svgs';
import router from '../utils/router';

class ManageLayout extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isStarted: props.isLoading,
        };
    }

    componentDidMount() {
        const { isLoading } = this.props;

        if (isLoading) {
            NProgress.start();
        }
    }

    componentDidUpdate() {
        const { isLoading } = this.props;
        const { isStarted } = this.state;

        if (isLoading !== isStarted) {
            this.changeProgressBar(isLoading);
        }
    }

    componentWillUnmount() {
        NProgress.done();
    }

    changeProgressBar(isLoading) {
        if (isLoading) {
            NProgress.start();
            this.setState({
                isStarted: isLoading,
            });
        } else {
            NProgress.done();
            this.setState({
                isStarted: isLoading,
            });
        }
    }

    openHelpdesk() {
        const { company, history } = this.props;
        if (company) {
            router.toCompany(history, company);
        } else {
            router.to(history, '/');
        }
    }

    render() {
        const {
            children,
            isLoading,
            user,
            company,
            theme,
            hideLBImg,
            hideMyAccountLink,
        } = this.props;

        return (
            <div>
                <div className="manage-layout">
                    {hideLBImg === false && (
                        <div className="left-bottom-img">
                            <ManageLayoutIllustrationBL theme={theme} />
                        </div>
                    )}
                    <div className="right-top-img">
                        <ManageLayoutIllustrationTR theme={theme} />
                    </div>
                    <div className="relative min-vh-100 d-flex flex-column">
                        <div className="w-100">
                            <User
                                user={user}
                                companyId={company && company.slug}
                                role={company && company.role}
                                onClick={() => this.openHelpdesk()}
                                hideMyAccountLink={hideMyAccountLink}
                                showStatusText
                            />
                        </div>
                        <div className="flex-grow-1 d-flex flex-column">{children}</div>
                    </div>
                </div>
                <ProgressOverlay isLoading={isLoading} />
            </div>
        );
    }
}

export default withRouter(ManageLayout);

ManageLayout.propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    user: PropTypes.object,
    company: PropTypes.object,
    theme: PropTypes.string,
    hideLBImg: PropTypes.bool,
    hideMyAccountLink: PropTypes.bool,
};

ManageLayout.defaultProps = {
    isLoading: false,
    theme: 'purple',
    user: null,
    company: null,
    hideLBImg: false,
    hideMyAccountLink: false,
};
