import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import trans from '../../../localization';
import TicketReply from './TicketReply';
import TicketAttachments from './TicketAttachments';

const ActivityTicketReply = props => {
    const { activity, seeTicket, containerClassName, onMoreClick } = props;
    // eslint-disable-next-line camelcase
    const { user, customer, ticket_message } = activity;

    return (
        <Container
            activity={activity}
            buttonText={trans('see_ticket')}
            onButtonClick={seeTicket}
            className={containerClassName}
            onMoreClick={onMoreClick}
        >
            <p className="text-dark text-small">
                {trans('x_has_replied_to_ticket_you_are_assigned_to', {
                    name: (customer && customer.name) || (user && user.name),
                })}
            </p>
            <TicketReply activity={activity} />
            {/* eslint-disable-next-line camelcase */}
            {ticket_message &&
                ticket_message.attachments &&
                ticket_message.attachments.length > 0 && (
                    <TicketAttachments attachments={ticket_message.attachments} />
                )}
        </Container>
    );
};

export default ActivityTicketReply;

ActivityTicketReply.propTypes = {
    activity: PropTypes.object.isRequired,
    seeTicket: PropTypes.func,
    containerClassName: PropTypes.string,
    onMoreClick: PropTypes.func,
};

ActivityTicketReply.defaultProps = {
    seeTicket: () => {},
    containerClassName: null,
    onMoreClick: null,
};
