import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../localization';

const ModalActionBar = props => {
    const { onUnlink, onCancel, onSave, canUnlink, canSave } = props;

    return (
        <div className="modal-action-bar w-100 d-flex align-items-center justify-content-between px-4 py-3">
            {canUnlink ? (
                <div className="field d-flex flex-grow-1  justify-content-start align-items-center">
                    <button
                        type="button"
                        onClick={onUnlink}
                        className="btn btn-link unlink-btn text-xsmall text-red"
                    >
                        <i className="fa fa-trash-alt" /> {trans('unlink_agent')}
                    </button>
                </div>
            ) : (
                <span className="text-danger small">{trans('you_can_not_change_own_profile')}</span>
            )}
            <div className="field d-flex flex-grow-1  justify-content-end align-items-center">
                <button
                    type="button"
                    onClick={onCancel}
                    className="btn cancel-btn border-radius-circle mr-3"
                >
                    <i className="fa fa-times" />
                </button>
                {canSave && (
                    <button type="button" onClick={onSave} className="btn btn-primary save-btn">
                        {trans('save_changes')}
                    </button>
                )}
            </div>
        </div>
    );
};

export default ModalActionBar;

ModalActionBar.propTypes = {
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    onUnlink: PropTypes.func,
    canUnlink: PropTypes.bool,
    canSave: PropTypes.bool,
};

ModalActionBar.defaultProps = {
    onCancel: () => {},
    onSave: () => {},
    onUnlink: () => {},
    canUnlink: false,
    canSave: true,
};
