import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const Information = props => {
    const {
        activity,
        onClick,
        buttonText,
        activityImage,
        activityText,
        activityTextClassName,
        buttonDisabled,
    } = props;

    const { company } = activity;

    return (
        <div className="activity-information d-flex mw-100 flex-wrap">
            {activityImage && (
                <img
                    className="activity-information-image p-1 pr-2 d-none d-lg-block flex-all-1"
                    src={activityImage}
                    alt="Activity illustration"
                />
            )}
            {activityText && (
                <div className="d-flex flex-column justify-content-center align-items-end flex-all-1">
                    <p>
                        <span className={`text-dark text-small ${activityTextClassName}`}>
                            {activityText}
                        </span>
                        <span className="text-dark font-weight-bold text-small">
                            {' '}
                            {company && company.name}
                        </span>
                    </p>
                    <Button
                        onClick={onClick}
                        text={buttonText}
                        withoutContainer
                        disabled={buttonDisabled}
                    />
                </div>
            )}
        </div>
    );
};

export default Information;

Information.propTypes = {
    activity: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    activityImage: PropTypes.any,
    activityText: PropTypes.string,
    activityTextClassName: PropTypes.string,
    buttonDisabled: PropTypes.bool,
};

Information.defaultProps = {
    onClick: () => {},
    buttonText: null,
    activityImage: null,
    activityText: null,
    activityTextClassName: null,
    buttonDisabled: false,
};
