import * as moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';
import HoverTooltip from '../../HoverTooltip';

const TicketRepliedAt = ({ isReplied, lastUnRepliedMessageAt }) => {
    const instance = lastUnRepliedMessageAt ? moment(lastUnRepliedMessageAt) : moment();
    const fullDate = instance.format('MMM D YYYY, hh:mm A');

    const classNames = !isReplied
        ? `bg-white text-warning border-1 border-radius-4 px-1`
        : 'text-dark-gray';

    const fullText = isReplied
        ? trans('replied_at_x', { diff: fullDate })
        : trans('not_replied', { diff: fullDate });

    const text = instance.fromNow();

    return (
        <HoverTooltip title={fullText}>
            <span className={classNames}>{text}</span>
        </HoverTooltip>
    );
};

export default TicketRepliedAt;

TicketRepliedAt.propTypes = {
    isReplied: PropTypes.bool,
    lastUnRepliedMessageAt: PropTypes.string,
};

TicketRepliedAt.defaultProps = {
    isReplied: false,
    lastUnRepliedMessageAt: null,
};
