import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import { CarouselPane, StepCarousel } from '../../../components';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import trans from '../../../localization';
import integrationImages from '../../../assets/images/integrations';
import router from '../../../utils/router';

class ListPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            companyId: match.params && match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            integrations: [],
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchIntegrations()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchIntegrations() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `integrations?companyId=${companyId}`,
        }).then(response => {
            this.setState({
                integrations: response.data.data,
            });
        });
    }

    onClick = type => {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company, `integrations/${type}/edit`);
    };

    goBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    getProviderLogo = type => integrationImages[type];

    getProviderName = type => trans(`integration_types.${type}`);

    render() {
        const { isLoading, user, company, integrations } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="blue" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane onPrev={this.goBack} scroll center middle>
                        <div>
                            <ManageHeading
                                title={trans('integrations')}
                                subtitle={trans('integrations_list_text')}
                            />
                            <div className="integration-list pt-5">
                                {integrations.map(x => (
                                    <div
                                        key={`integration-${x.id}`}
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => this.onClick(x.provider_type)}
                                        className="d-flex flex-column justify-content-center align-items-center p-2 cursor-pointer"
                                    >
                                        <div className="shadow-box-container integration-box">
                                            <img
                                                src={this.getProviderLogo(x.provider_type)}
                                                alt={this.getProviderName(x.provider_type)}
                                            />
                                        </div>
                                        <span className="text-dc-gray text-small py-2 integration-name text-align-center">
                                            {this.getProviderName(x.provider_type)}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

ListPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(ListPage);
