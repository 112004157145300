import React from 'react';
import PropTypes from 'prop-types';
import trans from '../localization';

const LoadingBar = props => {
    const { value } = props;

    return (
        <div className="progress">
            <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-label={trans('loading')}
                aria-valuenow={value}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${100}%` }}
            />
        </div>
    );
};

export default LoadingBar;

LoadingBar.propTypes = {
    value: PropTypes.number,
};

LoadingBar.defaultProps = {
    value: 100,
};
