import React from 'react';
import PropTypes from 'prop-types';

const ManageHeading = ({ title, subtitle }) => {
    return (
        <div className="align-items-center d-flex flex-column manage-heading">
            <h1 className="title">{title}</h1>
            {subtitle && <p className="subtitle">{subtitle}</p>}
        </div>
    );
};

ManageHeading.propTypes = {
    title: PropTypes.any.isRequired,
    subtitle: PropTypes.string,
};

ManageHeading.defaultProps = {
    subtitle: null,
};

export default ManageHeading;
