import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ManageInput = ({ className, append, valueTemplate, onEnter, ...others }) => {
    return (
        <div className={cx('manage-input-wrapper', className)}>
            <div className="manage-input-value-template">{!!valueTemplate && valueTemplate}</div>
            <input
                className={cx('manage-input', valueTemplate && 'manage-input--custom-value')}
                onKeyDown={e => e.key === 'Enter' && onEnter()}
                {...others}
            />
            {!!append && <div className="manage-input-append">{append}</div>}
        </div>
    );
};

export default ManageInput;

ManageInput.propTypes = {
    append: PropTypes.any,
    className: PropTypes.string,
    valueTemplate: PropTypes.any,
    onEnter: PropTypes.func,
};

ManageInput.defaultProps = {
    className: '',
    append: null,
    valueTemplate: null,
    onEnter: () => {},
};
