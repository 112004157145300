import { AuthService } from '../services';

const goToHomeInAuth = history => {
    const path = AuthService.check() ? '/' : '/auth/signIn';
    return history ? history.push(path) : null;
};

export default {
    goToHomeInAuth,
};
