import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import UserIcon from './UserIcon';
import trans from '../../localization';

const User = ({
    className,
    user,
    isAway,
    showStatusText,
    role,
    companyId,
    onClick,
    hideMyAccountLink,
}) => {
    const onControlledClick = e => {
        if (e.target.id === 'my-account-link') return;
        if (typeof onClick === 'function') onClick(e);
    };

    return (
        <div
            tabIndex={0}
            role="button"
            onClick={onControlledClick}
            className={cx('user py-4 px-5 d-flex', { 'cursor-pointer': !!onClick }, className)}
        >
            <UserIcon user={user} isAway={isAway} showStatusText={showStatusText} />
            <div className="info d-flex flex-column justify-content-center ml-3">
                <span className="name d-block">{user.name}</span>
                <div>
                    {role && <span className="mr-3 role">{trans(`roles.${role}`)}</span>}
                    {hideMyAccountLink === false && (
                        <Link
                            className="account-link text-body"
                            to={{
                                pathname: '/myAccount',
                                state: {
                                    companyId,
                                },
                            }}
                        >
                            <span id="my-account-link">
                                <i className="fal fa-cog px-1" />
                                {trans('my_account')}
                            </span>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default User;
User.propTypes = {
    user: PropTypes.object,
    isAway: PropTypes.bool,
    showStatusText: PropTypes.bool,
    role: PropTypes.string,
    companyId: PropTypes.string,
    onClick: PropTypes.func,
    hideMyAccountLink: PropTypes.bool,
    className: PropTypes.any,
};

User.defaultProps = {
    user: null,
    isAway: false,
    showStatusText: false,
    role: '',
    companyId: null,
    onClick: null,
    hideMyAccountLink: false,
    className: null,
};
