import React from 'react';
import { Route } from 'react-router-dom';
import Kernel from './Kernel';
import appRoutes from '../pages/app';
import authRoutes from '../pages/auth';
import helpdeskRoutes from '../pages/helpdesk';
import callbackRoutes from '../pages/callback';
import NotFoundPage from '../pages/errors/NotFoundPage';
import AuthorizationErrorPage from '../pages/errors/AuthorizationErrorPage';

const routes = [...appRoutes, ...helpdeskRoutes, ...authRoutes, ...callbackRoutes];

const extra = (
    <>
        <Route path="/errors/notFound" component={NotFoundPage} exact />
        <Route path="/errors/authorization" component={AuthorizationErrorPage} exact />
    </>
);

export default new Kernel().boot(routes, extra);
