import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './sass/app.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'air-datepicker/dist/css/datepicker.min.css';
import './boostrap';
import './viewport-unit';
import authService from './services/AuthService';
import './services/EventService';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

authService.init().then(() => {
    // eslint-disable-next-line no-undef
    ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
