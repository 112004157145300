import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api, ApiService, AuthService } from '../../services';
import { AuthCardView } from '../../components/auth';
import { FormButton, FormInput } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';
import authService from '../../services/AuthService';

class ResetPasswordPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: {
                password: '',
                password_confirmation: '',
            },
            errors: {},
            isSending: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        const { isSending, data } = this.state;
        const { history } = this.props;

        if (isSending) {
            return;
        }

        this.setState({
            isSending: true,
        });

        const query = ApiService.formatSearch(this.props);

        Api.request({
            method: 'POST',
            url: `auth/resetPassword?${query}`,
            data,
        })
            .then(() => {
                if (authService.user()) {
                    const user = authService.user();
                    user.email_verified = true;
                    AuthService.setUser(user);
                }

                history.push('/');
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                });
            })
            .finally(() => {
                this.setState({
                    isSending: false,
                });
            });
    }

    onChange(event) {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    }

    render() {
        const { isSending, errors, data } = this.state;

        // eslint-disable-next-line camelcase
        const { password, password_confirmation: passwordConfirmation } = data;

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView
                    title={trans('we_have_got_you')}
                    subtitle={trans('reset_password_subtitle')}
                >
                    <form onSubmit={this.onSubmit}>
                        <FormInput
                            className="pt-2"
                            labelText={trans('attributes.new_password')}
                            validationError={errors.password}
                            onChange={this.onChange}
                            value={password}
                            type="password"
                            name="password"
                            placeholder={trans('placeholders.password')}
                        />

                        <FormInput
                            className="pt-2 mb-4"
                            labelText={trans('attributes.new_password_confirmation')}
                            validationError={errors.password_confirmation}
                            onChange={this.onChange}
                            value={passwordConfirmation}
                            type="password"
                            name="password_confirmation"
                            placeholder={trans('placeholders.password_confirmation')}
                        />

                        <div className="row pt-4">
                            <div className="col d-flex">
                                <FormButton
                                    text={trans('reset_password')}
                                    disabled={isSending}
                                    type="submit"
                                    className="flex-grow-1 text-uppercase"
                                />
                            </div>
                        </div>
                    </form>
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(ResetPasswordPage);

ResetPasswordPage.propTypes = {
    history: PropTypes.object.isRequired,
};
