import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import { logo } from '../../assets/images';
import routeHelpers from '../../routes/routeHelpers';

const AuthCardView = ({
    history,
    children,
    title,
    subtitle,
    titlePreLink,
    titlePreLinkTo,
    centerHeading,
    titleSmall,
    centerHeadingSmall,
    alwaysShowSubtitle,
}) => {
    const goToHome = () => {
        routeHelpers.goToHomeInAuth(history);
    };

    return (
        <div className="new-auth-card-view">
            <PerfectScrollbar className="new-auth-card-view-scroll">
                <div className="new-auth-card-view-scroll-content">
                    <div className="new-auth-card-view-scroll-content-field logo">
                        <div role="button" tabIndex="0" onClick={goToHome}>
                            <img src={logo} alt="Despatch Cloud" />
                        </div>
                    </div>
                    <div className="new-auth-card-view-scroll-content-field heading">
                        <div
                            className={cx(
                                { 'text-center': centerHeading },
                                { 'heading-center-sm': centerHeadingSmall }
                            )}
                        >
                            <span className="title">
                                {titlePreLink && (
                                    <Link
                                        className="title-prelink dc-dark-link"
                                        to={titlePreLinkTo}
                                    >
                                        {titlePreLink}
                                    </Link>
                                )}
                                {title}
                            </span>
                            <span className="title-sm">{titleSmall || title}</span>
                            <br />
                            <span
                                className={cx('subtitle', {
                                    'subtitle-always-show': alwaysShowSubtitle,
                                })}
                            >
                                {subtitle}
                            </span>
                        </div>
                    </div>
                    <div className="new-auth-card-view-scroll-content-field body">{children}</div>
                </div>
            </PerfectScrollbar>
        </div>
    );
};

export default withRouter(AuthCardView);

AuthCardView.propTypes = {
    history: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    titleSmall: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    titlePreLink: PropTypes.string,
    titlePreLinkTo: PropTypes.string,
    centerHeadingSmall: PropTypes.bool,
    centerHeading: PropTypes.bool,
    alwaysShowSubtitle: PropTypes.bool,
};

AuthCardView.defaultProps = {
    subtitle: null,
    titleSmall: null,
    titlePreLink: null,
    titlePreLinkTo: null,
    centerHeading: false,
    centerHeadingSmall: false,
    alwaysShowSubtitle: false,
};
