import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CarouselPane, StepCarousel } from '../../../components';
import trans from '../../../localization';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';
import { FormButton, ManageField, ManageInput } from '../../../elements';
import notify from '../../../utils/notify';
import definitions from '../../../definitions';
import router from '../../../utils/router';

class SetupEmailPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            channelId: match.params.channelId,
            channel: null,
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            step: 0,
            email: null,
        };
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchChannel()]).finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchChannel = () => {
        const { channelId } = this.state;

        return Api.request({
            method: 'GET',
            url: `channels/${channelId}`,
        }).then(response => {
            const channel = response.data.data;
            // noinspection JSUnresolvedVariable
            if (
                channel.type !== definitions.PROVIDER_EMAIL ||
                channel.provider.forward_email_verified
            ) {
                const { history } = this.props;
                const { companyId } = this.state;
                router.toCompany(history, companyId, `channels/${channel.id}/edit/email`);
            }

            this.setState({
                channel,
                email: channel.provider.forward_email,
            });
        });
    };

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    handleSubmitSkip = e => {
        if (e) {
            e.preventDefault();
        }

        const { history } = this.props;
        const { company } = this.state;

        notify.saved();
        router.toCompany(history, company, 'channels');
    };

    handleSubmitServer = e => {
        if (e) {
            e.preventDefault();
        }

        const { isLoading, company, channel } = this.state;
        if (isLoading) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: `channels/${channel.id}/email/setup`,
            data: {
                company_id: company.id,
                use_email_server: true,
            },
        })
            .then(() => {
                const { history } = this.props;
                router.toCompany(history, company, `channels/${channel.id}/edit/email`);
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    handleSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        const { isLoading, company, channel, email } = this.state;
        if (!email) {
            this.handleSubmitSkip();
            return;
        }

        if (isLoading) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: `channels/${channel.id}/email/setup`,
            data: {
                company_id: company.id,
                forward_email: email,
            },
        })
            .then(response => {
                const { history } = this.props;
                const updatedChannel = response.data.data;

                if (updatedChannel.provider.forward_email) {
                    router.toCompany(
                        history,
                        company,
                        `channels/${updatedChannel.id}/setup/email/unverified`
                    );
                } else {
                    router.toCompany(
                        history,
                        company,
                        `channels/${updatedChannel.id}/setup/email/finish`
                    );
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    goToBack = () => {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company, 'channels');
    };

    onChange = event => {
        const { target } = event;

        this.setState({
            email: target.value,
        });
    };

    renderStep1 = () => {
        const { email, channel } = this.state;

        return (
            <form onSubmit={this.handleSubmit}>
                <ManageHeading
                    title={
                        <>
                            {trans('setup_your_x')}
                            <span className="font-weight-bold">
                                {channel ? channel.name : trans('channel')}
                            </span>
                        </>
                    }
                />

                <div className="d-flex justify-content-center mt-4 pt-5 setup-email-page">
                    <div className="d-flex flex-column">
                        <PerfectScrollbar className="px-4 manage-form-scroll overflow-hidden d-flex flex-column">
                            <ManageField title={trans('which_email_would_you_like_to_use')}>
                                <ManageInput
                                    name="email"
                                    placeholder={trans('placeholders.email')}
                                    type="text"
                                    onChange={this.onChange}
                                    value={email || ''}
                                    onEnter={this.handleSubmit}
                                />
                            </ManageField>
                        </PerfectScrollbar>

                        <div className="align-items-center d-flex justify-content-end mt-4 px-4">
                            <div className="mr-4 text-small text-light-gray">
                                {trans('this_can_be_changed_later')} *
                            </div>

                            <FormButton
                                type="submit"
                                className="manage-btn"
                                style={{ minWidth: 150 }}
                                text={email ? trans('next_step') : trans('skip')}
                            />
                        </div>

                        <div className="mt-5 pt-3">
                            <button
                                type="button"
                                className="btn btn-link d-flex justify-content-between text-dark w-100 font-18"
                                onClick={this.handleSubmitServer}
                            >
                                <span>{trans('i_want_to_use_my_own_server')}</span>
                                <span>
                                    <i className="fa fa fa-arrow-right" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    render() {
        const { step, user, company, isLoading, channel } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel step={step}>
                    <CarouselPane
                        onNext={this.handleSubmit}
                        onPrev={this.goToBack}
                        middle
                        center
                        scroll
                    >
                        {channel && this.renderStep1()}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(SetupEmailPage);

SetupEmailPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
