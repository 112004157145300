import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api, ApiService, AuthService } from '../../services';
import { SocialLogin } from '../../components';
import { AuthCardView } from '../../components/auth';
import { FormButton, FormInput, FormTextButton } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';
import router from '../../utils/router';

class SignUpPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: {
                email: '',
                name: '',
                company: '',
            },
            errors: {},
            isSending: false,
            isAuthUser: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (!AuthService.guest()) {
            this.setState(
                {
                    isSending: true,
                    isAuthUser: true,
                },
                this.fetchAuthUser
            );
        }
    }

    fetchAuthUser() {
        return Api.request({
            method: 'GET',
            url: 'auth/details',
        }).then(response => {
            if (response.data.data) {
                const { data } = response.data;
                this.setState({
                    data,
                    isSending: false,
                });
            }
        });
    }

    onSubmit(event) {
        event.preventDefault();

        const { isSending, data } = this.state;
        const { history } = this.props;

        if (isSending) {
            return null;
        }

        this.setState({
            isSending: true,
        });

        if (!AuthService.guest()) {
            return Api.request({
                method: 'POST',
                url: 'auth/details',
                data,
            })
                .then(response => {
                    AuthService.setUser(response.data.data);
                    if (!AuthService.hasPassword()) {
                        history.push('/auth/setPassword');
                    }
                })
                .catch(error => {
                    const errors = ApiService.parseErrors(error);
                    this.setState({
                        errors,
                        isSending: false,
                    });
                });
        }

        return Api.request(
            {
                method: 'POST',
                url: 'auth/signUp',
                data,
            },
            true
        )
            .then(response => {
                const { token } = response.data.data;
                AuthService.setToken(token);
                AuthService.fetchUser()
                    .then(() => {
                        router.replace(history, '/auth/setPassword');
                    })
                    .catch(() => {
                        this.setState({
                            isSending: false,
                        });
                    });
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                    isSending: false,
                });
            });
    }

    onChange(event) {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    }

    signOutAndDelete = e => {
        e.preventDefault();
        Api.request({
            url: '/auth/signOutAndDelete',
            method: 'POST',
        }).then(() => {
            const { history } = this.props;
            AuthService.logout();
            history.push('/');
        });

        return false;
    };

    render() {
        const { isSending, errors, data, isAuthUser } = this.state;
        const { name, email, company } = data;

        const emailVerified = AuthService.emailVerified();

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView
                    title={trans('lets_get_started')}
                    subtitle={trans('lets_get_started_subtitle')}
                    titleSmall={trans('sign_up')}
                >
                    <form onSubmit={this.onSubmit}>
                        <FormInput
                            labelText={trans('attributes.your_name')}
                            validationError={errors.name}
                            onChange={this.onChange}
                            type="text"
                            name="name"
                            value={name}
                            placeholder={trans('placeholders.sample_name')}
                        />

                        <FormInput
                            labelText={trans('attributes.your_email')}
                            validationError={errors.email}
                            onChange={this.onChange}
                            type="email"
                            name="email"
                            value={email}
                            placeholder={trans('placeholders.sample_email')}
                            disabled={emailVerified}
                        />

                        <FormInput
                            labelText={trans('attributes.your_company')}
                            validationError={errors.company}
                            onChange={this.onChange}
                            type="text"
                            name="company"
                            value={company}
                            placeholder={trans('placeholders.your_company_name')}
                            disabled={emailVerified}
                        />

                        <div className="mt-4 d-flex align-items-center">
                            {!isAuthUser && (
                                <FormTextButton
                                    type="link"
                                    to="/auth/signIn"
                                    text={trans('i_have_an_account')}
                                />
                            )}
                            {isAuthUser && (
                                <FormTextButton
                                    type="link"
                                    to="/"
                                    onClick={this.signOutAndDelete}
                                    text={trans('i_have_an_account')}
                                />
                            )}
                            <div className="ml-auto">
                                <FormButton
                                    text={isAuthUser ? trans('next') : trans('get_started')}
                                    disabled={isSending || [company, email, name].includes('')}
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(SignUpPage);

SignUpPage.propTypes = {
    history: PropTypes.object.isRequired,
};
