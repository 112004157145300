const to = (history, path, state = {}) => {
    history.push(path, state);
};

const replace = (history, path, state = {}) => {
    history.replace(path, state);
};

const backOrGo = (history, path, state = {}, n = 1) => {
    if (history.length > n) {
        history.go(n * -1);
    } else {
        to(history, path, state);
    }
};

const companySlug = company => {
    return company && company.slug ? company.slug : company;
};

const pathCompany = (company, path = null) => {
    const companyId = companySlug(company);
    return path ? `/companies/${companyId}/${path}` : `/companies/${companyId}`;
};

const toCompany = (history, company, path = null, state = {}) => {
    const fullPath = pathCompany(company, path);
    to(history, fullPath, state);
};

export default {
    to,
    replace,
    backOrGo,
    companySlug,
    pathCompany,
    toCompany,
};
