import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Col, Container, Row } from 'react-bootstrap';
import { CarouselPane, StepCarousel } from '../../../components';
import trans from '../../../localization';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import ManageList from '../../../components/helpdesk/list/ManageList';
import ManageListItem from '../../../components/helpdesk/list/ManageListItem';
import providers from '../../../assets/images/providers';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';
import { FormButton, ManageField, ManageInput } from '../../../elements';
import ColorPicker from '../../../components/helpdesk/manage/ColorPicker';
import IconPicker from '../../../components/helpdesk/manage/IconPicker';
import TicketService from '../../../services/TicketService';
import definitions from '../../../definitions';
import router from '../../../utils/router';
import IconPreviewUpload from '../../../components/helpdesk/manage/IconPreviewUpload';
import CropperModal from '../../../components/helpdesk/CropperModal';

class CreatePage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            step: 0,
            icons: [],
            iconPage: 1,
            iconLastPage: null,
            data: {
                name: '',
                provider_type: null,
                color: null,
                icon: null,
            },
            image: null,
            showCropper: false,
        };

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.fetchCompany().finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    getIcons = () => {
        this.setState({
            isLoading: true,
        });

        const { iconPage } = this.state;

        return Api.request({
            method: 'GET',
            url: 'icons',
            params: {
                page: iconPage,
            },
        })
            .then(response => {
                const { icons } = this.state;

                // noinspection JSUnresolvedVariable
                this.setState({
                    icons: TicketService.merge(response.data.data, icons),
                    iconPage: response.data.meta.current_page,
                    iconLastPage: response.data.meta.last_page,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    handleSubmitName = () => {
        const { step, isLoading, data } = this.state;

        if (isLoading) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: 'channels/create/check',
            data,
        })
            .then(() => {
                if (step < 2) {
                    this.setState(
                        {
                            step: step + 1,
                        },
                        () => {
                            this.getIcons();
                        }
                    );
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    handleSubmitStore = () => {
        const { history } = this.props;
        const { isLoading, data, company } = this.state;
        if (isLoading) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        const formData = { ...data, company_id: company.id };

        if (formData.image) {
            delete formData.icon;
        }

        Api.request({
            method: 'POST',
            url: 'channels/create',
            data: formData,
        })
            .then(response => {
                const channel = response.data.data;
                switch (channel.type) {
                    case definitions.PROVIDER_EMAIL:
                        router.toCompany(history, company, `channels/${channel.id}/setup/email`);
                        break;

                    case definitions.PROVIDER_EBAY:
                        router.toCompany(history, company, `channels/${channel.id}/setup/ebay`);
                        break;

                    default:
                        router.toCompany(history, company, `channels`);
                        break;
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    handleBack = () => {
        const { step } = this.state;
        if (step > 0) {
            this.setState({
                step: step - 1,
            });
        }
    };

    historyBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    onClickProvider = item => {
        const { step, data } = this.state;
        data.provider_type = item.type || item.provider;

        this.setState({
            data,
            step: step + 1,
        });
    };

    onChange = event => {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    };

    changeColor = color => {
        const { data } = this.state;
        data.color = color;

        this.setState({
            data,
        });
    };

    changeIcon = icon => {
        const { data } = this.state;
        data.icon = icon;
        data.image = null;

        this.setState({
            data,
        });
    };

    onClickUpload = () => {
        if (this.fileInputRef && this.fileInputRef.current) {
            this.fileInputRef.current.value = null;
            this.fileInputRef.current.click();
        }
    };

    changeImage = e => {
        const file = e && e.target && e.target.files[0];

        if (!file) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        const reader = new FileReader();
        reader.onload = () => {
            this.setState({
                isLoading: false,
                showCropper: true,
                image: reader.result,
            });
        };
        reader.readAsDataURL(file);
    };

    onCloseCrop = () => {
        this.setState({
            showCropper: false,
            image: null,
        });
    };

    onCrop = image => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                image,
                icon: null,
            },
            showCropper: false,
            image: null,
        });
    };

    goToNextIconPage() {
        return new Promise((resolve, reject) => {
            const { iconPage } = this.state;
            const nextPage = iconPage + 1;

            this.setState(
                {
                    iconPage: nextPage,
                },
                () => {
                    this.getIcons()
                        .then(resolve)
                        .catch(reject);
                }
            );
        });
    }

    renderStep1 = () => (
        <div className="row">
            <div className="col d-flex flex-column align-items-center justify-content-center">
                <ManageHeading
                    title={
                        <>
                            {trans('setup_a_new')}
                            <span className="font-weight-bold">{trans('channel')}</span>
                        </>
                    }
                    subtitle={trans('set_up_a_new_channel_text')}
                />
                <ManageList
                    items={providers}
                    renderItem={x => (
                        <ManageListItem
                            onClick={this.onClickProvider}
                            key={`provider-${x.provider}`}
                            item={x}
                            type="image"
                            showCog={false}
                        />
                    )}
                />
            </div>
        </div>
    );

    renderStep2 = () => {
        const { data, isLoading } = this.state;

        return (
            <>
                <ManageHeading
                    title={
                        <>
                            {trans('name_your_x')}
                            <span className="font-weight-bold">{trans('channel')}</span>
                        </>
                    }
                    subtitle={trans('name_your_channel_text')}
                />

                <div className="d-flex justify-content-center">
                    <div className="mt-4 d-flex flex-column">
                        <PerfectScrollbar className="px-4 manage-form-scroll overflow-hidden d-flex flex-column">
                            <ManageField title={trans('what_should_we_call_this_source')}>
                                <ManageInput
                                    name="name"
                                    placeholder={trans('placeholders.source_name')}
                                    type="text"
                                    onChange={this.onChange}
                                    value={data.name || ''}
                                    onKeyDown={e => e.key === 'Enter' && this.handleSubmitName()}
                                />
                            </ManageField>
                        </PerfectScrollbar>

                        <div className="d-flex align-items-center px-4 mt-4">
                            <div className="ml-auto mr-4 text-small text-light-gray">
                                {trans('this_can_be_changed_later')} *
                            </div>
                            <FormButton
                                onClick={this.handleSubmitName}
                                type="button"
                                className="manage-btn"
                                text={trans(isLoading ? 'please_wait' : 'next_step')}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    renderStep3 = () => {
        const { data, icons, iconPage, iconLastPage, isLoading, image, showCropper } = this.state;

        return (
            <>
                <ManageHeading
                    title={
                        <>
                            {trans('design_your_x')}
                            <span className="font-weight-bold">{trans('channel')}</span>
                        </>
                    }
                    subtitle={trans('design_your_channel_text')}
                />

                <Container className="manage-form">
                    <Row>
                        <Col lg={{ span: 10, offset: 1 }}>
                            <div className="my-4">
                                <div className="mb-1 text-xsmall">Preview</div>
                                <div className="d-flex align-items-center">
                                    <IconPreviewUpload
                                        onClick={this.onClickUpload}
                                        color={data.color}
                                        icon={data.icon}
                                        image={data.image}
                                        title={trans('click_to_upload_your_own_logo')}
                                        empty={(!data.icon || !data.color) && !data.image}
                                        large
                                    />
                                    <input
                                        ref={this.fileInputRef}
                                        onChange={this.changeImage}
                                        type="file"
                                        hidden
                                    />
                                    <strong className="pl-3">{data.name}</strong>
                                </div>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <ColorPicker
                                        value={data.color}
                                        onChange={c => this.changeColor(c)}
                                    />
                                </Col>
                                <Col md={6}>
                                    <IconPicker
                                        icons={icons}
                                        isLastPage={iconPage >= iconLastPage}
                                        onNextPage={() => this.goToNextIconPage()}
                                        color={data.color}
                                        value={data.icon}
                                        onChange={c => this.changeIcon(c)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div className="align-items-center d-flex mt-4 px-4 w-100">
                            <div className="ml-auto mr-4 text-small text-light-gray">
                                {trans('this_can_be_changed_later')} *
                            </div>
                            <FormButton
                                onClick={this.handleSubmitStore}
                                type="button"
                                className="manage-btn"
                                text={trans(isLoading ? 'please_wait' : 'next_step')}
                            />
                        </div>
                    </Row>
                    <CropperModal
                        onClose={this.onCloseCrop}
                        onCrop={this.onCrop}
                        show={showCropper}
                        src={image}
                        width={128}
                        height={128}
                    />
                </Container>
            </>
        );
    };

    render() {
        const { step, user, company, isLoading } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel step={step}>
                    <CarouselPane onPrev={this.historyBack} scroll center middle>
                        {this.renderStep1()}
                    </CarouselPane>

                    <CarouselPane
                        onNext={this.handleSubmitName}
                        onPrev={this.handleBack}
                        scroll
                        center
                        middle
                    >
                        {this.renderStep2()}
                    </CarouselPane>

                    <CarouselPane
                        onNext={this.handleSubmitStore}
                        onPrev={this.handleBack}
                        scroll
                        center
                        middle
                    >
                        {this.renderStep3()}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(CreatePage);

CreatePage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
