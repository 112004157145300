import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Api, AuthService, Socket } from '../../../services';
import { ManageLayout } from '../../../layouts';
import { CarouselPane, StepCarousel } from '../../../components';
import UserIcon from '../../../components/helpdesk/UserIcon';
import MasonryContainer from '../../../components/MasonryContainer';
import trans from '../../../localization';
import Activity from '../../../components/helpdesk/activity';
import CropperModal from '../../../components/helpdesk/CropperModal';
import MainHomePage from '../../helpdesk/HomePage';
import eventService from '../../../services/EventService';
import definitions from '../../../definitions';
import router from '../../../utils/router';
import dialog from '../../../utils/dialog';

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.inputProfileImage = React.createRef();

        const { location } = this.props;

        this.state = {
            user: AuthService.user(),
            companyId: location.state && location.state.companyId,
            company: null,
            isLoading: true,
            activities: [],
            companies: [],
            showCropper: false,
            file: null,
            unlink: false,
        };

        this.goBack = this.goBack.bind(this);
        this.onClickUploadImage = this.onClickUploadImage.bind(this);
        this.onChangeProfileImage = this.onChangeProfileImage.bind(this);
    }

    componentDidMount() {
        const fetchPromises = Promise.all([
            this.fetchCompany(),
            this.fetchUser(),
            this.fetchCompanies(),
            this.fetchActivities(),
        ]);

        fetchPromises.finally(() => {
            this.setState(
                {
                    isLoading: false,
                },
                () => {
                    const { user } = this.state;
                    Socket.init().user(user.id);
                    this.startListeningEvents();
                }
            );
        });
    }

    componentWillUnmount() {
        const { user } = this.state;
        eventService.removeListener(definitions.EVENT_USER_ACTIVITY);
        Socket.disconnectUser(user.id);
    }

    startListeningEvents() {
        eventService.on(definitions.EVENT_USER_ACTIVITY, activity => {
            const { activities } = this.state;
            activities.splice(0, 0, activity);

            this.setState({
                activities,
            });
        });
    }

    onClickUploadImage() {
        if (this.inputProfileImage) {
            this.inputProfileImage.current.value = null;
            this.inputProfileImage.current.click();
        }
    }

    onChangeProfileImage(e) {
        const file = e.target && e.target.files && e.target.files[0];

        if (!file) {
            this.setState({
                file: null,
            });
            return;
        }

        this.setState(
            {
                isLoading: true,
                showCropper: true,
            },
            () => {
                const reader = new FileReader();
                reader.onload = () => {
                    this.setState({
                        isLoading: false,
                        file: reader.result,
                    });
                };
                reader.readAsDataURL(file);
            }
        );
    }

    onCloseCrop() {
        this.setState({
            showCropper: false,
        });
    }

    onCrop(image) {
        if (!image) {
            return;
        }

        this.setState(
            {
                isLoading: true,
            },
            () => {
                Api.request({
                    method: 'POST',
                    url: 'myAccount/updateImage',
                    data: { image },
                }).then(response => {
                    if (response.data && response.data.data) {
                        AuthService.setUser(response.data.data);
                    }
                    this.setState({
                        showCropper: false,
                        user: response.data.data,
                        isLoading: false,
                    });
                });
            }
        );
    }

    goBack() {
        const { history } = this.props;
        history.goBack();
    }

    fetchUser() {
        return AuthService.fetchUser().then(user => {
            this.setState({
                user,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;
        if (!companyId) {
            return Promise.resolve();
        }

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState(
                {
                    company: response.data.data,
                },
                () => {
                    this.fetchUnlinkOrDelete();
                }
            );
        });
    }

    fetchCompanies() {
        return Api.request({
            method: 'GET',
            url: 'companies',
        }).then(response => {
            this.setState({
                companies: response.data.data,
            });
        });
    }

    fetchActivities() {
        return Api.request({
            method: 'GET',
            url: 'myAccount/activities',
        }).then(response => {
            this.setState({
                activities: response.data.data,
            });
        });
    }

    fetchUnlinkOrDelete() {
        const { company } = this.state;

        Api.request({
            method: 'GET',
            url: `myAccount/unlinkOrDelete/${company.slug}`,
        }).then(response => {
            this.setState({
                unlink: response.data.data,
            });
        });
    }

    unlinkHelpdesk(e) {
        e.preventDefault();

        const { company, unlink } = this.state;

        dialog
            .confirm(
                unlink === 'delete'
                    ? trans('do_you_want_to_delete_helpdesk_x', { name: company.name })
                    : trans('do_you_want_to_unlink_helpdesk_x', { name: company.name }),
                unlink === 'delete'
                    ? trans('delete_helpdesk_confirmation_content')
                    : trans('unlink_helpdesk_confirmation_content')
            )
            .then(() => {
                const { history } = this.props;

                Api.request({
                    method: 'DELETE',
                    url: `myAccount/unlink/${company.slug}`,
                }).then(response => {
                    if (response.data.data === true) {
                        history.push('/');
                    }
                });
            });
    }

    renderMenu() {
        const { company, companies, unlink } = this.state;
        return (
            <div className="my-account-content-menu col flex-grow-0 d-flex flex-column justify-content-start align-items-end text-right">
                <p className="font-weight-bold mb-0">{trans('account_services')}</p>
                <div className="menu-item">
                    <Link
                        className="menu-link"
                        to={{
                            pathname: '/myAccount/changeMyDetails',
                            state: {
                                companyId: company && company.slug,
                            },
                        }}
                    >
                        {trans('change_details')}
                    </Link>
                </div>
                <div className="menu-item">
                    <Link
                        className="menu-link"
                        to={{
                            pathname: '/myAccount/changePassword',
                            state: {
                                companyId: company && company.slug,
                            },
                        }}
                    >
                        {trans('change_password')}
                    </Link>
                </div>
                <div className="menu-item">
                    <Link
                        className="menu-link"
                        to={{
                            pathname: '/myAccount/changeTimezone',
                            state: {
                                companyId: company && company.slug,
                            },
                        }}
                    >
                        {trans('change_timezone')}
                    </Link>
                </div>
                <div className="menu-item">
                    <Link
                        className="menu-link"
                        to={{
                            pathname: '/myAccount/editIcon',
                            state: {
                                companyId: company && company.slug,
                            },
                        }}
                    >
                        {trans('change_icon')}
                    </Link>
                </div>
                <div className="menu-item">
                    <Link
                        className="menu-link"
                        to={{
                            pathname: '/myAccount/cannedResponses',
                            state: {
                                companyId: company && company.slug,
                            },
                        }}
                    >
                        {trans('canned_responses')}
                    </Link>
                </div>
                <div className="menu-item">
                    <Link
                        className="menu-link"
                        to={{
                            pathname: '/myAccount/signatures',
                            state: {
                                companyId: company && company.slug,
                            },
                        }}
                    >
                        {trans('signatures')}
                    </Link>
                </div>
                {company && unlink && (
                    <div className="menu-item">
                        <Link
                            to="/"
                            onClick={e => this.unlinkHelpdesk(e)}
                            className="menu-link text-red"
                        >
                            {unlink === 'delete'
                                ? trans('delete_helpdesk')
                                : trans('unlink_helpdesk')}
                        </Link>
                    </div>
                )}
                <p className="font-weight-bold mb-0 pt-5">{trans('api')}</p>
                <div className="menu-item">
                    <Link
                        className="menu-link"
                        to={{
                            pathname: '/myAccount/userTokens',
                        }}
                    >
                        {trans('tokens')}
                    </Link>
                </div>
                <p className="font-weight-bold mb-0 pt-5">{trans('helpdesks')}</p>
                {companies.map(x => (
                    <div key={router.companySlug(x)} className="menu-item">
                        <Link className="menu-link" to={router.pathCompany(x)}>
                            {x.name}
                        </Link>
                    </div>
                ))}
                <div className="menu-item">
                    <Link
                        className="menu-link"
                        to={{
                            pathname: '/auth/createCompany',
                        }}
                    >
                        <i className="fas fa-fw fa-plus" /> {trans('create')}
                    </Link>
                </div>
            </div>
        );
    }

    renderHeader() {
        const { user, company } = this.state;
        return (
            <div className="my-account-header row flex-column">
                <p className="font-weight-bold text-dark heading-medium">{trans('my_account')}</p>
                <div className="my-account-header-user d-flex">
                    <div className="d-flex justify-content-center align-items-center ml-4">
                        <UserIcon user={user} />
                        <div className="d-flex flex-column ml-4">
                            <span className="name">{user.name}</span>
                            <span className="role">
                                {company && company.role && trans(company.role)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="my-account-header-upload position-relative">
                    <button
                        onClick={this.onClickUploadImage}
                        type="button"
                        className="my-account-header-upload-button position-absolute btn"
                    >
                        <i className="fa fa-upload" />
                        <input
                            ref={this.inputProfileImage}
                            onChange={this.onChangeProfileImage}
                            type="file"
                            className="d-none"
                        />
                    </button>
                </div>
            </div>
        );
    }

    static acceptInvitation(activity) {
        // eslint-disable-next-line camelcase
        const { accept_url } = activity;
        // eslint-disable-next-line no-undef
        const a = document.createElement('a');
        // eslint-disable-next-line camelcase
        a.href = accept_url;
        a.click();
    }

    seeTicket(activity) {
        const { history } = this.props;

        const { ticket, company } = activity;
        router.toCompany(history, company, `tickets/${ticket.id}`);
    }

    render() {
        const { isLoading, user, company, activities, showCropper, file } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="purple"
                user={user}
                company={company}
                hideLBImg
                hideMyAccountLink
            >
                <StepCarousel>
                    <CarouselPane onPrev={this.goBack} fluid className="pb-0 w-100" scroll>
                        <div className="my-account container-fluid">
                            {this.renderHeader()}
                            <div className="my-account-content row flex-row flex-md-nowrap">
                                {activities.length > 0 ? (
                                    <MasonryContainer
                                        containerClassName="my-account-content-feed"
                                        items={activities.map(activity => (
                                            <Activity
                                                activity={activity}
                                                containerClassName="my-account-content-feed-item"
                                                acceptInvitation={() =>
                                                    HomePage.acceptInvitation(activity)
                                                }
                                                seeTicket={() => this.seeTicket(activity)}
                                            />
                                        ))}
                                    />
                                ) : (
                                    MainHomePage.renderEmptyMessage()
                                )}
                                {this.renderMenu()}
                            </div>
                            <CropperModal
                                onClose={() => this.onCloseCrop()}
                                onCrop={image => this.onCrop(image)}
                                show={showCropper}
                                src={file}
                                width={80}
                                height={80}
                            />
                        </div>
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(HomePage);

HomePage.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
