import React from 'react';
import PropTypes from 'prop-types';
import fileTypeToIcon from '../../../utils/fileTypeToIcon';
import HoverTooltip from '../../HoverTooltip';
import trans from '../../../localization';

const TicketAttachments = props => {
    const { attachments, onAttachmentClick } = props;

    return (
        <div className="d-flex flex-column my-2">
            <p className="activity-attachment-title text-dark mb-1">
                {trans('attachments')}
                {`(${attachments.length})`}
            </p>
            <div className="attachment-list">
                {attachments.map(attachment => (
                    <HoverTooltip key={attachment.id} title={attachment.name}>
                        <button
                            type="button"
                            className="file"
                            onClick={() => onAttachmentClick(attachment)}
                        >
                            <div className="opacity" />
                            {attachment.preview_url && (
                                <img
                                    src={attachment.preview_url}
                                    alt={`${attachment.name} Preview`}
                                    className="w-100 h-100"
                                />
                            )}
                            <i className={`fa fa-${fileTypeToIcon(attachment.type)}`} />
                        </button>
                    </HoverTooltip>
                ))}
            </div>
        </div>
    );
};

export default TicketAttachments;

TicketAttachments.propTypes = {
    attachments: PropTypes.array,
    onAttachmentClick: PropTypes.func,
};

TicketAttachments.defaultProps = {
    attachments: [],
    onAttachmentClick: () => {},
};
