import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';
import { ManageCheckbox, ManageField, ManageInput, ManageSelectbox } from '../../../elements';
import dialog from '../../../utils/dialog';

const channelStatusItems = [
    { title: trans('active'), value: true },
    { title: trans('disabled'), value: false },
];

const EditChannelFields = ({ channel, company, onChangeChannelSettings, onDeleteChannel }) => {
    return (
        <div className="row mt-4">
            <div className="col-lg-5 mb-4">
                <ManageField title={trans('channel_status')}>
                    <ManageSelectbox
                        items={channelStatusItems}
                        value={channel.is_active}
                        onChange={e => onChangeChannelSettings('is_active', e)}
                    />
                </ManageField>
            </div>
            <div className="col-lg-7 mb-4">
                <ManageField title={trans('channel_name')}>
                    <ManageInput
                        placeholder={trans('channel_name')}
                        type="text"
                        value={channel.name}
                        onChange={e => onChangeChannelSettings('name', e)}
                        append={
                            <button
                                type="button"
                                className="text-danger"
                                onClick={() => {
                                    dialog.deleteModelConfirm(channel.name).then(onDeleteChannel);
                                }}
                            >
                                <i className="fa fa-trash mr-2" />
                                {trans('delete_channel')}
                            </button>
                        }
                    />
                </ManageField>
            </div>
            {(company && company.is_ai_enabled) && (
                <div className="col-5 mb-4">
                    <ManageCheckbox
                        onChange={() =>
                            onChangeChannelSettings('is_enabled_auto_ai_response', {
                                target: { value: !channel.is_enabled_auto_ai_response },
                            })
                        }
                        label={trans('enable_auto_ai_response')}
                        checked={channel.is_enabled_auto_ai_response}
                    />
                </div>
            )}
        </div>
    );
};

EditChannelFields.propTypes = {
    company: PropTypes.object.isRequired,
    channel: PropTypes.object.isRequired,
    onChangeChannelSettings: PropTypes.func.isRequired,
    onDeleteChannel: PropTypes.func.isRequired,
};

export default EditChannelFields;
