import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtml from 'raw-html-react';
import trans from '../../../localization';

export default class MessageBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showing: false,
        };

        this.onClickShowMore = this.onClickShowMore.bind(this);
    }

    onClickShowMore() {
        this.setState({
            showing: true,
        });
    }

    render() {
        const { showing } = this.state;
        const { body, short } = this.props;

        if (!short || showing) {
            return <ReactHtml html={body} componentMap={{}} />;
        }

        return (
            <div>
                <p>
                    {short}...
                    <button
                        type="button"
                        className="btn btn-sm btn-link p-0 ml-2"
                        onClick={this.onClickShowMore}
                    >
                        {trans('see_more')}
                    </button>
                </p>
            </div>
        );
    }
}

MessageBody.propTypes = {
    body: PropTypes.string.isRequired,
    short: PropTypes.string,
};

MessageBody.defaultProps = {
    short: null,
};
