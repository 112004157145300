// eslint-disable-next-line camelcase
import burnt_sienna from './burnt_sienna.svg';
// eslint-disable-next-line camelcase
import edge_blue from './edge_blue.svg';
// eslint-disable-next-line camelcase
import electric_blue from './electric_blue.svg';
import flavescent from './flavescent.svg';
// eslint-disable-next-line camelcase
import french_pink from './french_pink.svg';
// eslint-disable-next-line camelcase
import french_sky_blue from './french_sky_blue.svg';
import gunmetal from './gunmetal.svg';
// eslint-disable-next-line camelcase
import majorelle_blue from './majorelle_blue.svg';
import melon from './melon.svg';
// eslint-disable-next-line camelcase
import mountain_meadow from './mountain_meadow.svg';
// eslint-disable-next-line camelcase
import pale_violet from './pale_violet.svg';
// eslint-disable-next-line camelcase
import sea_green from './sea_green.svg';

export default {
    burnt_sienna,
    edge_blue,
    electric_blue,
    flavescent,
    french_pink,
    french_sky_blue,
    gunmetal,
    majorelle_blue,
    melon,
    mountain_meadow,
    pale_violet,
    sea_green,
};
