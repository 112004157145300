import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Popover from '../Popover';
import trans from '../../localization';

export default class AutoLoadCompleteInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || '',
            items: [],
            cancelRequest: null,
        };

        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClickPopover = this.onClickPopover.bind(this);
        this.renderItems = this.renderItems.bind(this);
    }

    onClickPopover() {
        const { suggestion } = this.props;
        const { value } = this.state;
        if (!value && suggestion) {
            this.search('');
        }
    }

    onChange(e) {
        const { value } = e.target;
        const { cancelRequest } = this.state;
        if (cancelRequest && typeof cancelRequest === 'function') {
            cancelRequest();
        }

        this.setState(
            {
                value,
            },
            () => {
                this.search(value);
            }
        );
    }

    onKeyDown(e) {
        if (e.key !== 'Enter') {
            return;
        }

        const { canCreate, onCreate } = this.props;

        if (!canCreate) return;

        const { value } = this.state;

        if (value === '') return;
        onCreate(value).then(() => this.clear());
    }

    onClick(item) {
        const { onClick, showSelectedValue } = this.props;

        onClick(item).then(() => {
            if (showSelectedValue) {
                this.setState({
                    value: item.value,
                });
                document.activeElement.blur();
            } else {
                this.clear();
            }
        });
    }

    search(value) {
        const { onSearch } = this.props;
        const { CancelToken } = axios;
        onSearch(value, {
            cancelToken: new CancelToken(cancel => {
                this.setState({
                    cancelRequest: cancel,
                });
            }),
        }).then(items => {
            this.setState({
                items,
            });
        });
    }

    clear() {
        this.setState({
            value: '',
            items: [],
        });

        document.activeElement.blur();
    }

    renderItems() {
        const { items, value } = this.state;
        const { itemMapper, canCreate } = this.props;
        return items.length ? (
            items.map(itemMapper || (x => x)).map(x => (
                <span
                    key={x.value}
                    role="button"
                    tabIndex={0}
                    onClick={() => this.onClick(x)}
                    className="outline-none"
                >
                    {x.label}
                </span>
            ))
        ) : (
            <div className="text-xsmall">
                {canCreate ? (
                    <span>
                        {trans('press_x_to_add_y', {
                            x: (
                                <kbd
                                    className="mx-1 cursor-pointer"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => this.onKeyDown({ key: 'Enter' })}
                                >
                                    {trans('enter')}
                                </kbd>
                            ),
                            y: value,
                        })}
                    </span>
                ) : (
                    <span>{trans('no_items')}</span>
                )}
            </div>
        );
    }

    render() {
        const { value } = this.state;
        const { placeholder, fullWidth, scroll, scrollbarClassnames } = this.props;

        return (
            <Popover
                label={
                    <div className="input-wrapper">
                        <input
                            type="text"
                            style={{ width: '100%' }}
                            value={value}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            placeholder={placeholder}
                        />
                    </div>
                }
                align="center"
                onClick={this.onClickPopover}
                popoverClassName={fullWidth ? 'w-100' : ''}
            >
                {scroll ? (
                    <PerfectScrollbar className={scrollbarClassnames}>
                        {this.renderItems()}
                    </PerfectScrollbar>
                ) : (
                    this.renderItems()
                )}
            </Popover>
        );
    }
}

AutoLoadCompleteInput.propTypes = {
    onCreate: PropTypes.func,
    onClick: PropTypes.func,
    onSearch: PropTypes.func,
    itemMapper: PropTypes.func,
    canCreate: PropTypes.bool,
    suggestion: PropTypes.bool,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
    showSelectedValue: PropTypes.bool,
    value: PropTypes.string,
    scroll: PropTypes.bool,
    scrollbarClassnames: PropTypes.string,
};

AutoLoadCompleteInput.defaultProps = {
    onCreate: () => {},
    onClick: () => {},
    onSearch: () => {},
    itemMapper: null,
    canCreate: true,
    suggestion: false,
    placeholder: '',
    fullWidth: false,
    showSelectedValue: false,
    value: '',
    scroll: false,
    scrollbarClassnames: '',
};
