import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import trans from '../../localization';
import { ErrorLayout } from '../../layouts/ErrorLayout';

const NotFoundPage = ({ history }) => {
    return (
        <ErrorLayout history={history}>
            <h2 className="h2 text-white mt-2 lead">
                {trans('we_could_not_find_any_page_or_data')}
            </h2>
        </ErrorLayout>
    );
};

NotFoundPage.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(NotFoundPage);
