import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import trans from '../../localization';
import definitions from '../../definitions';
import FilterGroup from './FilterGroup';
import CompanyService from '../../services/CompanyService';
import AutoLoadCompleteInput from './AutoLoadCompleteInput';
import MapperService from '../../services/MapperService';
import router from '../../utils/router';

export default class Sidebar extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            active: false,
            searchQuery: '',
            showingDeletedSources: false,
        };

        this.selectItem = this.selectItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.onSearchTags = this.onSearchTags.bind(this);
        this.onSearchCustomers = this.onSearchCustomers.bind(this);

        this.onSubmitSearch = this.onSubmitSearch.bind(this);
        this.showOrHideDeletedSources = this.showOrHideDeletedSources.bind(this);
    }

    selectItem(key, item) {
        const { filters, onFilter } = this.props;
        const existingItem = filters[key].find(x => x.id === item.id);

        if (existingItem === undefined) {
            filters[key].push(item);
            onFilter(key, filters[key]);
        }

        return Promise.resolve();
    }

    removeItem(key, item) {
        const { filters, onFilter } = this.props;
        const index = filters[key].indexOf(item);

        if (index > -1) {
            filters[key].splice(index, 1);
            onFilter(key, filters[key]);
        }
    }

    static renderItem(item, onRemove) {
        return (
            <button key={item.id} type="button" className="tag" onClick={() => onRemove(item)}>
                {item.name}
                <span>
                    <i className="fad fa-fw fa-times-circle" />
                </span>
            </button>
        );
    }

    onSearchTags(query, options) {
        const { company } = this.props;
        return new Promise((resolve, reject) => {
            CompanyService.tags(company.slug, query, options)
                .then(response => resolve(response.data.data))
                .catch(reject);
        });
    }

    onSearchCustomers(query, options) {
        const { company } = this.props;
        return new Promise((resolve, reject) => {
            CompanyService.customers(company.slug, query, options)
                .then(response => resolve(response.data.data))
                .catch(reject);
        });
    }

    onSubmitSearch(event) {
        event.preventDefault();

        const { searchQuery } = this.state;
        const { onSearch } = this.props;

        onSearch(searchQuery);
        this.setState({ active: false });
    }

    showOrHideDeletedSources() {
        const { showingDeletedSources } = this.state;
        this.setState({
            showingDeletedSources: !showingDeletedSources,
        });
    }

    renderDeletedSources() {
        const { counts, company, filters, onFilter } = this.props;
        const { showingDeletedSources } = this.state;

        return (
            <FilterGroup
                items={company.deletedSources}
                defaultSelectedItems={filters.sources}
                itemCounts={counts && counts.sources}
                title={
                    showingDeletedSources
                        ? trans('hide_deleted_channels')
                        : trans('show_deleted_channels')
                }
                onChange={selected => onFilter('sources', selected)}
                onShowOrHide={this.showOrHideDeletedSources}
                isShowing={showingDeletedSources}
            />
        );
    }

    render() {
        const { company, counts, totalCount, filters, onFilter, show } = this.props;
        const { active, searchQuery } = this.state;

        if (!company) {
            return (
                <div
                    className={cx(
                        'sidebar-navigation',
                        { 'sidebar-active': active },
                        { 'd-none': !show }
                    )}
                >
                    <button
                        type="button"
                        className="sidebar-trigger"
                        onClick={() => this.setState({ active: !active })}
                    >
                        <i
                            className={cx('fas mr-2', {
                                'fa-chevron-down': active,
                                'fa-chevron-up': !active,
                            })}
                        />
                        {trans('filters')}
                    </button>

                    <div className="d-flex justify-content-center align-items-center h-100">
                        <i className="fa fa-spinner fa-spin" />
                    </div>
                </div>
            );
        }

        return (
            <div
                className={cx(
                    'sidebar-navigation',
                    { 'sidebar-active': active },
                    { 'd-none': !show }
                )}
            >
                <button
                    type="button"
                    className="sidebar-trigger"
                    onClick={() => this.setState({ active: !active })}
                >
                    <i
                        className={cx('fas mr-2', {
                            'fa-chevron-down': active,
                            'fa-chevron-up': !active,
                        })}
                    />
                    {trans('filters')}
                </button>
                <div className="create-ticket-wrapper my-4">
                    <Link
                        to={router.pathCompany(company.slug, 'createTicket')}
                        className="create-ticket px-4 unselectable"
                    >
                        {trans('create_ticket')}
                        <i className="fad fa-paper-plane paper-plane" />
                        <i className="fad fa-mail-bulk chat" />
                    </Link>
                </div>
                <div className="filters">
                    <PerfectScrollbar>
                        <div className="filter-option mx-3 sidebar-search-option">
                            <div className="list-group">
                                <div className="list-group-title">
                                    <h5>{trans('search')}</h5>
                                </div>
                                <form className="input-wrapper" onSubmit={this.onSubmitSearch}>
                                    <input
                                        type="text"
                                        style={{ width: '100%' }}
                                        onChange={e =>
                                            this.setState({ searchQuery: e.target.value })
                                        }
                                        placeholder={trans('placeholders.search_for_ticket')}
                                        value={searchQuery}
                                    />
                                </form>
                            </div>
                        </div>

                        <FilterGroup
                            itemTransPrefix="statuses"
                            items={definitions.statuses}
                            defaultSelectedItems={filters.statuses}
                            totalCount={totalCount}
                            itemCounts={counts && counts.statuses}
                            title={trans('tickets')}
                            onChange={selected => onFilter('statuses', selected)}
                        />

                        <FilterGroup
                            itemTransPrefix="priorities"
                            items={definitions.priorities}
                            defaultSelectedItems={filters.priorities}
                            itemCounts={counts && counts.priorities}
                            title={trans('priority')}
                            onChange={selected => onFilter('priorities', selected)}
                        />

                        {company.ticketTypes && (
                            <FilterGroup
                                items={company.ticketTypes}
                                defaultSelectedItems={filters.ticketTypes}
                                itemCounts={counts && counts.ticketTypes}
                                title={trans('ticket_types')}
                                onChange={selected => onFilter('ticketTypes', selected)}
                            />
                        )}

                        <FilterGroup
                            items={company.users}
                            defaultSelectedItems={filters.users}
                            itemCounts={counts && counts.users}
                            title={trans('users')}
                            onChange={selected => onFilter('users', selected)}
                        />

                        <div className="filter-option mx-4">
                            <div className="list-group">
                                <div className="list-group-title">
                                    <h5>{trans('tags')}</h5>
                                </div>
                                <div className="input-wrapper">
                                    <AutoLoadCompleteInput
                                        placeholder={trans('placeholders.search_for_tag')}
                                        itemMapper={MapperService.mapAutoComplete}
                                        onSearch={this.onSearchTags}
                                        onClick={selected => this.selectItem('tags', selected)}
                                        canCreate={false}
                                        fullWidth
                                        suggestion
                                    />
                                </div>
                                <div className="tags">
                                    {filters.tags.map(x =>
                                        Sidebar.renderItem(x, r => this.removeItem('tags', r))
                                    )}
                                </div>
                            </div>
                        </div>

                        <FilterGroup
                            items={company.sources}
                            defaultSelectedItems={filters.sources}
                            itemCounts={counts && counts.sources}
                            title={trans('channels')}
                            onChange={selected => onFilter('sources', selected)}
                        />

                        {company.deletedSources.length > 0 && this.renderDeletedSources()}

                        <div className="filter-option mx-4">
                            <div className="list-group">
                                <div className="list-group-title">
                                    <h5>{trans('customers')}</h5>
                                </div>
                                <div className="input-wrapper">
                                    <AutoLoadCompleteInput
                                        placeholder={trans('placeholders.search_for_customer')}
                                        itemMapper={MapperService.mapAutoComplete}
                                        onSearch={this.onSearchCustomers}
                                        onClick={selected => this.selectItem('customers', selected)}
                                        canCreate={false}
                                        fullWidth
                                        suggestion
                                    />
                                </div>
                                <div className="tags">
                                    {filters.customers.map(x =>
                                        Sidebar.renderItem(x, r => this.removeItem('customers', r))
                                    )}
                                </div>
                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        );
    }
}

Sidebar.propTypes = {
    counts: PropTypes.object.isRequired,
    totalCount: PropTypes.number,
    filters: PropTypes.object,
    onFilter: PropTypes.func,
    onSearch: PropTypes.func,
    show: PropTypes.bool,
    company: PropTypes.object,
};

Sidebar.defaultProps = {
    totalCount: null,
    filters: {},
    onFilter: () => {},
    onSearch: () => {},
    show: true,
    company: {},
};
