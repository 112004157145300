import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Spinner = ({ className, color, ...rest }) => {
    return <div className={cx(`dc-spinner`, `text-color-${color}`, className)} {...rest} />;
};

Spinner.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};
Spinner.defaultProps = {
    className: null,
    color: 'primary',
};

export default Spinner;
