import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import trans from '../../../localization';
import { HoverTooltip } from '../../index';

const CancelButton = ({ title, onClick, className, icon }) => {
    const button = (
        <button
            onClick={onClick}
            type="button"
            className={cx('btn btn-manage cancel-btn border-radius-circle mr-3', className)}
        >
            <i className={cx('fa', icon)} />
        </button>
    );

    if (title) {
        return <HoverTooltip title={title}>{button}</HoverTooltip>;
    }

    return button;
};

CancelButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.string,
};

CancelButton.defaultProps = {
    title: trans('cancel'),
    onClick: () => {},
    className: null,
    icon: 'fa-times',
};

export default CancelButton;
