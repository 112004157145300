import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api, ApiService, AuthService } from '../../services';
import { AuthCardView } from '../../components/auth';
import { FormButton, FormInput, FormTextButton } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';

class SetPasswordPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: {
                password: '',
                password_confirmation: '',
            },
            errors: {},
            isSending: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        const { isSending, data } = this.state;
        const { history } = this.props;

        if (isSending) {
            return;
        }

        this.setState({
            isSending: true,
        });

        Api.request(
            {
                method: 'POST',
                url: 'auth/setPassword',
                data,
            },
            true
        )
            .then(response => {
                AuthService.setUser(response.data.data);
                if (AuthService.user().email_verified) {
                    history.push('/');
                } else {
                    history.push('/auth/inviteMembers');
                }
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                    isSending: false,
                });
            });
    }

    onChange(event) {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    }

    render() {
        const { isSending, errors, data } = this.state;

        // eslint-disable-next-line camelcase
        const { password, password_confirmation: passwordConfirmation } = data;

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView
                    title={trans('lets_get_started')}
                    subtitle={trans('lets_get_started_subtitle')}
                    titleSmall={trans('set_password')}
                >
                    <form onSubmit={this.onSubmit}>
                        <FormInput
                            labelText={trans('attributes.password')}
                            validationError={errors.password}
                            onChange={this.onChange}
                            type="password"
                            name="password"
                            value={password}
                            placeholder={trans('placeholders.password')}
                        />

                        <FormInput
                            labelText={trans('attributes.password_confirmation')}
                            validationError={errors.password_confirmation}
                            onChange={this.onChange}
                            type="password"
                            name="password_confirmation"
                            value={passwordConfirmation}
                            placeholder={trans('placeholders.password_confirmation')}
                        />

                        <div className="pt-2 d-flex align-items-center">
                            <FormTextButton
                                type="link"
                                to="/auth/signUp"
                                text={trans('i_made_a_mistake')}
                            />
                            <div className="ml-auto">
                                <FormButton
                                    text={trans('next_step')}
                                    disabled={isSending}
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(SetPasswordPage);

SetPasswordPage.propTypes = {
    history: PropTypes.object.isRequired,
};
