import definitions from '../../../definitions';
import amazonLogo from './amazon-logo.png';
import ebayLogo from './ebay-logo.png';
import emailLogo from './email-logo.png';

import shopify from './shopify.png';
import magento from './magento.svg';
import wooCommerce from './woo-commerce.png';
import bigCommerce from './big-commerce.png';
import ekm from './ekm.svg';
import openCart from './opencart.png';
import prestaShop from './prestashop.png';
import cubeCart from './cubecart.png';
// import fruugoLogo from './fruugo-logo.png';
// import whatsappLogo from './whatsapp-logo.png';
// import smsLogo from './sms-logo.png';

export default [
    {
        provider: definitions.PROVIDER_AMAZON,
        active: true,
        image: amazonLogo,
    },
    {
        provider: definitions.PROVIDER_EBAY,
        active: true,
        image: ebayLogo,
    },
    {
        provider: definitions.PROVIDER_EMAIL,
        active: true,
        image: emailLogo,
    },
    {
        type: definitions.PROVIDER_EMAIL,
        provider: definitions.PROVIDER_SHOPIFY,
        active: true,
        image: shopify,
    },
    {
        type: definitions.PROVIDER_EMAIL,
        provider: definitions.PROVIDER_MAGENTO,
        active: true,
        image: magento,
    },
    {
        type: definitions.PROVIDER_EMAIL,
        provider: definitions.PROVIDER_WOO_COMMERCE,
        active: true,
        image: wooCommerce,
    },
    {
        type: definitions.PROVIDER_EMAIL,
        provider: definitions.PROVIDER_BIG_COMMERCE,
        active: true,
        image: bigCommerce,
    },
    {
        type: definitions.PROVIDER_EMAIL,
        provider: definitions.PROVIDER_EKM,
        active: true,
        image: ekm,
    },
    {
        type: definitions.PROVIDER_EMAIL,
        provider: definitions.PROVIDER_OPENCART,
        active: true,
        image: openCart,
    },
    {
        type: definitions.PROVIDER_EMAIL,
        provider: definitions.PROVIDER_PRESTASHOP,
        active: true,
        image: prestaShop,
    },
    {
        type: definitions.PROVIDER_EMAIL,
        provider: definitions.PROVIDER_CUBECART,
        active: true,
        image: cubeCart,
    },
    // {
    //     provider: definitions.PROVIDER_FRUUGO,
    //     active: false,
    //     image: fruugoLogo,
    // },
    // {
    //     provider: definitions.PROVIDER_WHATSAPP,
    //     active: false,
    //     image: whatsappLogo,
    // },
    // {
    //     provider: definitions.PROVIDER_SMS,
    //     active: false,
    //     image: smsLogo,
    // },
];
