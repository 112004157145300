import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CarouselPane, StepCarousel } from '../../components';
import ManageHeading from '../../components/helpdesk/heading/ManageHeading';
import { ManageLayout } from '../../layouts';
import { Api, AuthService } from '../../services';
import { ManageCheckbox, ManageSelectbox } from '../../elements';
import CancelAndSaveFooter from '../../components/helpdesk/carousel/CancelAndSaveFooter';
import trans from '../../localization';
import definitions from '../../definitions';
import notify from '../../utils/notify';

class WorktimeCalendarPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            step: 0,
            calendar: {},
        };
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchCalendar()]).finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    historyBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    setCalendarState = (day, data) => {
        const { calendar } = this.state;

        this.setState({
            calendar: {
                ...calendar,
                [day]: {
                    ...calendar[day],
                    ...data,
                },
            },
        });
    };

    timeOptions = () => {
        return Array(24)
            .fill(0)
            .flatMap((x, i) => {
                const hour = `${i < 10 ? `0${i}` : i}:00`;
                const half = `${i < 10 ? `0${i}` : i}:30`;

                return [
                    { title: hour, value: hour },
                    { title: half, value: half },
                ];
            });
    };

    fetchCalendar = () => {
        const { companyId } = this.state;

        Api.request(
            {
                url: `companies/${companyId}/worktimes`,
                method: 'GET',
            },
            true
        ).then(response => {
            this.setState({
                calendar: response.data.data,
            });
        });
    };

    saveCalendar = () => {
        const { companyId, calendar } = this.state;
        this.setState({
            isLoading: true,
        });
        Api.request({
            url: `companies/${companyId}/worktimes`,
            method: 'PUT',
            data: calendar,
        })
            .then(() => {
                notify.saved();
                this.historyBack();
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    render() {
        const { step, user, company, isLoading, calendar } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel step={step}>
                    <CarouselPane onPrev={this.historyBack} scroll>
                        <ManageHeading
                            title={trans('worktime_x', {
                                x: <span className="font-weight-bold">{trans('calendar')}</span>,
                            })}
                            subtitle={trans('worktime_calender_text')}
                        />

                        <div className="worktime-container">
                            <table className="worktime-table">
                                <thead>
                                    <tr>
                                        <th>{trans('day_of_week')}</th>
                                        <th>{trans('day_off')}</th>
                                        <th>{trans('start_time')}</th>
                                        <th>{trans('end_time')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {definitions.DAYS_OF_WEEK.map(day => (
                                        <tr key={day.id}>
                                            <td>{trans(day.name)}</td>
                                            <td width="80" className="text-center">
                                                <ManageCheckbox
                                                    onChange={e =>
                                                        this.setCalendarState(day.id, {
                                                            is_off_day: e.target.checked,
                                                        })
                                                    }
                                                    checked={
                                                        (calendar[day.id] &&
                                                            calendar[day.id].is_off_day) ||
                                                        false
                                                    }
                                                    className="m-0 mx-auto"
                                                />
                                            </td>
                                            <td width="150">
                                                <ManageSelectbox
                                                    items={this.timeOptions()}
                                                    value={
                                                        calendar[day.id] &&
                                                        calendar[day.id].start_time
                                                    }
                                                    onChange={e =>
                                                        this.setCalendarState(day.id, {
                                                            start_time: e.target.value,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td width="150">
                                                <ManageSelectbox
                                                    items={this.timeOptions()}
                                                    value={
                                                        calendar[day.id] &&
                                                        calendar[day.id].end_time
                                                    }
                                                    onChange={e =>
                                                        this.setCalendarState(day.id, {
                                                            end_time: e.target.value,
                                                        })
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </CarouselPane>
                </StepCarousel>

                <CancelAndSaveFooter onSubmit={this.saveCalendar} onCancel={this.historyBack} />
            </ManageLayout>
        );
    }
}

export default withRouter(WorktimeCalendarPage);

WorktimeCalendarPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
