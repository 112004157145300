import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import BaseEditPage from './BaseEditPage';

class EditManualPage extends BaseEditPage {
    componentDidMount() {
        this.onMount();
    }

    render() {
        return super.layout();
    }
}

export default withRouter(EditManualPage);

EditManualPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
