import ListPage from './ListPage';
import CreateOrEditPage from './CreateOrEditPage';

export default [
    {
        path: '/myAccount/userTokens',
        middleware: ['auth'],
        component: ListPage,
    },
    {
        path: '/myAccount/userTokens/create',
        middleware: ['auth'],
        component: CreateOrEditPage,
    },
    {
        path: '/myAccount/userTokens/:id/edit',
        middleware: ['auth'],
        component: CreateOrEditPage,
    },
];
