import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Carousel, Col, Container, Image, Row } from 'react-bootstrap';
import NProgress from 'nprogress';
import * as moment from 'moment';
import { authLeftImg, carouselImg1 } from '../assets/images';
import { ProgressOverlay } from '../components';
import trans from '../localization';

class AuthLayout extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isStarted: props.isLoading,
            year: moment().format('YYYY'),
            carouselItems: [
                {
                    key: 1,
                    image: carouselImg1,
                    h1: 'Take Control',
                    p:
                        'Keep track of customer conversations by converting them into tickets. Convert all.',
                },
                {
                    key: 2,
                    image: carouselImg1,
                    h1: 'Take Control',
                    p:
                        'Keep track of customer conversations by converting them into tickets. Convert all.',
                },
                {
                    key: 3,
                    image: carouselImg1,
                    h1: 'Take Control',
                    p:
                        'Keep track of customer conversations by converting them into tickets. Convert all.',
                },
            ],
        };
    }

    componentDidMount() {
        // eslint-disable-next-line no-undef
        document.body.classList.add('auth-body');
        const { isLoading } = this.props;

        if (isLoading) {
            NProgress.start();
        }
    }

    componentDidUpdate() {
        const { isLoading } = this.props;
        const { isStarted } = this.state;

        if (isLoading !== isStarted) {
            this.changeProgressBar(isLoading);
        }
    }

    componentWillUnmount() {
        NProgress.done();
        // eslint-disable-next-line no-undef
        document.body.classList.remove('auth-body');
    }

    changeProgressBar(isLoading) {
        if (isLoading) {
            NProgress.start();
            this.setState({
                isStarted: isLoading,
            });
        } else {
            NProgress.done();
            this.setState({
                isStarted: isLoading,
            });
        }
    }

    static renderCarouselItem(item) {
        return (
            <Carousel.Item className="w-100 h-100" key={item.key}>
                <Carousel.Caption>
                    <h1 className="bold-font text-white">{item.h1}</h1>
                    <p className="light-font">{item.p}</p>
                </Carousel.Caption>
                <img src={item.image} alt={item.h1} className="w-100 h-90" />
            </Carousel.Item>
        );
    }

    render() {
        const { children, isLoading } = this.props;
        const { carouselItems, year } = this.state;

        return (
            <div>
                <Container
                    fluid
                    className="auth-container d-flex align-items-center w-100 h-100 position-absolute p-0"
                >
                    <Row className="auth-container-wrapper auth-height no-gutters w-100 m-auto align-items-center">
                        <Col className="col auth-height d-none d-xl-flex justify-content-center align-items-center col-auth-image">
                            <Image
                                src={authLeftImg}
                                className="w-100 h-auto p-md-4 p-lg-5"
                                alt="auth page logo"
                            />
                        </Col>
                        <Col className="p-0 auth-height col-auto col-auth-card d-flex justify-content-center">
                            {children}
                        </Col>
                        <Col className="p-0 auth-height col d-flex auth-carousel-container">
                            <Carousel className="w-100 h-100" controls={false} interval={3000}>
                                {carouselItems.map(x => AuthLayout.renderCarouselItem(x))}
                            </Carousel>
                        </Col>
                    </Row>
                    <div className="footer">
                        <p>{`©${year}`} Despatch Cloud Ltd</p>
                        <p>
                            {trans('company_number')}: 09615192 | {trans('vat_number')}: GB214577410
                            | {` ${trans('ico_register_number')}`}: A8116774
                        </p>
                    </div>
                </Container>

                <ProgressOverlay isLoading={isLoading} />
            </div>
        );
    }
}

AuthLayout.propTypes = {
    children: PropTypes.node.isRequired,
    isLoading: PropTypes.bool,
};

AuthLayout.defaultProps = {
    isLoading: false,
};

export default AuthLayout;
