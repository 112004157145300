import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../../../services';
import { ManageLayout } from '../../../../layouts';
import { CarouselPane, StepCarousel } from '../../../../components';
import trans from '../../../../localization';
import CheckboxBorderContainer from '../../../../components/helpdesk/CheckboxBorderContainer';
import IconPreview from '../../../../components/helpdesk/manage/IconPreview';
import notify from '../../../../utils/notify';
import CancelAndSaveFooter from '../../../../components/helpdesk/carousel/CancelAndSaveFooter';

class ListPage extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;

        this.state = {
            companyId: location.state && location.state.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            signatures: [],
            selectedSignatureIds: [],
            updateSignature: false,
            data: {
                body: '',
            },
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchSignatures()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        if (!companyId) return Promise.resolve();

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchSignatures() {
        return Api.request({
            method: 'GET',
            url: 'signatures',
        }).then(response => {
            this.setState({
                signatures: response.data.data.channel,
                data: response.data.data.user || {},
                updateSignature: !!response.data.data.user,
            });
        });
    }

    deleteSignatures = () => {
        const { selectedSignatureIds, signatures } = this.state;

        const setStateSuccess = () => {
            this.setState({
                selectedSignatureIds: [],
                signatures: signatures.filter(x => selectedSignatureIds.indexOf(x.id) < 0),
                isLoading: false,
            });
        };

        const setLoading = loading => {
            this.setState({
                isLoading: loading,
            });
        };

        setLoading(true);

        Api.request({
            method: 'DELETE',
            url: 'signatures/bulk/delete',
            data: {
                ids: selectedSignatureIds,
            },
            header: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {
                if (response.data.data === true) {
                    setStateSuccess();
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    updatePersonalSignature = () => {
        this.setState({
            isLoading: true,
        });

        const { data, updateSignature } = this.state;

        const config = updateSignature
            ? {
                  method: 'PUT',
                  url: `signatures/${data.id}/edit`,
                  data,
              }
            : {
                  method: 'POST',
                  url: 'signatures/create',
                  data,
              };

        const stopLoading = () => {
            this.setState({
                isLoading: false,
            });
        };

        const setStateSuccess = response => {
            this.setState({
                isLoading: false,
                updateSignature: true,
                data: response.data.data,
            });

            notify.saved();
        };

        Api.request(config)
            .then(setStateSuccess)
            .catch(stopLoading);
    };

    onCreateClick = () => {
        const { history } = this.props;
        const { company } = this.state;

        history.push('/myAccount/signatures/create', {
            companyId: company && company.slug,
        });
    };

    onSignatureClick = signature => {
        const { history } = this.props;
        const { company } = this.state;

        history.push(`/myAccount/signatures/${signature.id}/edit`, {
            companyId: company && company.slug,
            data: signature,
        });
    };

    onChange = (field, value) => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                [field]: value,
            },
        });
    };

    goBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    onSelectSignature = id => {
        const { selectedSignatureIds } = this.state;

        const index = selectedSignatureIds.indexOf(id);

        if (index < 0) {
            selectedSignatureIds.push(id);
        } else {
            selectedSignatureIds.splice(index, 1);
        }

        this.setState({
            selectedSignatureIds,
        });
    };

    renderSignatures = () => {
        const { signatures, selectedSignatureIds } = this.state;

        return (
            <div className="col-lg-12 d-flex flex-column pt-5">
                <div className="pb-4 d-flex justify-content-space-between align-items-center flex-wrap">
                    <span className="font-weight-bold text-large">
                        {trans('channel_signatures')}
                    </span>
                    {selectedSignatureIds.length > 0 && (
                        <button
                            onClick={() => this.deleteSignatures()}
                            type="button"
                            className="btn p-0 text-red text-xsmall ml-auto no-outline-text-btn"
                        >
                            <i className="fa fa-trash pr-1" />
                            {trans('i_want_to_delete_x_signatures.beforeX')}
                            <div className="text-white bg-red font-weight-bold px-1 manage-badge">
                                {selectedSignatureIds.length}
                            </div>
                            {trans('i_want_to_delete_x_signatures.afterX')}
                        </button>
                    )}
                </div>
                <div className="d-flex flex-wrap">
                    <div
                        role="button"
                        tabIndex="0"
                        onClick={this.onCreateClick}
                        className="pr-3 pb-4"
                        key="create-signature"
                    >
                        <div className="checkbox-border-container dashed-db-border align-items-center">
                            <span className="text-light-gray">{trans('new_signature')}</span>
                        </div>
                    </div>
                    {signatures.map(x => (
                        <div className="pr-3 pb-4" key={`signature-${x.id}`}>
                            <CheckboxBorderContainer
                                id={x.id}
                                onSelect={() => this.onSelectSignature(x.id)}
                                onClick={() => this.onSignatureClick(x)}
                            >
                                <div className="d-flex">
                                    <IconPreview color={x.color} icon={x.icon} />
                                    <div className="d-flex flex-column pl-2">
                                        <span className="font-weight-bold text-small text-dark">
                                            {x.channel}
                                        </span>
                                        <span className="text-2xsmall text-offblack">
                                            {x.company}
                                        </span>
                                    </div>
                                </div>
                            </CheckboxBorderContainer>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    render() {
        const { isLoading, user, company, data } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="purple"
                user={user}
                company={company}
                hideLBImg
            >
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-4"
                        scroll
                        fluid
                    >
                        <div className="row pb-4">
                            <div className="col-lg-12 d-flex flex-column">
                                <span className="font-weight-bold text-3xlarge pb-4">
                                    {trans('signatures')}
                                </span>
                                <span className="font-weight-light text-darker-gray">
                                    {trans('signature_page_text')}
                                </span>
                            </div>
                        </div>
                        <div className="row d-flex flex-column">{this.renderSignatures()}</div>

                        <div className="row">
                            <div className="col-lg-9 pt-4">
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-column pb-4">
                                        <span className="font-weight-bold text-large pb-4">
                                            {trans('personal_signature')}
                                        </span>
                                        <span className="font-weight-light text-darker-gray">
                                            {trans('personal_signature_subtitle')}
                                        </span>
                                    </div>
                                    <div className="manage-textarea-container">
                                        <textarea
                                            value={data.body}
                                            placeholder={trans(
                                                'placeholders.type_signature_content_here'
                                            )}
                                            onChange={e => this.onChange('body', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CancelAndSaveFooter
                            submitTitle={trans('save_changes')}
                            onSubmit={this.updatePersonalSignature}
                        />
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

ListPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(ListPage);
