import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import definitions from '../../../definitions';
import images from '../../../assets/images/colors';

export default class ColorPicker extends PureComponent {
    onClick(color) {
        const { onChange } = this.props;

        onChange(color);
    }

    renderColor(color) {
        const { disabled, value } = this.props;
        const selected = value === color;

        return (
            <button
                className={classNames('btn btn-color position-relative', selected)}
                key={color}
                type="button"
                disabled={disabled}
                onClick={() => this.onClick(color)}
            >
                {selected && (
                    <div className="position-absolute text-white selected-icon">
                        <i className="fa-check fas" />
                    </div>
                )}
                <img className="btn-color-image" src={images[color]} alt={color} />
            </button>
        );
    }

    render() {
        return (
            <PerfectScrollbar className="color-picker-component d-flex flex-wrap justify-content-center">
                <div className="color-picker-list d-flex flex-wrap align-content-start justify-content-start">
                    {definitions.colors.map(color => this.renderColor(color))}
                </div>
            </PerfectScrollbar>
        );
    }
}

ColorPicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

ColorPicker.defaultProps = {
    value: null,
    onChange: () => {},
    disabled: false,
};
