import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import { ManageLayout } from '../../../layouts';
import { FormButton, ManageInput, ManageSelectbox } from '../../../elements';
import trans from '../../../localization';
import { Api, AuthService } from '../../../services';
import helpers from '../../../helpers';
import { coloredUsers, questionMarkDuotone } from '../../../assets/images';
import ManagePopup from '../../../components/helpdesk/ManagePopup';
import LabeledCheckbox from '../../../components/helpdesk/LabeledCheckbox';
import { CarouselPane, StepCarousel } from '../../../components';
import router from '../../../utils/router';

class CreatePage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            step: 0,
            isLoading: true,
            user: AuthService.user(),
            companyId: match.params.companyId,
            company: null,
            emails: [],
            emailInputs: [0],
            data: [],
            showPreviewPermissionsPopup: false,
            adminPermissions: [],
            userPermissions: [],
        };

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.onSubmitEmails = this.onSubmitEmails.bind(this);
        this.onSubmitRoles = this.onSubmitRoles.bind(this);
        this.openPreviewPermissionsPopup = this.openPreviewPermissionsPopup.bind(this);
        this.goToTickets = this.goToTickets.bind(this);
        this.goBack = this.goBack.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.goToList = this.goToList.bind(this);
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchPermissions()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    onChangeEmail(e, i) {
        const { value } = e.target;
        const { emails } = this.state;

        emails[i] = value;

        this.setState({
            emails,
        });
    }

    onChangeRole(email, role) {
        const { data } = this.state;

        const existingItem = data.find(x => x.email === email);

        if (existingItem) {
            existingItem.role = role;
            const index = data.indexOf(existingItem);
            data.splice(index, 1, existingItem);
        } else {
            data.push({
                email,
                role,
            });
        }

        this.setState({
            data,
        });
    }

    onSubmitEmails(e) {
        if (e && e.preventDefault instanceof Function) {
            e.preventDefault();
        }

        const { emails, company, step } = this.state;

        Api.request({
            method: 'POST',
            url: `companies/${company.slug}/agents/create/emails`,
            data: { emails },
        }).then(response => {
            if (response.data.data === true) {
                this.setState({
                    step: step + 1,
                    data: emails.map(x => ({ email: x, role: 'user' })),
                });
            }
        });
    }

    onSubmitRoles(e) {
        if (e && e.preventDefault instanceof Function) {
            e.preventDefault();
        }

        const { data, company, step } = this.state;

        const emails = data.map(x => x.email);
        const roles = data.map(x => x.role);

        Api.request({
            method: 'POST',
            url: `companies/${company.slug}/agents/create`,
            data: { emails, roles },
        }).then(response => {
            if (response.data.data === true) {
                this.setState({
                    step: step + 1,
                });
            }
        });
    }

    goToTickets() {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company);
    }

    goToList() {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company, 'agents');
    }

    goBack() {
        const { history } = this.props;
        history.goBack();
    }

    handleBack() {
        const { step } = this.state;
        this.setState({
            step: step - 1,
        });
    }

    openPreviewPermissionsPopup() {
        const { showPreviewPermissionsPopup } = this.state;

        this.setState({
            showPreviewPermissionsPopup: !showPreviewPermissionsPopup,
        });
    }

    addAnotherEmail() {
        const { emailInputs } = this.state;

        emailInputs.push(emailInputs.length);

        this.setState({
            emailInputs,
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchPermissions() {
        const mapData = data => {
            return Object.keys(data).map(x => ({ name: x, value: data[x] }));
        };
        return Api.request({
            method: 'GET',
            url: 'permissions',
        }).then(response => {
            this.setState({
                userPermissions: mapData(response.data.data.user),
                adminPermissions: mapData(response.data.data.admin),
            });
        });
    }

    renderSetupAgentEmails() {
        const { emailInputs } = this.state;

        return (
            <div>
                <ManageHeading
                    title={
                        <>
                            {trans('setup_a_new')}
                            <span className="font-weight-bold">{trans('agent')}</span>
                        </>
                    }
                    subtitle={trans('create_agent_email_text')}
                />
                <form
                    onSubmit={this.onSubmitEmails}
                    className="d-flex mx-auto flex-column manage-form"
                >
                    <p className="mt-5 px-4 align-self-start form-field-title">
                        {trans('agent_emails')}
                    </p>
                    <PerfectScrollbar className="px-4 manage-form-scroll">
                        {emailInputs.map((x, i) => (
                            <ManageInput
                                className={i > 0 ? 'mt-4' : ''}
                                key={`email-${x}`}
                                placeholder={trans('placeholders.agents_emails')}
                                type="text"
                                onChange={e => this.onChangeEmail(e, x)}
                            />
                        ))}
                    </PerfectScrollbar>
                    <div className="d-flex px-4 mt-2 flex-column align-items-end flex-grow-1">
                        <button
                            type="button"
                            className="btn btn-link manage-link-btn"
                            onClick={() => this.addAnotherEmail()}
                        >
                            {trans('add_another_agent')}
                        </button>
                        <FormButton
                            type="submit"
                            className="manage-btn mt-2"
                            text={trans('next_step')}
                        />
                    </div>
                </form>
            </div>
        );
    }

    renderSetupRoles() {
        const { emails } = this.state;

        const roles = helpers.getRoles();

        return (
            <div>
                <ManageHeading
                    title={
                        <>
                            {trans('setup_a_new')}
                            <span className="font-weight-bold">{trans('agent')}</span>
                        </>
                    }
                    subtitle={trans('set_roles_for_agent')}
                />
                <form className="manage-form mx-auto" onSubmit={this.onSubmitRoles}>
                    <p className="mt-5 px-4 align-self-start manage-form-field-title question-mark-parent">
                        {trans('what_can_they_do')}
                        <button
                            onClick={this.openPreviewPermissionsPopup}
                            type="button"
                            className="btn btn-link question-mark"
                        >
                            <img src={questionMarkDuotone} alt={trans('what_can_they_do')} />
                        </button>
                    </p>
                    <PerfectScrollbar className="px-4 manage-form-scroll overflow-hidden">
                        {emails.map(x => (
                            <div
                                key={`agent-${x}`}
                                className="d-flex flex-grow-1 justify-content-between align-items-center pb-4"
                            >
                                <p className="agent-email m-0 mr-4 text-truncate text-color-dark">
                                    {x}
                                </p>
                                <ManageSelectbox
                                    className="min-width-293px"
                                    onChange={e => this.onChangeRole(x, e.target.value)}
                                    key={`mdropdown-${x}`}
                                    id={x}
                                    items={roles}
                                />
                            </div>
                        ))}
                    </PerfectScrollbar>
                    <div className="d-flex px-4 flex-column align-items-end flex-grow-1">
                        <FormButton
                            type="submit"
                            className="manage-btn pb-1"
                            text={trans('next_step')}
                        />
                    </div>
                </form>
            </div>
        );
    }

    renderInvitesSent() {
        return (
            <>
                <ManageHeading
                    title={trans('invites_sent')}
                    subtitle={trans('invites_sent_subtitle')}
                />
                <div className="background-invites-sent">
                    <div className="d-flex flex-column align-items-center pt-2">
                        <img src={coloredUsers} alt="Colored user logos" className="mt-5" />
                        <p className="text-color-dark text-capitalize heading-medium font-weight-bold m-0 mt-minus-2rem mb-3">
                            {trans('invites_sent')}
                        </p>
                        <FormButton
                            type="submit"
                            className="manage-btn mt-5"
                            text={trans('go_to_tickets')}
                            onClick={this.goToTickets}
                        />
                        <p className="text-uppercase text-dark-gray m-0 mt-3">{trans('or')}</p>
                        <button
                            type="button"
                            className="btn btn-link text-small text-off-black mt-2"
                            onClick={this.goToList}
                        >
                            {trans('back_to_settings')}
                        </button>
                    </div>
                </div>
            </>
        );
    }

    static renderModalField(data, titleKey) {
        return (
            <div className="modal-field">
                <p className="title text-color-dark">{trans(titleKey)}</p>
                <div className="d-flex flex-wrap">
                    {data.map(x => (
                        <LabeledCheckbox
                            key={x}
                            containerClassName="mr-3 my-1"
                            readOnly
                            checked={x.value}
                            item={{
                                id: `permission-${x.name}`,
                                name: x.name,
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    }

    renderPreviewPermissionsModalContent() {
        const { userPermissions, adminPermissions } = this.state;

        return (
            <div className="p-3">
                <p className="manage-form-field-title font-weight-bold mb-4">Preview permissions</p>
                <div>
                    {CreatePage.renderModalField(adminPermissions, 'administrator_permissions')}
                    <hr />
                    {CreatePage.renderModalField(userPermissions, 'support_permissions')}
                </div>
            </div>
        );
    }

    render() {
        const { user, company, isLoading, showPreviewPermissionsPopup, step } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="purple" user={user} company={company}>
                <StepCarousel step={step}>
                    <CarouselPane
                        onNext={this.onSubmitEmails}
                        onPrev={this.goBack}
                        scroll
                        center
                        middle
                    >
                        {this.renderSetupAgentEmails()}
                    </CarouselPane>

                    <CarouselPane
                        onNext={this.onSubmitRoles}
                        onPrev={this.handleBack}
                        scroll
                        center
                        middle
                    >
                        {this.renderSetupRoles()}
                    </CarouselPane>

                    <CarouselPane
                        onNext={this.goBack}
                        onPrev={this.handleBack}
                        scroll
                        center
                        middle
                    >
                        {this.renderInvitesSent()}
                    </CarouselPane>
                </StepCarousel>
                <ManagePopup
                    show={showPreviewPermissionsPopup}
                    onClose={() => this.setState({ showPreviewPermissionsPopup: false })}
                    body={this.renderPreviewPermissionsModalContent()}
                />
            </ManageLayout>
        );
    }
}

CreatePage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(CreatePage);
