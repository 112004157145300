import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import definitions from '../../definitions';
import trans from '../../localization';
import RespondAttachment from './RespondAttachment';
import LabeledCheckbox from './LabeledCheckbox';
import UserIcon from './UserIcon';
import SwitchCheckbox from '../../elements/SwitchCheckbox';
import { AuthService } from '../../services';
import { AppContext } from '../../utils/app-context';

export default class QuickRespondPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: AuthService.user(),
            type: null,
        };
    }

    attachFile = e => {
        const { attachments = [] } = this.getFromContext();

        attachments.push(...e.target.files);

        this.setToContext({ attachments });
    };

    deleteFromArray = (key, value) => {
        const { [key]: array = [] } = this.getFromContext();
        const index = array.indexOf(value);

        if (index !== -1) {
            array.splice(index, 1);
        }

        this.setToContext({ array });
    };

    onChangeData = (property, value) => {
        const state = this.getFromContext;

        if (!state) {
            this.setToContext({
                [property]: value,
            });
        }

        this.setToContext({
            ...state,
            [property]: value,
        });
    };

    onChangeType = e => {
        const newType =
            e.target.checked === false ? definitions.TYPE_MESSAGE : definitions.TYPE_NOTE;

        this.setState({
            type: newType,
        });
    };

    sendMessage = () => {
        const { type: typeState } = this.state;
        const { type } = this.props;

        const { onSendMessage, ticket } = this.props;

        const {
            messageBody: body = '',
            attachments = [],
            forwardAttachments = [],
            signature = AuthService.user().use_signature,
            cc = [],
            bcc = [],
            fwd = [],
            cannedResponse = null,
        } = this.getFromContext();

        // eslint-disable-next-line no-undef
        const formData = new FormData();

        formData.append('body', body);
        formData.append('type', typeState || type);
        formData.append('signature', signature ? '1' : '0');

        const appendValues = (arr, name) => arr.forEach(x => formData.append(`${name}[]`, x.value));

        appendValues(fwd, 'fwd');
        appendValues(cc, 'cc');
        appendValues(bcc, 'bcc');

        if (cannedResponse) {
            formData.append('canned_response_id', cannedResponse.id);
        }

        attachments.forEach(x => formData.append('files[]', x, x.name));
        forwardAttachments.map(x => formData.append('forward_attachment_ids[]', x.id));

        attachments.forEach(x => formData.append('files[]', x, x.name));

        onSendMessage(ticket, formData).then(this.clearContext);
    };

    setToContext = (state = {}, callback) => {
        const { setObjectWithId } = this.context;
        const { ticket } = this.props;

        if (!ticket) {
            return;
        }

        setObjectWithId('quickRespond', ticket.id, state, callback);
    };

    getFromContext = () => {
        const { getObjectWithId } = this.context;
        const { ticket } = this.props;

        if (!ticket) {
            return {};
        }

        return getObjectWithId('quickRespond', ticket.id);
    };

    clearContext = () => {
        this.setToContext({
            messageBody: '',
            attachments: [],
            forwardAttachments: [],
            receiverAdditions: {},
            fwd: [],
            cc: [],
            bcc: [],
            signature: AuthService.user().use_signature,
            cannedResponse: null,
        });
    };

    render() {
        const { onClose, mimeTypes, type, show, offsetRight } = this.props;
        const { type: typeState, user } = this.state;

        const {
            messageBody: body = '',
            attachments = [],
            forwardAttachments = [],
            signature = AuthService.user().use_signature,
        } = this.getFromContext();

        const messageType = typeState || type;
        const sendingAsMessage = messageType === definitions.TYPE_MESSAGE;
        const switchToValue = sendingAsMessage ? definitions.TYPE_NOTE : definitions.TYPE_MESSAGE;

        return (
            <div
                className="quickrespond-popup"
                style={{
                    right: `${offsetRight}px`,
                    opacity: show ? 1 : 0,
                    visibility: show ? 'visible' : 'hidden',
                }}
            >
                <div className="quickrespond-popup-content">
                    <div className="d-flex flex-column flex-grow-1">
                        <div className="quickrespond-popup-content-section">
                            <div className="d-flex text-xsmall cursor-default">
                                <UserIcon user={user} className="user-icon-shadow mr-2" />
                                <div>
                                    <div className="font-weight-bold text-dark text-small">
                                        {user.name}
                                    </div>
                                    <div className="text-primary text-xsmall">{user.email}</div>
                                </div>
                            </div>
                            <div className="ml-auto" />
                            <div className="">
                                <SwitchCheckbox
                                    id="message-type"
                                    onChange={this.onChangeType}
                                    checked={!sendingAsMessage}
                                    label={
                                        messageType
                                            ? trans('switch_to_x', { name: trans(switchToValue) })
                                            : null
                                    }
                                />
                            </div>
                        </div>
                        <div className="quickrespond-popup-content-section body">
                            <textarea
                                value={body}
                                className="message-textarea"
                                onChange={e => this.onChangeData('messageBody', e.target.value)}
                                placeholder={trans('type_your_message_here')}
                            />
                        </div>
                        <div className="quickrespond-popup-content-section text-border-color border-top-1">
                            <div>
                                <RespondAttachment
                                    mimeTypes={mimeTypes}
                                    attachments={attachments}
                                    forwardAttachments={forwardAttachments}
                                    onAttach={this.attachFile}
                                    onDeleteAttachment={attachment =>
                                        this.deleteFromArray('attachments', attachment)
                                    }
                                    onDeleteForwardAttachment={attachment =>
                                        this.deleteFromArray('forwardAttachments', attachment)
                                    }
                                />
                            </div>
                            <div className="ml-auto" />
                            {sendingAsMessage && (
                                <div className="d-flex align-items-center text-nowrap">
                                    <LabeledCheckbox
                                        item={{
                                            name: trans('signature'),
                                            id: 'signature',
                                        }}
                                        checked={signature}
                                        onChange={e =>
                                            this.onChangeData('signature', e.target.checked)
                                        }
                                        containerClassName={classNames(
                                            'pl-2 pr-5',
                                            { 'theme-primary': signature },
                                            { 'theme-lightgray': !signature }
                                        )}
                                    />
                                </div>
                            )}
                            <div className="d-flex">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="ml-2 btn btn-bg-cancel border-radius-circle div-36 text-white text-xsmall"
                                >
                                    <i className="fa fa-times" />
                                </button>

                                <button
                                    type="submit"
                                    onClick={this.sendMessage}
                                    className="ml-2 btn bg-primary border-radius-50 text-white text-xsmall px-4"
                                >
                                    {trans('send')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

QuickRespondPopup.contextType = AppContext;

QuickRespondPopup.propTypes = {
    onClose: PropTypes.func,
    mimeTypes: PropTypes.array,
    type: PropTypes.string,
    show: PropTypes.bool,
    offsetRight: PropTypes.number,
    onSendMessage: PropTypes.func,
    ticket: PropTypes.object,
};

QuickRespondPopup.defaultProps = {
    onClose: () => {},
    mimeTypes: null,
    type: definitions.TYPE_MESSAGE,
    show: false,
    offsetRight: 0,
    onSendMessage: () => {},
    ticket: null,
};
