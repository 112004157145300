import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Popover } from '../index';

const FilterOption = ({
    title,
    options,
    value,
    onChange,
    valueKey,
    colorKey,
    labelKey,
    useColor,
}) => {
    const selectedOption = options.find(x => x[valueKey] === value);
    const selectedLabel = selectedOption ? selectedOption[labelKey] : '';
    const selectedValue = selectedOption ? selectedOption[valueKey] : -1;
    let optionClass = null;

    if (value) {
        optionClass =
            useColor && selectedOption
                ? `ticket-status-${selectedOption[colorKey]}`
                : `ticket-status-${value}`;
    }

    return (
        <div className="filter-option mx-4">
            <div className="list-group">
                <div className="list-group-title">
                    <h5>{title}</h5>
                </div>
                <Popover
                    label={
                        <div className={`select-wrapper status-icons py-1 px-2 ${optionClass}`}>
                            <div className="pl-4" style={{ minHeight: '1.5rem' }}>
                                {selectedLabel}
                            </div>
                        </div>
                    }
                    align="center"
                >
                    {options.map(x => (
                        <a
                            className={cx(
                                `list-group-item list-group-item-action filter-option-${x[colorKey]} py-1 cursor-pointer`,
                                { active: selectedValue === x[valueKey] }
                            )}
                            href={`#filter-option-${x[valueKey]}`}
                            key={x[valueKey]}
                            role="button"
                            tabIndex={0}
                            onClick={e => {
                                onChange({ ...e, target: { ...e.target, value: x[valueKey] } });
                                document.activeElement.blur();
                            }}
                            value={x[valueKey]}
                        >
                            <p className="text-truncate">{x[labelKey]}</p>
                        </a>
                    ))}
                </Popover>
            </div>
        </div>
    );
};

export default FilterOption;

FilterOption.propTypes = {
    options: PropTypes.array,
    title: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    valueKey: PropTypes.string,
    colorKey: PropTypes.string,
    labelKey: PropTypes.string,
    useColor: PropTypes.bool,
};

FilterOption.defaultProps = {
    options: null,
    title: null,
    onChange: () => {},
    value: null,
    valueKey: 'value',
    colorKey: 'value',
    labelKey: 'title',
    useColor: false,
};
