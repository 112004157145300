import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { successEmail } from '../../assets/images';
import { Api } from '../../services';
import { AuthCardView } from '../../components/auth';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';
import { URL } from '../../elements';

class VerifyEmailPage extends Component {
    static sentMessage() {
        return (
            <div className="auth-email-sent-message">
                <p className="mb-0 mt-4 pt-2 text-center text-muted">
                    {trans('we_sent_a_new_email')}
                </p>
            </div>
        );
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            isSent: false,
            isSending: false,
        };
    }

    resend() {
        this.setState({
            isSending: true,
        });

        Api.request({
            method: 'POST',
            url: 'auth/resend',
        }).finally(() => {
            this.setState({
                isSent: true,
                isSending: false,
            });
        });
    }

    paragraph() {
        return (
            <div className="auth-email-sent-message">
                <p className="mb-0 mt-4 pt-2 text-center text-muted">
                    {trans('i_have_not_received_email')}
                    <URL onClick={() => this.resend()}> {trans('resend_me_an_email')}</URL>
                </p>
                <p className="text-muted text-center">{trans('do_not_forget_check_your_spam')}</p>
            </div>
        );
    }

    render() {
        const { isSending, isSent } = this.state;

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView
                    title={trans('you_are_all_ready_to_go')}
                    subtitle={trans('you_are_all_ready_to_go_subtitle')}
                    titleSmall={trans('verify_your_account')}
                    centerHeadingSmall
                >
                    <div className="auth-email-sent text-center pt-5">
                        <img
                            src={successEmail}
                            alt={trans('email_sent')}
                            className="auth-email-sent-image img-fluid mb-5 mt-2"
                        />

                        <h2 className="auth-email-sent-text text-center font-weight-bold">
                            {trans('email_sent')}
                        </h2>
                    </div>

                    {isSent ? VerifyEmailPage.sentMessage() : this.paragraph()}
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(VerifyEmailPage);
