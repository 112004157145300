import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ManageLayout } from '../../../../layouts';
import { ManageField, ManageSelectbox } from '../../../../elements';
import trans from '../../../../localization';
import { Api, AuthService } from '../../../../services';
import { CarouselPane, StepCarousel } from '../../../../components';
import CancelAndSaveFooter from '../../../../components/helpdesk/carousel/CancelAndSaveFooter';

class CreateOrEditPage extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;

        const data = location.state && location.state.data;

        this.state = {
            isLoading: true,
            user: AuthService.user(),
            companyId: location.state && location.state.companyId,
            company: null,
            channels: data
                ? [
                      {
                          title: (data && `${data.company} > ${data.channel}`) || '',
                          value: 0,
                      },
                  ]
                : [],
            isUpdate: !!data,
            data: data || {
                source_id: '',
                body: '',
            },
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchChannels()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        if (!companyId) return Promise.resolve();

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchChannels() {
        const { data, isUpdate } = this.state;

        if (isUpdate) {
            return Promise.resolve();
        }

        return Api.request({
            method: 'GET',
            url: 'channels',
        }).then(response => {
            this.setState({
                channels: response.data.data.map(x => ({
                    title: `${x.company.name} > ${x.name}`,
                    value: x.id,
                })),
                data: {
                    ...data,
                    source_id: response.data.data[0].id,
                },
            });
        });
    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    onChange = (field, value) => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                [field]: value,
            },
        });
    };

    createSignature = () => {
        const { data } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: 'signatures/create',
            data,
        })
            .then(() => {
                const { history } = this.props;
                history.goBack();
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    updateSignature = () => {
        const { data } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'PUT',
            url: `signatures/${data.id}/edit`,
            data: {
                body: data.body,
            },
        })
            .then(() => {
                const { history } = this.props;
                history.goBack();
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    render() {
        const { isLoading, user, company, data, channels, isUpdate } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="purple"
                user={user}
                company={company}
                hideLBImg
            >
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-4"
                        scroll
                        fluid
                    >
                        <div className="d-flex flex-column pb-5">
                            <span className="font-weight-bold text-3xlarge pb-4">
                                {isUpdate ? trans('update_signature') : trans('create_signature')}
                            </span>
                            <span className="font-weight-light text-darker-gray">
                                {trans('create_signature_text')}
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 pt-4">
                                <ManageField title={trans('channel')}>
                                    <ManageSelectbox
                                        value={data.source_id}
                                        items={channels}
                                        disabled={isUpdate}
                                        onChange={e => this.onChange('source_id', e.target.value)}
                                    />
                                </ManageField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9 pt-5">
                                <ManageField title={trans('signature_content')}>
                                    <div className="manage-textarea-container">
                                        <textarea
                                            value={data.body}
                                            placeholder={trans(
                                                'placeholders.type_signature_content_here'
                                            )}
                                            onChange={e => this.onChange('body', e.target.value)}
                                        />
                                    </div>
                                </ManageField>
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>

                <CancelAndSaveFooter
                    submitTitle={isUpdate ? trans('save_changes') : trans('create')}
                    onSubmit={isUpdate ? this.updateSignature : this.createSignature}
                    onCancel={this.goBack}
                />
            </ManageLayout>
        );
    }
}

CreateOrEditPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(CreateOrEditPage);
