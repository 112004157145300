import React from 'react';
import PropTypes from 'prop-types';

const ProgressOverlay = props => {
    const { isLoading } = props;

    if (isLoading) {
        return <div className="progress-overlay" />;
    }

    return <div />;
};

export default ProgressOverlay;

ProgressOverlay.propTypes = {
    isLoading: PropTypes.bool,
};

ProgressOverlay.defaultProps = {
    isLoading: false,
};
