import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Socket } from '../services';

class HelpdeskLayout extends PureComponent {
    componentDidMount() {
        const { match } = this.props;

        Socket.init().company(match.params.companyId);
    }

    componentWillUnmount() {
        const { match } = this.props;

        Socket.disconnect(match.params.companyId);
    }

    render() {
        const { children } = this.props;

        return <div>{children}</div>;
    }
}

export default withRouter(HelpdeskLayout);

HelpdeskLayout.propTypes = {
    children: PropTypes.node.isRequired,
    match: PropTypes.object.isRequired,
};
