import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import ChannelIcon from '../ChannelIcon';
import UserIcon from '../UserIcon';
import trans from '../../../localization';
import ImageIcon from '../ImageIcon';
import helpers from '../../../helpers';

const ManageListItem = ({ type, item, showCog, cogMenu, onClick, onCogClick, isActive }) => {
    const showingCog = showCog || onCogClick || cogMenu.length;

    if (!item) {
        return (
            <div className="list-add">
                <button
                    type="button"
                    onClick={onClick}
                    className="btn btn-link d-flex flex-column align-items-center text-decoration-none"
                >
                    <div className="d-flex align-items-center">
                        <div className="add-btn d-flex justify-content-center align-items-center">
                            <i className="fal fa-times" />
                        </div>
                    </div>
                    <p className="add-text mt-3">{trans(`add_${type}`)}</p>
                </button>
            </div>
        );
    }

    return (
        <div className="list-item">
            {!!showingCog && (
                <div className="d-flex w-100 justify-content-end">
                    {(showCog || onCogClick) && (
                        <button type="button" onClick={onCogClick} className="btn btn-link cog-btn">
                            <i className="fa fa-cog" />
                        </button>
                    )}

                    {cogMenu.length > 0 && (
                        <Dropdown>
                            <Dropdown.Toggle variant="link" className="cog-btn">
                                <i className="fa fa-cog" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {cogMenu.map(menu => (
                                    <Dropdown.Item
                                        key={`menu-${menu.title}`}
                                        href="#/"
                                        onClick={() => menu.onClick()}
                                    >
                                        {menu.title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            )}
            <button
                type="button"
                onClick={() => onClick(item)}
                className="btn btn-link d-flex flex-column align-items-center flex-grow-1 text-decoration-none justify-content-center"
            >
                <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                    {type === 'agent' && <UserIcon user={item} />}
                    {type === 'channel' && <ChannelIcon isActive={isActive} channel={item} />}
                    {type === 'image' && (
                        <ImageIcon
                            className="mt-4"
                            image={item.image}
                            alt={trans(`providers.${item.provider}`)}
                        />
                    )}
                    {type === 'ticketType' && (
                        <ChannelIcon isActive channel={{ color: item.color, icon: 'circle' }} />
                    )}
                    {type === 'link' && (
                        <ChannelIcon isActive channel={{ color: item.color, icon: item.icon }} />
                    )}
                </div>
                <div className="flex-shrink-0 p-2 text-light-gray font-weight-light">
                    {item.name && <p className="name text-light-gray text-truncate">{item.name}</p>}
                    {type === 'invitation' && item.email && (
                        <p className="name text-dark text-truncate">{item.email}</p>
                    )}
                    {type === 'agent' && item.role && <p className="role">{trans(item.role)}</p>}
                    {type === 'invitation' && (
                        <p className="name text-light-gray text-truncate">
                            {trans(`roles.${item.role}`)}
                        </p>
                    )}
                    {type === 'image' && item.provider && (
                        <p className="name text-truncate">{trans(`providers.${item.provider}`)}</p>
                    )}
                    {type === 'invitation' && item.user && (
                        <p className="role text-light-gray">{item.user.name}</p>
                    )}
                    {type === 'channel' && item.type && (
                        <p className="role">{helpers.providerName(item)}</p>
                    )}
                </div>
            </button>
        </div>
    );
};

export default ManageListItem;

ManageListItem.propTypes = {
    item: PropTypes.object,
    type: PropTypes.string,
    showCog: PropTypes.bool,
    cogMenu: PropTypes.array,
    onClick: PropTypes.func,
    onCogClick: PropTypes.func,
    isActive: PropTypes.bool,
};

ManageListItem.defaultProps = {
    item: null,
    type: null,
    showCog: false,
    cogMenu: [],
    onClick: () => {},
    onCogClick: null,
    isActive: null,
};
