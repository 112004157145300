import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Navbar from '../../components/helpdesk/Navbar';
import { AppLayout } from '../../layouts';
import { Api } from '../../services';
import QuickRespond from '../../components/helpdesk/QuickRespond';
import SidebarTicket from '../../components/helpdesk/SidebarTicket';
import definitions from '../../definitions';
import TicketService from '../../services/TicketService';
import eventService from '../../services/EventService';
import NavigationMenu from '../../components/helpdesk/NavigationMenu';
import { CustomerOrdersSidebar } from '../../components';
import router from '../../utils/router';
import CompanyService from '../../services/CompanyService';

class TicketPage extends Component {
    constructor(props) {
        super(props);

        const { match, location } = props;

        this.state = {
            isLoading: true,
            companyId: match.params.companyId,
            company: null,
            companies: [],
            ticketId: match.params.ticketId,
            ticket: null,
            ticketMessages: [],
            previousState: location.state,
            showMenu: false,
            orders: [],
            showOrders: false,
            mimeTypes: [],
            canFetchOrders: false,
            hasSignature: false,
            messagesPage: 0,
            isLastMessagesPage: true,
            ticketTypes: [],
            sources: [],
            showSources: false,
            lastAIResponse: null,
            lastAIResponseId: null,
        };

        this.updateTicket = this.updateTicket.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.assignUser = this.assignUser.bind(this);
        this.addTag = this.addTag.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.goToSettingPage = this.goToSettingPage.bind(this);
        this.closeTicket = this.closeTicket.bind(this);
    }

    componentDidMount() {
        const promises = [
            this.fetchCompany(),
            this.fetchTicket(),
            this.fetchCompanies(),
            this.fetchMessages(),
            this.fetchTicketTypes(),
            this.fetchChannels(),
        ];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState(
                {
                    isLoading: false,
                },
                () => {
                    this.startListeningEvents();
                }
            );

            this.fetchDetail();
        });
    }

    componentWillUnmount() {
        const { company } = this.state;
        if (company) {
            eventService.removeListener(definitions.EVENT_TICKET_MESSAGE_SENT);
            eventService.removeListener(definitions.EVENT_TICKET_UPDATED);
            eventService.removeListener(definitions.EVENT_TICKET_UPDATED_WITHOUT_LISTENER);
            eventService.removeListener(definitions.EVENT_TICKET_MESSAGE_RECEIVED);
            eventService.removeListener(definitions.EVENT_TICKET_MESSAGE_DELETED);
        }
    }

    toggleMenu() {
        const { showMenu } = this.state;

        this.setState({
            showMenu: !showMenu,
        });
    }

    goToSettingPage(settingItem) {
        const { company } = this.state;
        const { history } = this.props;

        CompanyService.goToSettingsPage(history, company, settingItem);
    }

    startListeningEvents() {
        eventService.on(definitions.EVENT_TICKET_UPDATED, ticket => {
            const { ticket: currentTicket } = this.state;
            if (currentTicket && currentTicket.id === ticket.id) {
                this.setState({
                    ticket,
                });
            }
        });

        eventService.on(definitions.EVENT_TICKET_UPDATED_WITHOUT_LISTENER, ticket => {
            const { ticket: currentTicket } = this.state;
            if (currentTicket && currentTicket.id === ticket.id) {
                this.setState({
                    ticket,
                });
            }
        });

        eventService.on(definitions.EVENT_TICKET_MESSAGE_SENT, ticketMessage => {
            const { ticket, ticketMessages } = this.state;

            // noinspection JSUnresolvedVariable
            if (ticket && ticket.id === ticketMessage.ticket_id && ticketMessages) {
                this.setState({
                    ticketMessages: TicketService.updateMessage(ticketMessage, ticketMessages),
                });
            }
        });

        eventService.on(definitions.EVENT_TICKET_MESSAGE_RECEIVED, ticketMessage => {
            const { ticket, ticketMessages } = this.state;

            // noinspection JSUnresolvedVariable
            if (ticket && ticket.id === ticketMessage.ticket_id && ticketMessages) {
                this.setState({
                    ticketMessages: TicketService.addMessage(ticketMessage, ticketMessages),
                });
            }
        });

        eventService.on(definitions.EVENT_TICKET_MESSAGE_DELETED, ticketMessage => {
            const { ticket, ticketMessages } = this.state;

            if (ticket && ticket.id === ticketMessage.ticket_id && ticketMessages) {
                this.setState({
                    ticketMessages: TicketService.deleteMessage(ticketMessage, ticketMessages),
                });
            }
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchCompanies() {
        return Api.request({
            method: 'GET',
            url: 'companies',
            params: {
                counts: true,
            },
        }).then(response => {
            this.setState({
                companies: response.data.data,
            });
        });
    }

    fetchTicket() {
        const { ticketId } = this.state;

        return Api.request({
            method: 'GET',
            url: `tickets/${ticketId}`,
        }).then(response => {
            this.setState({
                ticket: response.data.data,
                showSources: response.data.data.can_change_channel,
            });
        });
    }

    fetchMessages() {
        return new Promise((resolve, reject) => {
            const { ticketId, messagesPage, ticketMessages } = this.state;

            return Api.request({
                method: 'GET',
                url: `tickets/${ticketId}/messages`,
                params: {
                    page: messagesPage + 1,
                },
            })
                .then(response => {
                    // noinspection JSUnresolvedVariable
                    this.setState(
                        {
                            ticketMessages: [...ticketMessages, ...response.data.data],
                            isLastMessagesPage:
                                response.data.meta.current_page === response.data.meta.last_page,
                            messagesPage: response.data.meta.current_page,
                        },
                        () => {
                            resolve(response);
                        }
                    );
                })
                .catch(reject);
        });
    }

    fetchTicketTypes() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: 'ticketTypes',
            params: {
                companyId,
            },
        }).then(response => {
            this.setState({
                ticketTypes: response.data.data,
            });
        });
    }

    fetchChannels() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: 'channels',
            params: {
                companyId,
                create_ticket: true,
            },
        }).then(response => {
            this.setState({
                sources: response.data.data,
            });
        });
    }

    fetchDetail() {
        const { ticketId } = this.state;

        Api.request({
            method: 'POST',
            url: 'ticketViewers',
            data: {
                ticket_id: ticketId,
            },
        });

        return Api.request({
            method: 'GET',
            url: `tickets/${ticketId}/detail`,
        }).then(response => {
            const { mimeTypes, canFetchOrders, hasSignature, lastAIResponse } = response.data.data;

            this.setState({
                mimeTypes,
                canFetchOrders,
                hasSignature,
                lastAIResponse,
                lastAIResponseId: ticketId,
            });
        });
    }

    fetchCannedResponses() {
        const { ticketId } = this.state;

        this.setState({
            isLoading: true,
        });

        return Api.request({
            method: 'GET',
            url: `cannedResponses/tickets/${ticketId}`,
        })
            .then(response => {
                this.setState({
                    isLoading: false,
                });

                return response;
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    onFetchOrders() {
        this.setState({
            isLoading: true,
        });

        const { isLoading, ticket, company } = this.state;
        if (isLoading || !ticket) {
            return null;
        }

        return Api.request({
            url: `companies/${company.slug}/orders/${ticket.customer.id}`,
            method: 'GET',
        })
            .then(res => {
                this.setState({
                    orders: res.data.data,
                    showOrders: true,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    updateTicket(ticketId, key, value) {
        const { showSources } = this.state;
        let request = Promise.resolve();

        switch (key) {
            case definitions.KEY_STATUS:
                request = TicketService.updateStatus(ticketId, value);
                break;

            case definitions.KEY_PRIORITY:
                request = TicketService.updatePriority(ticketId, value);
                break;

            case definitions.KEY_TICKET_TYPE_ID:
                request = TicketService.updateTicketType(ticketId, value);
                break;

            case definitions.KEY_SOURCE_ID:
                if (showSources) {
                    request = TicketService.updateSource(ticketId, value);
                }
                break;

            default:
                break;
        }

        this.setState({
            isLoading: true,
        });

        request
            .then(response => {
                this.setState({
                    ticket: response.data.data,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    closeTicket(extra = {}) {
        const { company, previousState } = this.state;
        const { history } = this.props;
        let state = extra;

        if (previousState) {
            state = {
                viewType: previousState.viewType,
                statuses: previousState.statuses,
                priorities: previousState.priorities,
                ...extra,
            };
        }

        router.toCompany(history, company, null, state);
    }

    refreshTicket(ticket) {
        this.setState({
            ticket,
        });
    }

    refreshTicketMessages(ticketMessages) {
        return new Promise(resolve => {
            this.setState(
                {
                    ticketMessages,
                },
                () => {
                    resolve();
                }
            );
        });
    }

    searchCustomer(customer) {
        this.closeTicket({
            customers: [customer],
        });
    }

    sendMessage(ticket, formData) {
        return new Promise((resolve, reject) => {
            this.setState({
                isLoading: true,
                lastAIResponse: null,
                lastAIResponseId: null,
            });

            TicketService.sendMessage(ticket.id, formData)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        });
    }

    searchUser(query, setItems) {
        const { company, ticket } = this.state;

        if (!company || !ticket) {
            return Promise.resolve();
        }

        return Api.request({
            method: 'GET',
            url: `companies/${company.slug}/users?search=${query}`,
            params: {
                ticket_id: ticket.id,
            },
        }).then(res => {
            setItems(res.data.data);
        });
    }

    assignUser(user) {
        const { ticket } = this.state;

        this.setState({
            isLoading: true,
        });

        return Api.request({
            method: 'POST',
            url: `tickets/${ticket.id}/user`,
            data: { user_id: user.id },
        })
            .then(response => {
                this.setState({
                    isLoading: false,
                    ticket: response.data.data,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    removeAssignedUser = user => {
        const { ticket } = this.state;

        this.setState({
            isLoading: true,
        });

        return Api.request({
            method: 'DELETE',
            url: `tickets/${ticket.id}/user`,
            data: { user_id: user.id },
        })
            .then(response => {
                this.setState({
                    isLoading: false,
                    ticket: response.data.data,
                });
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    addTag(tag) {
        const { ticket } = this.state;

        this.setState({
            isLoading: true,
        });

        const data = tag.id ? { tag_id: tag.id } : { name: tag };
        return Api.request({
            method: 'PUT',
            url: `tickets/${ticket.id}/tag`,
            data,
        })
            .then(response => {
                this.setState({
                    ticket: response.data.data,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    deleteTag(tag) {
        const { ticket } = this.state;

        this.setState({
            isLoading: true,
        });

        return Api.request({
            method: 'DELETE',
            url: `tickets/${ticket.id}/tag`,
            data: { tag_id: tag.id },
        })
            .then(response => {
                this.setState({
                    isLoading: false,
                    ticket: response.data.data,
                });
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    renderCompany() {
        const { company, companies, viewType } = this.state;

        return (
            <Navbar
                viewType={viewType}
                role={company && company.role}
                companies={companies}
                showSearch={false}
                showViewTypes={false}
                onMenuClick={this.toggleMenu}
                onLogoClick={() => this.closeTicket()}
            />
        );
    }

    renderTicket() {
        const {
            ticket,
            ticketMessages,
            company,
            mimeTypes,
            canFetchOrders,
            hasSignature,
            orders,
            showOrders,
            isLastMessagesPage,
            ticketTypes,
            showSources,
            sources,
            lastAIResponse,
            lastAIResponseId,
        } = this.state;

        const { history } = this.props;

        return (
            <div>
                <div className="platform-wrapper fullmessage">
                    <div className="main-content container-fluid message-page">
                        <div className="container-fluid messages">
                            <QuickRespond
                                single
                                ticket={ticket}
                                company={company}
                                messages={ticketMessages}
                                mimeTypes={mimeTypes}
                                onCloseTicket={() => this.closeTicket()}
                                sendMessage={(t, message) => this.sendMessage(t, message)}
                                updateTicket={t => this.refreshTicket(t)}
                                updateMessages={m => this.refreshTicketMessages(m)}
                                onClickCustomer={customer => this.searchCustomer(customer)}
                                getCannedResponses={() => this.fetchCannedResponses()}
                                canFetchOrders={false}
                                isLastPage={isLastMessagesPage}
                                onNextPage={() => this.fetchMessages()}
                                history={history}
                                hasSignature={hasSignature}
                                lastAIResponse={lastAIResponse}
                                lastAIResponseId={lastAIResponseId}
                            />
                        </div>
                    </div>
                </div>

                <SidebarTicket
                    ticket={ticket}
                    company={company}
                    updateTicket={this.updateTicket}
                    searchUser={this.searchUser}
                    assignUser={this.assignUser}
                    removeAssignedUser={this.removeAssignedUser}
                    addTag={this.addTag}
                    deleteTag={this.deleteTag}
                    onFetchOrders={() => this.onFetchOrders()}
                    canFetchOrders={canFetchOrders}
                    ticketTypes={ticketTypes}
                    showSources={showSources}
                    sources={sources}
                />

                <CustomerOrdersSidebar
                    ticket={ticket}
                    orders={orders}
                    active={showOrders}
                    onClose={() =>
                        this.setState({
                            showOrders: false,
                        })
                    }
                    absolute
                />
            </div>
        );
    }

    render() {
        const { company, isLoading, ticket, showMenu } = this.state;
        const { history } = this.props;

        return (
            <AppLayout isLoading={isLoading}>
                {company && this.renderCompany()}
                {ticket && company && this.renderTicket()}
                {company && (
                    <NavigationMenu
                        active={showMenu}
                        company={company}
                        role={company.role}
                        closeMenu={this.toggleMenu}
                        goToSettingPage={this.goToSettingPage}
                        history={history}
                    />
                )}
            </AppLayout>
        );
    }
}

export default withRouter(TicketPage);

TicketPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
