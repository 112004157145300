import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CarouselPane, LoadingBar, StepCarousel } from '../../../components';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';
import ApiService from '../../../services/ApiService';
import notify from '../../../utils/notify';
import router from '../../../utils/router';

class VerifySetupEmailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: AuthService.user(),
            company: null,
            channel: null,
        };
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchChannel()]).finally(() => {
            this.tryToVerify();
        });
    }

    fetchCompany = () => {
        const { match } = this.props;

        return Api.request({
            method: 'GET',
            url: `companies/${match.params.companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    fetchChannel = () => {
        const { match } = this.props;

        return Api.request({
            method: 'GET',
            url: `channels/${match.params.channelId}`,
        }).then(response => {
            this.setState({
                channel: response.data.data,
            });
        });
    };

    tryToVerify() {
        const query = ApiService.formatSearch(this.props);
        const { company, channel } = this.state;
        const { history } = this.props;

        Api.request({
            method: 'POST',
            url: `companies/${company.slug}/channels/${channel.id}/setup/email/verify?${query}`,
        })
            .then(() => {
                notify.success('we_verified_your_email_address');
                router.toCompany(history, company, `channels/${channel.id}/setup/email/finish`);
            })
            .catch(() => {
                router.toCompany(history, company, `channels/${channel.id}/setup/email/unverified`);
            });
    }

    render() {
        const { user, company } = this.state;

        return (
            <ManageLayout isLoading theme="green" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane
                        className="d-flex flex-column justify-content-center"
                        scroll
                        middle
                        center
                    >
                        <LoadingBar />
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(VerifySetupEmailPage);

VerifySetupEmailPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
