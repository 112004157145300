import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import definitions from '../../../definitions';
import { Api } from '../../../services';
import { HoverTooltip } from '../../../components';
import trans from '../../../localization';
import { ManageCheckbox, ManageField, ManageInput, ManageSelectbox } from '../../../elements';
import advancedSettingsBanner from '../../../assets/images/advanced-mail-settings-banner.png';
import helpers from '../../../helpers';
import notify from '../../../utils/notify';
import ChannelLogs from '../../../components/helpdesk/ChannelLogs';
import BaseEditPage from './BaseEditPage';

const encryptionMethods = [
    { title: trans('none'), value: 0 },
    { title: 'TLS', value: definitions.MAIL_ENCRYPTION.TLS },
    { title: 'SSL', value: definitions.MAIL_ENCRYPTION.SSL },
];

const incomingServerServices = [
    { title: trans('none'), value: 0 },
    { title: 'POP3', value: definitions.MAIL_SERVER_TYPE.POP3 },
    { title: 'IMAP', value: definitions.MAIL_SERVER_TYPE.IMAP },
];

const DKIMValue = `v=DKIM1; k=rsa; p=MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvzVRMd6+LKcL4ubnd4RTkwHQuBhFuemdsLcMN3bFOcQYzmUAekU9BENxykta05fzjj5D1uUHpRByHrnwU+Yj3f/EVXjx9cJ2xsPJJHX4iYdX8CwYVbhignPxMt/346bb8U8TkT1JAaOT6Q+GTQ0e97E9NuKLP9F4XAPL7E2uTCzl/16jvXe51qjoLxZgUP4/ApQh1QUYlwURqWcdn3/TWuSVzy73DSCXnYLSjIT79+tBTX8amwdoqa5CmsLsKaBndRp7Dq4vXIrMBqOq99zuirxvBaJw9oqk5viwG5RmS55ZK7QnrCuVyWTiDURvtxOGQl98T1q57IaG9a+ejZHSPwIDAQAB;`;

class EditEmailPage extends BaseEditPage {
    constructor(props) {
        const state = {
            email: '',
            hasForwardEmail: null,
            forwardEmailVerified: null,
            copiedIndicator: false,
            isIncomingTesting: false,
            isOutgoingTesting: false,
            canSendVerification: true,
            channel: {
                icon: '',
                color: '',
                name: '',
                provider: {
                    forward_email: '',
                    use_email_server: false,
                    use_template: false,
                    use_company_name_as_sender_name_when_sending_emails: false,
                    email_server: {
                        use_incoming: '1',
                        outgoing_is_active: false,
                        outgoing_server: '',
                        outgoing_port: '',
                        outgoing_encryption: '',
                        outgoing_username: '',
                        outgoing_password: '',
                        reply_to_email: '',
                        from_email: '',
                        dkim: false,
                        dkim_domain: '',
                        incoming_is_active: false,
                        incoming_server: '',
                        incoming_port: '',
                        incoming_service: '',
                        incoming_encryption: '',
                        incoming_username: '',
                        incoming_password: '',
                        incoming_delete_after_fetch: '',
                    },
                },
            },
        };

        super(props, state);

        this.logsRef = React.createRef();
        this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);
        this.testOutgoing = this.testOutgoing.bind(this);
        this.testIncoming = this.testIncoming.bind(this);
        this.connectOrDisconnectGoogleAccount = this.connectOrDisconnectGoogleAccount.bind(this);
        this.connectOrDisconnectOutlookAccount = this.connectOrDisconnectOutlookAccount.bind(this);
        this.sendVerificationEmail = this.sendVerificationEmail.bind(this);
    }

    componentDidMount() {
        this.onMount([this.fetchMailServers()]).then(() => {
            const { channel } = this.state;
            this.applyChannelData(channel);
        });
    }

    fetchMailServers() {
        return Api.request({
            method: 'GET',
            url: `defaultEmailServers`,
        }).then(response => {
            this.setState({
                providers: response.data.data,
            });
        });
    }

    onChangeChannelProviderSettings(field, e) {
        const { channel } = this.state;
        const value = e.target ? e.target.value : e;

        this.setState({
            channel: {
                ...channel,
                provider: {
                    ...channel.provider,
                    [field]: value,
                },
            },
        });
    }

    onChangeChannelMailServerSettings(field, e) {
        const { channel } = this.state;
        const value = e.target ? e.target.value : e;

        this.setState({
            channel: {
                ...channel,
                provider: {
                    ...channel.provider,
                    email_server: {
                        ...channel.provider.email_server,
                        [field]: value,
                    },
                },
            },
        });
    }

    applyPreset(preset) {
        const { providers, channel } = this.state;

        const presetSettings = providers[preset];
        channel.provider.email_server = {
            ...channel.provider.email_server,
            ...presetSettings,
        };

        this.applyChannelData(channel);
    }

    applyChannelData(resChannel) {
        const { channel } = this.state;

        // noinspection JSUnresolvedVariable
        this.setState({
            email: resChannel.provider.email,
            hasForwardEmail: !!resChannel.provider.forward_email,
            forwardEmailVerified: resChannel.provider.forward_email_verified,
            channel: {
                ...channel,
                ...resChannel,
                provider: {
                    ...channel.provider,
                    ...resChannel.provider,
                    use_email_server: !!resChannel.provider.use_email_server,
                    email_server: {
                        ...channel.provider.email_server,
                        ...resChannel.provider.email_server,
                        use_incoming:
                            resChannel.provider.email_server &&
                            resChannel.provider.email_server.use_incoming
                                ? '1'
                                : '0',
                    },
                },
            },
        });
    }

    handleCopyToClipboard() {
        const copyText = this.copyableText;
        copyText.select();
        // copyText.setSelectionRange(0, 99999); // Uncomment for mobile.
        document.execCommand('copy');
        notify.success('copied_clipboard');

        this.setState(
            {
                copiedIndicator: true,
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        copiedIndicator: false,
                    });
                }, 1000);
            }
        );
    }

    testOutgoing() {
        const { isOutgoingTesting, channel } = this.state;
        if (isOutgoingTesting) {
            return;
        }

        this.setState({
            isOutgoingTesting: true,
        });

        const data = this.getData();
        data.use_email_server = true;

        Api.request({
            method: 'POST',
            url: `channels/${channel.id}/email/test/outgoing`,
            data,
        })
            .then(() => {
                notify.success('connected_outgoing_server_success');
            })
            .finally(() => {
                this.setState({
                    isOutgoingTesting: false,
                });

                this.refreshLogs();
            });
    }

    testIncoming() {
        const { isIncomingTesting, channel } = this.state;
        if (isIncomingTesting) {
            return;
        }

        this.setState({
            isIncomingTesting: true,
        });

        const data = this.getData();
        data.use_email_server = true;
        data.use_incoming = true;

        Api.request({
            method: 'POST',
            url: `channels/${channel.id}/email/test/incoming`,
            data,
        })
            .then(() => {
                notify.success('connected_incoming_server_success');
            })
            .finally(() => {
                this.setState({
                    isIncomingTesting: false,
                });

                this.refreshLogs();
            });
    }

    connectOrDisconnectGoogleAccount() {
        const { channel } = this.state;

        Api.request({
            method: 'POST',
            url: `channels/${channel.id}/email/gmail`,
            data: this.getData(),
        }).then(({ data }) => {
            if (data.url) {
                localStorage.setItem(definitions.CALLBACK_GMAIL_CHANNEL_ID, data.channelId);
                window.location.href = data.url;
            } else {
                window.location.reload();
            }
        });
    }

    connectOrDisconnectOutlookAccount() {
        const { channel } = this.state;

        Api.request({
            method: 'POST',
            url: `channels/${channel.id}/email/outlook`,
            data: this.getData(),
        }).then(({ data }) => {
            if (data.url) {
                localStorage.setItem(definitions.CALLBACK_OUTLOOK_CHANNEL_ID, data.channelId);
                window.location.href = data.url;
            } else {
                window.location.reload();
            }
        });
    }

    sendVerificationEmail() {
        const { match } = this.props;

        this.setState(
            {
                isLoading: true,
                canSendVerification: false,
            },
            () => {
                Api.request({
                    method: 'POST',
                    url: `channels/${match.params.channelId}/email/resend`,
                })
                    .then(() => {
                        notify.success('sent_new_mail');
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            }
        );
    }

    getData() {
        const parent = super.getData();
        const { channel, company } = this.state;

        return {
            ...parent,
            company_id: company.id,
            forward_email: channel.provider.forward_email,

            use_template: helpers.parseBoolean(channel.provider.use_template),
            use_company_name_on_sender: helpers.parseBoolean(
                channel.provider.use_company_name_on_sender
            ),
            use_email_server: helpers.parseBoolean(channel.provider.use_email_server),
            use_incoming: helpers.parseBoolean(channel.provider.email_server.use_incoming),

            outgoing_server: channel.provider.email_server.outgoing_server,
            outgoing_port: channel.provider.email_server.outgoing_port,
            outgoing_encryption:
                channel.provider.email_server.outgoing_encryption !== '0'
                    ? channel.provider.email_server.outgoing_encryption
                    : null,
            outgoing_username: channel.provider.email_server.outgoing_username,
            outgoing_password: channel.provider.email_server.outgoing_password,
            from_email: channel.provider.email_server.from_email,
            reply_to_email: channel.provider.email_server.reply_to_email,

            incoming_server: channel.provider.email_server.incoming_server,
            incoming_port: channel.provider.email_server.incoming_port,
            incoming_encryption:
                channel.provider.email_server.incoming_encryption !== '0'
                    ? channel.provider.email_server.incoming_encryption
                    : null,
            incoming_service: channel.provider.email_server.incoming_service,
            incoming_username: channel.provider.email_server.incoming_username,
            incoming_password: channel.provider.email_server.incoming_password,
            incoming_delete_after_fetch: channel.provider.email_server.incoming_delete_after_fetch,
            dkim: channel.provider.email_server.dkim || false,
            dkim_domain: channel.provider.email_server.dkim_domain || '',
        };
    }

    refreshLogs() {
        if (this.logsRef && this.logsRef.current && this.logsRef.current.refresh) {
            this.logsRef.current.refresh();
        }
    }

    renderProviderFields() {
        const {
            email,
            copiedIndicator,
            channel,
            forwardEmailVerified,
            hasForwardEmail,
            canSendVerification,
        } = this.state;

        return (
            <>
                <div className="col-12 col-lg-6 mb-4">
                    <ManageField title={trans('forwarded_your_emails_to')}>
                        <ManageInput
                            placeholder={trans('forwarded_your_emails_to')}
                            value={email}
                            type="text"
                            disabled
                            append={
                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                    <button
                                        onClick={this.handleCopyToClipboard}
                                        type="button"
                                        className="btn p-1"
                                    >
                                        {copiedIndicator ? (
                                            <i className="fa fas fa-check text-success" />
                                        ) : (
                                            <i className="fa fas fa-copy text-primary" />
                                        )}
                                    </button>
                                    <input
                                        type="text"
                                        ref={x => {
                                            this.copyableText = x;
                                        }}
                                        value={email}
                                        readOnly
                                        className="copyable-hidden-input"
                                    />
                                </div>
                            }
                        />
                    </ManageField>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                    <ManageField title={trans('email')}>
                        <ManageInput
                            placeholder={trans('email')}
                            value={channel.provider.forward_email || ''}
                            onChange={e => this.onChangeChannelProviderSettings('forward_email', e)}
                            type="email"
                            disabled={forwardEmailVerified}
                            append={
                                hasForwardEmail &&
                                (forwardEmailVerified ? (
                                    <span className="text-success">
                                        <i className="fa fa-shield-check mr-1" />
                                        {trans('verified')}
                                    </span>
                                ) : (
                                    <HoverTooltip
                                        title={trans(
                                            'verify_your_email_to_start_receiving_tickets'
                                        )}
                                    >
                                        <div className="text-light-gray font-weight-light">
                                            <i className="fas fa-shield-check mr-1" />
                                            {trans('unverified')}
                                        </div>
                                    </HoverTooltip>
                                ))
                            }
                        />
                    </ManageField>
                    {hasForwardEmail && !forwardEmailVerified && canSendVerification && (
                        <div className="flex-grow-1 d-flex justify-content-end">
                            <button
                                type="button"
                                onClick={this.sendVerificationEmail}
                                className="btn btn-link text-small font-weight-light"
                            >
                                {trans('resend_verification_email')}
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    }

    renderCustomMailServer() {
        const {
            channel,
            providers,
            isIncomingTesting,
            isOutgoingTesting,
            copiedIndicator,
        } = this.state;

        if (!channel.provider.use_email_server) {
            return (
                <div className="col-12 mb-4">
                    <ManageCheckbox
                        onChange={() =>
                            this.onChangeChannelProviderSettings(
                                'use_email_server',
                                !channel.provider.use_email_server
                            )
                        }
                        label={trans('use_your_own_email_server')}
                        checked={channel.provider.use_email_server}
                    />
                </div>
            );
        }

        const providerSelections =
            providers.map &&
            providers.map(x => ({
                title: x.name,
                value: providers.indexOf(x),
            }));

        const emailProviders = [
            { title: trans('select_an_option'), value: null },
            ...providerSelections,
        ];

        return (
            <div className="col-12 manage-focus-field">
                <div className="font-weight-bold text-medium mb-4">
                    {trans('advanced_settings')}
                </div>
                <div className="mb-4">
                    <ManageCheckbox
                        onChange={() =>
                            this.onChangeChannelProviderSettings(
                                'use_email_server',
                                !channel.provider.use_email_server
                            )
                        }
                        label={trans('use_your_own_email_server')}
                        checked={channel.provider.use_email_server}
                    />
                </div>
                <div>
                    <div className="row align-items-center mb-4">
                        {/* Mail Service */}
                        <div className="col-lg-5 mb-4">
                            <ManageField title={trans('mail_service')}>
                                <ManageSelectbox
                                    items={emailProviders}
                                    onChange={e => this.applyPreset(e.target.value)}
                                />
                            </ManageField>
                        </div>
                        {/* Use Mail Server For */}
                        <div className="col-lg-7 mb-4">
                            <ManageField title={trans('use_mail_server_for')}>
                                <div className="row">
                                    <div className="col">
                                        <ManageCheckbox
                                            label={trans('incoming_and_outgoing')}
                                            value="1"
                                            checked={
                                                channel.provider.email_server &&
                                                channel.provider.email_server.use_incoming === '1'
                                            }
                                            onChange={e =>
                                                this.onChangeChannelMailServerSettings(
                                                    'use_incoming',
                                                    e
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="col">
                                        <ManageCheckbox
                                            label={trans('outgoing_only')}
                                            value="0"
                                            checked={
                                                channel.provider.email_server &&
                                                channel.provider.email_server.use_incoming === '0'
                                            }
                                            onChange={e =>
                                                this.onChangeChannelMailServerSettings(
                                                    'use_incoming',
                                                    e
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </ManageField>
                        </div>
                    </div>
                    {/* Incoming Server Options */}
                    <div className="bg-lightest-gray p-4 mb-4 border-radius-10">
                        <div className="font-weight-bold text-medium mb-4">
                            {trans('incoming_mail_server')}
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-4">
                                <ManageInput
                                    placeholder={trans('mail_server')}
                                    type="text"
                                    value={
                                        channel.provider.email_server &&
                                        channel.provider.email_server.incoming_server
                                    }
                                    onChange={e =>
                                        this.onChangeChannelMailServerSettings('incoming_server', e)
                                    }
                                />
                            </div>
                            <div className="col-lg-3 mb-4">
                                <ManageInput
                                    placeholder={trans('port')}
                                    type="text"
                                    value={
                                        channel.provider.email_server &&
                                        channel.provider.email_server.incoming_port
                                    }
                                    onChange={e =>
                                        this.onChangeChannelMailServerSettings('incoming_port', e)
                                    }
                                    className="text-align-center"
                                />
                            </div>
                            <div className="col-lg-3 mb-4">
                                <ManageSelectbox
                                    items={incomingServerServices}
                                    value={
                                        channel.provider.email_server &&
                                        channel.provider.email_server.incoming_service
                                    }
                                    onChange={e =>
                                        this.onChangeChannelMailServerSettings(
                                            'incoming_service',
                                            e
                                        )
                                    }
                                />
                            </div>
                            <div className="col-lg-2 mb-4">
                                <ManageField title={trans('encryption')}>
                                    <ManageSelectbox
                                        items={encryptionMethods}
                                        value={
                                            channel.provider.email_server &&
                                            channel.provider.email_server.incoming_encryption
                                        }
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings(
                                                'incoming_encryption',
                                                e
                                            )
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-5 mb-4">
                                <ManageField title={trans('username')}>
                                    <ManageInput
                                        placeholder={trans('username')}
                                        type="text"
                                        value={
                                            channel.provider.email_server &&
                                            channel.provider.email_server.incoming_username
                                        }
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings(
                                                'incoming_username',
                                                e
                                            )
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-5 mb-4">
                                <ManageField title={trans('password')}>
                                    <ManageInput
                                        placeholder={trans('password')}
                                        type="password"
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings(
                                                'incoming_password',
                                                e
                                            )
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <div className="py-2">
                                    <ManageCheckbox
                                        label={trans('delete_emails_from_server_after_fetching')}
                                        checked={
                                            channel.provider.email_server &&
                                            channel.provider.email_server
                                                .incoming_delete_after_fetch
                                        }
                                        onChange={() =>
                                            this.onChangeChannelMailServerSettings(
                                                'incoming_delete_after_fetch',
                                                channel.provider.email_server &&
                                                    !channel.provider.email_server
                                                        .incoming_delete_after_fetch
                                            )
                                        }
                                    />
                                </div>
                                <div className=" d-flex align-items-center min-width-223">
                                    {isIncomingTesting ? (
                                        <button
                                            disabled
                                            type="button"
                                            className="btn btn-primary btn-manage save-btn flex-grow-1"
                                        >
                                            <i className="fas fa-spin fa-spinner" />{' '}
                                            <span className="text-normal ml-1">
                                                {trans('testing')}...
                                            </span>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-manage save-btn flex-grow-1"
                                            onClick={this.testIncoming}
                                        >
                                            <i className="fas fa-wifi" />{' '}
                                            <span className="text-normal ml-1">
                                                {trans('test_connection')}
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Outgoing Server Options */}
                    <div className="bg-lightest-gray p-4 mb-4 border-radius-10">
                        <div className="font-weight-bold text-medium mb-4">
                            {trans('outgoing_mail_server')}
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-5 mb-4">
                                <ManageInput
                                    placeholder={trans('mail_server')}
                                    type="text"
                                    value={
                                        channel.provider.email_server &&
                                        channel.provider.email_server.outgoing_server
                                    }
                                    onChange={e =>
                                        this.onChangeChannelMailServerSettings('outgoing_server', e)
                                    }
                                />
                            </div>
                            <div className="col-lg-2 mb-4">
                                <ManageInput
                                    placeholder={trans('port')}
                                    type="text"
                                    value={
                                        channel.provider.email_server &&
                                        channel.provider.email_server.outgoing_port
                                    }
                                    onChange={e =>
                                        this.onChangeChannelMailServerSettings('outgoing_port', e)
                                    }
                                    className="text-align-center"
                                />
                            </div>
                            <div className="col-lg-5 mb-4" />
                            <div className="col-lg-2 mb-4">
                                <ManageField title={trans('encryption')}>
                                    <ManageSelectbox
                                        items={encryptionMethods}
                                        value={
                                            channel.provider.email_server &&
                                            channel.provider.email_server.outgoing_encryption
                                        }
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings(
                                                'outgoing_encryption',
                                                e
                                            )
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-5 mb-4">
                                <ManageField title={trans('username')}>
                                    <ManageInput
                                        placeholder={trans('username')}
                                        type="text"
                                        value={
                                            channel.provider.email_server &&
                                            channel.provider.email_server.outgoing_username
                                        }
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings(
                                                'outgoing_username',
                                                e
                                            )
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-5 mb-4">
                                <ManageField title={trans('password')}>
                                    <ManageInput
                                        placeholder={trans('password')}
                                        type="password"
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings(
                                                'outgoing_password',
                                                e
                                            )
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <ManageField title={trans('from_email')}>
                                    <ManageInput
                                        placeholder={trans('from_email')}
                                        type="text"
                                        value={
                                            channel.provider.email_server &&
                                            channel.provider.email_server.from_email
                                        }
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings('from_email', e)
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <ManageField title={trans('reply_to_email')}>
                                    <ManageInput
                                        placeholder={trans('reply_to_email')}
                                        type="text"
                                        value={
                                            channel.provider.email_server &&
                                            channel.provider.email_server.reply_to_email
                                        }
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings(
                                                'reply_to_email',
                                                e
                                            )
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <ManageField title="DKIM Domain">
                                    <ManageInput
                                        placeholder="mywebsite.com"
                                        type="text"
                                        value={
                                            channel.provider.email_server &&
                                            channel.provider.email_server.dkim_domain
                                        }
                                        onChange={e =>
                                            this.onChangeChannelMailServerSettings('dkim_domain', e)
                                        }
                                    />
                                </ManageField>
                            </div>
                            <div className="col-lg-6">
                                <ManageCheckbox
                                    label="Enable DKIM for outgoing emails?"
                                    checked={
                                        channel.provider.email_server &&
                                        channel.provider.email_server.dkim
                                    }
                                    onChange={() => {
                                        const newValue =
                                            channel.provider.email_server &&
                                            !channel.provider.email_server.dkim;

                                        if (
                                            newValue &&
                                            !channel.provider.email_server.dkim_domain
                                        ) {
                                            window.alert('Please enter your domain to enable DKIM');

                                            this.onChangeChannelMailServerSettings('dkim', false);
                                        } else {
                                            this.onChangeChannelMailServerSettings(
                                                'dkim',
                                                newValue
                                            );
                                        }
                                    }}
                                />
                            </div>
                            {channel.provider.email_server && channel.provider.email_server.dkim && (
                                <div className="col-lg-12 mb-4">
                                    <ManageField title="Create a TXT record in the DNS records of your domain">
                                        <ManageInput
                                            value="helpdesk._domainkey"
                                            type="text"
                                            disabled
                                            append={
                                                <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                    <button
                                                        onClick={this.handleCopyToClipboard}
                                                        type="button"
                                                        className="btn p-1"
                                                    >
                                                        {copiedIndicator ? (
                                                            <i className="fa fas fa-check text-success" />
                                                        ) : (
                                                            <i className="fa fas fa-copy text-primary" />
                                                        )}
                                                    </button>
                                                    <input
                                                        type="text"
                                                        ref={x => {
                                                            this.copyableText = x;
                                                        }}
                                                        value="helpdesk._domainkey"
                                                        readOnly
                                                        className="copyable-hidden-input"
                                                    />
                                                </div>
                                            }
                                        />
                                    </ManageField>
                                </div>
                            )}
                            {channel.provider.email_server && channel.provider.email_server.dkim && (
                                <div className="col-lg-12 mb-4">
                                    <ManageInput
                                        value={DKIMValue}
                                        type="text"
                                        disabled
                                        append={
                                            <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                <button
                                                    onClick={this.handleCopyToClipboard}
                                                    type="button"
                                                    className="btn p-1"
                                                >
                                                    {copiedIndicator ? (
                                                        <i className="fa fas fa-check text-success" />
                                                    ) : (
                                                        <i className="fa fas fa-copy text-primary" />
                                                    )}
                                                </button>
                                                <input
                                                    type="text"
                                                    ref={x => {
                                                        this.copyableText = x;
                                                    }}
                                                    value={DKIMValue}
                                                    readOnly
                                                    className="copyable-hidden-input"
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                            )}
                            <div className="col d-flex justify-content-center justify-content-md-end">
                                <div className=" d-flex align-items-center min-width-223">
                                    {isOutgoingTesting ? (
                                        <button
                                            disabled
                                            type="button"
                                            className="btn btn-primary btn-manage save-btn flex-grow-1"
                                        >
                                            <i className="fas fa-spin fa-spinner" />{' '}
                                            <span className="text-normal ml-1">
                                                {trans('testing')}...
                                            </span>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-manage save-btn flex-grow-1"
                                            onClick={this.testOutgoing}
                                        >
                                            <i className="fas fa-wifi" />{' '}
                                            <span className="text-normal ml-1">
                                                {trans('test_connection')}
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-lightest-gray p-4 border-radius-10">
                        <div className="font-weight-bold text-medium mb-4">
                            Gmail Connection (Beta) - Outgoing Only
                        </div>
                        <div className="row align-items-center px-3">
                            <p>
                                Gmail users cannot have a stable Helpdesk experience over connection
                                protocols such as SMTP. That's why we continue to work on connecting
                                Gmail via APIs, so Helpdesk will send your emails via APIs. Since
                                this feature is still in beta, contact us to get involved in using
                                this feature.
                            </p>

                            <button
                                type="button"
                                className="btn btn-primary btn-manage save-btn flex-grow-1"
                                onClick={this.connectOrDisconnectGoogleAccount}
                            >
                                <i className="fab fa-google" />
                                <span className="text-normal ml-2">
                                    {channel.provider.email_server &&
                                    channel.provider.email_server.has_gmail_connection
                                        ? 'Disconnect your Google account'
                                        : 'Connect your Google account'}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div className="bg-lightest-gray p-4 border-radius-10">
                        <div className="font-weight-bold text-medium mb-4">
                            Outlook Connection (Beta) - Incoming Only
                        </div>
                        <div className="row align-items-center px-3">
                            <p>
                                Outlook users cannot have a stable Helpdesk experience over
                                connection protocols such as IMAP. That's why we continue to work on
                                connecting Outlook via APIs, so Helpdesk will read your emails via
                                APIs. Since this feature is still in beta, contact us to get
                                involved in using this feature.
                            </p>

                            <button
                                type="button"
                                className="btn btn-primary btn-manage save-btn flex-grow-1"
                                onClick={this.connectOrDisconnectOutlookAccount}
                            >
                                <i className="fab fa-microsoft" />
                                <span className="text-normal ml-2">
                                    {channel.provider.email_server &&
                                    channel.provider.email_server.has_outlook_connection
                                        ? 'Disconnect your Outlook account'
                                        : 'Connect your Outlook account'}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { company, channel, providers } = this.state;

        if (!company || !channel || !providers) {
            return null;
        }

        return super.layout(
            <div className="row">
                <div className="col-5 mb-4">
                    <ManageCheckbox
                        onChange={() =>
                            this.onChangeChannelProviderSettings(
                                'use_template',
                                !channel.provider.use_template
                            )
                        }
                        label={trans('use_theme_when_sending_emails')}
                        checked={channel.provider.use_template}
                    />
                </div>

                <div className="col-7 mb-4">
                    <ManageCheckbox
                        onChange={() =>
                            this.onChangeChannelProviderSettings(
                                'use_company_name_on_sender',
                                !channel.provider.use_company_name_on_sender
                            )
                        }
                        label={trans('use_company_name_as_sender_name_when_sending_emails')}
                        checked={channel.provider.use_company_name_on_sender}
                    />
                </div>

                {!channel.provider.use_email_server && this.renderProviderFields()}

                {!channel.provider.use_email_server && (
                    <div className="col-12 my-4">
                        <div
                            className="banner"
                            role="button"
                            tabIndex="0"
                            onClick={() =>
                                this.onChangeChannelProviderSettings(
                                    'use_email_server',
                                    !channel.provider.use_email_server
                                )
                            }
                        >
                            <img
                                src={advancedSettingsBanner}
                                alt="Advanced Settings"
                                className="banner-media"
                            />
                            <div className="banner-content">
                                <div className="d-flex align-items-center p-4">
                                    <div className="fa-2x">
                                        <i className="fa fas fa-lock" />
                                    </div>
                                    <div className="ml-4">
                                        <div className="font-weight-semibold text-2xlarge">
                                            {trans('advanced_settings')}
                                        </div>
                                        <div>{trans('mail_server_settings')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.renderCustomMailServer()}

                {channel && channel.id && (
                    <div className="col-12 mb-4">
                        <ChannelLogs ref={this.logsRef} channelId={channel.id} />
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(EditEmailPage);

EditEmailPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
