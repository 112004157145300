import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class URL extends PureComponent {
    handleClick(e) {
        e.preventDefault();
        const { onClick } = this.props;
        return onClick && onClick(e);
    }

    render() {
        const { href, children, ...others } = this.props;
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <a href={href} onClick={e => this.handleClick(e)} {...others}>
                {children}
            </a>
        );
    }
}

URL.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any.isRequired,
    href: PropTypes.string,
};

URL.defaultProps = {
    onClick: () => {},
    href: '#',
};
