import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { errorEmail } from '../../assets/images';
import { Api, ApiService, AuthService } from '../../services';
import { LoadingBar, SocialLogin } from '../../components';
import { AuthCardView } from '../../components/auth';
import { FormButton, FormInput } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';
import notify from '../../utils/notify';
import storage from '../../utils/storage';
import definitions from '../../definitions';

class AcceptInvitationPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            signUp: false,
            isSending: false,
            response: null,
            errors: {},
            data: {
                name: '',
            },
            isAuthUser: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.tryToAccept();
    }

    onSubmit(event) {
        event.preventDefault();

        const { isSending, response, data } = this.state;
        const { history } = this.props;

        if (isSending) {
            return null;
        }

        this.setState({
            isSending: true,
        });

        return Api.request({
            method: 'POST',
            url: response.path,
            data,
        })
            .then(acceptResponse => {
                const { token } = acceptResponse.data.data;
                AuthService.setToken(token);
                AuthService.fetchUser()
                    .then(() => {
                        history.push('/auth/setPassword');
                    })
                    .catch(() => {
                        this.setState({
                            isSending: false,
                        });
                    });
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                    isSending: false,
                });
            });
    }

    onChange(event) {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    }

    tryToAccept() {
        const query = ApiService.formatSearch(this.props);
        const { history } = this.props;

        Api.request({
            method: 'GET',
            url: `invitations/accept?${query}`,
        })
            .then(response => {
                const { data } = response.data;

                if (data === true) {
                    notify.success('you_have_successfully_joined_helpdesk');
                    history.push('/');
                    return;
                }

                const redirect = () => {
                    if (data && data.type === 'showSignUp') {
                        if (data.error) {
                            notify.errorMessage(data.error);
                        }

                        this.setState({
                            response: data,
                            data: { name: data.name || '' },
                            signUp: true,
                            isAuthUser: !AuthService.guest(),
                        });

                        storage.set(
                            definitions.STORAGE_KEY_ACCEPT_INVITATION,
                            window.location.href
                        );
                    } else if (data && data.type === 'showSignIn') {
                        notify.errorMessage(data.error);
                        history.replace('/');
                    }
                };

                if (AuthService.check()) {
                    AuthService.logout().then(redirect);
                } else {
                    redirect();
                }
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                });
            });
    }

    renderAccept() {
        const { signUp } = this.state;

        return !signUp ? (
            <LoadingBar />
        ) : (
            <div className="auth-email-sent">
                <div className="text-center pt-5">
                    <img
                        src={errorEmail}
                        alt={trans('verification_failed')}
                        className="auth-email-sent-image img-fluid mb-5 mt-2"
                    />
                    <h2 className="auth-email-sent-text text-center font-weight-bold">
                        {trans('something_went_wrong')}
                    </h2>
                </div>
            </div>
        );
    }

    renderSignUpForm() {
        const { errors, response, isSending, data, isAuthUser } = this.state;
        const { name } = data;

        return (
            <>
                <SocialLogin
                    isSending={isSending}
                    onBeforeSend={() => this.setState({ isSending: true })}
                    onSendEnd={() => this.setState({ isSending: false })}
                />
                <form onSubmit={this.onSubmit}>
                    <FormInput
                        labelText={trans('attributes.your_name')}
                        validationError={errors.name}
                        onChange={this.onChange}
                        type="text"
                        name="name"
                        value={name}
                        placeholder={trans('placeholders.sample_name')}
                    />

                    <FormInput
                        labelText={trans('attributes.your_email')}
                        type="email"
                        name="email"
                        value={response && response.email}
                        readOnly
                    />

                    <FormInput
                        labelText={trans('attributes.your_company')}
                        type="text"
                        name="company"
                        value={response && response.company}
                        readOnly
                    />

                    <div className="row mt-4">
                        <div className="col d-flex justify-content-end">
                            <FormButton
                                text={isAuthUser ? trans('next') : trans('get_started')}
                                disabled={isSending}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </>
        );
    }

    render() {
        const { isSending, signUp } = this.state;

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView
                    title={trans('you_are_all_ready_to_go')}
                    subtitle={trans('you_are_all_ready_to_go_subtitle')}
                    titleSmall={trans('accept_invitation')}
                >
                    {signUp ? this.renderSignUpForm() : this.renderAccept()}
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(AcceptInvitationPage);

AcceptInvitationPage.propTypes = {
    history: PropTypes.object.isRequired,
};
