import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import trans from '../../localization';

const CannedResponses = props => {
    const {
        onClose,
        show,
        onSelectCannedResponse,
        single,
        activeCannedResponseId,
        items,
        fullHeight,
        company,
    } = props;

    const renderEmptyMessage = () => (
        <div className="d-flex flex-column justify-content-center text-center h-100 w-100 text-dark text-center text-small">
            <p>{trans('you_have_not_created_any_responses_yet')}</p>
            <p>
                {trans('go_to_x_page_to_create_one', {
                    name: (
                        <Link
                            to={{
                                pathname: '/myAccount/cannedResponses',
                                state: { companyId: company && company.slug },
                            }}
                        >
                            {trans('canned_responses')}
                        </Link>
                    ),
                })}
            </p>
        </div>
    );

    return (
        <div
            className={classNames(
                'canned-responses',
                { show },
                { single },
                { 'full-height': fullHeight }
            )}
        >
            <div className="d-flex justify-content-between text-xsmall">
                <span className="text-dark">{trans('canned_responses')}</span>
                <div role="button" tabIndex="0" className="close-button" onClick={onClose}>
                    <i className="fa fa-times p-1" />
                    {trans('close')}
                </div>
            </div>
            {items.length === 0 ? (
                renderEmptyMessage()
            ) : (
                <PerfectScrollbar className="scrollbar-container-canned-responses">
                    <div className={classNames('canned-response-list', { single })}>
                        {items.map(x => (
                            <div
                                key={`canned-response-${x.id}`}
                                role="button"
                                tabIndex="0"
                                onClick={() => onSelectCannedResponse(x)}
                                className={classNames('canned-response-item', {
                                    active: x.id === activeCannedResponseId,
                                })}
                            >
                                <span className="name">{x.title}</span>
                                <span className="description">{x.description}</span>
                            </div>
                        ))}
                    </div>
                </PerfectScrollbar>
            )}
        </div>
    );
};

export default CannedResponses;

CannedResponses.propTypes = {
    show: PropTypes.bool,
    single: PropTypes.bool,
    fullHeight: PropTypes.bool,
    items: PropTypes.array,
    onSelectCannedResponse: PropTypes.func,
    onClose: PropTypes.func,
    activeCannedResponseId: PropTypes.number,
    company: PropTypes.object,
};

CannedResponses.defaultProps = {
    show: false,
    single: false,
    fullHeight: false,
    items: [],
    onSelectCannedResponse: () => {},
    onClose: () => {},
    activeCannedResponseId: null,
    company: null,
};
