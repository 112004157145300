import React from 'react';
import PropTypes from 'prop-types';

const Button = props => {
    const { onClick, text, withoutContainer, ...others } = props;

    if (withoutContainer) {
        return (
            <button
                type="button"
                onClick={onClick}
                className="activity-btn btn btn-primary px-5 text-small"
                {...others}
            >
                {text}
            </button>
        );
    }

    return (
        <div className="d-flex flex-grow-1 justify-content-end p-3">
            <button
                type="button"
                onClick={onClick}
                className="activity-btn btn btn-primary px-5 text-small"
                {...others}
            >
                {text}
            </button>
        </div>
    );
};

export default Button;

Button.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    withoutContainer: PropTypes.bool,
};

Button.defaultProps = {
    onClick: () => {},
    text: null,
    withoutContainer: false,
};
