import despatchcloud from './despatchcloud.svg';

export default {
    despatchcloud,
};

// {
//     provider: 'despatchcloud',
//     logo: despatchcloud,
// },
