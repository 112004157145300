import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';

const TicketPriority = props => {
    const { ticket, className } = props;

    return (
        <div className={`ticket-priority text-priority-${ticket.priority} ${className}`}>
            {trans(`priorities.${ticket.priority}`)}
        </div>
    );
};

export default TicketPriority;

TicketPriority.propTypes = {
    ticket: PropTypes.object,
    className: PropTypes.string,
};

TicketPriority.defaultProps = {
    ticket: null,
    className: '',
};
