import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import trans from '../localization';
import { logoCry } from '../assets/images';
import router from '../utils/router';

export class ErrorLayout extends PureComponent {
    constructor(props) {
        super(props);

        this.goToHome = this.goToHome.bind(this);
        this.goToBack = this.goToBack.bind(this);
    }

    goToHome() {
        const { history } = this.props;
        router.replace(history, '/');
    }

    goToBack() {
        const { history } = this.props;
        router.backOrGo(history, '/', {}, 2);
    }

    render() {
        const { children } = this.props;

        return (
            <div className="bg-primary d-flex flex-column justify-content-center align-items-center min-h-100vh w-100">
                <div className="justify-content-center row">
                    <div className="col col-lg-4 col-md-6">
                        <img src={logoCry} alt={trans('something_wrongs')} className="img-fluid" />
                    </div>
                </div>
                <h1 className="mt-4 h1 text-white font-weight-bold">{trans('something_wrongs')}</h1>
                {children}
                <div className="d-flex justify-content-center mt-5">
                    <button type="button" className="btn btn-outline-light" onClick={this.goToHome}>
                        {trans('go_to_home')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary ml-3"
                        onClick={this.goToBack}
                    >
                        {trans('go_back')}
                    </button>
                </div>
            </div>
        );
    }
}

ErrorLayout.propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
};

export default ErrorLayout;
