import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { comingSoon } from '../assets/images';
import trans from '../localization';

const SettingItem = props => {
    const { isComingSoon, icon, name, onClick, className } = props;

    return (
        <button
            type="button"
            className={classNames(
                'setting-item',
                { 'setting-item-coming-soon': isComingSoon },
                className
            )}
            onClick={onClick}
        >
            <div className="setting-item-image">
                <img className="setting-item-image-object" src={icon} alt={name && trans(name)} />
                {isComingSoon && (
                    <img
                        className="setting-item-coming-soon-image"
                        src={comingSoon}
                        alt="Coming soon"
                    />
                )}
            </div>
            <p className="setting-item-name">{name ? trans(name) : '-'}</p>
        </button>
    );
};

SettingItem.propTypes = {
    isComingSoon: PropTypes.bool,
    icon: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.any,
};

SettingItem.defaultProps = {
    isComingSoon: false,
    icon: null,
    name: null,
    className: null,
    onClick: () => {},
};

export default SettingItem;
