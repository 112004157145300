/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';
import CancelButton from '../manage/CancelButton';
import HoverTooltip from '../../HoverTooltip';

const CancelAndSaveFooter = ({
    onCancel,
    onSubmit,
    onRemove,
    removeTitle,
    submitTitle,
    onArchive,
    shadow,
    isSubmitDisabled = false,
    disabledInfoText = null,
}) => {
    const type = onSubmit ? 'button' : 'submit';
    const onClick = onSubmit || (() => {});

    return (
        <div
            className={`float-bottom-container ${
                shadow ? 'float-bottom-container--shadow' : ''
            } d-flex justify-content-end bg-white w-100 `}
        >
            {onArchive && (
                <CancelButton
                    title={trans('archive_all_tickets_in_this_channel')}
                    className="archive-btn"
                    icon="fa-archive"
                    onClick={onArchive}
                />
            )}
            {onCancel && <CancelButton onClick={onCancel} />}
            {onRemove && (
                <button
                    type="button"
                    onClick={onRemove}
                    className="btn btn-manage btn-outline-danger save-btn mr-3"
                >
                    {removeTitle}
                </button>
            )}
            {isSubmitDisabled ? (
                <HoverTooltip title={disabledInfoText}>
                    <button
                        type={type}
                        className="btn btn-primary btn-manage save-btn"
                        disabled={true}
                    >
                        {submitTitle}
                    </button>
                </HoverTooltip>
            ) : (
                <button
                    type={type}
                    onClick={onClick}
                    className="btn btn-primary btn-manage save-btn"
                >
                    {submitTitle}
                </button>
            )}
        </div>
    );
};

CancelAndSaveFooter.propTypes = {
    shadow: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onArchive: PropTypes.func,
    onRemove: PropTypes.func,
    removeTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    submitTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

CancelAndSaveFooter.defaultProps = {
    shadow: false,
    onSubmit: null,
    onCancel: null,
    onArchive: null,
    onRemove: null,
    removeTitle: trans('remove'),
    submitTitle: trans('save_changes'),
};

export default CancelAndSaveFooter;
