import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginationScrollbar from '../PaginationScrollbar';
import { Api } from '../../services';
import trans from '../../localization';
import UserIcon from './UserIcon';
import DateTime from '../DateTime';

class AuditLogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            lastPage: 1,
            logs: [],
        };
    }

    componentDidMount() {
        const { setLoading } = this.props;

        const fetchPromises = Promise.all([this.fetchLogs()]);

        if (setLoading instanceof Function) {
            setLoading(true);
        }

        fetchPromises.finally(() => {
            if (setLoading instanceof Function) {
                setLoading(false);
            }
        });
    }

    fetchLogs() {
        const { path } = this.props;
        const { page, logs } = this.state;

        return Api.request({
            method: 'GET',
            url: `audits/${path}`,
            params: {
                page: page + 1,
            },
        }).then(response => {
            this.setState({
                logs: [...logs, ...response.data.data],
                page: response.data.meta.current_page,
                lastPage: response.data.meta.last_page,
            });
        });
    }

    getLogDetail = (oldValue, newValue, attribute) => {
        const getLocalizationGroup = attr => {
            switch (attr) {
                case 'priority':
                    return 'priorities';
                case 'status':
                    return 'statuses';
                default:
                    return null;
            }
        };

        if (oldValue && newValue) {
            const getLocalKey = value => {
                const localGroupKey = getLocalizationGroup(attribute);

                if (localGroupKey) {
                    return `${localGroupKey}.${value}`;
                }
                return value;
            };

            console.log({
                new: getLocalKey(newValue),
                old: getLocalKey(oldValue),
            });

            return trans('x_to_y', {
                new: trans(getLocalKey(newValue).toString()) || newValue,
                old: trans(getLocalKey(oldValue).toString()) || oldValue,
            });
        }

        if (newValue) {
            return newValue;
        }

        if (oldValue) {
            return oldValue;
        }

        return '-';
    };

    renderLogDetail = log => {
        const text = this.getLogDetail(log.old, log.new, log.attribute);

        if (text.length > 32) {
            return <span>{`${text.substring(0, 30)}...`}</span>;

            // return (
            //     <HoverTooltip title={text}>
            //         <span>{`${text.substring(0, 30)}...`}</span>
            //     </HoverTooltip>
            // );
        }

        return <span>{text}</span>;
    };

    renderLog = (x, i) => {
        const { showModel } = this.props;
        return (
            <tr key={`log-${i}`}>
                <td>
                    <div className="d-flex align-items-center">
                        <UserIcon user={x.user} className="medium" />
                        <div>
                            <span className="pl-2">{(x.user && x.user.name) || '-'}</span>
                        </div>
                    </div>
                </td>
                {showModel && <td>{x.model_name}</td>}
                <td>
                    <span
                        className={`text-color-${x.type} border-1 border-radius-4 px-2 py-1 text-nowrap`}
                    >
                        {trans(`ticket_log_types.${x.type}`)}
                    </span>
                </td>
                <td>
                    <span>{trans(`attributes.${x.attribute}`)}</span>
                </td>
                <td>
                    <DateTime datetime={x.date} format="MMM DD, YYYY \at hh:mm A" />
                </td>
                <td>{this.renderLogDetail(x)}</td>
            </tr>
        );
    };

    render() {
        const { logs, lastPage, page } = this.state;
        const { showModel } = this.props;

        if (logs.length === 0) {
            return null;
        }

        return (
            <div className="auditlogs">
                <PaginationScrollbar
                    className="slim-gray mh-75"
                    onNextPage={() => this.fetchLogs()}
                    isLastPage={lastPage === page}
                >
                    <table className="auditlogs-table">
                        <thead>
                        <tr>
                            <th>{trans('performed_by')}</th>
                            {showModel && <th>{trans('model')}</th>}
                            <th>{trans('type')}</th>
                            <th>{trans('changed')}</th>
                            <th>{trans('date')}</th>
                            <th>{trans('details')}</th>
                        </tr>
                        </thead>
                        <tbody>{logs.map(this.renderLog)}</tbody>
                    </table>
                </PaginationScrollbar>
            </div>
        );
    }
}

export default AuditLogs;

AuditLogs.propTypes = {
    path: PropTypes.string.isRequired,
    showModel: PropTypes.bool,
    setLoading: PropTypes.func,
};

AuditLogs.defaultProps = {
    showModel: false,
    setLoading: null,
};
