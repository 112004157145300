import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

const CarouselPane = ({ className, fluid, scroll, children, middle, center }) => {
    return (
        <div className={cx('step-carousel-pane', className)}>
            {scroll ? (
                <PerfectScrollbar
                    className={cx(
                        'step-carousel-content',
                        { 'step-carousel-content-fluid': fluid },
                        {
                            'step-carousel-content-scroll': scroll,
                        },
                        {
                            'd-flex flex-column justify-content-center step-carousel-content-middle': middle,
                        },
                        {
                            'd-flex justify-content-center align-items-center step-carousel-content-middle': center,
                        },
                        className
                    )}
                >
                    {children}
                </PerfectScrollbar>
            ) : (
                <div
                    className={cx(
                        'step-carousel-content',
                        { 'step-carousel-content-fluid': fluid },
                        {
                            'd-flex flex-column justify-content-center step-carousel-content-middle': middle,
                        },
                        {
                            'd-flex justify-content-center step-carousel-content-middle': center,
                        },
                        className
                    )}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

class StepCarousel extends PureComponent {
    render() {
        const { children, noNavbar, step } = this.props;

        const filteredChildren = children.length
            ? children.filter(x => x.type.name === CarouselPane.name)
            : [children];

        const currentPane = filteredChildren[step];

        const calculatedStyles = {
            transform: `translate(${-step * 100}vw, 0)`,
            width: `${children.length * 100}vw`,
        };

        const handleClickPrev = () => {
            if (typeof currentPane.props.onPrev === 'function') {
                currentPane.props.onPrev(step);
            }
        };

        const handleClickNext = () => {
            if (typeof currentPane.props.onNext === 'function') {
                currentPane.props.onNext(step);
            }
        };

        return (
            <div className={cx('step-carousel', { 'step-carousel-navbar': !noNavbar })}>
                <div className="step-carousel-holder" style={calculatedStyles}>
                    {filteredChildren}
                </div>

                {currentPane && currentPane.props.onPrev && (
                    <button
                        type="button"
                        className="step-carousel-arrow step-carousel-arrow-left"
                        onClick={handleClickPrev}
                    >
                        <i className="fas fa-chevron-left" />
                    </button>
                )}

                {currentPane && currentPane.props.onNext && (
                    <button
                        type="button"
                        className="step-carousel-arrow step-carousel-arrow-right"
                        onClick={handleClickNext}
                    >
                        <i className="fas fa-chevron-right" />
                    </button>
                )}
            </div>
        );
    }
}

StepCarousel.propTypes = {
    step: PropTypes.number,
    children: PropTypes.any,
    noNavbar: PropTypes.bool,
};

StepCarousel.defaultProps = {
    step: 0,
    children: [],
    noNavbar: false,
};

CarouselPane.propTypes = {
    scroll: PropTypes.bool,
    fluid: PropTypes.bool,
    children: PropTypes.any,
    // eslint-disable-next-line react/no-unused-prop-types
    onPrev: PropTypes.any,
    // eslint-disable-next-line react/no-unused-prop-types
    onNext: PropTypes.any,
    className: PropTypes.string,
    middle: PropTypes.bool,
    center: PropTypes.bool,
};

CarouselPane.defaultProps = {
    scroll: false,
    fluid: false,
    children: [],
    onPrev: null,
    onNext: null,
    className: null,
    middle: false,
    center: false,
};

export default StepCarousel;
export { CarouselPane };
