import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import { CarouselPane, StepCarousel } from '../../../components';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import trans from '../../../localization';
import { ManageInput } from '../../../elements';
import CancelAndSaveFooter from '../../../components/helpdesk/carousel/CancelAndSaveFooter';

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;

        this.state = {
            user: AuthService.user(),
            companyId: location.state && location.state.companyId,
            company: null,
            isLoading: true,
            data: {
                current_password: '',
                new_password: '',
                new_password_confirmation: '',
            },
        };

        this.goBack = this.goBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const fetchPromises = Promise.all([this.fetchCompany(), this.fetchUser()]);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        if (!companyId) return Promise.resolve();

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchUser() {
        return AuthService.fetchUser().then(user => {
            this.setState({
                user,
            });
        });
    }

    handleSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            isLoading: true,
        });

        const { data } = this.state;
        Api.request({
            method: 'POST',
            url: 'myAccount/changePassword',
            data,
        })
            .then(() => {
                this.goBack();
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    onChange = event => {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    };

    goBack() {
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const { isLoading, user, company, data } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="purple"
                user={user}
                company={company}
                hideMyAccountLink
            >
                <form onSubmit={this.handleSubmit}>
                    <StepCarousel step={0}>
                        <CarouselPane
                            onPrev={this.goBack}
                            onNext={this.handleSubmit}
                            middle
                            center
                            scroll
                        >
                            <div className="change-password-page">
                                <ManageHeading
                                    title={trans('change_x', {
                                        name: <strong>{trans('password')}</strong>,
                                    })}
                                    subtitle={trans('change_my_password_text')}
                                />

                                <p className="title text-color-dark text-size-18px">
                                    {trans('current_password')}
                                </p>
                                <ManageInput
                                    name="current_password"
                                    placeholder={trans('password')}
                                    type="password"
                                    onChange={this.onChange}
                                    value={data.current_password}
                                />

                                <p className="title text-color-dark text-size-18px">
                                    {trans('new_password')}
                                </p>
                                <ManageInput
                                    name="new_password"
                                    placeholder={trans('password')}
                                    type="password"
                                    onChange={this.onChange}
                                    value={data.new_password}
                                />

                                <p className="title text-color-dark text-size-18px">
                                    {trans('new_password_confirmation')}
                                </p>
                                <ManageInput
                                    name="new_password_confirmation"
                                    placeholder={trans('password')}
                                    type="password"
                                    onChange={this.onChange}
                                    value={data.new_password_confirmation}
                                />
                            </div>
                        </CarouselPane>
                    </StepCarousel>

                    <CancelAndSaveFooter onCancel={this.goBack} />
                </form>
            </ManageLayout>
        );
    }
}

export default withRouter(ChangePassword);

ChangePassword.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
