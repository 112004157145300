import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Popover = ({
    children,
    label,
    align = 'left',
    close,
    className,
    popoverClassName,
    onClick,
}) => {
    return (
        // eslint-disable-next-line
        <div
            onClick={onClick}
            className={cx('dc-popover', `dc-popover--align-${align}`, popoverClassName)}
            role="region"
            tabIndex={-1}
        >
            <div className="dc-popover-trigger" role="button">
                {label}
            </div>
            <div className={cx('dc-popover-content', className)}>
                {close && (
                    <button
                        tabIndex={0}
                        type="button"
                        className="dc-popover-close"
                        onClick={() => {
                            document.activeElement.blur();
                        }}
                    >
                        <i className="fa fas fa-times" />
                    </button>
                )}
                {children}
            </div>
        </div>
    );
};

Popover.propTypes = {
    children: PropTypes.any,
    label: PropTypes.any,
    align: PropTypes.string,
    className: PropTypes.string,
    popoverClassName: PropTypes.string,
    close: PropTypes.bool,
    onClick: PropTypes.func,
};

Popover.defaultProps = {
    children: null,
    label: null,
    align: 'left',
    close: false,
    className: '',
    popoverClassName: '',
    onClick: () => {},
};

export default Popover;
