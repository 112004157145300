import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import trans from '../../localization';
import { HoverTooltip } from '../index';
import MultipleEmailSelect from '../MultipleEmailSelect';
import AutoCompleteInput from './AutoCompleteInput';
import ColorListItem from '../ColorListItem';
import RespondAttachment from './RespondAttachment';
import Popover from '../Popover';

export default class CreateTicketBox extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            receiverAdditions: {
                cc: false,
                bcc: false,
                fwd: false,
            },
        };

        this.textarea = React.createRef();
        this.onChangeSource = this.onChangeSource.bind(this);
    }

    onChangeSource(sourceId) {
        const { onChangeSource } = this.props;
        document.activeElement.blur();
        onChangeSource(sourceId);
    }

    renderTicket() {
        const {
            ticket,
            onSubmit,
            onChangeBody,
            onChangeSubject,
            onSearchPerson,
            onCancelCreate,
            onChangeCustomer,
            onSearchCustomer,
            onSelectCustomer,
            onChangeReceiver,
            onAttachFile,
            onClear,
            showCustomerNameField,
            clearCustomer,
            onDeleteAttachment,
            sources,
            showSources,
        } = this.props;

        const { receiverAdditions } = this.state;

        return (
            <div className="loaded">
                <div className="controls single h-100">
                    <div className="receiver-controls d-flex w-100 pl-2 py-3">
                        <div className="d-flex align-items-center flex-grow-1">
                            <div
                                className={classNames('create-ticket-input d-flex flex-grow-1', {
                                    'opacity-8': !!ticket.customer_id,
                                })}
                            >
                                <span className="create-ticket-input-label">
                                    {trans('attributes.customer')}
                                </span>
                                <AutoCompleteInput
                                    onChange={onSearchCustomer}
                                    onSelect={onSelectCustomer}
                                    itemMap={(x, i) => ({
                                        ...x,
                                        label: (
                                            <ColorListItem
                                                className={i > 0 ? 'mt-1' : undefined}
                                                color={x.color}
                                            >
                                                <span title={x.display_name}>{x.short_name}</span>
                                            </ColorListItem>
                                        ),
                                        value: x.name,
                                    })}
                                    placeholder={trans('placeholders.customer_name_or_email')}
                                    selected={ticket.customer_email}
                                    disabled={!!ticket.customer_id}
                                />
                            </div>
                            <div
                                className={classNames(
                                    'create-ticket-input d-flex flex-grow-1',
                                    {
                                        'opacity-8': !!ticket.customer_id,
                                    },
                                    { hide: !showCustomerNameField || !ticket.customer_id },
                                    {
                                        show:
                                            ticket.customer_id ||
                                            (ticket.customer_email && showCustomerNameField),
                                    }
                                )}
                            >
                                <div className="flex-grow-1 d-flex">
                                    <div className="create-ticket-input-label ">
                                        {trans('attributes.customer_name')}
                                    </div>
                                    <input
                                        type="text"
                                        placeholder={trans('placeholders.customer_name')}
                                        className="create-ticket-input-input"
                                        onChange={onChangeCustomer}
                                        value={ticket.customer_name}
                                        disabled={!!ticket.customer_id}
                                    />
                                </div>
                            </div>
                            {!!ticket.customer_id && (
                                <HoverTooltip title={trans('clear_customer_selection')}>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        className="cursor-pointer px-2 py-0 text-small text-dc-dark-blue border-right"
                                        onClick={clearCustomer}
                                    >
                                        <i className="fa fa-times mx-22" />
                                    </span>
                                </HoverTooltip>
                            )}
                        </div>
                        <div className="d-flex align-items-center ml-auto">
                            <div
                                role="button"
                                tabIndex="0"
                                className="control-button border-right"
                                onClick={() =>
                                    this.setState(
                                        {
                                            receiverAdditions: {
                                                ...receiverAdditions,
                                                cc: !receiverAdditions.cc,
                                            },
                                        },
                                        () => onClear('cc')
                                    )
                                }
                            >
                                {trans('cc')}
                            </div>

                            <div
                                role="button"
                                tabIndex="0"
                                className="control-button border-right"
                                onClick={() =>
                                    this.setState(
                                        {
                                            receiverAdditions: {
                                                ...receiverAdditions,
                                                bcc: !receiverAdditions.bcc,
                                            },
                                        },
                                        () => onClear('bcc')
                                    )
                                }
                            >
                                {trans('bcc')}
                            </div>
                            <div
                                role="button"
                                tabIndex="0"
                                className="control-button"
                                onClick={() =>
                                    this.setState(
                                        {
                                            receiverAdditions: {
                                                ...receiverAdditions,
                                                fwd: !receiverAdditions.fwd,
                                            },
                                        },
                                        () => onClear('fwd')
                                    )
                                }
                            >
                                <i className="fad fa-share rotate-y-180 mr-2" />
                                {trans('forward')}
                            </div>
                        </div>
                    </div>
                    {
                        <div
                            className={classNames('receiver-inputs-wrapper', {
                                show: Object.values(receiverAdditions).includes(true),
                            })}
                        >
                            <div
                                className={classNames('receiver-inputs gray-placeholder', {
                                    show: true, // Object.values(receiverAdditions).includes(true),
                                })}
                            >
                                {receiverAdditions.cc && (
                                    <MultipleEmailSelect
                                        placeholder={trans('start_typing_here')}
                                        onSearch={onSearchPerson}
                                        onSelect={receiver =>
                                            onChangeReceiver(
                                                'cc',
                                                {
                                                    ...receiver,
                                                    value: receiver.email || receiver.name,
                                                    label: receiver.email || receiver.name,
                                                },
                                                true
                                            )
                                        }
                                        onRemove={receiver => onChangeReceiver('cc', receiver)}
                                        items={ticket.cc}
                                        label="CC"
                                    />
                                )}
                                {receiverAdditions.bcc && (
                                    <MultipleEmailSelect
                                        placeholder={trans('start_typing_here')}
                                        onSearch={onSearchPerson}
                                        onSelect={receiver =>
                                            onChangeReceiver(
                                                'bcc',
                                                {
                                                    ...receiver,
                                                    value: receiver.email || receiver.name,
                                                    label: receiver.email || receiver.name,
                                                },
                                                true
                                            )
                                        }
                                        onRemove={receiver => onChangeReceiver('bcc', receiver)}
                                        items={ticket.bcc}
                                        label="BCC"
                                    />
                                )}
                                {receiverAdditions.fwd && (
                                    <MultipleEmailSelect
                                        placeholder={trans('start_typing_here')}
                                        onSearch={onSearchPerson}
                                        onSelect={receiver =>
                                            onChangeReceiver(
                                                'fwd',
                                                {
                                                    ...receiver,
                                                    value: receiver.email || receiver.name,
                                                    label: receiver.email || receiver.name,
                                                },
                                                true
                                            )
                                        }
                                        onRemove={receiver => onChangeReceiver('fwd', receiver)}
                                        items={ticket.fwd}
                                        label="FWD"
                                    />
                                )}
                            </div>
                        </div>
                    }
                    <div className="receiver-controls d-flex justify-content-between w-100 pl-2 py-3">
                        <div className="d-flex align-items-center flex-grow-1">
                            <div className="create-ticket-input d-flex flex-grow-1">
                                <div className="create-ticket-input-label ">{trans('subject')}</div>
                                <input
                                    type="text"
                                    placeholder={trans('start_typing')}
                                    className="create-ticket-input-input"
                                    onChange={onChangeSubject}
                                />
                            </div>
                        </div>

                        <div
                            className={classNames(
                                'create-ticket-input d-flex ml-auto w-25',
                                {
                                    'opacity-8': !!ticket.customer_id,
                                },
                                { hide: !showSources },
                                { show: showSources }
                            )}
                        >
                            <div className="flex-grow-1 d-flex">
                                <div className="create-ticket-input-label ">{trans('channel')}</div>
                                <Popover
                                    label={
                                        <div
                                            className="input-wrapper text-xsmall"
                                            style={{ marginTop: 1 }}
                                        >
                                            {ticket.source_id
                                                ? sources.find(s => s.id === ticket.source_id).name
                                                : ''}
                                        </div>
                                    }
                                    align="center"
                                >
                                    {sources.length &&
                                        sources.map(m => (
                                            <ColorListItem
                                                key={`source-${m.id}`}
                                                onClick={() => this.onChangeSource(m.id)}
                                                color={m.color}
                                            >
                                                {m.name}
                                            </ColorListItem>
                                        ))}
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start flex-grow-1 w-100 p-2">
                        <textarea
                            className="message-textarea"
                            name="body"
                            onChange={onChangeBody}
                            placeholder={trans('type_your_message_here')}
                            ref={this.textarea}
                        />
                    </div>
                    <form onSubmit={onSubmit} className="message-controls">
                        <div className="d-flex">
                            <RespondAttachment
                                showMimeTypes={false}
                                attachments={ticket.files}
                                onAttach={onAttachFile}
                                onDeleteAttachment={onDeleteAttachment}
                            />
                        </div>
                        <div className="ml-auto" />
                        <div className="pl-1 d-flex">
                            <button type="button" onClick={onSubmit} className="send-message">
                                <i className="plane fas fa-paper-plane" />
                                <span className="text-small px-1">{trans('create')}</span>
                            </button>
                            <button
                                type="button"
                                onClick={onCancelCreate}
                                className="ml-1 close-message"
                            >
                                {trans(`cancel`)}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    render() {
        return <div className="col quickrespond">{this.renderTicket()}</div>;
    }
}

CreateTicketBox.propTypes = {
    ticket: PropTypes.object,
    onChangeBody: PropTypes.func,
    onChangeSubject: PropTypes.func,
    onChangeCustomer: PropTypes.func,
    onSearchCustomer: PropTypes.func,
    onSelectCustomer: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancelCreate: PropTypes.func,
    onChangeReceiver: PropTypes.func,
    onAttachFile: PropTypes.func,
    onDeleteAttachment: PropTypes.func,
    onSearchPerson: PropTypes.func,
    onClear: PropTypes.func,
    showCustomerNameField: PropTypes.bool,
    clearCustomer: PropTypes.func,
    sources: PropTypes.array,
    showSources: PropTypes.bool,
    onChangeSource: PropTypes.func,
};

CreateTicketBox.defaultProps = {
    ticket: null,
    onChangeBody: () => {},
    onChangeSubject: () => {},
    onChangeCustomer: () => {},
    onSearchCustomer: () => {},
    onSelectCustomer: () => {},
    onSubmit: () => {},
    onCancelCreate: () => {},
    onChangeReceiver: () => {},
    onAttachFile: () => {},
    onDeleteAttachment: () => {},
    onSearchPerson: () => {},
    onClear: () => {},
    showCustomerNameField: false,
    clearCustomer: () => {},
    sources: () => {},
    showSources: false,
    onChangeSource: () => {},
};
