import CreatePage from './CreatePage';
import ListPage from './ListPage';
import SetupEmailPage from './SetupEmailPage';
import EditEmailPage from './EditEmailPage';
import UnverifiedSetupEmailPage from './UnverifiedSetupEmailPage';
import FinishSetupEmailPage from './FinishSetupEmailPage';
import SetupEbayPage from './SetupEbayPage';
import ConnectEbayPage from './ConnectEbayPage';
import SuccessEbayPage from './SuccessEbayPage';
import ErrorEbayPage from './ErrorEbayPage';
import EditEbayPage from './EditEbayPage';
import EditManualPage from './EditManualPage';
import VerifySetupEmailPage from './VerifySetupEmailPage';
import parameters from '../../../routes/parameters';

export default [
    // CRUD
    {
        path: '/companies/:companyId/channels/create',
        middleware: ['auth'],
        component: CreatePage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels',
        middleware: ['auth'],
        component: ListPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/edit/manual',
        middleware: ['auth'],
        component: EditManualPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/edit/email',
        middleware: ['auth'],
        component: EditEmailPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/edit/ebay',
        middleware: ['auth'],
        component: EditEbayPage,
        ...parameters.companyIdParameters,
    },

    // Email Provider
    {
        path: '/companies/:companyId/channels/:channelId/setup/email',
        middleware: ['auth'],
        component: SetupEmailPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/setup/email/unverified',
        middleware: ['auth'],
        component: UnverifiedSetupEmailPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/setup/email/verify',
        middleware: ['auth'],
        component: VerifySetupEmailPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/setup/email/finish',
        middleware: ['auth'],
        component: FinishSetupEmailPage,
        ...parameters.companyIdParameters,
    },

    // Ebay Provider
    {
        path: '/companies/:companyId/channels/:channelId/setup/ebay',
        middleware: ['auth'],
        component: SetupEbayPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/setup/ebay/connect',
        middleware: ['auth'],
        component: ConnectEbayPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/setup/ebay/success',
        middleware: ['auth'],
        component: SuccessEbayPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/channels/:channelId/setup/ebay/error',
        middleware: ['auth'],
        component: ErrorEbayPage,
        ...parameters.companyIdParameters,
    },
];
