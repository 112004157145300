/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React, { createContext } from 'react';

export const AppContext = createContext({
    set: () => {},
    get: () => {},
    getObjectWithId: () => {},
    setObjectWithId: () => {},
});

export class AppProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quickRespond: {
                // { <ticket_id>: <state_object>, <ticket_id>: <state_object> }
            },
            set: this.set,
            get: this.get,
            getObjectWithId: this.getObjectWithId,
            setObjectWithId: this.setObjectWithId,
        };
    }

    get = name => {
        const { [name]: state } = this.state;
        return state;
    };

    set = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    getObjectWithId = (name, id) => {
        const { [name]: state } = this.state;
        const { [id]: idState } = state;

        return idState || {};
    };

    setObjectWithId = (name, id, value, callback) => {
        const { [name]: state } = this.state;
        const { [id]: idState } = state;
        if (!idState) {
            this.setState(
                {
                    [name]: {
                        ...state,
                        [id]: value,
                    },
                },
                callback
            );
            return;
        }

        this.setState(
            {
                [name]: {
                    ...state,
                    [id]: {
                        ...idState,
                        ...value,
                    },
                },
            },
            callback
        );
    };

    render() {
        const { children } = this.props;
        return <AppContext.Provider value={this.state}>{children}</AppContext.Provider>;
    }
}

export const AppConsumer = AppContext.Consumer;
