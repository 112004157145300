import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import trans from '../../localization';
import FilterOption from './FilterOption';
import helpers from '../../helpers';
import AutoCompleteInput from './AutoCompleteInput';
import UserIcon from './UserIcon';
import ColorListItem from '../ColorListItem';

const SidebarCreateTicket = props => {
    const {
        ticket,
        onSearchUser,
        onSearchTag,
        onAddTag,
        onDeleteTag,
        onChangePriority,
        onChangeStatus,
        onAddAssignment,
        onDeleteAssignment,
    } = props;
    const statuses = helpers.getStatuses();
    const priorities = helpers.getPriorities();

    return (
        <div className="sidebar-navigation sit-right">
            <div className="filters">
                <PerfectScrollbar>
                    <FilterOption
                        name="status"
                        title={trans('status')}
                        value={ticket.status}
                        options={statuses}
                        onChange={onChangeStatus}
                    />

                    <FilterOption
                        name="priority"
                        title={trans('priority')}
                        value={ticket.priority}
                        options={priorities}
                        onChange={onChangePriority}
                    />

                    <div className="filter-option mx-4">
                        <div className="list-group">
                            <div className="list-group-title">
                                <h5>{trans('assigned_agent')}</h5>
                            </div>

                            {!!ticket.users.length &&
                                ticket.users.map(x => (
                                    <div className="d-flex align-items-center mb-1" key={x.id}>
                                        <UserIcon user={x} className="agent-icon" />
                                        <div className="ml-2 text-truncate">{x.name}</div>
                                        <a
                                            href="#remove-assigned-user"
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => onDeleteAssignment(x)}
                                            className="tag ml-auto flex-shrink-0 cursor-pointer text-dark-gray"
                                        >
                                            <i className="fad fa-times-circle" />
                                        </a>
                                    </div>
                                ))}

                            <AutoCompleteInput
                                onChange={onSearchUser}
                                onSelect={onAddAssignment}
                                itemMap={(x, i) => ({
                                    ...x,
                                    label: (
                                        <ColorListItem className={i > 0 && 'mt-1'} color={x.color}>
                                            {x.name}
                                        </ColorListItem>
                                    ),
                                    value: x.name,
                                })}
                            />
                        </div>
                    </div>

                    <div className="filter-option mx-4">
                        <div className="list-group">
                            <div className="list-group-title">
                                <h5>{trans('add_tags')}</h5>
                            </div>

                            <AutoCompleteInput
                                onChange={onSearchTag}
                                onSelect={onAddTag}
                                onEnter={onAddTag}
                                itemMap={x => ({
                                    ...x,
                                    label: (
                                        <div
                                            className={cx(
                                                `list-group-item list-group-item-action active font-weight-normal py-1 pl-4 cursor-pointer`
                                            )}
                                        >
                                            {x.name}
                                        </div>
                                    ),
                                    value: x.name,
                                })}
                            />

                            <div className="tags">
                                {ticket.tags.length > 0 &&
                                    ticket.tags.map(x => (
                                        <button
                                            key={`tag-${x.id || x.value}`}
                                            type="button"
                                            className="tag"
                                            onClick={() => onDeleteTag(x)}
                                        >
                                            {x.name || x.value}
                                            <span className="ml-1">
                                                <i className="fad fa-times-circle" />
                                            </span>
                                        </button>
                                    ))}
                            </div>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    );
};

SidebarCreateTicket.propTypes = {
    ticket: PropTypes.object.isRequired,
    onSearchUser: PropTypes.func,
    onSearchTag: PropTypes.func,
    onAddTag: PropTypes.func,
    onDeleteTag: PropTypes.func,
    onChangePriority: PropTypes.func,
    onAddAssignment: PropTypes.func,
    onDeleteAssignment: PropTypes.func,
    onChangeStatus: PropTypes.func,
};

SidebarCreateTicket.defaultProps = {
    onSearchUser: () => {},
    onSearchTag: () => {},
    onAddTag: () => {},
    onDeleteTag: () => {},
    onChangePriority: () => {},
    onAddAssignment: () => {},
    onChangeStatus: () => {},
    onDeleteAssignment: () => {},
};

export default SidebarCreateTicket;
