import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import { ProgressOverlay } from '../components';

class AppLayout extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isStarted: props.isLoading,
        };
    }

    componentDidMount() {
        const { isLoading } = this.props;

        if (isLoading) {
            NProgress.start();
        }
    }

    componentDidUpdate() {
        const { isLoading } = this.props;
        const { isStarted } = this.state;

        if (isLoading !== isStarted) {
            this.changeProgressBar(isLoading);
        }
    }

    componentWillUnmount() {
        NProgress.done();
    }

    changeProgressBar(isLoading) {
        if (isLoading) {
            NProgress.start();
            this.setState({
                isStarted: isLoading,
            });
        } else {
            NProgress.done();
            this.setState({
                isStarted: isLoading,
            });
        }
    }

    render() {
        const { children, isLoading } = this.props;

        return (
            <>
                {children}
                <ProgressOverlay isLoading={isLoading} />
            </>
        );
    }
}

export default AppLayout;

AppLayout.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
};

AppLayout.defaultProps = {
    children: null,
    isLoading: false,
};
