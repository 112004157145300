import parameters from '../../../routes/parameters';
import ListPage from './ListPage';
import CreateOrEditPage from './CreateOrEditPage';

export default [
    {
        path: '/companies/:companyId/ticketTypes',
        middleware: ['auth'],
        component: ListPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/ticketTypes/create',
        middleware: ['auth'],
        component: CreateOrEditPage,
    },
    {
        path: '/companies/:companyId/ticketTypes/:ticketTypeId/edit',
        middleware: ['auth'],
        component: CreateOrEditPage,
    },
];
