import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import SetPasswordPage from './SetPasswordPage';
import InviteMembersPage from './InviteMembersPage';
import VerifyEmailPage from './VerifyEmailPage';
import VerifyPage from './VerifyPage';
import AcceptInvitationPage from './AcceptInvitationPage';
import RecoverPasswordPage from './RecoverPasswordPage';
import SignOutPage from './SignOutPage';
import SocialPage from './SocialPage';
import ResetPasswordPage from './ResetPasswordPage';
import CreateCompany from './CreateCompany';
import EditCompany from './EditCompany';
import parameters from '../../routes/parameters';

export default [
    {
        path: '/auth/signIn',
        middleware: ['guest'],
        component: SignInPage,
    },
    {
        path: '/auth/signUp',
        middleware: ['newUser'],
        component: SignUpPage,
    },
    {
        path: '/auth/social/:provider',
        middleware: ['guest'],
        component: SocialPage,
    },
    {
        path: '/auth/setPassword',
        middleware: ['hasNotPassword'],
        component: SetPasswordPage,
    },
    {
        path: '/auth/inviteMembers',
        middleware: ['notVerified'],
        component: InviteMembersPage,
    },
    {
        path: '/invitations/accept',
        component: AcceptInvitationPage,
    },
    {
        path: '/auth/verifyEmail',
        middleware: ['notVerified'],
        component: VerifyEmailPage,
    },
    {
        path: '/auth/verify',
        component: VerifyPage,
    },
    {
        path: '/auth/recoverPassword',
        middleware: ['guest'],
        component: RecoverPasswordPage,
    },
    {
        path: '/auth/resetPassword',
        middleware: ['guest'],
        component: ResetPasswordPage,
    },
    {
        path: '/auth/signOut',
        component: SignOutPage,
        middleware: ['auth'],
    },
    {
        path: '/auth/createCompany',
        middleware: ['auth'],
        component: CreateCompany,
    },
    {
        path: '/auth/editCompany/:company',
        middleware: ['auth'],
        component: EditCompany,
        ...parameters.companyParameters,
    },
];
