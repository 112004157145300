import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Container from './Container';
import Information from './Information';
import { activityPrivateNote } from '../../../assets/images';
import trans from '../../../localization';
import TicketAttachments from './TicketAttachments';

const ActivityPrivateNote = props => {
    const { activity, seeTicket, containerClassName, onMoreClick } = props;

    // eslint-disable-next-line camelcase
    const { user, ticket_message } = activity;

    const activityText = trans('x_added_a_private_note_to_a_ticket_you_are_assigned_to', {
        name: user && user.name && user.name.split(' ')[0],
    });
    const buttonText = trans('see_ticket');

    return (
        <Container
            activity={activity}
            className={classNames('private-note', containerClassName)}
            onMoreClick={onMoreClick}
        >
            <Information
                activity={activity}
                onClick={seeTicket}
                buttonText={buttonText}
                activityImage={activityPrivateNote}
                activityText={activityText}
                activityTextClassName="mb-0"
            />
            <p className="text-small text-dark activity-text break-word pt-2">{activity.new}</p>
            {/* eslint-disable-next-line camelcase */}
            {ticket_message &&
                ticket_message.attachments &&
                ticket_message.attachments.length > 0 && (
                    <TicketAttachments attachments={ticket_message.attachments} />
                )}
        </Container>
    );
};

export default ActivityPrivateNote;

ActivityPrivateNote.propTypes = {
    activity: PropTypes.object.isRequired,
    seeTicket: PropTypes.func,
    containerClassName: PropTypes.string,
    onMoreClick: PropTypes.func,
};

ActivityPrivateNote.defaultProps = {
    seeTicket: () => {},
    containerClassName: null,
    onMoreClick: null,
};
