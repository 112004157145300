import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import { ManageLayout } from '../../../layouts';
import { ManageCheckbox, ManageField, ManageInput, ManageSelectbox } from '../../../elements';
import trans from '../../../localization';
import { Api, AuthService } from '../../../services';
import helpers from '../../../helpers';
import notify from '../../../utils/notify';
import { CarouselPane, StepCarousel } from '../../../components';
import CancelAndSaveFooter from '../../../components/helpdesk/carousel/CancelAndSaveFooter';
import definitions from '../../../definitions';

const despatchCloudVersions = definitions.DESPATCH_CLOUD_VERSIONS;

class DespatchCloudEditPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            isLoading: true,
            isSaveDisabled: true,
            user: AuthService.user(),
            companyId: match.params.companyId,
            company: null,
            providerType: match.params.providerType,
            integration: {
                provider: {
                    is_active: false,
                    web_service_key: '',
                    company_url: '',
                    version: '3_4',
                },
            },
            isTesting: false,
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchIntegration()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchIntegration() {
        const { companyId, providerType } = this.state;

        return Api.request({
            method: 'GET',
            url: `integrations/${companyId}/${providerType}`,
        }).then(response => {
            const responseData = response.data.data;

            if (responseData.provider.version == null) {
                responseData.provider.version = '3_4';
            }

            this.setState({
                integration: responseData,
            });
        });
    }

    onChange = (field, value) => {
        const { integration } = this.state;
        this.setState({
            integration: {
                ...integration,
                [field]: value,
            },
        });
    };

    onChangeProvider = (field, value) => {
        const { integration, isLoading } = this.state;

        const newIntegration = {
            ...integration,
            provider: {
                ...integration.provider,
                [field]: value,
            },
        };

        if (!isLoading && field === 'version') {
            newIntegration.provider.web_service_key = '';
            newIntegration.provider.company_url = '';
            newIntegration.provider.email = '';
            newIntegration.provider.password = '';
        }

        this.setState({
            integration: newIntegration,
        });
    };

    onSaveChanges = () => {
        const { companyId, providerType, integration } = this.state;

        this.setState({
            isLoading: true,
        });

        const data = {
            is_active: helpers.parseBoolean(integration.is_active),
            web_service_key: integration.provider.web_service_key,
            company_url: integration.provider.company_url,
            version: integration.provider.version,
        };

        Api.request({
            method: 'POST',
            url: `integrations/${companyId}/${providerType}`,
            data,
        })
            .then(() => {
                notify.saved();
                this.goBack();
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    onTestConnection = () => {
        const { companyId, integration } = this.state;

        this.setState({
            isTesting: true,
        });

        let data;
        let requestUrl;
        let isLogin = false;
        if (integration.provider.version === '3_4') {
            requestUrl = `integrations/${companyId}/despatchcloud/test`;
            data = {
                is_active: helpers.parseBoolean(integration.is_active),
                web_service_key: integration.provider.web_service_key,
                company_url: integration.provider.company_url,
                version: integration.provider.version,
            };
        } else {
            // requestUrl = new URL('/public-api/auth/login', integration.provider.company_url).href;
            requestUrl = `integrations/${companyId}/despatchcloud/login`;
            data = {
                company_url: integration.provider.company_url,
                email: integration.provider.email,
                password: integration.provider.password,
                version: integration.provider.version,
            };
            isLogin = true;
        }

        Api.request({
            method: 'POST',
            url: requestUrl,
            data,
        })
            .then(response => {
                if (isLogin) {
                    const token = response.data.data;

                    this.onChangeProvider('web_service_key', token);
                }

                this.setState({
                    isSaveDisabled: false,
                });
                notify.success('connection_successfully');
            })
            .finally(() => {
                this.setState({
                    isTesting: false,
                });
            });
    };

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { user, company, isLoading, integration, isTesting, isSaveDisabled } = this.state;
        const isVersion34 = integration.provider.version === '3_4';

        return (
            <ManageLayout isLoading={isLoading} theme="blue" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane onPrev={this.goBack} scroll middle center>
                        <div style={{ minWidth: 600 }}>
                            <ManageHeading
                                title={trans(`integration_types.${integration.provider_type}`)}
                                subtitle={trans('integration_details_text')}
                            />
                            <div className="pt-5">
                                <ManageField title={trans('version')}>
                                    <ManageSelectbox
                                        value={integration.provider.version ?? null}
                                        onChange={e =>
                                            this.onChangeProvider('version', e.target.value)
                                        }
                                        items={despatchCloudVersions}
                                    />
                                </ManageField>
                            </div>
                            <div className="pt-4">
                                <ManageField title={trans('company_url')}>
                                    <ManageInput
                                        placeholder={
                                            isVersion34
                                                ? trans('dc_domain_3_4')
                                                : trans('dc_domain')
                                        }
                                        value={integration.provider.company_url || ''}
                                        onChange={e =>
                                            this.onChangeProvider('company_url', e.target.value)
                                        }
                                        type="text"
                                    />
                                </ManageField>
                            </div>
                            {isVersion34 ? (
                                <div className="pt-4">
                                    <ManageField title={trans('web_service_key')}>
                                        <ManageInput
                                            placeholder={
                                                integration.provider.has_web_service_key
                                                    ? trans('existing_web_service_key')
                                                    : trans('web_service_key')
                                            }
                                            value={integration.provider.web_service_key || ''}
                                            onChange={e =>
                                                this.onChangeProvider(
                                                    'web_service_key',
                                                    e.target.value,
                                                )
                                            }
                                            type="text"
                                        />
                                    </ManageField>
                                </div>
                            ) : (
                                <>
                                    <div className="pt-4">
                                        <ManageField title={trans('email')}>
                                            <ManageInput
                                                placeholder={trans('email')}
                                                value={integration.provider.email || ''}
                                                onChange={e =>
                                                    this.onChangeProvider('email', e.target.value)
                                                }
                                                type="text"
                                            />
                                        </ManageField>
                                    </div>
                                    <div className="pt-4">
                                        <ManageField title={trans('password')}>
                                            <ManageInput
                                                placeholder={trans('password')}
                                                value={integration.provider.password || ''}
                                                onChange={e =>
                                                    this.onChangeProvider(
                                                        'password',
                                                        e.target.value,
                                                    )
                                                }
                                                type="text"
                                            />
                                        </ManageField>
                                    </div>
                                </>
                            )}
                            <div className="pt-4 d-flex align-items-center flex-wrap">
                                <ManageCheckbox
                                    onChange={() =>
                                        this.onChange('is_active', !integration.is_active)
                                    }
                                    label={trans('enable_this_integration')}
                                    checked={integration.is_active || false}
                                />
                                <div className="ml-auto" />
                                <button
                                    disabled={isTesting}
                                    type="button"
                                    className="btn btn-primary btn-manage save-btn ml-5 min-width-230"
                                    onClick={this.onTestConnection}
                                >
                                    <i
                                        className={
                                            isTesting ? 'fas fa-spin fa-spinner' : 'fas fa-wifi'
                                        }
                                    />{' '}
                                    <span className="text-normal ml-1">
                                        {isTesting ? trans('testing') : trans('test_connection')}
                                        ...
                                    </span>
                                </button>
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>

                <CancelAndSaveFooter
                    onSubmit={this.onSaveChanges}
                    onCancel={this.goBack}
                    isSubmitDisabled={isSaveDisabled}
                    disabledInfoText={isSaveDisabled ? trans('connect_before_saving') : ''}
                />
            </ManageLayout>
        );
    }
}

DespatchCloudEditPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(DespatchCloudEditPage);
