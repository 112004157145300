import eventService from '../services/EventService';
import trans from '../localization';

const confirm = (title, content, noText = null, yesText = null, buttons = []) => {
    return new Promise((resolve, reject) => {
        eventService.emit('dialog', {
            title,
            content,
            buttons: [
                { slot: noText || trans('no'), click: reject },
                { slot: yesText || trans('yes'), click: resolve, classNames: 'btn-danger' },
                ...buttons,
            ],
            cancelable: false,
        });
    });
};

const deleteConfirm = (title = null, content = null) => {
    return new Promise((resolve, reject) => {
        eventService.emit('dialog', {
            title: title || trans('do_you_want_to_delete'),
            content: content || trans('will_be_deleted_permanently'),
            buttons: [
                { slot: trans('cancel'), click: reject },
                { slot: trans('delete'), click: resolve, classNames: 'btn-danger' },
            ],
            cancelable: false,
        });
    });
};

const deleteModelConfirm = name => {
    return deleteConfirm(
        trans('do_you_want_to_delete_name', { name }),
        trans('will_be_deleted_permanently_name', { name })
    );
};

export default {
    confirm,
    deleteConfirm,
    deleteModelConfirm,
};
