import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import definitions from '../../../definitions';
import { HoverTooltip } from '../../index';
import PaginationScrollbar from '../../PaginationScrollbar';

export default class IconPicker extends Component {
    onClick(color) {
        const { onChange } = this.props;

        onChange(color);
    }

    renderIcon(icon) {
        const { disabled, value, color } = this.props;
        const selected = value === icon.name;

        return (
            <HoverTooltip title={icon.name} key={icon.id}>
                <button
                    className={classNames(
                        `align-items-center btn d-flex justify-content-center text-color-${color}`,
                        { selected }
                    )}
                    type="button"
                    disabled={disabled}
                    onClick={() => this.onClick(icon.name)}
                >
                    <i className={`fas fa-${icon.name}`} />
                </button>
            </HoverTooltip>
        );
    }

    render() {
        const { icons, onNextPage, isLastPage } = this.props;

        return (
            <PaginationScrollbar
                onNextPage={p => onNextPage(p)}
                isLastPage={isLastPage}
                className="icon-picker-component d-flex flex-wrap justify-content-center"
            >
                <div className="icon-picker-list d-flex flex-wrap justify-content-start">
                    {icons.map(icon => this.renderIcon(icon))}
                </div>
            </PaginationScrollbar>
        );
    }
}

IconPicker.propTypes = {
    icons: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    onNextPage: PropTypes.func,
    isLastPage: PropTypes.bool,
};

IconPicker.defaultProps = {
    value: null,
    onChange: () => {},
    disabled: false,
    color: definitions.COLOR_SEA_GREEN,
    onNextPage: () => Promise.resolve(),
    isLastPage: false,
};
