import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

const HoverTooltip = ({ title, placement, children, disabled }) => {
    if (disabled) {
        return <>{children}</>;
    }

    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip id={Math.random()}>{title}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    );
};

export default HoverTooltip;

HoverTooltip.propTypes = {
    title: PropTypes.any.isRequired,
    placement: PropTypes.string,
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
};

HoverTooltip.defaultProps = {
    placement: 'top',
    disabled: false,
};
