import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import AuthService from './AuthService';
import trans from '../localization';
import helpers from '../helpers';
import notify from '../utils/notify';
import history from '../routes/history';

export default class ApiService {
    constructor() {
        // noinspection JSUnresolvedVariable,ES6ModulesDependencies
        this.client = axios.create({
            baseURL: helpers.env('REACT_APP_API_URL'),
            // timeout: 10000,
            responseType: 'json',
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
        });

        this.client.interceptors.request.use(ApiService.onFulfilledRequestInterceptor);
    }

    request(config, dontNotify) {
        const request = this.client.request(config);

        request.catch(error => ApiService.handleError(error, dontNotify));

        return request;
    }

    static handleError(error, dontNotify) {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 404:
                    history.push('/errors/notFound');
                    break;

                case 403:
                    history.push('/errors/authorization');
                    break;

                default:
                    break;
            }
        }

        if (error.response && error.response.status === 429) {
            const message = ApiService.parseErrorMessage(error);
            notify.errorMessage(message);
        } else if (!axios.isCancel(error) && !dontNotify) {
            const message = ApiService.parseErrorMessage(error);
            notify.errorMessage(message);
        }
    }

    static parseErrors(error) {
        const errors = {};

        if (
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
        ) {
            // eslint-disable-next-line no-undef
            window._.each(error.response.data.errors, (values, key) => {
                if (values instanceof Array) {
                    // eslint-disable-next-line no-undef
                    errors[key] = window._.first(values);
                } else {
                    errors[key] = values;
                }
            });
        }

        return errors;
    }

    static parseErrorMessage(error) {
        let message = trans('server_error');

        if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;

            if (error.response && error.response.data && error.response.data.errors) {
                // eslint-disable-next-line
                const errors = window._.values(error.response.data.errors);
                message = ApiService.getFirstError(errors);
            }
        } else if (error.response && error.response.message) {
            message = error.response.message;
        }

        return message;
    }

    static formatSearch(props) {
        if (props.location && props.location.search) {
            let { search } = props.location;
            if (search.length > 0 && search.substr(0, 1) === '?') {
                search = search.substr(1);
            }

            return search;
        }

        return '';
    }

    static getFirstError(array) {
        if (array instanceof Array) {
            // eslint-disable-next-line no-undef
            return ApiService.getFirstError(window._.first(array));
        }

        return array;
    }

    static onFulfilledRequestInterceptor(config) {
        if (AuthService.hasToken()) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
        }

        return config;
    }
}
