import ListPage from './ListPage';
import CreateOrEditPage from './CreateOrEditPage';

export default [
    {
        path: '/myAccount/cannedResponses',
        middleware: ['auth'],
        component: ListPage,
    },
    {
        path: '/myAccount/cannedResponses/create',
        middleware: ['auth'],
        component: CreateOrEditPage,
    },
    {
        path: '/myAccount/cannedResponses/:responseId/edit',
        middleware: ['auth'],
        component: CreateOrEditPage,
    },
];
