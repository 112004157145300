import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import ManageList from '../../../components/helpdesk/list/ManageList';
import ManageListItem from '../../../components/helpdesk/list/ManageListItem';
import trans from '../../../localization';
import { CarouselPane, StepCarousel } from '../../../components';
import router from '../../../utils/router';

class ListPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            channels: [],
        };

        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchChannels()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    onCreateClick = () => {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company, 'channels/create');
    };

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    fetchChannels = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: 'channels',
            params: {
                companyId,
            },
        }).then(response => {
            this.setState({
                channels: response.data.data,
            });
        });
    };

    goToEditChannel = item => {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company, `channels/${item.id}/edit/${item.type}`);
    };

    goBack() {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company);
    }

    getMenuItems(item) {
        const menu = [];

        menu.push({
            title: trans('edit'),
            onClick: () => this.goToEditChannel(item),
        });

        return menu;
    }

    render() {
        const { isLoading, user, company, channels } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane onPrev={this.goBack} scroll center middle>
                        <div className="row">
                            <div className="col d-flex d-flex flex-column align-items-center justify-content-center">
                                <ManageHeading
                                    title={
                                        <>
                                            <span className="font-weight-normal">
                                                {trans('channels')}
                                            </span>
                                        </>
                                    }
                                    subtitle={trans('channels_list_text')}
                                />
                                <ManageList
                                    items={channels}
                                    renderItem={x => (
                                        <ManageListItem
                                            key={`channel-${x.id}`}
                                            item={x}
                                            type="channel"
                                            isActive={x.is_active}
                                            onClick={() => this.goToEditChannel(x)}
                                            cogMenu={this.getMenuItems(x)}
                                        />
                                    )}
                                    renderFirstItem={() => (
                                        <ManageListItem
                                            key="add-agent"
                                            type="channel"
                                            onClick={() => this.onCreateClick()}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(ListPage);

ListPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
