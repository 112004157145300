import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import { CarouselPane, StepCarousel } from '../../../components';
import trans from '../../../localization';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import ColorPicker from '../../../components/helpdesk/manage/ColorPicker';
import UserIcon from '../../../components/helpdesk/UserIcon';
import CropperModal from '../../../components/helpdesk/CropperModal';
import definitions from '../../../definitions';
import CancelAndSaveFooter from '../../../components/helpdesk/carousel/CancelAndSaveFooter';

class EditIconPage extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;
        const user = AuthService.user();

        this.state = {
            user,
            companyId: location.state && location.state.companyId,
            company: null,
            isLoading: true,
            data: {
                color: user.color,
                image: null,
                remove_image: false,
            },
            file: null,
            showCropper: false,
        };

        this.goBack = this.goBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onClickImage = this.onClickImage.bind(this);
        this.onCloseCrop = this.onCloseCrop.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        const fetchPromises = Promise.all([this.fetchCompany(), this.fetchUser()]);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    onClickImage() {
        this.clearFile();
        this.fileInputRef.current.click();
    }

    onFileChange(e) {
        if (e.target && e.target.files && e.target.files.length) {
            const file = e.target.files[0];
            this.setState(
                {
                    isLoading: true,
                    showCropper: true,
                },
                () => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.setState({
                            isLoading: false,
                            file: reader.result,
                        });
                    };
                    reader.readAsDataURL(file);
                }
            );
        } else {
            this.setState({
                file: null,
            });
        }
    }

    onCrop(image) {
        const { data } = this.state;
        data.image = image;
        data.remove_image = false;

        this.setState(
            {
                data,
            },
            () => {
                this.onCloseCrop();
            }
        );
    }

    onCloseCrop() {
        this.setState({
            showCropper: false,
            file: null,
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        if (!companyId) return Promise.resolve();

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchUser() {
        return AuthService.fetchUser().then(user => {
            const { data } = this.state;
            data.color = user.color;

            this.setState({
                user,
                data,
            });
        });
    }

    changeColor(color) {
        const { data } = this.state;
        data.color = color;

        this.setState({
            data,
        });
    }

    handleSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            isLoading: true,
        });

        const { data } = this.state;
        Api.request({
            method: 'POST',
            url: 'myAccount/updateIcon',
            data,
        })
            .then(response => {
                AuthService.setUser(response.data.data);
                this.goBack();
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    goBack() {
        const { history } = this.props;
        history.goBack();
    }

    useInitial() {
        this.setState({
            data: {
                color: definitions.COLOR_SEA_GREEN,
                image: null,
                remove_image: true,
            },
        });

        this.clearFile();
    }

    clearFile() {
        this.fileInputRef.current.value = null;
        this.setState({
            file: null,
        });
    }

    render() {
        const { isLoading, user, company, data, file, showCropper } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="purple"
                user={user}
                company={company}
                hideMyAccountLink
            >
                <form onSubmit={this.handleSubmit}>
                    <StepCarousel>
                        <CarouselPane
                            onPrev={this.goBack}
                            onNext={this.handleSubmit}
                            center
                            middle
                            scroll
                        >
                            <div className="edit-my-icon-page">
                                <ManageHeading
                                    title={
                                        <>
                                            {trans('edit_my')}{' '}
                                            <strong>{trans('agent_icon')}</strong>
                                        </>
                                    }
                                    subtitle={trans('edit_icon_text')}
                                />

                                <p className="lead-title">
                                    Being basic is boring, spice up your profile
                                </p>

                                <p className="lead-color-label">Colour</p>

                                <ColorPicker value={data.color} onChange={this.changeColor} />

                                <p className="lead-upload-image-label">Upload an image</p>
                                <Row noGutters>
                                    <Col bsPrefix="col-auto" className="cancel-selected-image">
                                        <UserIcon
                                            imageUrl={data.image}
                                            color={data.color}
                                            user={user}
                                            onClick={this.onClickImage}
                                            hideImage={data.remove_image}
                                        >
                                            <i className="fas fa-upload" />
                                        </UserIcon>

                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={this.onFileChange}
                                            ref={this.fileInputRef}
                                            name="file"
                                            className="d-none"
                                        />

                                        <CropperModal
                                            onClose={this.onCloseCrop}
                                            onCrop={this.onCrop}
                                            show={showCropper}
                                            src={file}
                                            width={80}
                                            height={80}
                                        />
                                    </Col>
                                    <Col className="align-items-start d-flex flex-column justify-content-center user-information">
                                        <div className="user-name">{user.name}</div>
                                        <div className="user-email">{user.email}</div>
                                    </Col>
                                </Row>

                                <button
                                    onClick={() => this.useInitial()}
                                    type="button"
                                    className="btn btn-link skip-use-initials p-0"
                                >
                                    {trans('skip_and_just_use_initials')}
                                </button>
                            </div>
                        </CarouselPane>
                    </StepCarousel>

                    <CancelAndSaveFooter onCancel={this.goBack} />
                </form>
            </ManageLayout>
        );
    }
}

export default withRouter(EditIconPage);

EditIconPage.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
