import React, { PureComponent } from 'react';
import { Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import routes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import history from './routes/history';
import { AppProvider } from './utils/app-context';
import Dialog from './components/Dialog';

class App extends PureComponent {
    render() {
        return (
            <div>
                <AppProvider>
                    <Router history={history}>{routes}</Router>
                    <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
                    <Dialog />
                </AppProvider>
            </div>
        );
    }
}

export default App;
