import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CarouselPane, StepCarousel } from '../../../components';
import trans from '../../../localization';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';
import definitions from '../../../definitions';
import { FormButton } from '../../../elements';
import router from '../../../utils/router';

class SuccessEbayPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            channelId: match.params.channelId,
            channel: null,
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            step: 0,
        };

        this.goToBack = this.goToBack.bind(this);
        this.goToTickets = this.goToTickets.bind(this);
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchChannel()]).finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchChannel = () => {
        const { channelId } = this.state;

        return Api.request({
            method: 'GET',
            url: `channels/${channelId}`,
        }).then(response => {
            const channel = response.data.data;
            // noinspection JSUnresolvedVariable
            if (channel.type !== definitions.PROVIDER_EBAY) {
                const { history } = this.props;
                const { companyId } = this.state;
                router.toCompany(history, companyId, 'channels');
            }

            this.setState({
                channel,
            });
        });
    };

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    goToBack = () => {
        const { history } = this.props;
        const { company, channel } = this.state;

        router.toCompany(history, company, `channels/${channel.id}/edit/ebay`);
    };

    goToTickets() {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company);
    }

    renderSelectSiteIdStep = () => {
        const { channel, company } = this.state;

        return (
            <div className="background-ebay-success">
                <div className="text-center mt-4">
                    <div className={`verification-sign bg-color-${channel.color}`}>
                        <i className={`fa fas fa-${channel.icon}`} />
                    </div>
                    <div className="mt-4">
                        <h4 className="m-0">
                            <strong>{channel.name}</strong>
                        </h4>
                        <div className="mt-4">
                            <div>{trans('youre_all_setup')}</div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <FormButton
                            type="button"
                            className="manage-btn"
                            text={trans('go_to_tickets')}
                            onClick={this.goToTickets}
                        />
                    </div>
                    <div className="mt-2 text-light-gray">{trans('or')}</div>
                    <div className="mt-2">
                        <Link
                            to={router.pathCompany(company, 'channels/create')}
                            className="text-dark"
                        >
                            {trans('setup_another_source')}
                        </Link>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { step, user, company, isLoading, channel } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel step={step}>
                    <CarouselPane onPrev={this.goToBack} scroll middle center>
                        {channel && this.renderSelectSiteIdStep()}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(SuccessEbayPage);

SuccessEbayPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
