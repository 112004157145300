import { Api } from './index';

export default class TicketService {
    static updateStatus(ticketId, status) {
        return Api.request({
            method: 'POST',
            url: `tickets/${ticketId}/status`,
            data: {
                status,
            },
        });
    }

    static updatePriority(ticketId, priority) {
        return Api.request({
            method: 'POST',
            url: `tickets/${ticketId}/priority`,
            data: {
                priority,
            },
        });
    }

    static updateTicketType(ticketId, ticketTypeId) {
        return Api.request({
            method: 'POST',
            url: `tickets/${ticketId}/ticketType`,
            data: {
                ticket_type_id: ticketTypeId,
            },
        });
    }

    static updateSource(ticketId, sourceId) {
        return Api.request({
            method: 'POST',
            url: `tickets/${ticketId}/source`,
            data: {
                source_id: sourceId,
            },
        });
    }

    static sendMessage(ticketId, formData) {
        return Api.request({
            method: 'POST',
            url: `tickets/${ticketId}/messages`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    static cancelSendingMessage(ticketId, messageId) {
        return Api.request({
            method: 'DELETE',
            url: `tickets/${ticketId}/messages/${messageId}/cancel`,
        });
    }

    static getTicketsInGroups(ticketGroups) {
        let tickets = [];

        window._.each(ticketGroups, ticketGroup => {
            tickets = [...tickets, ...ticketGroup];
        });

        return tickets;
    }

    static ticketIsExists(ticket, ticketGroups) {
        const tickets = TicketService.getTicketsInGroups(ticketGroups);
        return window._.some(tickets, { id: ticket.id });
    }

    static getTickets(ticketGroups) {
        const tickets = [];

        window._.each(window._.values(ticketGroups), ticketList => {
            window._.each(ticketList, ticket => {
                tickets.push(ticket);
            });
        });

        return tickets;
    }

    static addMessage(ticketMessage, ticketMessages) {
        if (window._.some(ticketMessages, { id: ticketMessage.id })) {
            return ticketMessages;
        }

        ticketMessages.splice(0, 0, ticketMessage);
        return ticketMessages;
    }

    static updateMessage(ticketMessage, ticketMessages) {
        const index = window._.findIndex(ticketMessages, { id: ticketMessage.id });

        if (index > -1) {
            const updatedTicketMessages = ticketMessages;
            updatedTicketMessages[index] = ticketMessage;
            return updatedTicketMessages;
        }

        return ticketMessages;
    }

    static deleteMessage(ticketMessage, ticketMessages) {
        const index = window._.findIndex(ticketMessages, { id: ticketMessage.id });

        if (index > -1) {
            const updatedTicketMessages = ticketMessages;
            updatedTicketMessages.splice(index, 1);
            return updatedTicketMessages;
        }

        return ticketMessages;
    }

    static merge(items, currentItems) {
        // eslint-disable-next-line
        const newItems = window._.filter(items, item => {
            // eslint-disable-next-line
            return !window._.some(currentItems, { id: item.id });
        });

        return [...currentItems, ...newItems];
    }

    static addTicket(ticket, ticketGroups) {
        const tickets = TicketService.getTickets(ticketGroups);
        tickets.push(ticket);

        return tickets;
    }

    static updateTicket(ticket, ticketGroups) {
        const tickets = TicketService.getTickets(ticketGroups);
        const index = window._.findIndex(tickets, { id: ticket.id });

        if (index > -1) {
            tickets[index] = ticket;
        }

        return tickets;
    }
}
