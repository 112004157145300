import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { ManageLayout } from '../../../layouts';
import trans from '../../../localization';
import { Api, AuthService } from '../../../services';
import { CarouselPane, StepCarousel } from '../../../components';
import CancelAndSaveFooter from '../../../components/helpdesk/carousel/CancelAndSaveFooter';
import { ManageField, ManageInput } from '../../../elements';
import { ColorPicker } from '../../../components/helpdesk/manage';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';

class CreateOrEditPage extends Component {
    constructor(props) {
        super(props);

        const { location, match } = this.props;

        this.state = {
            isLoading: true,
            user: AuthService.user(),
            companyId: match.params.companyId,
            company: null,
            ticketTypeId: match.params.ticketTypeId,
            isUpdate: !!(location.state && location.state.data),
            data: (location.state && location.state.data) || {
                name: '',
                color: '',
            },
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchTicketType()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchTicketType() {
        const { ticketTypeId } = this.state;

        return new Promise((resolve, reject) => {
            if (!ticketTypeId) {
                this.setState(
                    {
                        isUpdate: false,
                    },
                    resolve
                );
            } else {
                Api.request({
                    method: 'GET',
                    url: `ticketTypes/${ticketTypeId}`,
                })
                    .then(response => {
                        this.setState(
                            {
                                data: response.data.data,
                                isUpdate: true,
                            },
                            resolve
                        );
                    })
                    .catch(reject);
            }
        });
    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    onChange = (field, value) => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                [field]: value,
            },
        });
    };

    createTicketType = () => {
        const { data, companyId } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: 'ticketTypes/create',
            data: {
                company_id: companyId,
                ...data,
            },
        })
            .then(() => {
                const { history } = this.props;
                history.goBack();
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    updateTicketType = () => {
        const { data } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'PUT',
            url: `ticketTypes/${data.id}/edit`,
            data,
        })
            .then(() => {
                const { history } = this.props;
                history.goBack();
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    render() {
        const { isLoading, user, company, data, isUpdate } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="orange"
                user={user}
                company={company}
                hideLBImg
            >
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-4"
                        scroll
                        center
                        middle
                    >
                        <ManageHeading
                            title={
                                isUpdate ? trans('update_ticket_type') : trans('create_ticket_type')
                            }
                            subtitle={
                                isUpdate
                                    ? trans('update_ticket_type_description')
                                    : trans('create_ticket_type_description')
                            }
                        />

                        <Container className="manage-form">
                            <Row className="pt-2">
                                <Col lg={{ span: 10, offset: 1 }}>
                                    <ManageField title={trans('name')}>
                                        <ManageInput
                                            value={data.name}
                                            placeholder={trans('placeholders.ticket_type_name')}
                                            onChange={e => this.onChange('name', e.target.value)}
                                            type="text"
                                        />
                                    </ManageField>
                                </Col>
                            </Row>

                            <Row className="pt-5 justify-content-center">
                                <Col md={5}>
                                    <ColorPicker
                                        value={data.color}
                                        onChange={c => this.onChange('color', c)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </CarouselPane>
                </StepCarousel>

                <CancelAndSaveFooter
                    submitTitle={isUpdate ? trans('save_changes') : trans('create')}
                    onSubmit={isUpdate ? this.updateTicketType : this.createTicketType}
                    onCancel={this.goBack}
                />
            </ManageLayout>
        );
    }
}

CreateOrEditPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(CreateOrEditPage);
