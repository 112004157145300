import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { ManageField } from '../../../elements';
import { Api } from '../../../services';
import trans from '../../../localization';
import ChannelLogs from '../../../components/helpdesk/ChannelLogs';
import BaseEditPage from './BaseEditPage';
import router from '../../../utils/router';

class EditEbayPage extends BaseEditPage {
    constructor(props) {
        super(props);

        this.disconnectAccount = this.disconnectAccount.bind(this);
    }

    componentDidMount() {
        super.onMount();
    }

    disconnectAccount() {
        const { channel } = this.state;
        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: `channels/${channel.id}/ebay/disconnect`,
        })
            .then(response => {
                this.setState({
                    channel: { ...channel, ...response.data.data },
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    render() {
        const { company, channel } = this.state;
        const isProviderPresent = channel.provider && channel.provider.email;

        return super.layout(
            <>
                <div className="row mt-4">
                    <div className="col-lg-12 mb-4">
                        {!!channel.provider && (
                            <ManageField title={trans('authorisation')}>
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div
                                            className={`ebay-channel-logo bg-color-${channel.color}`}
                                        >
                                            <i className="fab fa-ebay mt-1" />
                                            <div
                                                className={`isactive-icon bg color-active-${
                                                    channel.provider && channel.provider.is_active
                                                        ? 'active'
                                                        : 'inactive'
                                                }`}
                                            >
                                                {channel.provider && channel.provider.is_active ? (
                                                    <i className="fa fa-check" />
                                                ) : (
                                                    <i className="fa fa-times" />
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column text-dark ml-4">
                                            <span>{channel.provider && channel.provider.slug}</span>
                                            <span>
                                                {channel.provider && channel.provider.email}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column pr-2 mt-4 mt-md-0">
                                        <div className="d-flex justify-content-end">
                                            {isProviderPresent && (
                                                <span
                                                    className={`ebay-mail-status color ${
                                                        channel.provider &&
                                                        channel.provider.is_verified
                                                            ? 'color-active-active'
                                                            : ''
                                                    } mr-4`}
                                                >
                                                    <i className="fa fa-shield-check mr-1" />
                                                    {channel.provider &&
                                                    channel.provider.is_verified
                                                        ? trans('verified')
                                                        : trans('unverified')}
                                                </span>
                                            )}
                                            <span
                                                className={`ebay-provider-status bg color-active-${
                                                    channel.provider && channel.provider.is_active
                                                        ? 'active'
                                                        : 'inactive'
                                                }`}
                                            >
                                                {channel.provider && channel.provider.is_active
                                                    ? trans('active')
                                                    : trans('inactive')}
                                            </span>
                                        </div>
                                        {channel.provider && channel.provider.is_active ? (
                                            <button
                                                type="button"
                                                onClick={this.disconnectAccount}
                                                className="btn btn-manage danger-link-btn p-0 mt-2 font-weight-light"
                                            >
                                                {trans('disconnect_account')}
                                            </button>
                                        ) : (
                                            <Link
                                                to={router.pathCompany(
                                                    company,
                                                    `channels/${channel.id}/setup/ebay`
                                                )}
                                                className="btn-manage link-btn text-xsmall text-align-right font-weight-light mt-2"
                                            >
                                                {isProviderPresent
                                                    ? trans('reconnect_account')
                                                    : trans('connect_account')}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </ManageField>
                        )}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        {channel && channel.id && <ChannelLogs channelId={channel.id} />}
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(EditEbayPage);

EditEbayPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
