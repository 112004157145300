import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api, AuthService } from '../../services';
import { AuthLayout } from '../../layouts';
import ApiService from '../../services/ApiService';
import { LoadingBar } from '../../components';
import { AuthCardView } from '../../components/auth';
import trans from '../../localization';
import { FormButton, FormInput } from '../../elements';
import router from '../../utils/router';

class SocialPage extends Component {
    constructor(props, context) {
        super(props, context);

        const { match } = props;

        this.state = {
            isLoading: true,
            provider: match.params.provider,
            signUp: false,
            errors: {},
            data: {
                company: '',
            },
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.fetchToken();
    }

    onSubmit(event) {
        event.preventDefault();

        const { isSending, data } = this.state;
        const { history } = this.props;

        if (isSending) {
            return;
        }

        this.setState({
            isSending: true,
        });

        Api.request(
            {
                method: 'POST',
                url: 'auth/social/signUp',
                data,
            },
            true
        )
            .then(response => {
                const user = response.data.data;
                AuthService.setUser(user);
                AuthService.redirectToNext(history);
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                    isSending: false,
                });
            });
    }

    onChange(event) {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    }

    fetchToken() {
        const query = ApiService.formatSearch(this.props);
        const { provider } = this.state;

        Api.request({
            method: 'GET',
            url: `auth/social/${provider}?${query}`,
        })
            .then(response => {
                const { token } = response.data.data;
                const { history } = this.props;

                AuthService.setTokenAndFetch(token).then(() => {
                    if (AuthService.hasPassword()) {
                        AuthService.redirectToNext(history);
                    } else {
                        this.setState({
                            signUp: true,
                        });
                    }
                });
            })
            .catch(() => {
                const { history } = this.props;
                router.replace(history, '/auth/signIn');
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    renderSignUpForm() {
        const { errors, isSending, data } = this.state;
        const { company } = data;
        const user = AuthService.user();

        return (
            <form onSubmit={this.onSubmit}>
                <FormInput
                    labelText={trans('attributes.your_name')}
                    type="text"
                    name="name"
                    value={user.name}
                    readOnly
                />

                <FormInput
                    labelText={trans('attributes.your_email')}
                    type="email"
                    name="email"
                    value={user.email}
                    readOnly
                />

                <FormInput
                    labelText={trans('attributes.your_company')}
                    validationError={errors.company}
                    onChange={this.onChange}
                    value={company}
                    type="text"
                    name="company"
                    placeholder={trans('placeholders.your_company_name')}
                />

                <div className="row">
                    <div className="col d-flex justify-content-end">
                        <FormButton
                            text={trans('get_started')}
                            disabled={isSending}
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        );
    }

    render() {
        const { isLoading, signUp } = this.state;

        return (
            <AuthLayout isLoading={isLoading}>
                <AuthCardView
                    title={trans('you_are_all_ready_to_go')}
                    subtitle={trans('you_are_all_ready_to_go_subtitle')}
                    titleSmall={trans('sign_up')}
                >
                    {signUp ? this.renderSignUpForm() : <LoadingBar />}
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(SocialPage);

SocialPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
