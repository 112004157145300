import React from 'react';
import PropTypes from 'prop-types';
import UserIcon from '../UserIcon';
import trans from '../../../localization';
import MessageTime from './MessageTime';
import TicketAttachments from '../ticket/attachments/TicketAttachments';
import definitions from '../../../definitions';
import MessageBody from './MessageBody';
import URL from '../../../elements/URL';

const Message = ({
    message,
    onClickCustomer,
    onForwardAttachment,
    isFirstMessage,
    onTryAgain,
    onCancelSending,
    canFetchOrders,
    onFetchOrders,
}) => {
    let messageClass = message.user ? 'message-sent' : 'message-received';
    if (message.type === definitions.TYPE_NOTE) {
        messageClass = 'internal-note';
    }

    return (
        <div className={messageClass}>
            {message.user && (
                <div className="message-header mb-3">
                    <UserIcon user={message.user} />
                    <div className="ml-2">
                        <h3 id="conversation_reply_contact-1">{message.user.name}</h3>
                        {message.user.role && <span>{trans(`roles.${message.user.role}`)}</span>}
                    </div>
                </div>
            )}
            {message.customer && (
                <div className="d-flex justify-content-between mb-3 message-header">
                    <div className="d-flex">
                        <UserIcon user={message.customer} />
                        <div className="ml-2">
                            <h3 id="conversation_reply_contact-1">{message.customer.name}</h3>
                            <URL
                                onClick={() => onClickCustomer(message.customer)}
                                className="p-0 small"
                            >
                                {message.customer.email ||
                                    `${trans('customer')} #${message.customer.id}`}
                            </URL>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                        {canFetchOrders && (
                            <button
                                type="button"
                                onClick={onFetchOrders}
                                className="btn-fetch-order shadow"
                            >
                                <i className="fas fa-shopping-cart" />
                            </button>
                        )}
                    </div>
                </div>
            )}
            <div className="actual-message">
                <MessageBody body={message.body} short={isFirstMessage ? null : message.short} />
                <div className="time pt-2">
                    <MessageTime
                        tryAgain={onTryAgain}
                        message={message}
                        cancelSending={onCancelSending}
                    />
                </div>
                {message.attachments && message.attachments.length ? (
                    <div className="attatchments-wrapper py-2">
                        <hr />
                        <h3>
                            {trans('attachments')}{' '}
                            <span className="badge badge-pill badge-secondary py-1 px-2 blank">
                                {message.attachments.length}
                            </span>
                        </h3>
                        <TicketAttachments
                            attachments={message.attachments}
                            onForward={attachment => onForwardAttachment(attachment)}
                        />
                    </div>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
};

export default Message;

Message.propTypes = {
    message: PropTypes.object.isRequired,
    onClickCustomer: PropTypes.func,
    onForwardAttachment: PropTypes.func,
    onTryAgain: PropTypes.func,
    onCancelSending: PropTypes.func,
    isFirstMessage: PropTypes.bool,
    canFetchOrders: PropTypes.bool,
    onFetchOrders: PropTypes.func,
};

Message.defaultProps = {
    onClickCustomer: () => {},
    onForwardAttachment: () => {},
    onTryAgain: () => {},
    onCancelSending: () => {},
    isFirstMessage: false,
    canFetchOrders: false,
    onFetchOrders: null,
};
