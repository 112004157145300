import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Api } from '../../../services';
import PaginationScrollbar from '../../PaginationScrollbar';
import { HoverTooltip } from '../../index';
import definitions from '../../../definitions';
import TicketService from '../../../services/TicketService';

export default class IconLoaderPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            icons: [],
            page: 1,
            lastPage: 2,
        };

        this.onNextPage = this.onNextPage.bind(this);
    }

    componentDidMount() {
        this.fetchIcons().catch(() => {});
    }

    fetchIcons() {
        const { page, lastPage, icons } = this.state;
        if (page >= lastPage) {
            return Promise.resolve();
        }

        return Api.request(
            {
                method: 'GET',
                url: 'icons',
                params: {
                    page,
                },
            },
            true
        ).then(response => {
            // noinspection JSUnresolvedVariable
            this.setState({
                icons: TicketService.merge(response.data.data, icons),
                page: response.data.meta.current_page,
                lastPage: response.data.meta.last_page,
            });
        });
    }

    onNextPage() {
        return new Promise((resolve, reject) => {
            const { page } = this.state;
            this.setState(
                {
                    page: page + 1,
                },
                () => {
                    this.fetchIcons()
                        .then(resolve)
                        .catch(reject);
                }
            );
        });
    }

    renderIcon(icon) {
        const { disabled, value, color, onChange } = this.props;
        const selected = value === icon.name;

        return (
            <HoverTooltip title={icon.name} key={icon.id}>
                <button
                    className={classNames(
                        `align-items-center btn d-flex justify-content-center text-color-${color}`,
                        { selected }
                    )}
                    type="button"
                    disabled={disabled}
                    onClick={() => onChange(icon.name)}
                >
                    <i className={`fas fa-${icon.name}`} />
                </button>
            </HoverTooltip>
        );
    }

    render() {
        const { icons, page, lastPage } = this.state;

        return (
            <PaginationScrollbar
                onNextPage={this.onNextPage}
                isLastPage={page >= lastPage}
                className="icon-picker-component d-flex flex-wrap justify-content-center"
            >
                <div className="icon-picker-list d-flex flex-wrap justify-content-start">
                    {icons.map(icon => this.renderIcon(icon))}
                </div>
            </PaginationScrollbar>
        );
    }
}

IconLoaderPicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    color: PropTypes.string,
};

IconLoaderPicker.defaultProps = {
    value: null,
    onChange: () => {},
    disabled: false,
    color: definitions.COLOR_SEA_GREEN,
};
