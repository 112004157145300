import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import router from '../../../utils/router';

const TicketId = props => {
    const { ticket, company, className } = props;

    if (!ticket || !company) {
        return <span className="small bg-primary" />;
    }

    return (
        <Link
            to={router.pathCompany(company, `tickets/${ticket.id}`)}
            className={`small ${className}`}
        >
            {' '}
            {`(#${ticket.id})`}
        </Link>
    );
};

export default TicketId;

TicketId.propTypes = {
    ticket: PropTypes.object,
    company: PropTypes.object,
    className: PropTypes.string,
};

TicketId.defaultProps = {
    ticket: null,
    company: null,
    className: '',
};
