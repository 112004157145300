import React from 'react';
import PropTypes from 'prop-types';
import { questionMarkDuotone } from '../../assets/images';
import { HoverTooltip } from '../index';

const LabeledCheckbox = ({
    item,
    containerClassName,
    checked,
    questionMark,
    questionMarkTooltip,
    ...others
}) => {
    const className = checked ? 'text-color-dark' : 'text-color-light-gray';
    const id = `checkbox-${item.name}-${item.id}`;

    return (
        <div className={`d-flex align-items-center labeled-checkbox ${containerClassName}`}>
            <div className="custom-checkbox">
                <input
                    type="checkbox"
                    className="checkbox"
                    name={`${id}_checkbox`}
                    id={id}
                    value="39"
                    checked={checked}
                    {...others}
                />
                <div className="cont">
                    <label htmlFor={id}>
                        <i className="fas fa-check" aria-hidden="true" />
                    </label>
                </div>
            </div>
            <p className={`text my-0 ${className}`}>
                {item.name}
                {questionMark && (
                    <HoverTooltip title={questionMarkTooltip}>
                        <img src={questionMarkDuotone} alt={item.name} className="mb-2 ml-2" />
                    </HoverTooltip>
                )}
            </p>
        </div>
    );
};

LabeledCheckbox.propTypes = {
    item: PropTypes.object.isRequired,
    containerClassName: PropTypes.string,
    checked: PropTypes.bool,
    questionMark: PropTypes.bool,
    questionMarkTooltip: PropTypes.string,
};

LabeledCheckbox.defaultProps = {
    containerClassName: '',
    checked: false,
    questionMark: false,
    questionMarkTooltip: '',
};

export default LabeledCheckbox;
