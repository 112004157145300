const encode = object => {
    return JSON.stringify(object);
};

const decode = json => {
    try {
        return JSON.parse(json);
    } catch (e) {
        return null;
    }
};

const get = (key, defaultValue = null) => {
    const value = localStorage.getItem(key);
    return value ? decode(value) : defaultValue;
};

const set = (key, value) => {
    return localStorage.setItem(key, encode(value));
};

const getId = (key, id, defaultValue = null) => {
    const value = localStorage.getItem(`${key}.${id}`);
    return value ? decode(value) : defaultValue;
};

const remove = key => {
    return localStorage.removeItem(key);
};

const setId = (key, id, value) => {
    return localStorage.setItem(`${key}.${id}`, encode(value));
};

export default {
    encode,
    decode,
    remove,
    get,
    set,
    getId,
    setId,
};
