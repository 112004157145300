import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import trans from '../../localization';
import { ErrorLayout } from '../../layouts/ErrorLayout';

const AuthorizationErrorPage = ({ history }) => {
    return (
        <ErrorLayout history={history}>
            <h2 className="h2 text-white mt-2 lead">{trans('you_do_not_have_permission')}</h2>
        </ErrorLayout>
    );
};

AuthorizationErrorPage.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(AuthorizationErrorPage);
