import React from 'react';
import PropTypes from 'prop-types';

const ChannelIcon = props => {
    const { channel, className, isActive, ...others } = props;

    const colorClass = channel && channel.color ? `bg-color-${channel.color}` : 'bg-success';
    const iconClass = channel && channel.icon ? `fa-${channel.icon}` : 'fa-question';

    return (
        <div
            className={`text-white circle-icon thin bold unassigned ${className} ${isActive &&
                colorClass}`}
            {...others}
        >
            {channel.image ? (
                <img className="w-100 h-100" src={channel.image} alt="Channel logo" />
            ) : (
                <i className={`fa ${iconClass}`} />
            )}
        </div>
    );
};

export default ChannelIcon;

ChannelIcon.propTypes = {
    channel: PropTypes.object,
    className: PropTypes.string,
    isActive: PropTypes.bool,
};

ChannelIcon.defaultProps = {
    channel: null,
    className: '',
    isActive: null,
};
