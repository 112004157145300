import Echo from 'laravel-echo';
import io from 'socket.io-client';
import AuthService from './AuthService';
import definitions from '../definitions';
import eventService from './EventService';
import helpers from '../helpers';
import parameters from '../routes/parameters';

export default class SocketService {
    init() {
        if (!this.echo) {
            if (AuthService.check()) {
                this.echo = new Echo({
                    broadcaster: 'socket.io',
                    host: helpers.env('REACT_APP_SOCKET_URL'),
                    client: io,
                    auth: {
                        headers: {
                            Authorization: `Bearer ${AuthService.getToken()}`,
                        },
                    },
                });
            }
        }

        return this;
    }

    company(companyId) {
        if (!this.echo) return null;

        const id = companyId.match(parameters.slugRegex);
        if (id && id.length) {
            this.channel = this.echo.join(`company.${id[1]}`);
        } else {
            this.channel = this.echo.join(`company.${companyId}`);
        }

        this.listen();

        return this.channel;
    }

    user(userId) {
        if (!this.echo) return;

        this.userChannel = this.echo.join(`user.${userId}`);

        this.userChannel.listen(definitions.EVENT_USER_ACTIVITY, payload => {
            eventService.emit(definitions.EVENT_USER_ACTIVITY, payload);
        });
    }

    disconnectUser(userId) {
        if (!this.echo) return;
        this.echo.leaveChannel(`user.${userId}`);
    }

    listen() {
        if (!this.echo) return;
        this.listeners = [];

        definitions.events.forEach(e => {
            this.channel.listen(e, payload => {
                eventService.emit(e, payload);
            });

            this.listeners.push(e);
        });
    }

    disconnect(companyId) {
        if (!this.echo) return;
        this.listeners = [];

        if (companyId) {
            this.echo.leaveChannel(`company.${companyId}`);
        }

        this.echo.disconnect();
    }
}
