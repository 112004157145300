import React from 'react';
import PropTypes from 'prop-types';

const ManageLayoutIllustrationBL = props => {
    const { theme } = props;
    const themeColors = {
        purple: {
            lightColor: '#cc88e8',
            darkColor: '#9b59b6',
        },
        green: {
            lightColor: '#8ed7a5',
            darkColor: '#1abc9c',
        },
        orange: {
            lightColor: '#FFCB78',
            darkColor: '#D9A24A',
        },
        blue: {
            lightColor: '#25BBF3',
            darkColor: '#3596D4',
        },
        red: {
            lightColor: '#ff7878',
            darkColor: '#d94a51',
        },
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="338.948"
            height="224.743"
            viewBox="0 0 338.948 224.743"
        >
            <g transform="translate(-944.275 -460.578)">
                <g transform="translate(1234.33 615.184) rotate(30)" fill="none">
                    <path d="M28.229,0,56.458,48.392H0Z" stroke="none" />
                    <path
                        d="M 28.22880554199219 7.938529968261719 L 6.964141845703125 44.39222717285156 L 32.44142913818359 44.39222717285156 L 49.49346160888672 44.39222717285156 L 28.22880554199219 7.938529968261719 M 28.22880554199219 -7.62939453125e-06 L 56.45760726928711 48.39222717285156 L 32.44142913818359 48.39222717285156 L -3.814697265625e-06 48.39222717285156 L 28.22880554199219 -7.62939453125e-06 Z"
                        stroke="none"
                        fill={themeColors[theme].lightColor}
                    />
                </g>
                <path
                    d="M16.5,0A16.5,16.5,0,1,1,0,16.5,16.5,16.5,0,0,1,16.5,0Z"
                    transform="translate(944.275 460.578)"
                    fill={themeColors[theme].darkColor}
                />
            </g>
        </svg>
    );
};

export default ManageLayoutIllustrationBL;

ManageLayoutIllustrationBL.propTypes = {
    theme: PropTypes.string.isRequired,
};
