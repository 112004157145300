import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '../Popover';

const AutoCompleteInput = ({
    onChange,
    onSelect,
    onEnter,
    itemMap,
    suggestion,
    placeholder,
    selected,
    disabled,
}) => {
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);
    const [suggestionsLoaded, setSuggestionsLoaded] = useState(false);

    const onChangeNative = e => {
        const v = e.target.value;
        onChange(v, setItems);
        setValue(v);
    };

    const onSelectNative = item => () => {
        setItems([]);
        setValue('');
        setSuggestionsLoaded(false);
        document.activeElement.blur();
        onSelect({ ...item });
    };

    const onKeyDownNative = e => {
        if (e.key !== 'Enter') {
            return;
        }
        onEnter({
            id: undefined,
            value,
        });

        setItems([]);
        setValue('');
        setSuggestionsLoaded(false);
        document.activeElement.blur();
    };

    if (suggestion && !suggestionsLoaded && items.length === 0) {
        setSuggestionsLoaded(true);
        onChange(value, setItems);
    }

    return (
        <Popover
            label={
                <div className="input-wrapper">
                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={value || selected}
                        onChange={onChange && onChangeNative}
                        onKeyDown={onEnter && onKeyDownNative}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </div>
            }
            align="center"
        >
            {items.length ? (
                items.map(itemMap).map(x => (
                    <span
                        key={`${x.value}#${x.id}`}
                        role="button"
                        tabIndex={0}
                        onClick={onSelect && onSelectNative(x)}
                        className="outline-none"
                    >
                        {x.label}
                    </span>
                ))
            ) : (
                <div className="text-small">
                    {typeof onEnter === 'function' ? (
                        <span>
                            Press
                            <kbd
                                className="mx-1 cursor-pointer"
                                role="button"
                                tabIndex={0}
                                onClick={() => onKeyDownNative({ key: 'Enter' })}
                            >
                                Enter
                            </kbd>
                            to add: {value}
                        </span>
                    ) : (
                        <span>No items</span>
                    )}
                </div>
            )}
        </Popover>
    );
};

AutoCompleteInput.propTypes = {
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onEnter: PropTypes.func,
    itemMap: PropTypes.func,
    suggestion: PropTypes.bool,
    placeholder: PropTypes.string,
    selected: PropTypes.string,
    disabled: PropTypes.bool,
};

AutoCompleteInput.defaultProps = {
    onChange: null,
    onSelect: null,
    onEnter: null,
    itemMap: x => x,
    suggestion: false,
    placeholder: '',
    selected: '',
    disabled: false,
};

export default AutoCompleteInput;
