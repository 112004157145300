import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ManageLayout } from '../../../../layouts';
import { ManageField, ManageInput, ManageSelectbox } from '../../../../elements';
import trans from '../../../../localization';
import { Api, AuthService } from '../../../../services';
import { CarouselPane, StepCarousel } from '../../../../components';
import notify from '../../../../utils/notify';
import CancelAndSaveFooter from '../../../../components/helpdesk/carousel/CancelAndSaveFooter';

class CreateOrEditPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            isLoading: true,
            user: AuthService.user(),
            copiedIndicator: false,
            id: match.params && match.params.id,
            companies: [
                {
                    title: trans('all'),
                    value: '',
                },
            ],
            data: {},
        };

        this.tokenRef = React.createRef();
    }

    componentDidMount() {
        Promise.all([this.fetchUserToken(), this.fetchCompanies()]).finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchUserToken() {
        const { id } = this.state;
        if (id) {
            return Api.request({
                method: 'GET',
                url: `userTokens/${id}`,
            }).then(response => {
                this.setState({
                    data: response.data.data,
                });
            });
        }

        return Promise.resolve();
    }

    fetchCompanies() {
        return Api.request({
            method: 'GET',
            url: 'companies?role=admin',
        }).then(response => {
            const { companies } = this.state;

            this.setState({
                companies: [
                    ...companies,
                    ...response.data.data.map(x => ({
                        title: x.name,
                        value: x.id,
                    })),
                ],
            });
        });
    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    onChange = (field, value) => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                [field]: value,
            },
        });
    };

    create = () => {
        const { data } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'POST',
            url: 'userTokens/create',
            data,
        })
            .then(response => {
                this.setState(
                    {
                        data: response.data.data,
                    },
                    () => {
                        notify.created();
                        this.showTokenOr(() => {
                            const { history } = this.props;
                            history.goBack();
                        });
                    }
                );
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    update = () => {
        const { id, data } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'PUT',
            url: `userTokens/${id}/edit`,
            data,
        })
            .then(() => {
                notify.updated();
                const { history } = this.props;
                history.goBack();
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    regenerate = () => {
        const { id } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'PUT',
            url: `userTokens/${id}/regenerate`,
        })
            .then(response => {
                notify.success('token_regenerated_success');
                this.setState({
                    data: response.data.data,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    showTokenOr(or) {
        const { data } = this.state;
        if (!data.token) {
            or();
        }
    }

    handleCopyToClipboard = () => {
        if (this.tokenRef && this.tokenRef.current) {
            const copyText = this.tokenRef.current;
            copyText.select();
            // copyText.setSelectionRange(0, 99999); // Uncomment for mobile.
            document.execCommand('copy');
            notify.success('copied_clipboard');

            this.setState(
                {
                    copiedIndicator: true,
                },
                () => {
                    setTimeout(() => {
                        this.setState({
                            copiedIndicator: false,
                        });
                    }, 1000);
                }
            );
        }
    };

    renderForm() {
        const { id, data, companies } = this.state;

        return (
            <>
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-4"
                        scroll
                        fluid
                    >
                        <div className="d-flex flex-column pb-5">
                            <span className="font-weight-bold text-3xlarge pb-4">
                                {id ? trans('update_token') : trans('generate_token')}
                            </span>
                            <span className="font-weight-light text-darker-gray">
                                {id
                                    ? trans('update_token_text')
                                    : trans('generate_token_page_text')}
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 pt-4">
                                <ManageField title={trans('attributes.token_name')}>
                                    <ManageInput
                                        value={data.name}
                                        placeholder={trans('placeholders.token_name')}
                                        onChange={e => this.onChange('name', e.target.value)}
                                        type="text"
                                    />
                                </ManageField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 pt-4">
                                <ManageField title={trans('attributes.company')}>
                                    <ManageSelectbox
                                        value={data.company_id}
                                        items={companies}
                                        onChange={e => this.onChange('company_id', e.target.value)}
                                    />
                                </ManageField>
                            </div>
                        </div>
                        {id && (
                            <div className="row">
                                <div className="col-lg-6 pt-4">
                                    <div className="manage-field">
                                        <div className="manage-field-label ">
                                            {trans('regenerate_token')}
                                        </div>
                                        <div className="manage-field-content align-items-start flex-column">
                                            <p className="small text-muted">
                                                {trans('regenerate_token_text')}
                                            </p>

                                            <button
                                                onClick={this.regenerate}
                                                type="button"
                                                className="btn btn-light btn-lg btn-manage btn-block"
                                            >
                                                {trans('regenerate_token')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CarouselPane>
                </StepCarousel>

                <CancelAndSaveFooter
                    submitTitle={id ? trans('save_changes') : trans('create_token')}
                    onSubmit={id ? this.update : this.create}
                    onCancel={this.goBack}
                />
            </>
        );
    }

    renderToken() {
        const { data, copiedIndicator } = this.state;

        return (
            <>
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-4"
                        scroll
                        fluid
                    >
                        <div className="d-flex flex-column pb-5">
                            <span className="font-weight-bold text-3xlarge pb-4">
                                {trans('token')}
                            </span>
                            <span className="font-weight-light text-darker-gray">
                                {trans('please_copy_your_new_personal_access_token')}
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 pt-4">
                                <ManageField title={trans('attributes.token_name')}>
                                    <ManageInput value={data.name} type="text" disabled />
                                </ManageField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 pt-4">
                                <ManageField title={trans('attributes.token')}>
                                    <ManageInput
                                        value={data.token}
                                        type="text"
                                        disabled
                                        append={
                                            <div className="flex-grow-0 flex-shrink-0 w-auto">
                                                <button
                                                    onClick={this.handleCopyToClipboard}
                                                    type="button"
                                                    className="btn p-1"
                                                >
                                                    {copiedIndicator ? (
                                                        <i className="fa fas fa-check text-success" />
                                                    ) : (
                                                        <i className="fa fas fa-copy text-primary" />
                                                    )}
                                                </button>
                                                <input
                                                    type="text"
                                                    ref={this.tokenRef}
                                                    value={data.token}
                                                    readOnly
                                                    className="copyable-hidden-input"
                                                />
                                            </div>
                                        }
                                    />
                                </ManageField>
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>
                <div className="float-bottom-container d-flex justify-content-end">
                    <button
                        type="button"
                        onClick={this.goBack}
                        className="btn btn-primary btn-manage save-btn"
                    >
                        {trans('done')}
                    </button>
                </div>
            </>
        );
    }

    render() {
        const { isLoading, user, data } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="orange" user={user} hideLBImg>
                {!isLoading && (data.token ? this.renderToken() : this.renderForm())}
            </ManageLayout>
        );
    }
}

CreateOrEditPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(CreateOrEditPage);
