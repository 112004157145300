import * as moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';
import HoverTooltip from '../../HoverTooltip';

const TicketOverdue = ({ datetime, format, isOverdue, expanded }) => {
    const instance = datetime ? moment(datetime) : moment();
    const fullText = format ? instance.format(format) : instance.toISOString();

    const diff = instance.diff(new Date(), 'hours');

    let color = 'secondary';

    if (diff < 0) {
        color = 'danger';
    } else if (diff <= 1) {
        color = 'warning';
    } else {
        color = 'success';
    }

    if (expanded) {
        const text = isOverdue
            ? trans('overdue')
            : trans('due_x', {
                  diff: instance.fromNow(),
              });

        return (
            <HoverTooltip title={fullText}>
                <div className="d-flex align-items-center labeled-icon cursor-default">
                    <span className={`labeled-icon-icon ${`bg-${color} text-white`}`}>
                        <i className="fa fa-calendar-day" />
                    </span>
                    <span className={`p-2 text-${color} flex-all-1`}>{text}</span>
                </div>
            </HoverTooltip>
        );
    }

    const classNames = isOverdue
        ? `bg-white text-${color} border-1 border-radius-4 px-1`
        : 'text-dark-gray';

    const text = trans('due_x', { diff: instance.fromNow() });

    return (
        <HoverTooltip title={fullText}>
            <span className={classNames}>{isOverdue ? trans('overdue') : text}</span>
        </HoverTooltip>
    );
};

export default TicketOverdue;

TicketOverdue.propTypes = {
    datetime: PropTypes.string,
    format: PropTypes.string,
    isOverdue: PropTypes.bool,
    expanded: PropTypes.bool,
};

TicketOverdue.defaultProps = {
    datetime: null,
    format: null,
    isOverdue: false,
    expanded: false,
};
