import parameters from '../../../routes/parameters';
import ListPage from './ListPage';
import ReportPage from "./ReportPage";

export default [
    {
        path: '/companies/:companyId/statistics',
        middleware: ['auth'],
        component: ListPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/statistics/report/:report',
        middleware: ['auth'],
        component: ReportPage,
    },
    {
        path: '/companies/:companyId/statistics/report/:report',
        middleware: ['auth'],
        component: ReportPage,
    },
];
