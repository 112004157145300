import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import trans from '../../localization';
import User from './User';
import {settingImages, statistics, ticketTemplate} from '../../assets/images';
import {Api, AuthService} from '../../services';
import SettingItem from '../SettingItem';
import router from '../../utils/router';

class NavigationMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: AuthService.user(),
            resolved: 0,
        };

        this.onKeyDown = this.onKeyDown.bind(this);
        this.goToMyAccount = this.goToMyAccount.bind(this);
        this.goToStatics = this.goToStatics.bind(this)

        this.settingsRef = React.createRef(null);
    }

    componentDidMount() {
        this.fetchResolvedCount();

        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown, false);
    }

    fetchResolvedCount() {
        const { company } = this.props;

        Api.request(
            {
                method: 'GET',
                url: `companies/${company.slug}/resolvedTickets/me/today`,
            },
            true
        ).then(res => {
            this.setState({
                resolved: res.data.data,
            });
        });
    }

    onKeyDown(e) {
        if (e.key === 'Escape') {
            const { closeMenu, active } = this.props;
            if (active) {
                closeMenu();
            }
        }
    }

    goToMyAccount() {
        const { history } = this.props;
        router.to(history, '/myAccount');
    }

    slideLeft = () => {
        const scrollLeft = (this.settingsRef.current && this.settingsRef.current.scrollLeft) || 0;
        // this.settingsRef.current.scrollLeft = scrollLeft - 150;
        this.settingsRef.current.scrollTo({ left: scrollLeft - 150, top: 0, behavior: 'smooth' });
    };

    slideRight = () => {
        const scrollLeft = (this.settingsRef.current && this.settingsRef.current.scrollLeft) || 0;
        // this.settingsRef.current.scrollLeft = scrollLeft + 150;
        this.settingsRef.current.scrollTo({ left: scrollLeft + 150, top: 0, behavior: 'smooth' });
    };

    goToStatics() {
        const { history, company } = this.props;
        router.toCompany(history, company, 'statistics');
    }

    render() {
        const { isAway, active, closeMenu, company, goToSettingPage } = this.props;
        const { user, resolved } = this.state;
        const companyId = company ? router.companySlug(company) : null;
        const settingItems = settingImages.filter(x =>
            company.role !== 'admin' ? !x.adminOnly : true
        );

        return (
            <div className={cx('navigation-menu', { 'navigation-menu-active': active })}>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div className="navigation-menu-backdrop" onClick={closeMenu} />
                <div className="navigation-menu-window">
                    <div className="navigation-menu-heading">
                        <button type="button" className="navigation-menu-close" onClick={closeMenu}>
                            <i className="fa fa-times" />
                        </button>

                        <User
                            className="navigation-menu-user pointer-cursor"
                            user={user}
                            isAway={isAway}
                            companyId={companyId}
                            showStatusText
                            role={company.role}
                            onClick={this.goToMyAccount}
                            hideMyAccountLink
                        />
                    </div>

                    <div className="navigation-menu-body">
                        <PerfectScrollbar className="navigation-menu-scrollbar">
                            <div className="navigation-menu-inner">
                                <div className="navigation-menu-summary">
                                    <h1 className="navigation-menu-summary-title">
                                        {trans('hey_x', {
                                            name: user.name && user.name.split(' ')[0],
                                        })}
                                    </h1>
                                    <p className="navigation-menu-summary-subtitle">
                                        {resolved !== null
                                            ? trans('x_resolved_today_you_super_hero', {
                                                  count: resolved,
                                              })
                                            : trans('you_superhero')}
                                    </p>
                                </div>
                                <div className="navigation-menu-helpdesk">
                                    <h3 className="navigation-menu-section-title">
                                        {trans('helpdesk')}
                                    </h3>
                                    <img
                                        className="navigation-menu-helpdesk-image"
                                        src={ticketTemplate}
                                        alt="Ticket"
                                        width="100%"
                                    />
                                    <div className="navigation-menu-helpdesk-link">
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            onClick={closeMenu}
                                        >
                                            {trans('go_back_to_tickets')}
                                        </button>
                                    </div>
                                </div>
                                <div className="navigation-menu-settings-section">
                                    <h3 className="navigation-menu-section-title">
                                        {company.name} {trans('settings')}
                                    </h3>
                                    <div
                                        className={`navigation-menu-settings__container ${
                                            settingItems.length > 6
                                                ? ''
                                                : 'navigation-menu-settings__container--no-scroll'
                                        }`}
                                    >
                                        <div
                                            ref={this.settingsRef}
                                            className={`navigation-menu-settings ${
                                                settingItems.length > 6
                                                    ? ''
                                                    : 'navigation-menu-settings--no-scroll'
                                            }`}
                                        >
                                            {settingItems.map(x => (
                                                <SettingItem
                                                    name={x.name}
                                                    icon={x.image}
                                                    key={x.name}
                                                    // isComingSoon={x.notImplemented}
                                                    onClick={() => goToSettingPage(x)}
                                                />
                                            ))}
                                            {6 - settingImages.length > 0 &&
                                                Array(6 - settingImages.length)
                                                    .fill()
                                                    .map(() => (
                                                        <SettingItem
                                                            className="setting-item--empty"
                                                            key={Math.random()}
                                                        />
                                                    ))}
                                            {settingItems.length > 6 && (
                                                <>
                                                    <div className="navigation-menu-settings__blur navigation-menu-settings__blur--right" />
                                                    <div className="navigation-menu-settings__blur navigation-menu-settings__blur--left" />
                                                    <button
                                                        onClick={this.slideRight}
                                                        className="navigation-menu-settings__slide-button navigation-menu-settings__slide-button--right"
                                                        type="button"
                                                    >
                                                        <i className="fa fa-chevron-right" />
                                                    </button>
                                                    <button
                                                        onClick={this.slideLeft}
                                                        className="navigation-menu-settings__slide-button navigation-menu-settings__slide-button--left"
                                                        type="button"
                                                    >
                                                        <i className="fa fa-chevron-left" />
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {company.role === 'admin' ? (
                                    <div className="cursor-pointer navigation-menu-statistics" onClick={this.goToStatics}>
                                        <h3 className="navigation-menu-section-title">
                                            {trans('statistics')}
                                        </h3>
                                        <div className="navigation-menu-statistics-banner">
                                            <img
                                                className="navigation-menu-statistics-banner-image"
                                                src={statistics}
                                                alt="Statistics"
                                            />
                                            <div className="navigation-menu-statistics-content">
                                                <h3 className="navigation-menu-statistics-title">
                                                    {trans('helpdesk_statistics')}
                                                </h3>
                                                <p className="navigation-menu-statistics-subtitle">
                                                    {trans('helpdesk_statistics_text')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (<div />)}
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        );
    }
}

NavigationMenu.propTypes = {
    role: PropTypes.string,
    isAway: PropTypes.bool,
    closeMenu: PropTypes.func,
    goToSettingPage: PropTypes.func,
    company: PropTypes.object,
    active: PropTypes.bool,
    history: PropTypes.object.isRequired,
};

NavigationMenu.defaultProps = {
    role: null,
    isAway: false,
    closeMenu: () => {},
    goToSettingPage: () => {},
    company: null,
    active: false,
};

export default NavigationMenu;
