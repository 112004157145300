import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ManageList = props => {
    const { items, renderItem, renderFirstItem } = props;

    return (
        <PerfectScrollbar className="mt-5 manage-list-scroll">
            <div className="d-flex justify-content-center">
                <div className="manage-list d-flex align-content-between flex-wrap justify-content-start">
                    {renderFirstItem()}
                    {items.map(renderItem)}
                </div>
            </div>
        </PerfectScrollbar>
    );
};

export default ManageList;

ManageList.propTypes = {
    items: PropTypes.array,
    renderItem: PropTypes.func,
    renderFirstItem: PropTypes.func,
};

ManageList.defaultProps = {
    items: [],
    renderItem: () => {},
    renderFirstItem: () => {},
};
