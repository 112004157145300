import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Modal } from 'react-bootstrap';

const ManagePopup = props => {
    const { show, onClose, header, body, footer, className, disableScroll } = props;
    let classes = 'manage-modal p-1 overflow-hidden';

    if (className) {
        classes += ` ${className}`;
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            className={classes}
            dialogClassName="manage-modal-dialog"
        >
            {disableScroll ? (
                <>
                    {header && <Modal.Header>{header}</Modal.Header>}
                    {body && <Modal.Body>{body}</Modal.Body>}
                    {footer && <Modal.Footer>{footer}</Modal.Footer>}
                </>
            ) : (
                <PerfectScrollbar>
                    {header && <Modal.Header>{header}</Modal.Header>}
                    {body && <Modal.Body>{body}</Modal.Body>}
                    {footer && <Modal.Footer>{footer}</Modal.Footer>}
                </PerfectScrollbar>
            )}
        </Modal>
    );
};

export default ManagePopup;

ManagePopup.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    header: PropTypes.any,
    body: PropTypes.any,
    footer: PropTypes.any,
    className: PropTypes.string,
    disableScroll: PropTypes.bool,
};

ManagePopup.defaultProps = {
    show: false,
    onClose: () => {},
    header: null,
    body: null,
    footer: null,
    className: null,
    disableScroll: false,
};
