import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CarouselPane, StepCarousel } from '../../../components';
import { ManageLayout } from '../../../layouts';
import { Api, AuthService } from '../../../services';

import unverifiedIcon from '../../../assets/images/unverified.svg';
import trans from '../../../localization';
import ChannelService from '../../../services/ChannelService';
import CompanyService from '../../../services/CompanyService';
import definitions from '../../../definitions';
import notify from '../../../utils/notify';
import Routes from '../../../services/Routes';

class UnverifiedSetupEmailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            user: AuthService.user(),
            company: null,
            channel: null,
            canSendVerification: true,
        };

        this.sendVerification = this.sendVerification.bind(this);
        this.goToBack = this.goToBack.bind(this);
    }

    componentDidMount() {
        Promise.all([this.fetchCompany(), this.fetchChannel()]).finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany = () => {
        const { match } = this.props;

        return CompanyService.show(match.params.companyId).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    fetchChannel = () => {
        const { match } = this.props;

        return ChannelService.show(match.params.channelId).then(response => {
            this.setState({
                channel: response.data.data,
            });
        });
    };

    canSee() {
        const { channel } = this.state;
        if (!channel) {
            return true;
        }

        if (channel.type !== definitions.PROVIDER_EMAIL) {
            return false;
        }

        if (!channel.provider || !channel.provider.forward_email) {
            return false;
        }

        // noinspection JSUnresolvedVariable
        if (channel.provider.forward_email_verified) {
            return false;
        }

        return true;
    }

    sendVerification() {
        const { channel } = this.state;

        this.setState(
            {
                isLoading: true,
                canSendVerification: false,
            },
            () => {
                Api.request({
                    method: 'POST',
                    url: `channels/${channel.id}/email/resend`,
                })
                    .then(() => {
                        notify.success('sent_new_mail');
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            }
        );
    }

    goToBack() {
        const { history } = this.props;
        const { company, channel } = this.state;

        Routes.toEditChannel(history, company, channel);
    }

    render() {
        const { user, isLoading, company, channel, canSendVerification } = this.state;
        const { history } = this.props;

        if (!this.canSee()) {
            history.goBack();
            return null;
        }

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane onPrev={this.goToBack} scroll middle center>
                        {channel && (
                            <div className="text-center">
                                <div className="verification-sign">
                                    <i className={`fa fas fa-${channel.icon}`} />
                                </div>
                                <div className="text-light-gray text-medium mt-4 d-inline-flex align-items-center">
                                    <img src={unverifiedIcon} alt="Unverified" className="mr-1" />
                                    {trans('unverified')}
                                </div>

                                {channel.provider && (
                                    <div className="mt-2">
                                        <h4 className="m-0">
                                            <strong>{channel.name}</strong>
                                        </h4>
                                        <div className="mt-4">
                                            <div>{trans('we_sent_a_verification_email_to')}</div>
                                            <div className="font-weight-bold">
                                                {channel.provider.forward_email}
                                            </div>
                                        </div>
                                        {canSendVerification && (
                                            <div className="mt-4">
                                                <button
                                                    className="btn btn-link"
                                                    type="button"
                                                    onClick={this.sendVerification}
                                                >
                                                    {trans('i_didnt_receive_an_email')}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(UnverifiedSetupEmailPage);

UnverifiedSetupEmailPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
