import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Popover } from '../index';
import trans from '../../localization';
import definitions from '../../definitions';

const SortMenu = ({ items, sort, order, align, onSortChange, onOrderChange }) => {
    const selected = items.find(x => x.value === sort);

    return (
        <Popover
            align={align}
            label={
                <div className="text-small font-weight-light">
                    {/* trans('sort_by') */}
                    <div className="ml-1 text-primary d-inline">
                        <i className={`mr-1 fad fa-sort-${order === 'asc' ? 'down' : 'up'}`} />
                        {selected && `${selected.label}`}
                    </div>
                </div>
            }
        >
            <div role="list" className="manage-sort-options">
                {items.map(item => (
                    <a
                        className={cx('manage-sort-options-item', {
                            'is-active': item.value === sort,
                        })}
                        href={`#item-${item.value}`}
                        key={item.value}
                        role="button"
                        tabIndex={-1}
                        onClick={
                            typeof onSortChange === 'function' && (() => onSortChange(item.value))
                        }
                    >
                        {item.label}
                        {item.value === sort && <i className="ml-2 fa fas fa-check text-xsmall" />}
                    </a>
                ))}
            </div>

            <div className="manage-order-options">
                <a
                    href="#order-desc"
                    role="button"
                    tabIndex={-1}
                    className={cx('manage-order-options-item', {
                        'is-active': order === definitions.ORDER_BY_TYPES.DESC,
                    })}
                    onClick={
                        typeof onOrderChange === 'function' &&
                        (() => onOrderChange(definitions.ORDER_BY_TYPES.DESC))
                    }
                >
                    {trans('descending')}
                    {order === definitions.ORDER_BY_TYPES.DESC && (
                        <i className="ml-2 fa fas fa-check text-xsmall" />
                    )}
                </a>
                <a
                    href="#order-asc"
                    role="button"
                    tabIndex={-1}
                    className={cx('manage-order-options-item', {
                        'is-active': order === definitions.ORDER_BY_TYPES.ASC,
                    })}
                    onClick={
                        typeof onOrderChange === 'function' &&
                        (() => onOrderChange(definitions.ORDER_BY_TYPES.ASC))
                    }
                >
                    {trans('ascending')}
                    {order === definitions.ORDER_BY_TYPES.ASC && (
                        <i className="ml-2 fa fas fa-check text-xsmall" />
                    )}
                </a>
            </div>
        </Popover>
    );
};

SortMenu.propTypes = {
    items: PropTypes.array,
    align: PropTypes.string,
    sort: PropTypes.any,
    order: PropTypes.any,
    onOrderChange: PropTypes.func,
    onSortChange: PropTypes.func,
};

SortMenu.defaultProps = {
    items: [],
    sort: null,
    order: null,
    align: 'left',
    onOrderChange: null,
    onSortChange: null,
};

export default SortMenu;
