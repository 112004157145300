import React from 'react';
import PropTypes from 'prop-types';

const ManageSelectbox = ({ selected, items, color, className, ...rest }) => {
    return (
        <div className={`d-flex ${className}`}>
            <div className="manage-selectbox-wrapper">
                <select
                    className={`manage-selectbox ${color ? `manage-selectbox--${color}` : ''}`}
                    {...rest}
                >
                    {items.map(x => (
                        <option
                            key={`role-${items.indexOf(x)}-${x.value}`}
                            value={x.value}
                            selected={selected && selected.value === x.value}
                        >
                            {x.title}
                        </option>
                    ))}
                </select>
                {!rest.disabled && (
                    <span
                        className={`select-icon ${
                            color ? 'select-icon--light' : ''
                        } d-flex align-items-center`}
                    >
                        <i className="fas fa-chevron-down" />
                    </span>
                )}
            </div>
        </div>
    );
};

ManageSelectbox.propTypes = {
    items: PropTypes.array,
    className: PropTypes.string,
    selected: PropTypes.any,
    color: PropTypes.string,
};

ManageSelectbox.defaultProps = {
    items: [],
    className: '',
    selected: null,
    color: null,
};

export default ManageSelectbox;
