import React, { createRef, useEffect } from 'react';

const Datepicker = props => {
    const datepicker = createRef();

    useEffect(() => {
        const { current } = datepicker;

        window.$(current).datepicker(props);

        return () => {
            window.$(current).datepicker('destroy');
        };
    });

    return <input type="text" ref={datepicker} readOnly />;
};

export default Datepicker;
