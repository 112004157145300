import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const IconPreview = ({ icon, color, className, large, ...rest }) => (
    <div className={cx(`icon-preview bg-color-${color}`, className, { large })} {...rest}>
        <i className={`icon-preview-icon text-white fa fa-fw fas fa-${icon}`} />
    </div>
);

IconPreview.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.any,
    large: PropTypes.bool,
};

IconPreview.defaultProps = {
    icon: '',
    color: '',
    className: null,
    large: false,
};

export default IconPreview;
