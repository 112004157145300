import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Api, ApiService } from '../../services';
import { SignUpLink, AuthCardView } from '../../components/auth';
import { FormButton, FormInput } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';
import { successEmail } from '../../assets/images';

class RecoverPasswordPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: {
                email: '',
            },
            errors: {},
            isSending: false,
            sentEmail: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        const { isSending, data } = this.state;

        if (isSending) {
            return;
        }

        this.setState({
            isSending: true,
        });

        Api.request(
            {
                method: 'POST',
                url: 'auth/resetPassword/email',
                data,
            },
            true
        )
            .then(() => {
                this.setState({
                    sentEmail: true,
                });
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                });
            })
            .finally(() => {
                this.setState({
                    isSending: false,
                });
            });
    }

    onChange(event) {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    }

    renderForm() {
        const { isSending, errors, data } = this.state;
        const { email } = data;

        return (
            <div className="d-flex flex-column h-100">
                <form onSubmit={this.onSubmit}>
                    <FormInput
                        labelText={trans('attributes.email')}
                        validationError={errors.email}
                        onChange={this.onChange}
                        value={email}
                        type="email"
                        name="email"
                        placeholder={trans('placeholders.email')}
                    />
                    <FormButton
                        className="mt-lg-4 text-uppercase"
                        isBlock
                        text={trans('recover_my_account')}
                        disabled={isSending}
                        type="submit"
                    />
                </form>
                <SignUpLink />
            </div>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    renderNotify() {
        const { data } = this.state;
        return (
            <div className="auth-email-sent text-center pt-5">
                <img
                    src={successEmail}
                    alt={trans('email_sent')}
                    className="auth-email-sent-image img-fluid mb-5 mt-2"
                />

                <h2 className="auth-email-sent-text text-center font-weight-bold">
                    {trans('email_sent')}
                </h2>
                <div className="auth-email-sent-message">
                    <p className="my-4 pt-1 text-center text-dc-light-gray">
                        {trans('if_x_exists_we_have_sent_an_email_to_reset_your_password', data)}
                    </p>
                    <Link to="/auth/signIn" className="text-medium text-lighter-blue">
                        {trans('back_to_login')}
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        const { isSending, sentEmail } = this.state;

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView
                    title={trans('well_who_is_this')}
                    subtitle={trans('recover_password_subtitle')}
                    titleSmall={trans('recover_my_account')}
                    titlePreLink="←"
                    titlePreLinkTo="/auth/signIn"
                    centerHeading
                >
                    {sentEmail ? this.renderNotify() : this.renderForm()}
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(RecoverPasswordPage);
