import ListPage from './ListPage';
import DespatchCloudEditPage from './DespatchCloudEditPage';
import parameters from '../../../routes/parameters';

export default [
    {
        path: '/companies/:companyId/integrations/:providerType/edit',
        middleware: ['auth'],
        component: DespatchCloudEditPage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/integrations',
        middleware: ['auth'],
        component: ListPage,
        ...parameters.companyIdParameters,
    },
];
