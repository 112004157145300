import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const StatusList = props => {
    const { items, footer, activeStatus, onClick } = props;
    return (
        <div className="d-flex flex-column align-items-start p-1">
            {items.map(x => (
                // eslint-disable-next-line
                <div
                    key={`quickrespond-status-${x.value}`}
                    role="button"
                    onClick={() => onClick(x)}
                    className={cx(
                        `status option ticket-status-option-${x.value} py-1 cursor-pointer`,
                        { 'active font-weight-bold': activeStatus === x.value }
                    )}
                >
                    {x.title}
                </div>
            ))}
            <span className="text-3xsmall font-weight-light text-light-gray align-self-end px-2">
                {footer}
            </span>
        </div>
    );
};

export default StatusList;

StatusList.propTypes = {
    items: PropTypes.array,
    footer: PropTypes.string,
    activeStatus: PropTypes.string,
    onClick: PropTypes.func,
};

StatusList.defaultProps = {
    items: [],
    footer: '',
    activeStatus: '',
    onClick: () => {},
};
