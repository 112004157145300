import * as queryString from 'query-string';
import definitions from './definitions';
import trans from './localization';

const toShortName = name => {
    const splits = name.split(' ');
    if (splits.length >= 2) {
        const firstLetter = splits[0].substr(0, 1).toUpperCase();
        const lastIndex = splits.length - 1;
        const secondLetter = splits[lastIndex].substr(0, 1).toUpperCase();

        return `${firstLetter}${secondLetter}`;
    }

    return name.substr(0, 2).toUpperCase();
};

const toFlatArray = (data, recursive = false) => {
    if (!data) return [];

    const flatMap = x => {
        if (x instanceof Array) {
            return x.flatMap(flatMap);
        }
        return x;
    };

    if (data instanceof Object) {
        const messyArray = Object.keys(data).map(x => data[x]);
        if (recursive) return messyArray.flatMap(flatMap);
        return messyArray.flat();
    }

    if (data instanceof Array) {
        if (recursive) return data.flatMap(flatMap);
        return data.flat();
    }

    return [];
};

const parseSearch = location => {
    if (location && location.search) {
        return queryString.parse(location.search);
    }

    return {};
};

const getStatuses = key => {
    if (key) {
        return definitions.statuses.indexOf(key) > -1
            ? {
                  value: key,
                  title: trans(`statuses.${key}`),
              }
            : null;
    }

    // eslint-disable-next-line no-undef
    return window._.map(definitions.statuses, status => {
        return {
            value: status,
            title: trans(`statuses.${status}`),
        };
    });
};

const getPriorities = key => {
    if (key) {
        return definitions.priorities.indexOf(key) > -1
            ? {
                  value: key,
                  title: trans(`priorities.${key}`),
              }
            : null;
    }

    // eslint-disable-next-line no-undef
    return window._.map(definitions.priorities, priority => {
        return {
            value: priority,
            title: trans(`priorities.${priority}`),
        };
    });
};

const getSortTypes = key => {
    if (key) {
        return definitions.sortTypes.indexOf(key) > -1
            ? {
                  value: key,
                  title: trans(key),
              }
            : null;
    }

    // eslint-disable-next-line no-undef
    return window._.map(definitions.sortTypes, sortType => {
        return {
            value: sortType,
            title: trans(sortType),
        };
    });
};

const getRoles = key => {
    if (key) {
        return definitions.roles.indexOf(key) > -1
            ? {
                  value: key,
                  title: trans(key),
              }
            : null;
    }

    // eslint-disable-next-line no-undef
    return window._.map(definitions.roles, role => {
        return {
            value: role,
            title: trans(role),
        };
    });
};

const getRules = () => {
    return Object.keys(definitions.rules)
        .map(section => {
            return {
                [section]: definitions.rules[section].map(x => ({
                    value: x,
                    title: trans(`${section}.${x}`),
                })),
            };
        })
        .reduce((acc, cur) => ({ ...acc, ...cur }), {});
};

const getIntervals = key => {
    if (key) {
        return definitions.intervals.indexOf(key) > -1
            ? {
                  value: key,
                  title: trans(`intervals.${key}`),
              }
            : null;
    }

    // eslint-disable-next-line no-undef
    return window._.map(definitions.intervals, interval => {
        return {
            value: interval,
            title: trans(`intervals.${interval}`),
        };
    });
};

const getOperationalHours = key => {
    if (key) {
        return definitions.operationalHours.indexOf(key) > -1
            ? {
                  value: key,
                  title: trans(`operational_hours_types.${key}`),
              }
            : null;
    }

    // eslint-disable-next-line no-undef
    return window._.map(definitions.operationalHours, operationalHour => {
        return {
            value: operationalHour,
            title: trans(`operational_hours_types.${operationalHour}`),
        };
    });
};

const env = (name, value) => {
    // noinspection ES6ModulesDependencies
    if (process.env[name]) {
        // noinspection ES6ModulesDependencies
        return process.env[name];
    }

    return value;
};

const environment = name => {
    const current = env(definitions.ENVIRONMENT_APP_URL, 'local');
    return name ? name === current : current;
};

const isLocal = () => {
    return environment(definitions.LOCAL_ENVIRONMENT);
};

const isDev = () => {
    return environment(definitions.DEVELOPMENT_ENVIRONMENT);
};

const isProduction = () => {
    return environment(definitions.PRODUCTION_ENVIRONMENT);
};

const isEmpty = obj => {
    return window._.isEmpty(obj);
};

const isNotEmpty = obj => {
    return !isEmpty(obj);
};

const parseBoolean = value => {
    if (typeof value === 'boolean') {
        return value;
    }

    if (typeof value === 'number') {
        return value > 0;
    }

    return !(value === '0' || value === 'false' || value === 'FALSE');
};

const isLarge = () => {
    return window.innerWidth >= definitions.BREAKPOINTS.lg;
};

const isXLarge = () => {
    return window.innerWidth >= definitions.BREAKPOINTS.xl;
};

const isXLargeForTicket = () => {
    return window.innerWidth >= 1600;
};

const isLargeForTicket = () => {
    return window.innerWidth >= 1450;
};

const providerName = channel => {
    // noinspection JSUnresolvedVariable
    return channel.is_amazon ? trans('providers.amazon') : trans(`providers.${channel.type}`);
};

const isEmail = email => {
    return new RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email);
};

export default {
    toShortName,
    parseSearch,
    getStatuses,
    getPriorities,
    getSortTypes,
    getRoles,
    getIntervals,
    getOperationalHours,
    env,
    isLocal,
    isDev,
    isProduction,
    isEmpty,
    isNotEmpty,
    toFlatArray,
    parseBoolean,
    isLarge,
    isXLarge,
    isLargeForTicket,
    isXLargeForTicket,
    providerName,
    isEmail,
    getRules,
};
