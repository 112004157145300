import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';
import UserIcon from '../UserIcon';
import { DateTime, HoverTooltip } from '../../index';

const Audit = ({ message }) => {
    if (!message.audit) return null;

    const { old: oldValue, new: newValue, attribute, type, date } = message.audit;

    let transKey;
    let shouldLocalizeValues = false;

    switch (type) {
        case 'updated':
            transKey = 'ticket_attribute_changed_from_y_to_z';
            shouldLocalizeValues = true;
            break;
        case 'deleted':
            if (attribute === 'users') {
                transKey = 'user_x_unassigned_to_ticket';
            }
            break;
        case 'created':
            if (attribute === 'users') {
                transKey = 'user_x_assigned_to_ticket';
            }
            break;
        default:
            break;
    }

    if (!transKey) return null;

    const getLocalKey = value => {
        let localGroupKey;

        switch (attribute) {
            case 'priority':
                localGroupKey = 'priorities';
                break;
            case 'status':
                localGroupKey = 'statuses';
                break;
            default:
                break;
        }

        if (localGroupKey) {
            return `${localGroupKey}.${value}`;
        }

        return value;
    };

    const localizedMessage = trans(transKey, {
        attribute: trans(`attributes.${attribute}`),
        new: newValue && shouldLocalizeValues ? trans(getLocalKey(newValue)) : newValue || '-',
        old: oldValue && shouldLocalizeValues ? trans(getLocalKey(oldValue)) : oldValue || '-',
    });

    return (
        <div className="message-audit">
            <div className="message-audit-user-icon">
                <HoverTooltip title={message.user && message.user.name}>
                    <UserIcon user={message.user} />
                </HoverTooltip>
            </div>
            <div className="message-audit-message">
                <div className="text-off-black text-small">{localizedMessage}</div>
                <div className="message-audit-message-date">
                    <DateTime datetime={date} /> - <DateTime datetime={date} diff />
                </div>
            </div>
        </div>
    );
};

export default Audit;

Audit.propTypes = {
    message: PropTypes.object.isRequired,
};
