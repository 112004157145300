import React, { Component } from 'react';
import cx from 'classnames';
import eventService from '../services/EventService';
import trans from '../localization';

export default class Dialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            title: '',
            content: '',
            buttons: [],
            cancelable: true,
            onCancel: () => {},
        };

        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        eventService.on(
            'dialog',
            ({ title, content, buttons = [], cancelable = true, onCancel, close = false }) => {
                this.setState({
                    show: !close,
                    title,
                    content,
                    buttons,
                    cancelable,
                    onCancel,
                });
            }
        );
    }

    componentWillUnmount() {
        eventService.removeListener('dialog');
    }

    close() {
        this.setState({
            show: false,
        });
    }

    cancel() {
        const { onCancel, cancelable } = this.state;

        if (!cancelable) {
            return;
        }

        if (onCancel instanceof Function) {
            onCancel();
        }

        this.close();
    }

    onClick(click) {
        if (click instanceof Function) {
            click();
        }

        this.close();
    }

    render() {
        const { show, title, content, buttons, cancelable } = this.state;

        if (!show) {
            return null;
        }

        return (
            <div className="popup-dialog">
                <div
                    id="close"
                    className="popup-dialog-overlay"
                    role="button"
                    tabIndex="0"
                    onClick={this.cancel}
                >
                    <label htmlFor="close" hidden={!cancelable}>
                        {trans('close')} <i className="fa fa-times" />
                    </label>
                </div>
                <div className="popup-dialog-content d-flex flex-column text-dark">
                    <div className="font-weight-bold pt-2">{title}</div>
                    <div className="flex-grow-1 d-flex align-items-center py-4">{content}</div>
                    <div className="d-flex justify-content-stretch pt-2">
                        {buttons.map((x, index) => (
                            <button
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                type="button"
                                onClick={() => this.onClick(x.click)}
                                className={cx(
                                    'flex-grow-1 btn btn-primary mx-2 border-radius-25 px-4 btn-sm',
                                    x.classNames
                                )}
                            >
                                {x.slot}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
