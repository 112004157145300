import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../../../services';
import { ManageLayout } from '../../../../layouts';
import { CarouselPane, StepCarousel } from '../../../../components';
import trans from '../../../../localization';
import CheckboxBorderContainer from '../../../../components/helpdesk/CheckboxBorderContainer';
import definitions from '../../../../definitions';

class ListPage extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;

        this.state = {
            companyId: location.state && location.state.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            cannedResponses: {
                user: [],
                company: [],
            },
            selectedResponseIds: {
                user: [],
                company: [],
            },
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        if (!companyId) return this.fetchCannedResponses();

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState(
                {
                    company: response.data.data,
                },
                this.fetchCannedResponses
            );
        });
    }

    fetchCannedResponses() {
        const { company } = this.state;
        const url = company ? `cannedResponses?company_id=${company.id}` : 'cannedResponses';

        return Api.request({
            method: 'GET',
            url,
        }).then(response => {
            this.setState({
                cannedResponses: response.data.data,
            });
        });
    }

    deleteCannedResponses = type => {
        const { selectedResponseIds, cannedResponses } = this.state;

        const setStateSuccess = () => {
            this.setState({
                selectedResponseIds: {
                    ...selectedResponseIds,
                    [type]: [],
                },
                cannedResponses: {
                    ...cannedResponses,
                    [type]: cannedResponses[type].filter(
                        x => selectedResponseIds[type].indexOf(x.id) < 0
                    ),
                },
                isLoading: false,
            });
        };

        const setLoading = loading => {
            this.setState({
                isLoading: loading,
            });
        };

        setLoading(true);

        Api.request({
            method: 'DELETE',
            url: 'cannedResponses/bulk/delete',
            data: {
                ids: selectedResponseIds[type],
            },
            header: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {
                if (response.data.data === true) {
                    setStateSuccess();
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    onCreateClick = type => {
        const { history } = this.props;
        const { company } = this.state;

        history.push('/myAccount/cannedResponses/create', {
            companyId: company && company.slug,
            responseType: type === 'company' ? company && company.id : '',
        });
    };

    onResponseClick = response => {
        const { history } = this.props;
        const { company } = this.state;

        history.push(`/myAccount/cannedResponses/${response.id}/edit`, {
            companyId: company && company.slug,
            responseType: response.type === 'company' ? company && company.id : '',
            data: { ...response, source_id: (response.channel && response.channel.id) || '' },
        });
    };

    goBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    onSelectResponse = (type, responseId) => {
        const { selectedResponseIds } = this.state;

        const index = selectedResponseIds[type].indexOf(responseId);

        if (index < 0) {
            selectedResponseIds[type].push(responseId);
        } else {
            selectedResponseIds[type].splice(index, 1);
        }

        this.setState({
            selectedResponseIds,
        });
    };

    renderCannedResponses = type => {
        const { cannedResponses, selectedResponseIds, company } = this.state;

        return (
            <div className="d-flex flex-column pt-5">
                <div className="pb-4 d-flex justify-content-space-between align-items-center flex-wrap">
                    <span className="font-weight-bold text-large">
                        {trans(`${type}_responses`) ||
                            trans('x_responses', { name: company && company.name })}
                    </span>
                    {selectedResponseIds[type].length > 0 && (
                        <button
                            onClick={() => this.deleteCannedResponses(type)}
                            type="button"
                            className="btn p-0 text-red text-xsmall ml-auto no-outline-text-btn"
                        >
                            <i className="fa fa-trash pr-1" />
                            {trans('i_want_to_delete_x_responses.beforeX')}
                            <div className="text-white bg-red font-weight-bold px-1 manage-badge">
                                {selectedResponseIds[type].length}
                            </div>
                            {trans('i_want_to_delete_x_responses.afterX')}
                        </button>
                    )}
                </div>
                <div className="d-flex flex-wrap">
                    <div
                        role="button"
                        tabIndex="0"
                        onClick={() => this.onCreateClick(type)}
                        className="pr-3 pb-4"
                        key="create-canned-response"
                    >
                        <div className="checkbox-border-container dashed-db-border align-items-center">
                            <span className="text-light-gray">{trans('new_response')}</span>
                        </div>
                    </div>
                    {cannedResponses[type].map(x => (
                        <div className="pr-3 pb-4" key={`canned-response-${type}-${x.id}`}>
                            <CheckboxBorderContainer
                                id={`${type}-${x.id}`}
                                onSelect={() => this.onSelectResponse(type, x.id)}
                                onClick={() => this.onResponseClick(x)}
                            >
                                <span className="font-weight-bold text-small text-dark">
                                    {x.title}
                                </span>
                                <span className="text-2xsmall text-offblack">{x.description}</span>
                            </CheckboxBorderContainer>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    render() {
        const { isLoading, user, company } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="orange"
                user={user}
                company={company}
                hideLBImg
            >
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-4"
                        scroll
                        fluid
                    >
                        <div className="d-flex flex-column pb-5">
                            <span className="font-weight-bold text-3xlarge pb-4">
                                {trans('canned_responses')}
                            </span>
                            <span className="font-weight-light text-darker-gray">
                                {trans('canned_responses_text')}
                            </span>
                        </div>
                        <div className="d-flex flex-column">
                            {!!company &&
                                company.role === definitions.ROLE_ADMIN &&
                                this.renderCannedResponses('company')}
                            {this.renderCannedResponses('user')}
                        </div>
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

ListPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(ListPage);
