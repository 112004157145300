import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import ManageList from '../../../components/helpdesk/list/ManageList';
import ManageListItem from '../../../components/helpdesk/list/ManageListItem';
import trans from '../../../localization';
import ManagePopup from '../../../components/helpdesk/ManagePopup';
import EditAgentModal from '../../../components/helpdesk/EditAgentModal';
import { CarouselPane, StepCarousel } from '../../../components';
import definitions from '../../../definitions';
import HomePage from '../HomePage';
import router from '../../../utils/router';
import dialog from '../../../utils/dialog';

class ListPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            agents: [],
            invitations: [],
            showEditAgentModal: false,
            openedUser: null,
            openedUserPermissions: [],
            listType: definitions.AGENT_LIST_TYPE_ACTIVE,
        };

        this.saveUser = this.saveUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.fetchOpenedUserPermissions = this.fetchOpenedUserPermissions.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchAgents(), this.fetchInvitations()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    onCreateClick() {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company, 'agents/create');
    }

    fetchOpenedUserPermissions() {
        const { openedUser } = this.state;

        if (!openedUser) return;

        this.setState(
            {
                isLoading: true,
            },
            () => {
                Api.request({
                    method: 'GET',
                    url: `permissions/${openedUser.role}`,
                }).then(response => {
                    let permissions = Object.keys(response.data.data);
                    permissions = permissions.map(x => ({
                        name: x,
                        value: response.data.data[x],
                    }));
                    this.setState({
                        isLoading: false,
                        openedUserPermissions: permissions,
                    });
                });
            }
        );
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchAgents() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}/agents`,
        }).then(response => {
            this.setState({
                agents: response.data.data,
            });
        });
    }

    fetchInvitations() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}/agents/invitations`,
        }).then(response => {
            this.setState({
                invitations: response.data.data,
            });
        });
    }

    toggleEditAgentModal(user) {
        if (user) {
            this.setState(
                {
                    openedUser: { ...user },
                    showEditAgentModal: true,
                },
                this.fetchOpenedUserPermissions
            );
        } else {
            this.setState({
                openedUser: null,
                showEditAgentModal: false,
            });
        }
    }

    saveUser(user) {
        const { company, agents } = this.state;
        this.setState(
            {
                isLoading: true,
            },
            () => {
                Api.request({
                    method: 'POST',
                    url: `companies/${company.slug}/agents/${user.id}`,
                    data: { role: user.role },
                })
                    .then(response => {
                        const updatedUser = response.data.data;
                        const oldUser = agents.find(x => x.id === updatedUser.id);

                        if (oldUser !== undefined) {
                            const index = agents.indexOf(oldUser);
                            if (index > -1) {
                                agents.splice(index, 1, updatedUser);
                                this.setState({
                                    agents,
                                    openedUser: null,
                                    showEditAgentModal: false,
                                    isLoading: false,
                                });
                            }
                        }
                    })
                    .catch(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            }
        );
    }

    deleteUser(user) {
        const agent = `${trans('agent').toLocaleLowerCase()} ${user.name}`;
        dialog.deleteModelConfirm(agent).then(() => {
            this.setState({
                isLoading: true,
            });

            const { company, agents } = this.state;
            Api.request({
                method: 'DELETE',
                url: `companies/${company.slug}/agents/${user.id}`,
            })
                .then(response => {
                    if (response.data.data === true) {
                        const oldUser = agents.find(x => x.id === user.id);

                        if (oldUser !== undefined) {
                            const index = agents.indexOf(oldUser);
                            if (index > -1) {
                                agents.splice(index, 1);
                                this.setState({
                                    agents,
                                    showEditAgentModal: false,
                                    openedUser: null,
                                    isLoading: false,
                                });
                            }
                        }
                    }
                })
                .catch(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        });
    }

    goBack() {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company);
    }

    resendInvitation = invitation => {
        this.setState({
            isLoading: true,
        });

        const { companyId } = this.state;

        return Api.request({
            method: 'POST',
            url: `companies/${companyId}/agents/invitations/${invitation.id}/resend`,
        })
            .then(response => {
                const { invitations } = this.state;
                invitations.splice(0, 0, response.data.data);
                this.setState({
                    invitations,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    revokeInvitation = invitation => {
        this.setState({
            isLoading: true,
        });

        const { companyId } = this.state;

        return Api.request({
            method: 'DELETE',
            url: `companies/${companyId}/agents/invitations/${invitation.id}/revoke`,
        })
            .then(() => {
                this.fetchInvitations();
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    renderActiveList() {
        const { agents } = this.state;

        return (
            <ManageList
                items={agents}
                renderItem={x => (
                    <ManageListItem
                        key={`agent-${x.id}`}
                        item={x}
                        type="agent"
                        onClick={() => this.toggleEditAgentModal(x)}
                        cogMenu={[
                            {
                                title: trans('edit'),
                                onClick: () => this.toggleEditAgentModal(x),
                            },
                        ]}
                    />
                )}
                renderFirstItem={() => (
                    <ManageListItem
                        key="add-agent"
                        type="agent"
                        onClick={() => this.onCreateClick()}
                    />
                )}
            />
        );
    }

    renderPendingList() {
        const { invitations } = this.state;

        if (invitations.length) {
            return (
                <ManageList
                    items={invitations}
                    renderItem={x => (
                        <ManageListItem
                            key={`invitation-${x.id}`}
                            item={x}
                            type="invitation"
                            cogMenu={[
                                {
                                    title: trans('resend'),
                                    onClick: () => this.resendInvitation(x),
                                },
                                {
                                    title: trans('revoke'),
                                    onClick: () => this.revokeInvitation(x),
                                },
                            ]}
                        />
                    )}
                />
            );
        }

        return <div className="mt-5 pt-3">{HomePage.renderEmptyMessage()}</div>;
    }

    render() {
        const {
            isLoading,
            user,
            company,
            showEditAgentModal,
            openedUser,
            openedUserPermissions,
            listType,
        } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="purple" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane onPrev={this.goBack} scroll center middle>
                        <div className="row">
                            <div className="col d-flex flex-column align-items-center justify-content-center">
                                <div className="manage-heading">
                                    <h1 className="title text-center">{trans('agents')}</h1>
                                    <p className="subtitle">{trans('agents_list_text')}</p>
                                </div>
                                <div className="d-flex justify-content-center w-100">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-list-type">
                                            {trans(listType)}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                href={`#/${definitions.AGENT_LIST_TYPE_ACTIVE}`}
                                                onClick={() =>
                                                    this.setState({
                                                        listType:
                                                            definitions.AGENT_LIST_TYPE_ACTIVE,
                                                    })
                                                }
                                            >
                                                {trans(definitions.AGENT_LIST_TYPE_ACTIVE)}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href={`#/${definitions.AGENT_LIST_TYPE_PENDING}`}
                                                onClick={() =>
                                                    this.setState({
                                                        listType:
                                                            definitions.AGENT_LIST_TYPE_PENDING,
                                                    })
                                                }
                                            >
                                                {trans(definitions.AGENT_LIST_TYPE_PENDING)}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                {listType === definitions.AGENT_LIST_TYPE_ACTIVE
                                    ? this.renderActiveList()
                                    : this.renderPendingList()}
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>
                {showEditAgentModal && (
                    <ManagePopup
                        show={showEditAgentModal}
                        onClose={() => this.toggleEditAgentModal()}
                        body={
                            <EditAgentModal
                                user={openedUser}
                                myUser={user}
                                onClose={() => this.toggleEditAgentModal()}
                                onSaveChanges={this.saveUser}
                                onUnlinkUser={this.deleteUser}
                                permissions={openedUserPermissions}
                                refreshPermissions={this.fetchOpenedUserPermissions}
                            />
                        }
                        className="notch-header-modal"
                        disableScroll
                    />
                )}
            </ManageLayout>
        );
    }
}

ListPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(ListPage);
