import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../../../services';
import { ManageLayout } from '../../../../layouts';
import { CarouselPane, DateTime, StepCarousel } from '../../../../components';
import trans from '../../../../localization';
import CheckboxBorderContainer from '../../../../components/helpdesk/CheckboxBorderContainer';
import notify from '../../../../utils/notify';

class ListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: AuthService.user(),
            isLoading: false,
            selectedIds: [],
            userTokens: [],
        };
    }

    componentDidMount() {
        this.fetchUserTokens();
    }

    fetchUserTokens() {
        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'GET',
            url: 'userTokens',
        })
            .then(response => {
                this.setState({
                    userTokens: response.data.data,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    deleteUserTokens = () => {
        const { selectedIds } = this.state;

        this.setState({
            isLoading: true,
        });

        Api.request({
            method: 'DELETE',
            url: 'userTokens/bulk/delete',
            data: {
                ids: selectedIds,
            },
        })
            .then(() => {
                notify.deleted();
                this.setState(
                    {
                        selectedIds: [],
                    },
                    () => {
                        this.fetchUserTokens();
                    }
                );
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    onCreateClick = () => {
        const { history } = this.props;
        history.push('/myAccount/userTokens/create');
    };

    onEditClick = response => {
        const { history } = this.props;
        history.push(`/myAccount/userTokens/${response.id}/edit`);
    };

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    onSelect = id => {
        const { selectedIds } = this.state;
        const index = selectedIds.indexOf(id);

        if (index < 0) {
            selectedIds.push(id);
        } else {
            selectedIds.splice(index, 1);
        }

        this.setState({
            selectedIds,
        });
    };

    renderUserTokens = () => {
        const { userTokens, selectedIds } = this.state;

        // noinspection JSUnresolvedVariable
        return (
            <div className="d-flex flex-column pt-5">
                <div className="pb-4 d-flex justify-content-space-between align-items-center flex-wrap">
                    <span className="font-weight-bold text-large">
                        {trans('personal_access_tokens')}
                    </span>
                    {selectedIds.length > 0 && (
                        <button
                            onClick={() => this.deleteUserTokens()}
                            type="button"
                            className="btn p-0 text-red text-xsmall ml-auto no-outline-text-btn"
                        >
                            <i className="fa fa-trash pr-1" />
                            {trans('i_want_to_delete_x_token', {
                                count: (
                                    <div className="text-white bg-red font-weight-bold px-1 manage-badge">
                                        {selectedIds.length}
                                    </div>
                                ),
                            })}
                        </button>
                    )}
                </div>
                <div className="d-flex flex-wrap">
                    <div
                        role="button"
                        tabIndex="0"
                        onClick={() => this.onCreateClick()}
                        className="pr-3 pb-4"
                        key="create-user-token"
                    >
                        <div className="checkbox-border-container dashed-db-border align-items-center">
                            <span className="text-light-gray">{trans('generate_new_token')}</span>
                        </div>
                    </div>
                    {userTokens.map(x => (
                        <div className="pr-3 pb-4" key={`user-token-${x.id}`}>
                            <CheckboxBorderContainer
                                id={x.id}
                                onSelect={() => this.onSelect(x.id)}
                                onClick={() => this.onEditClick(x)}
                            >
                                <span className="font-weight-bold text-small text-dark">
                                    {x.name}
                                </span>
                                <span className="text-2xsmall text-offblack">
                                    {trans('created_at', {
                                        date: <DateTime datetime={x.created_at} diff />,
                                    })}
                                </span>
                            </CheckboxBorderContainer>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    render() {
        const { isLoading, user } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="orange" user={user} hideLBImg>
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-4"
                        scroll
                        fluid
                    >
                        <div className="d-flex flex-column pb-5">
                            <span className="font-weight-bold text-3xlarge pb-4">
                                {trans('tokens')}
                            </span>
                            <span className="font-weight-light text-darker-gray">
                                {trans('tokens_list_page_text')}
                            </span>
                        </div>
                        <div className="d-flex flex-column">{this.renderUserTokens()}</div>
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

ListPage.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(ListPage);
