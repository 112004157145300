import ListPage from './ListPage';
import CreatePage from './CreatePage';
import parameters from '../../../routes/parameters';

export default [
    {
        path: '/companies/:companyId/agents/create',
        middleware: ['auth'],
        component: CreatePage,
        ...parameters.companyIdParameters,
    },
    {
        path: '/companies/:companyId/agents',
        middleware: ['auth'],
        component: ListPage,
        ...parameters.companyIdParameters,
    },
];
