import moment from 'moment';
import definitions from '../definitions';
import TicketService from '../services/TicketService';

const manyToManyFilter = (ticket, filters, name) => {
    const filterIds = window._.map(filters, 'id');
    const existsFilterIds = window._.map(ticket[name], 'id');
    const matches = window._.filter(existsFilterIds, existsFilterId => {
        return filterIds.indexOf(existsFilterId) > -1;
    });

    if (matches.length === 0) {
        return false;
    }

    return true;
};

const canShow = (ticket, { statuses, priorities, users, sources, tags, sortType, customers }) => {
    if (statuses && statuses.length && statuses.indexOf(ticket.status) === -1) {
        return false;
    }

    if (priorities && priorities.length && priorities.indexOf(ticket.priority) === -1) {
        return false;
    }

    if (users && users.length && !manyToManyFilter(ticket, users, 'users')) {
        return false;
    }

    if (sources && sources.length && window._.map(sources, 'id').indexOf(ticket.source.id) === -1) {
        return false;
    }

    if (tags && tags.length && !manyToManyFilter(ticket, tags, 'tags')) {
        return false;
    }

    if (
        customers &&
        customers.length &&
        window._.map(customers, 'id').indexOf(ticket.customer.id) === -1
    ) {
        return false;
    }

    if (sortType === definitions.SORT_TYPE_LAST_MESSAGE && !ticket.last_message_at) {
        return false;
    }

    return true;
};

const attrBySort = sort => {
    switch (sort) {
        case definitions.SORT_TYPE_DATE_CREATED:
            return 'created_at';

        case definitions.SORT_TYPE_DATE_MODIFIED:
            return 'updated_at';

        case definitions.SORT_TYPE_LAST_UNREPLIED:
            return 'last_unreplied_message_at';

        case definitions.SORT_TYPE_DUE_AT:
            return 'due_at';

        default:
            break;
    }

    return 'last_message_at';
};

const ticketGrouper = (ticketsOrGroups, others) => {
    let tickets = [];
    if (window._.isArray(ticketsOrGroups)) {
        tickets = ticketsOrGroups;
    } else {
        tickets = TicketService.getTickets(ticketsOrGroups);
    }

    tickets = window._.uniqBy(tickets, 'id');

    const groupedTickets = {};
    const sortType = others.sortOptions.sort;
    const orderBy = others.sortOptions.order;

    window._.each(tickets, ticket => {
        if (canShow(ticket, others)) {
            const groupKey = ticket.groups[sortType];
            if (!groupedTickets[groupKey]) {
                groupedTickets[groupKey] = [];
            }

            groupedTickets[groupKey].push(ticket);
        }
    });

    const groups = window._.orderBy(
        window._.keys(groupedTickets),
        key => {
            return moment(key).format('X');
        },
        [orderBy]
    );

    const sortedTickets = {};
    const attr = attrBySort(sortType);
    window._.each(groups, key => {
        sortedTickets[key] = window._.orderBy(
            groupedTickets[key],
            ticket => {
                return ticket[attr];
            },
            [orderBy]
        );
    });

    return sortedTickets;
};

export default ticketGrouper;
