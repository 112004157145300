import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../services';
import { AuthCardView } from '../../components/auth';
import trans from '../../localization';
import { AppLayout, AuthLayout } from '../../layouts';
import definitions from '../../definitions';
import router from '../../utils/router';
import CompanyService from '../../services/CompanyService';

class HomePage extends Component {
    constructor(props, context) {
        super(props, context);

        const { location } = this.props;

        this.state = {
            user: AuthService.user(),
            companies: [],
            loading: true,
            show: location.state && location.state.show,
        };
    }

    componentDidMount() {
        this.fetchCompanies();
    }

    fetchCompanies() {
        Api.request({
            method: 'GET',
            url: 'companies',
        })
            .then(response => {
                const { show } = this.state;
                const companies = response.data.data;
                if (!show && companies.length === 1) {
                    this.goToCompany(companies[0]);
                }

                this.setState({
                    companies,
                    show: show || companies.length !== 1,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    goToCompany(company) {
        const { history } = this.props;
        router.toCompany(history, company);
    }

    editCompany(company) {
        const { history } = this.props;
        const slug = CompanyService.slug(company);
        history.push(`/auth/editCompany/${slug}`);
    }

    render() {
        const { loading, companies, user, show } = this.state;
        const firstName = user.name.split(' ')[0];

        if (!show) {
            return <AppLayout isLoading={loading} />;
        }

        return (
            <AuthLayout isLoading={loading}>
                <AuthCardView
                    isAuth
                    title={trans('welcome_back_x', { name: firstName })}
                    subtitle={
                        <div className="d-flex text-dark">
                            <Link to="/myAccount">{trans('my_account')}</Link>
                            <span className="mx-1">{'|'}</span>
                            <span>
                                {trans('not_x', { name: firstName })}
                                <Link to="/auth/signOut" className="ml-1">
                                    {trans('logout')}
                                </Link>
                            </span>
                        </div>
                    }
                    alwaysShowSubtitle
                >
                    <div className="mt-5">
                        <h6 className="select-account-title font-weight-bold">
                            {trans('select_an_account')}
                        </h6>
                        <ul className="list-group list-group-flush list-group-reverse-flush">
                            {companies.map(company => (
                                <div
                                    key={company.id}
                                    className="align-items-center d-flex list-group-item list-group-item-action mb-3 no-gutters px-2 row"
                                >
                                    <button
                                        type="button"
                                        className="btn btn-link col text-color-dark text-left"
                                        onClick={() => this.goToCompany(company)}
                                    >
                                        {company.name}
                                    </button>
                                    <div>
                                        {company.role === definitions.ROLE_ADMIN && (
                                            <button
                                                type="button"
                                                className="btn btn-link pr-0 text-color-dark"
                                                onClick={() => this.editCompany(company)}
                                            >
                                                <i className="fal fa-cog" />
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-link pr-0 text-color-dark"
                                            onClick={() => this.goToCompany(company)}
                                        >
                                            <i className="fal fa-long-arrow-right" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex align-items-center list-group-item list-group-item-action px-2 py-3 mb-0">
                                <Link to={`/auth/createCompany`} className="text-decoration-none">
                                    {trans('create_new_helpdesk')}
                                </Link>
                                <Link to={`/auth/createCompany`} className="ml-auto px-1">
                                    <i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                        </ul>
                    </div>
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(HomePage);

HomePage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
