import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Modal } from 'react-bootstrap';
import trans from '../../localization';
import CancelButton from './manage/CancelButton';

class CropperModal extends PureComponent {
    constructor(props) {
        super(props);

        this.crop = this.crop.bind(this);
        this.cropperRef = React.createRef();
    }

    crop() {
        const { onCrop, onClose, width, height } = this.props;
        let croppedImage = null;

        if (this.cropperRef.current && this.cropperRef.current.cropper) {
            try {
                // noinspection JSUnresolvedFunction
                croppedImage = this.cropperRef.current.cropper
                    .getCroppedCanvas({ width, height })
                    .toDataURL();
            } catch (e) {
                //
            }
        }

        if (croppedImage) {
            onCrop(croppedImage);
        } else {
            onClose();
        }
    }

    render() {
        const { src, aspectRatio, show, onClose } = this.props;
        return (
            <Modal
                show={show}
                centered
                size="lg"
                onHide={onClose}
                dialogClassName="manage-modal-dialog"
            >
                <Modal.Body>
                    <div className="align-items-center cropper-modal-component d-flex flex-column justify-content-between bg-white">
                        {src && (
                            <Cropper
                                ref={this.cropperRef}
                                style={{
                                    height: 'auto',
                                    width: '100%',
                                    maxWidth: 799,
                                    maxHeight: '80vh',
                                }}
                                aspectRatio={aspectRatio}
                                responsive
                                src={src}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton onClick={onClose} />
                    <button
                        type="button"
                        onClick={this.crop}
                        className="btn btn-manage btn-primary save-btn"
                    >
                        {trans('crop_image')}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CropperModal;

CropperModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onCrop: PropTypes.func,
    src: PropTypes.string,
    aspectRatio: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

CropperModal.defaultProps = {
    show: false,
    onClose: () => {},
    onCrop: () => {},
    src: null,
    aspectRatio: 1,
    width: null,
    height: null,
};
