import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '../../helpers';
import trans from '../../localization';

class UserIcon extends PureComponent {
    renderContainer(content, hasImage, colorClass, others) {
        const { user, className, onClick, children } = this.props;
        const renderContent = hasImage || !children ? content : null;
        const renderClassName = hasImage ? `${className} has-image` : className;

        return (
            <div
                title={user.name}
                role="button"
                tabIndex="0"
                onClick={onClick}
                className={`circle-icon bold ${colorClass} ${renderClassName}`}
                {...others}
            >
                {renderContent}
                {children && <div className="children">{children}</div>}
            </div>
        );
    }

    static renderUserIcon(name, imageUrl, status, showStatusText, color, showStatus) {
        const content = imageUrl ? <img src={imageUrl} alt={name} /> : helpers.toShortName(name);
        const colorStatus = imageUrl && <div className={`status bg-color-${color}`} />;

        return (
            <>
                {content}
                {status && showStatusText && UserIcon.renderStatusText(status)}
                {showStatus && (status ? UserIcon.renderStatus(status) : colorStatus)}
            </>
        );
    }

    static renderStatusText(status) {
        return (
            <div className={`status ${status}`}>
                <span className={`status-text ${status}`}>{trans(`user_status.${status}`)}</span>
            </div>
        );
    }

    static renderStatus(status) {
        return <div className={`status ${status}`} title={trans(`user_status.${status}`)} />;
    }

    render() {
        const {
            name,
            color,
            user,
            isAway,
            className,
            showStatusText,
            onClick,
            children,
            imageUrl,
            status,
            hideImage,
            showStatus,
            ...others
        } = this.props;

        // noinspection JSUnresolvedVariable
        const renderImageUrl = hideImage ? null : imageUrl || (user ? user.image_url : null);
        const renderName = name || (user ? user.name : '');
        const renderColor = color || (user ? user.color : null);
        const colorClass = renderColor ? `bg-color-${renderColor}` : 'bg-success';

        if (renderName || renderImageUrl) {
            return this.renderContainer(
                UserIcon.renderUserIcon(
                    renderName,
                    renderImageUrl,
                    status,
                    showStatusText,
                    renderColor,
                    showStatus
                ),
                renderImageUrl,
                colorClass,
                others
            );
        }

        return (
            <div className={`circle-icon bold unassigned ${className}`} {...others}>
                {isAway && <div className="status away" />}
                <i className="fas fa-question" />
            </div>
        );
    }
}

export default UserIcon;

UserIcon.propTypes = {
    user: PropTypes.object,
    isAway: PropTypes.bool,
    className: PropTypes.string,
    showStatusText: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    color: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.any,
    status: PropTypes.string,
    hideImage: PropTypes.bool,
    showStatus: PropTypes.bool,
};

UserIcon.defaultProps = {
    user: null,
    isAway: false,
    className: '',
    showStatusText: false,
    onClick: null,
    children: null,
    color: null,
    name: null,
    imageUrl: null,
    status: null,
    hideImage: false,
    showStatus: false,
};
