import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import ManageList from '../../../components/helpdesk/list/ManageList';
import ManageListItem from '../../../components/helpdesk/list/ManageListItem';
import trans from '../../../localization';
import { CarouselPane, StepCarousel } from '../../../components';
import router from '../../../utils/router';

class ListPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            isLoading: true,
            links: [
                {
                    name: trans('resolved_tickets'),
                    icon: 'check-double',
                    color: 'mountain_meadow',
                    page: 'report/resolved_tickets'
                },
                {
                    name: trans('ticket_count'),
                    icon: 'mail-bulk',
                    color: 'edge_blue',
                    page: 'report/ticket_count'
                },
                {
                    name: trans('message_count'),
                    icon: 'paper-plane',
                    color: 'primary',
                    page: 'report/message_count'
                },
                {
                    name: trans('customer_ticket_messages'),
                    icon: 'tasks',
                    color: 'melon',
                    page: 'report/customer_ticket_messages'
                },
                {
                    name: trans('user_ticket_messages'),
                    icon: 'tasks',
                    color: 'gunmetal',
                    page: 'report/user_ticket_messages'
                },
                {
                    name: trans('ticket_messages'),
                    icon: 'tasks',
                    color: 'pale_violet',
                    page: 'report/ticket_messages'
                }
            ],
        };

        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        const promises = [this.fetchCompany()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    goToPage = item => {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company, `statistics/${item.page}`);
    };

    goBack() {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company);
    }

    render() {
        const { isLoading, user, company, links } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane onPrev={this.goBack} scroll center middle>
                        <div className="row">
                            <div className="col d-flex d-flex flex-column align-items-center justify-content-center">
                                <ManageHeading
                                    title={
                                        <>
                                            <span className="font-weight-normal">
                                                {trans('statistics')}
                                            </span>
                                        </>
                                    }
                                    subtitle={trans('get_reports_in_there', {name: company ? company.name : ''})}
                                />
                                <ManageList
                                    items={links}
                                    renderItem={(x, i) => (
                                        <ManageListItem
                                            key={`link-${i}`}
                                            item={x}
                                            type="link"
                                            isActive={true}
                                            onClick={() => this.goToPage(x)}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(ListPage);

ListPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
