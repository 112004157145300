import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Popover from '../Popover';
import ColorListItem from '../ColorListItem';
import trans from '../../localization';

export default class QuickUserAssign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            items: [],
            suggestion: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.mapUsers = this.mapUsers.bind(this);
        this.setItems = this.setItems.bind(this);
    }

    componentDidMount() {
        this.loadSuggestions();
    }

    onChange(e) {
        const { onChange } = this.props;
        const v = e.target.value;
        onChange(v, this.setItems);

        this.setState({
            value: v,
            suggestion: false,
        });
    }

    onSelect(item) {
        const { onSelect } = this.props;
        onSelect(item);
        this.clear();
    }

    onRemove(item) {
        const { onRemove } = this.props;
        onRemove(item);
        this.clear();
    }

    setItems(items) {
        this.setState({
            items,
            suggestion: false,
        });
    }

    clear() {
        this.setState({
            value: '',
            items: [],
            suggestion: false,
        });

        // noinspection JSUnresolvedFunction
        document.activeElement.blur();
    }

    mapUsers(x, i) {
        return {
            id: x.id,
            label: (
                <ColorListItem
                    className={cx({ 'mt-1': i > 0 }, 'active')}
                    right={
                        <span
                            role="button"
                            tabIndex={0}
                            onClick={() => this.onRemove(x)}
                            className="ml-auto flex-shrink-0 cursor-pointer text-dark-gray"
                        >
                            <i className="fad fa-times-circle" />
                        </span>
                    }
                    color={x.color}
                >
                    {x.name}
                </ColorListItem>
            ),
            value: x.id,
        };
    }

    loadSuggestions() {
        const { suggestion } = this.state;
        if (!suggestion) {
            this.setState(
                {
                    suggestion: true,
                },
                () => {
                    const { value } = this.state;
                    const { onChange } = this.props;
                    onChange(value, this.setItems);
                }
            );
        }
    }

    static mapSearch(x, i) {
        return {
            id: x.id,
            label: (
                <ColorListItem className={cx({ 'mt-1': i > 0 })} color={x.color}>
                    {x.name}
                </ColorListItem>
            ),
            value: x.id,
        };
    }

    render() {
        const { label, users } = this.props;
        const { value, items } = this.state;

        return (
            <Popover label={label} align="right">
                <input
                    type="text"
                    value={value}
                    onChange={this.onChange}
                    className="dc-popover-search-input"
                    placeholder={trans('type_to_search')}
                />

                {items.length > 0 ? (
                    items
                        .filter(x => !window._.some(users, { id: x.id }))
                        .map((x, i) => QuickUserAssign.mapSearch(x, i))
                        .map(x => (
                            <span
                                key={x.value}
                                role="button"
                                tabIndex={0}
                                onClick={() => this.onSelect(x)}
                                className="text-decoration-none"
                            >
                                {x.label}
                            </span>
                        ))
                ) : (
                    <span className="small text-light-gray">{trans('no_items')}</span>
                )}

                {users.length > 0 && <hr />}

                {users.map(this.mapUsers).map(x => (
                    <div key={x.value} className="text-decoration-none">
                        {x.label}
                    </div>
                ))}
            </Popover>
        );
    }
}

QuickUserAssign.propTypes = {
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onRemove: PropTypes.func,
    users: PropTypes.array,
    label: PropTypes.any,
};

QuickUserAssign.defaultProps = {
    onChange: null,
    onSelect: null,
    onRemove: null,
    users: [],
    label: null,
};
