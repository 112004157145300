import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import trans from '../localization';

export default class PaginationScrollbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };

        this.scrollDown = this.scrollDown.bind(this);
        this.scrollUp = this.scrollUp.bind(this);
    }

    scrollDown(e) {
        if (e.scrollTop + e.clientHeight >= e.scrollHeight) {
            this.nextPage();
        }
    }

    scrollUp(scrollContainer) {
        if (scrollContainer.scrollTop < 10) {
            this.nextPage();
        }
    }

    nextPage() {
        const { isLoading } = this.state;
        const { isLastPage, onNextPage } = this.props;

        if (isLoading || isLastPage) {
            return;
        }

        this.setState(
            {
                isLoading: true,
            },
            () => {
                onNextPage().then(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
            }
        );
    }

    static renderLoading() {
        return (
            <div className="text-center mx-2 px-1">
                <button type="button" className="btn btn-sm btn-primary" disabled>
                    <i className="fa fa-spin fa-spinner mr-2" />
                    {trans('loading')}
                </button>
            </div>
        );
    }

    render() {
        const { isLoading } = this.state;
        const { children, direction, ...others } = this.props;

        return (
            <PerfectScrollbar
                onScrollUp={direction === 'up' ? this.scrollUp : undefined}
                onScrollDown={direction === 'down' ? this.scrollDown : undefined}
                {...others}
            >
                {direction === 'up' && isLoading && PaginationScrollbar.renderLoading()}
                {children}
                {direction === 'down' && isLoading && PaginationScrollbar.renderLoading()}
            </PerfectScrollbar>
        );
    }
}

PaginationScrollbar.propTypes = {
    children: PropTypes.node.isRequired,
    onNextPage: PropTypes.func,
    isLastPage: PropTypes.bool,
    direction: PropTypes.string,
};

PaginationScrollbar.defaultProps = {
    isLastPage: false,
    onNextPage: () => Promise.resolve(),
    direction: 'down',
};
