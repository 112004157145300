import React from 'react';
import PropTypes from 'prop-types';
import trans from '../../../localization';

const EditChannelHeader = ({ channel }) => {
    return (
        <>
            <div className="d-flex align-items-center">
                <span className="heading-medium font-weight-bold">{trans('edit_channels')}</span>
                <span className="ml-4 text-small">{channel.name}</span>
            </div>
            <div className="row">
                <div className="col col-lg-4 text-light-gray">{trans('edit_channels_text')}</div>
                <div className="col col-lg-6" />
            </div>
        </>
    );
};

EditChannelHeader.propTypes = {
    channel: PropTypes.object.isRequired,
};

export default EditChannelHeader;
