import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class ListGroupContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: props.show,
        };
    }

    componentDidMount() {
        this.setState({
            show: true,
        });
    }

    componentWillUnmount() {
        this.setState({
            show: false,
        });
    }

    render() {
        const { show } = this.state;

        const { children } = this.props;

        return (
            <div
                className={classNames('list-group-content', {
                    show,
                })}
            >
                {children}
            </div>
        );
    }
}

ListGroupContent.propTypes = {
    show: PropTypes.bool,
    children: PropTypes.node,
};

ListGroupContent.defaultProps = {
    show: true,
    children: null,
};
