import { toast } from 'react-toastify';
import trans from '../localization';

const successMessage = message => {
    toast(message, {
        type: 'success',
    });
};

const success = (key, ...values) => {
    successMessage(trans(key, values));
};

const errorMessage = message => {
    toast(message, {
        type: 'error',
    });
};

const error = (key, ...values) => {
    errorMessage(trans(key, values));
};

const saved = () => {
    success('changes_saved_success');
};

const created = () => {
    success('created_success');
};

const updated = () => {
    success('updated_success');
};

const deleted = () => {
    success('deleted_success');
};

export default {
    successMessage,
    success,
    errorMessage,
    error,
    saved,
    deleted,
    created,
    updated,
};
