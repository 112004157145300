import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socialAuthImages } from '../assets/images';
import trans from '../localization';
import { Api } from '../services';

export default class SocialLogin extends Component {
    socialLogin(provider) {
        const { isSending, onSendEnd, onBeforeSend } = this.props;

        if (isSending) {
            return Promise.resolve();
        }

        onBeforeSend();

        return Api.request({
            method: 'GET',
            url: `auth/social/${provider}/redirect`,
        }).then(response => {
            // eslint-disable-next-line no-undef
            window.location.href = response.data.data;
            onSendEnd(response.data.data);
        });
    }

    render() {
        return (
            <div className="social-auth mt-3">
                <p className="text-uppercase">{trans('sign_in_with_your_favourite_platform')}</p>
                <div className="auth-links">
                    {socialAuthImages.map(social => (
                        <button
                            key={`auth-${social.name}`}
                            type="button"
                            className="link-btn"
                            onClick={() => this.socialLogin(social.name)}
                        >
                            <img
                                src={social.image}
                                alt={trans('sign_in_with_x', {
                                    name: social.name,
                                })}
                            />
                        </button>
                    ))}
                </div>
            </div>
        );
    }
}

SocialLogin.propTypes = {
    isSending: PropTypes.bool,
    onSendEnd: PropTypes.func,
    onBeforeSend: PropTypes.func,
};

SocialLogin.defaultProps = {
    isSending: false,
    onSendEnd: () => {},
    onBeforeSend: () => {},
};
