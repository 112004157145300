import ListPage from './ListPage';
import CreateOrEditPage from './CreateOrEditPage';

export default [
    {
        path: '/myAccount/signatures',
        middleware: ['auth'],
        component: ListPage,
    },
    {
        path: '/myAccount/signatures/create',
        middleware: ['auth'],
        component: CreateOrEditPage,
    },
    {
        path: '/myAccount/signatures/:signatureId/edit',
        middleware: ['auth'],
        component: CreateOrEditPage,
    },
];
