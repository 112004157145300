import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const TicketTypeList = props => {
    const { items, footer, activeTicketType, onClick } = props;
    return (
        <div className="d-flex flex-column align-items-start p-1">
            {items.map(x => (
                // eslint-disable-next-line
                <div
                    key={`quickrespond-status-${x.id}`}
                    role="button"
                    onClick={() => onClick(x)}
                    className={cx(
                        `status option ticket-status-option-${x.color} py-1 cursor-pointer`,
                        {
                            'active font-weight-bold': activeTicketType === x.id,
                        }
                    )}
                >
                    {x.name}
                </div>
            ))}
            <span className="text-3xsmall font-weight-light text-light-gray align-self-end px-2">
                {footer}
            </span>
        </div>
    );
};

export default TicketTypeList;

TicketTypeList.propTypes = {
    items: PropTypes.array,
    footer: PropTypes.string,
    activeTicketType: PropTypes.number,
    onClick: PropTypes.func,
};

TicketTypeList.defaultProps = {
    items: [],
    footer: '',
    activeTicketType: '',
    onClick: () => {},
};
