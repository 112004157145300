import React from 'react';
import PropTypes from 'prop-types';

const CheckboxBorderContainer = props => {
    const { id, children, onSelect, onClick } = props;

    const stopPropagation = e => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
    };

    return (
        <div
            role="button"
            tabIndex="0"
            onClick={onClick}
            className="checkbox-border-container custom-checkbox-in-border top-left"
        >
            <div
                role="button"
                tabIndex="-1"
                onClick={stopPropagation}
                className="custom-checkbox custom-checkbox-red"
            >
                <input
                    type="checkbox"
                    id={`checkbox-canned-response-${id}`}
                    className="checkbox"
                    onChange={onSelect}
                />
                <div className="cont">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor={`checkbox-canned-response-${id}`}>
                        <i className="fas fa-minus" />
                    </label>
                </div>
            </div>
            {children}
        </div>
    );
};

export default CheckboxBorderContainer;

CheckboxBorderContainer.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onSelect: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.node,
};

CheckboxBorderContainer.defaultProps = {
    onSelect: () => {},
    onClick: () => {},
    children: null,
};
