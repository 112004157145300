import React from 'react';
import PropTypes from 'prop-types';

const ManageButton = props => {
    const { children, text, ...others } = props;

    return (
        // eslint-disable-next-line react/button-has-type
        <button className="manage-button" {...others}>
            {text ? <span>{text}</span> : children}
        </button>
    );
};

export default ManageButton;

ManageButton.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node,
};

ManageButton.defaultProps = {
    text: null,
    children: null,
};
