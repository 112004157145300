import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import trans from '../../../localization';
import TicketInfo from './TicketInfo';

const ActivityTicketChanged = props => {
    const {
        activity,
        containerClassName,
        reopen,
        resolve,
        priority,
        status,
        seeTicket,
        onMoreClick,
    } = props;
    const { user, customer, company, ticket } = activity;
    const buttonText = trans('see_ticket');

    let activityTextKey;

    if (status || priority) {
        const key = status ? 'status' : 'priority';
        activityTextKey = `x_has_changed_the_${key}_on_a_ticket_you_are_assigned_to_on`;
    } else {
        const key = reopen ? 'reopened' : 'resolved';
        activityTextKey = `x_has_${key}_a_ticket_you_are_assigned_to_on`;
    }

    return (
        <Container
            activity={activity}
            className={containerClassName}
            onButtonClick={seeTicket}
            buttonText={buttonText}
            onMoreClick={onMoreClick}
        >
            <p>
                <span className="text-dark text-small">
                    {trans(activityTextKey, {
                        name: ((customer && customer.name) || (user && user.name) || '').split(
                            ' '
                        )[0],
                    })}
                </span>{' '}
                <span className="text-dark font-weight-bold text-small">
                    {company && company.name}
                </span>
            </p>
            <TicketInfo
                ticket={ticket}
                company={company}
                status={((status || reopen || resolve) && activity.new) || null}
                priority={(priority && activity.new) || null}
                reopen={reopen}
                resolve={resolve}
            />
        </Container>
    );
};

export default ActivityTicketChanged;

ActivityTicketChanged.propTypes = {
    activity: PropTypes.object.isRequired,
    containerClassName: PropTypes.string,
    reopen: PropTypes.bool,
    resolve: PropTypes.bool,
    priority: PropTypes.bool,
    status: PropTypes.bool,
    seeTicket: PropTypes.func,
    onMoreClick: PropTypes.func,
};

ActivityTicketChanged.defaultProps = {
    containerClassName: null,
    reopen: false,
    resolve: false,
    priority: false,
    status: false,
    seeTicket: () => {},
    onMoreClick: null,
};
