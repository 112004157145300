import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Popover from './Popover';
import trans from '../localization';

export default class MultipleEmailSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            searchItems: [],
            suggestion: false,
        };

        this.onChange = this.onChange.bind(this);
        this.setSearchItems = this.setSearchItems.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onChange(e) {
        const { onSearch } = this.props;
        const v = e.target.value;
        onSearch(v, this.setSearchItems);

        this.setState({
            value: v,
            suggestion: false,
        });
    }

    onClick(item) {
        const { onSelect } = this.props;
        onSelect(item);

        this.clear();
    }

    onRemove(item) {
        const { onRemove } = this.props;
        onRemove(item);

        this.clear();
    }

    onKeyDown(e) {
        if (e.key !== 'Enter') return;

        const { value } = e.target;
        this.onClick({
            key: value,
            email: value,
            name: value,
        });
    }

    setSearchItems(searchItems) {
        this.setState({
            searchItems,
        });
    }

    clear() {
        this.setState({
            value: '',
            searchItems: [],
            suggestion: false,
        });
    }

    loadSuggestions() {
        const { suggestion } = this.state;
        if (!suggestion) {
            this.setState(
                {
                    suggestion: true,
                },
                () => {
                    const { value } = this.state;
                    const { onSearch } = this.props;
                    onSearch(value, this.setSearchItems);
                }
            );
        }
    }

    render() {
        const { searchItems, value } = this.state;
        const { label, items, placeholder } = this.props;

        if (searchItems.length === 0) {
            this.loadSuggestions();
        }

        return (
            <div className="email-select">
                <div className="email-select-label">{label}:</div>

                {items.map(x => (
                    <div className="email-select-item" key={`${x.value}-${Math.random()}`}>
                        {x.label}

                        <span
                            className="ml-1 cursor-pointer"
                            role="button"
                            tabIndex={0}
                            onClick={() => this.onRemove(x)}
                        >
                            <i className="fad fa-times-circle" />
                        </span>
                    </div>
                ))}

                <Popover
                    className="px-0"
                    label={
                        <input
                            type="text"
                            value={value}
                            className="email-select-input"
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            placeholder={placeholder}
                        />
                    }
                >
                    <PerfectScrollbar className="email-select-options-scrollbar slim-gray">
                        {searchItems.length > 0 ? (
                            searchItems.map(x => (
                                <div
                                    className="email-select-option"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => this.onClick(x)}
                                    key={`${x.key}-${Math.random()}`}
                                >
                                    <div>{x.name}</div>
                                    <div className="text-2xsmall">{x.email}</div>
                                </div>
                            ))
                        ) : (
                            <div className="px-2 py-1">{trans('type_to_search')}</div>
                        )}
                    </PerfectScrollbar>
                </Popover>
            </div>
        );
    }
}

MultipleEmailSelect.propTypes = {
    items: PropTypes.array,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    onRemove: PropTypes.func,
};

MultipleEmailSelect.defaultProps = {
    items: [],
    placeholder: '',
    label: '',
    onSearch: () => {},
    onSelect: () => {},
    onRemove: () => {},
};
