import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ManageLayout } from '../../layouts';
import { CarouselPane, StepCarousel } from '../../components';
import ManageHeading from '../../components/helpdesk/heading/ManageHeading';
import { Api, AuthService } from '../../services';
import trans from '../../localization';
import AuditLogs from '../../components/helpdesk/AuditLogs';

class TicketLogsPage extends Component {
    constructor(props) {
        super(props);

        const { match } = props;

        this.state = {
            isLoading: true,
            user: AuthService.user(),
            companyId: match.params && match.params.companyId,
            ticketId: match.params && match.params.ticketId,
            company: null,
        };
    }

    componentDidMount() {
        const fetchPromises = Promise.all([this.fetchCompany()]);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    goBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        const { isLoading, user, company, ticketId } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="blue" user={user} company={company}>
                <StepCarousel step={0}>
                    <CarouselPane onPrev={this.goBack} center middle scroll>
                        <div>
                            <ManageHeading
                                title={trans('ticket_logs')}
                                subtitle={trans('ticket_logs_page_text')}
                            />
                            <div className="pt-4 pb-2">
                                <AuditLogs path={`tickets/${ticketId}`} />
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

export default withRouter(TicketLogsPage);

TicketLogsPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
