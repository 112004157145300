import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IconPreview } from './index';
import HoverTooltip from '../../HoverTooltip';

const IconPreviewUpload = ({ icon, image, color, large, title, empty, ...props }) => {
    return (
        <HoverTooltip title={title}>
            <div
                className={cx('icon-preview-upload position-relative', { empty }, { large })}
                role="button"
                tabIndex="0"
                {...props}
            >
                {image ? (
                    <img
                        className={cx('icon-preview-upload-preview', { large })}
                        src={image}
                        alt="Channel logo"
                    />
                ) : (
                    <IconPreview
                        className={cx('border-2 text-white', { large })}
                        color={color}
                        icon={icon}
                        large={large}
                    />
                )}
                <div
                    className={cx('icon-preview-upload-upload bg-secondary border-2 text-white', {
                        large,
                    })}
                >
                    <i className="fa fa-upload" />
                </div>
            </div>
        </HoverTooltip>
    );
};

IconPreviewUpload.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string,
    large: PropTypes.bool,
    title: PropTypes.string,
    empty: PropTypes.bool,
};

IconPreviewUpload.defaultProps = {
    large: false,
    color: '',
    icon: '',
    image: null,
    title: '',
    empty: false,
};

export default IconPreviewUpload;
