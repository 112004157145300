import React from 'react';
import PropTypes from 'prop-types';

const FormButton = props => {
    const { text, disabled, type, onClick, isBlock, className, ...others } = props;

    let classes = 'dc-form-btn btn btn-primary p-4';

    if (isBlock) {
        classes += ' btn-block';
    }

    if (className) {
        classes += ` ${className}`;
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <button disabled={disabled} type={type} className={classes} onClick={onClick} {...others}>
            {text}
        </button>
    );
};

export default FormButton;

FormButton.propTypes = {
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func,
    isBlock: PropTypes.bool,
    className: PropTypes.string,
};

FormButton.defaultProps = {
    disabled: false,
    type: 'text',
    onClick: () => {},
    isBlock: false,
    className: null,
};
