import React, { Component } from 'react';
import PropTypes from 'prop-types';
import trans from '../../localization';
import helpers from '../../helpers';
import SortMenu from './SortMenu';
import DateFilter from './DateFilter';

export default class TicketControl extends Component {
    constructor(props) {
        super(props);

        this.onSelectAll = this.onSelectAll.bind(this);
    }

    onSelectAll(e) {
        const { onSelectAll } = this.props;

        onSelectAll(e.target.checked);
    }

    render() {
        const { onChangeSortBy, isAllSelected, sortOptions, onChangeDate } = this.props;
        const sortTypes = helpers.getSortTypes();

        return (
            <div className="p-1 ticket-control">
                <div className="left">
                    <div className="select-all">
                        <div className="custom-checkbox">
                            <input
                                onChange={this.onSelectAll}
                                checked={isAllSelected}
                                type="checkbox"
                                className="checkbox"
                                name="select_all_checkbox"
                                id="select-all"
                                value="select-all"
                            />
                            <div className="cont">
                                <label htmlFor="select-all">
                                    <i className="fas fa-check" />
                                </label>
                            </div>
                        </div>
                        <span className="unselectable font-weight-light text-small">
                            {trans('select_all')}
                        </span>
                    </div>
                    <div className="spacer" />
                    <div className="sort-by text-small">
                        <SortMenu
                            sort={sortOptions.sort}
                            order={sortOptions.order}
                            items={sortTypes.map(x => ({ label: x.title, value: x.value }))}
                            onSortChange={value => {
                                onChangeSortBy({ ...sortOptions, sort: value });
                            }}
                            onOrderChange={value => {
                                onChangeSortBy({ ...sortOptions, order: value });
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-center px-4 py-2 ml-auto">
                    <span
                        className="pr-4 text-small text-dark font-weight-light"
                        style={{ display: 'none' }}
                    >
                        {trans('filter_by_date')}:
                    </span>
                    <DateFilter onChange={onChangeDate} />
                </div>
            </div>
        );
    }
}

TicketControl.propTypes = {
    onChangeSortBy: PropTypes.func,
    onChangeDate: PropTypes.func,
    onSelectAll: PropTypes.func,
    isAllSelected: PropTypes.bool,
    sortOptions: PropTypes.any,
};

TicketControl.defaultProps = {
    onChangeSortBy: () => {},
    onChangeDate: () => {},
    onSelectAll: () => {},
    isAllSelected: false,
    sortOptions: null,
};
