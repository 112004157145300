import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import { CarouselPane, StepCarousel } from '../../../components';
import trans from '../../../localization';
import {
    ManageField,
    ManageInput,
    ManageSelectbox,
    ManageButton,
    SwitchCheckbox,
} from '../../../elements';
import helpers from '../../../helpers';
import CancelAndSaveFooter from '../../../components/helpdesk/carousel/CancelAndSaveFooter';

class CreateAndEditPage extends Component {
    constructor(props) {
        super(props);

        const rules = helpers.getRules();

        const ruleSelectboxes = {
            priority: helpers.getPriorities(),
            status: helpers.getStatuses(),
            actionTask: rules.rule_action_tasks,
            field: rules.rule_fields,
            operator: rules.rule_operators,
            connective: rules.rule_connective,
        };

        const { match } = this.props;

        this.state = {
            companyId: match.params.companyId,
            ruleId: match.params.ruleId,
            isLoading: true,
            company: null,
            user: AuthService.user(),
            isEdit: Boolean(match.params.ruleId),
            ticketTypes: [],
            cannedResponses: [],
            agents: [],
            data: {
                name: '',
                description: '',
                is_active: false,
                ticketRuleConditions: [
                    {
                        id: Date.now(),
                        field: 'status',
                        operator: 'equal',
                        value: 'open',
                        connective: 'and',
                    },
                ],
                ticketRuleActions: [{ id: Date.now(), action: 'set_status', value: 'resolved' }],
            },
            ruleSelectboxes,
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeCondition = this.onChangeCondition.bind(this);
        this.onChangeAction = this.onChangeAction.bind(this);
        this.getActionValueOptions = this.getActionValueOptions.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });

            return Api.request({
                method: 'GET',
                url: 'cannedResponses',
                params: {
                    company_id: response.data.data.id,
                },
            }).then(responseCannedResponse => {
                this.setState({
                    cannedResponses: responseCannedResponse.data.data.company,
                });
            });
        });
    }

    fetchRule() {
        const { ruleId, data } = this.state;

        if (!ruleId) {
            return Promise.resolve();
        }

        return Api.request({
            method: 'GET',
            url: `ticketRules/${ruleId}`,
        }).then(response => {
            const responseData = { ...data, ...response.data.data };

            responseData.ticketRuleConditions = responseData.ticketRuleConditions.map(m => {
                return {
                    ...m,
                    connective: m.is_or ? 'or' : 'and',
                };
            });

            this.setState({
                data: { ...responseData },
            });
        });
    }

    fetchTicketTypes = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: 'ticketTypes',
            params: {
                companyId,
            },
        }).then(response => {
            this.setState({
                ticketTypes: response.data.data,
            });
        });
    };

    fetchChannels = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: 'channels',
            params: {
                companyId,
                isActive: true,
            },
        }).then(response => {
            const { ruleSelectboxes } = this.state;

            this.setState({
                ruleSelectboxes: {
                    ...ruleSelectboxes,
                    channel: response.data.data.map(c => {
                        return {
                            title: c.name,
                            value: c.id,
                        };
                    }),
                },
            });
        });
    };

    fetchAgents = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}/agents`,
        }).then(response => {
            this.setState({
                agents: response.data.data.map(c => {
                    return {
                        title: c.name,
                        value: c.id,
                    };
                }),
            });
        });
    };

    componentDidMount() {
        const fetchPromises = Promise.all([
            this.fetchCompany(),
            this.fetchRule(),
            this.fetchTicketTypes(),
            this.fetchChannels(),
            this.fetchAgents(),
        ]);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    goBack() {
        const { history } = this.props;
        history.goBack();
    }

    onSubmit() {
        const { isEdit, companyId, ruleId, data: stateData } = this.state;

        const setLoading = (isLoading = false) => {
            this.setState({ isLoading });
        };

        const data = {
            ...stateData,
            ticketRuleConditions: stateData.ticketRuleConditions.map(x => ({
                ...x,
                is_or: x.connective === 'or',
            })),
            company_id: companyId,
        };

        setLoading(true);

        if (isEdit) {
            Api.request({
                method: 'PUT',
                url: `ticketRules/${ruleId}/edit`,
                data,
            })
                .then(() => {
                    this.goBack();
                })
                .finally(setLoading);
        } else {
            Api.request({
                method: 'POST',
                url: 'ticketRules/create',
                data,
            })
                .then(() => {
                    this.goBack();
                })
                .finally(setLoading);
        }
    }

    onRemove() {
        const { ruleId } = this.state;

        const setLoading = (isLoading = false) => {
            this.setState({ isLoading });
        };

        setLoading(true);

        Api.request({
            method: 'DELETE',
            url: `ticketRules/${ruleId}/delete`,
        })
            .then(() => {
                this.goBack();
            })
            .finally(setLoading);
    }

    onChange(key, value) {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                [key]: value,
            },
        });
    }

    onChangeCondition(key, condition, value) {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                ticketRuleConditions: data.ticketRuleConditions.map(x =>
                    x.id === condition.id
                        ? {
                              ...condition,
                              [key]: value,
                          }
                        : x
                ),
            },
        });
    }

    onChangeAction(key, action, value) {
        const actionUpdates = {
            [key]: value,
        };

        if (key === 'action') {
            const newActionValueOptions = this.getActionValueOptions({ ...action, [key]: value });
            actionUpdates.value = newActionValueOptions[0]?.value ?? null;
        }

        this.setState(prev => ({
            data: {
                ...prev.data,
                ticketRuleActions: prev.data.ticketRuleActions.map(currentAction =>
                    currentAction.id === action.id
                        ? {
                              ...action,
                              ...actionUpdates,
                          }
                        : currentAction
                ),
            },
        }));
    }

    getActionValueOptions(action) {
        const { ruleSelectboxes, ticketTypes, cannedResponses, agents } = this.state;

        switch (action.action) {
            case 'set_status':
                return ruleSelectboxes.status;
            case 'set_priority':
                return ruleSelectboxes.priority;
            case 'set_ticket_type':
                return ticketTypes.map(ticketType => {
                    return {
                        value: ticketType.id,
                        title: ticketType.name,
                    };
                });
            case 'reply':
                return cannedResponses.map(c => {
                    return {
                        value: c.id,
                        title: c.title,
                    };
                });

            case 'forward':
                return agents;

            case 'forward_first':
                return agents;
            default:
                return [];
        }
    }

    render() {
        const { isLoading, isEdit, user, company, data, ruleSelectboxes, agents } = this.state;

        return (
            <ManageLayout
                theme="purple"
                user={user}
                company={company}
                isLoading={isLoading}
                hideLBImg
            >
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-2"
                        scroll
                        fluid
                    >
                        <div>
                            <div className="d-flex flex-column pt-3 pb-5" style={{ maxWidth: 600 }}>
                                <span className="font-weight-bold text-3xlarge pb-4">
                                    {trans('ticket_rules')}
                                </span>
                                <span className="font-weight-light text-darker-gray">
                                    {trans('ticket_rules_text')}
                                </span>
                            </div>
                            <div className="d-flex flex-wrap mb-4">
                                <div className="col-12 col-md-6 col-lg-4 p-2 p-2">
                                    <ManageField
                                        title={
                                            <div className="d-flex align-items-center">
                                                <span>{trans('rule_name')}</span>
                                                <div className="ml-auto px-3">
                                                    <SwitchCheckbox
                                                        type="manage"
                                                        checked={data.is_active}
                                                        onChange={e =>
                                                            this.onChange(
                                                                'is_active',
                                                                e.target.checked
                                                            )
                                                        }
                                                        label={trans('rule_types.active')}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    >
                                        <ManageInput
                                            value={data.name}
                                            onChange={e => this.onChange('name', e.target.value)}
                                            type="text"
                                        />
                                    </ManageField>
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 p-2 p-2">
                                    <ManageField title={trans('rule_description')}>
                                        <ManageInput
                                            value={data.description}
                                            onChange={e =>
                                                this.onChange('description', e.target.value)
                                            }
                                            type="text"
                                        />
                                    </ManageField>
                                </div>
                            </div>
                            <div className="pb-4 mb-4 border-bottom">
                                <p className="font-weight-bold text-medium mb-3">
                                    {trans('ticket_rule_conditions')}
                                </p>
                                <div className="d-flex flex-wrap mb-2">
                                    <div className="col-6 col-md-3 p-2">{trans('field')}</div>
                                    <div className="col-6 col-md-3 col-lg-2 p-2">
                                        {trans('operator')}
                                    </div>
                                    <div className="col-6 col-md-3 p-2">{trans('value')}</div>
                                    <div className="col-6 col-md-3 p-2">{trans('connective')}</div>
                                </div>
                                {data.ticketRuleConditions.map((condition, index) => (
                                    <div
                                        key={`condition-${condition.id}`}
                                        className="d-flex flex-wrap mb-2"
                                    >
                                        <div className="col-6 col-md-3 p-2">
                                            <ManageSelectbox
                                                value={condition.field}
                                                items={ruleSelectboxes.field}
                                                onChange={e =>
                                                    this.onChangeCondition(
                                                        'field',
                                                        condition,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-2 p-2">
                                            <ManageSelectbox
                                                value={condition.operator}
                                                items={
                                                    ruleSelectboxes[condition.field]
                                                        ? ruleSelectboxes.operator.filter(
                                                              x =>
                                                                  x.value === 'equal' ||
                                                                  x.value === 'not_equal'
                                                          )
                                                        : ruleSelectboxes.operator
                                                }
                                                onChange={e =>
                                                    this.onChangeCondition(
                                                        'operator',
                                                        condition,
                                                        e.target.value
                                                    )
                                                }
                                                color="light-blue"
                                            />
                                        </div>
                                        <div className="col-6 col-md-3 p-2">
                                            {ruleSelectboxes[condition.field] ? (
                                                <ManageSelectbox
                                                    value={condition.value}
                                                    items={ruleSelectboxes[condition.field]}
                                                    onChange={e =>
                                                        this.onChangeCondition(
                                                            'value',
                                                            condition,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <ManageInput
                                                    value={condition.value}
                                                    onChange={e =>
                                                        this.onChangeCondition(
                                                            'value',
                                                            condition,
                                                            e.target.value
                                                        )
                                                    }
                                                    type="text"
                                                />
                                            )}
                                        </div>
                                        <div className="col-5 col-md-2 col-xl-1 p-2">
                                            {index < data.ticketRuleConditions.length - 1 ? (
                                                <ManageSelectbox
                                                    value={condition.connective}
                                                    items={ruleSelectboxes.connective}
                                                    onChange={e =>
                                                        this.onChangeCondition(
                                                            'connective',
                                                            condition,
                                                            e.target.value
                                                        )
                                                    }
                                                    color="dark-blue"
                                                />
                                            ) : (
                                                <ManageButton
                                                    onClick={() =>
                                                        this.setState({
                                                            data: {
                                                                ...data,
                                                                ticketRuleConditions: [
                                                                    ...data.ticketRuleConditions,
                                                                    {
                                                                        id: Date.now(),
                                                                        field: 'status',
                                                                        operator: 'equal',
                                                                        value: 'open',
                                                                        connective: 'and',
                                                                    },
                                                                ],
                                                            },
                                                        })
                                                    }
                                                    type="button"
                                                >
                                                    <i className="fas fa-plus d-block d-lg-none" />
                                                    <span className="d-none d-lg-block text-truncate">
                                                        {trans('add_condition')}
                                                    </span>
                                                </ManageButton>
                                            )}
                                        </div>
                                        <div className="col-1 col-md-1 p-2 d-flex align-items-center">
                                            <button
                                                onClick={() => {
                                                    if (data.ticketRuleConditions.length === 1) {
                                                        return;
                                                    }
                                                    this.setState({
                                                        data: {
                                                            ...data,
                                                            ticketRuleConditions: data.ticketRuleConditions.filter(
                                                                x => x.id !== condition.id
                                                            ),
                                                        },
                                                    });
                                                }}
                                                disabled={data.ticketRuleConditions.length === 1}
                                                type="button"
                                                className={`bg-transparent border-0 ${
                                                    data.ticketRuleConditions.length === 1
                                                        ? 'text-dark-gray'
                                                        : 'text-darker-gray'
                                                }`}
                                            >
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="mb-4">
                                <p className="font-weight-bold text-medium mb-3">
                                    {trans('ticket_rule_actions')}
                                </p>
                                <div className="d-flex flex-wrap mb-2">
                                    <div className="col-6 col-md-4 p-2">{trans('task')}</div>
                                    <div className="col-6 col-md-5 col-lg-4 p-2">
                                        {trans('value')}
                                    </div>
                                    <div className="col-6 col-md-2 col-lg-4 p-2" />
                                </div>
                                {data.ticketRuleActions.map((action, index) => (
                                    <div
                                        key={`action-${action.id}`}
                                        className="d-flex mb-2 flex-wrap"
                                    >
                                        <div className="col-6 col-md-4 p-2">
                                            <ManageSelectbox
                                                value={action.action}
                                                items={ruleSelectboxes.actionTask}
                                                onChange={e =>
                                                    this.onChangeAction(
                                                        'action',
                                                        action,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-6 col-md-5 col-lg-4 p-2">
                                            <ManageSelectbox
                                                value={action.value}
                                                items={this.getActionValueOptions(action)}
                                                onChange={e =>
                                                    this.onChangeAction(
                                                        'value',
                                                        action,
                                                        e.target.value
                                                    )
                                                }
                                                color="light-blue"
                                            />

                                            {action.action == 'reply' && (
                                                <ManageSelectbox
                                                    value={action.user_id}
                                                    items={agents}
                                                    onChange={e =>
                                                        this.onChangeAction(
                                                            'user_id',
                                                            action,
                                                            e.target.value
                                                        )
                                                    }
                                                    color="light-blue"
                                                />
                                            )}
                                        </div>
                                        <div className="col-5 col-md-2 col-xl-1 p-2">
                                            <ManageButton
                                                onClick={() =>
                                                    this.setState({
                                                        data: {
                                                            ...data,
                                                            ticketRuleActions: [
                                                                ...data.ticketRuleActions,
                                                                {
                                                                    id: Date.now(),
                                                                    action: 'set_status',
                                                                    value: 'resolved',
                                                                },
                                                            ],
                                                        },
                                                    })
                                                }
                                                disabled={index < data.ticketRuleActions.length - 1}
                                                type="button"
                                            >
                                                <i className="fas fa-plus d-block d-lg-none" />
                                                <span className="d-none d-lg-block text-truncate">
                                                    {index < data.ticketRuleActions.length - 1
                                                        ? trans('rule_connective.and')
                                                        : trans('add_task')}
                                                </span>
                                            </ManageButton>
                                        </div>
                                        <div className="col-1 col-md-1 p-2 d-flex align-items-center">
                                            <button
                                                onClick={() => {
                                                    if (data.ticketRuleActions.length === 1) {
                                                        return;
                                                    }
                                                    this.setState({
                                                        data: {
                                                            ...data,
                                                            ticketRuleActions: data.ticketRuleActions.filter(
                                                                x => x.id !== action.id
                                                            ),
                                                        },
                                                    });
                                                }}
                                                disabled={data.ticketRuleActions.length === 1}
                                                type="button"
                                                className={`bg-transparent border-0 ${
                                                    data.ticketRuleActions.length === 1
                                                        ? 'text-dark-gray'
                                                        : 'text-darker-gray'
                                                }`}
                                            >
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CarouselPane>
                </StepCarousel>
                <CancelAndSaveFooter
                    shadow
                    submitTitle={isEdit ? trans('save_changes') : trans('create')}
                    removeTitle={
                        <span>
                            <i className="fas fa-trash-alt mr-2" />
                            {trans('delete_rule')}
                        </span>
                    }
                    onSubmit={this.onSubmit}
                    onRemove={isEdit ? this.onRemove : null}
                    onCancel={isEdit ? null : this.goBack}
                />
            </ManageLayout>
        );
    }
}

CreateAndEditPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
export default withRouter(CreateAndEditPage);
