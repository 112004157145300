import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Api, ApiService } from '../../services';
import { AuthCardView } from '../../components/auth';
import { FormButton, FormInput, FormTextButton } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';

class InviteMembersPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: {
                emails: ['', '', ''],
            },
            errors: {},
            isSending: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.renderFormInput = this.renderFormInput.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        const { isSending, data } = this.state;

        if (isSending) {
            return;
        }

        this.setState({
            isSending: true,
        });

        Api.request(
            {
                method: 'POST',
                url: 'auth/inviteMembers',
                data,
            },
            true
        )
            .then(() => {
                this.skip();
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                    isSending: false,
                });
            });
    }

    onChange(event) {
        const { data } = this.state;
        const regex = /^emails\[([0-9]+)]$/;
        const matches = regex.exec(event.target.name);

        if (matches) {
            data.emails[matches[1]] = event.target.value;
        } else {
            data[event.target.name] = event.target.value;
        }

        this.setState({
            data,
        });
    }

    skip() {
        const { history } = this.props;

        this.setState({
            isSending: true,
        });

        Api.request({
            method: 'POST',
            url: 'auth/resend',
        })
            .then(() => {
                history.push('/auth/verifyEmail');
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                    isSending: false,
                });
            });
    }

    addMember() {
        const { data } = this.state;
        const { emails } = data;
        emails.push('');

        this.setState({
            data: {
                emails,
            },
        });
    }

    renderFormInput(email, i) {
        const { errors, data } = this.state;
        const { emails } = data;
        return (
            <FormInput
                key={i}
                labelText={trans('team_member_x', {
                    index: i + 1,
                })}
                validationError={errors.emails && errors.emails[i]}
                onChange={this.onChange}
                value={emails[i]}
                type="email"
                name={`emails[${i}]`}
                id={`emails${i}`}
                placeholder={trans('placeholders.email')}
            />
        );
    }

    render() {
        const { isSending, data } = this.state;

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView
                    title={trans('invite_your_team')}
                    subtitle={trans('invite_your_team_subtitle')}
                >
                    <form onSubmit={this.onSubmit}>
                        {data.emails.map(this.renderFormInput)}

                        <Row className="auth-add-more-members">
                            <Col className="text-right pb-2">
                                <button
                                    onClick={() => this.addMember()}
                                    type="button"
                                    className="btn btn-link pr-0"
                                >
                                    {trans('add_more_members')}
                                </button>
                                <p className="auth-add-more-members-text small text-muted">
                                    {trans('do_not_worry_you_can_add_more')}
                                </p>
                            </Col>
                        </Row>

                        <div className="d-flex align-items-center">
                            <FormTextButton
                                type="button"
                                text={trans('skip')}
                                onClick={() => this.skip()}
                                hidden={isSending}
                            />
                            <div className="ml-auto">
                                <FormButton
                                    text={trans('invite_and_setup')}
                                    disabled={isSending}
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(InviteMembersPage);

InviteMembersPage.propTypes = {
    history: PropTypes.object.isRequired,
};
