import * as moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import trans from '../localization';

const DateTime = ({ datetime, format, diff, className }) => {
    const instance = datetime ? moment(datetime) : moment();
    const fullText = instance.toISOString();
    const text = diff
        ? trans('x_ago', {
              diff: instance.fromNow(true),
          })
        : instance.format(format);

    return (
        <span title={fullText} className={className}>
            {text}
        </span>
    );
};

export default DateTime;

DateTime.propTypes = {
    datetime: PropTypes.string,
    format: PropTypes.string,
    diff: PropTypes.bool,
    className: PropTypes.string,
};

DateTime.defaultProps = {
    datetime: null,
    format: 'DD MMMM YYYY hh:mmA',
    diff: false,
    className: null,
};
