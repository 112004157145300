import React from 'react';
import PropTypes from 'prop-types';

const ImageIcon = props => {
    const { image, alt, className, ...others } = props;

    return (
        <div className={`${className}`} {...others}>
            <img src={image} alt={alt} />
        </div>
    );
};

export default ImageIcon;

ImageIcon.propTypes = {
    image: PropTypes.any.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
};

ImageIcon.defaultProps = {
    className: '',
};
