import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HoverTooltip } from '../index';
import fileTypeToIcon from '../../utils/fileTypeToIcon';
import trans from '../../localization';

export default class RespondAttachment extends Component {
    constructor(props) {
        super(props);
        this.inputAttachment = React.createRef();
    }

    supportedExtensions = () => {
        const { mimeTypes } = this.props;

        if (!mimeTypes) {
            return trans('loading');
        }

        const extensions = window._.uniq(
            mimeTypes.map(mimeType => {
                return mimeType.extension;
            })
        );

        return trans('supported_extensions', {
            extensions: extensions.join(' '),
        });
    };

    acceptableMimeTypes = () => {
        const { mimeTypes } = this.props;

        if (!mimeTypes) {
            return trans('loading');
        }

        const mimeTypeArray = window._.uniq(
            mimeTypes.map(mimeType => {
                return mimeType.mime_type;
            })
        );

        return mimeTypeArray.join(',');
    };

    onAttach = () => {
        // clear last selection
        this.inputAttachment.current.value = null;

        this.inputAttachment.current.click();
    };

    renderAttachment = (attachment, index) => {
        const type = attachment.type ? attachment.type : 'file';
        const target = React.createRef();
        const { onDeleteAttachment } = this.props;

        return (
            <HoverTooltip key={index} title={attachment.name}>
                <button
                    onClick={() => onDeleteAttachment(attachment)}
                    type="button"
                    ref={target}
                    className="attachment"
                >
                    <i className="icon-delete fa fa-times" />
                    <i className={`icon-file fa fa-${fileTypeToIcon(type)}`} />
                </button>
            </HoverTooltip>
        );
    };

    renderForwardAttachment = forwardAttachment => {
        const { onDeleteForwardAttachment } = this.props;
        return (
            <HoverTooltip
                key={`forward-attachment-${forwardAttachment.id}`}
                title={forwardAttachment.name}
            >
                <button
                    onClick={() => onDeleteForwardAttachment(forwardAttachment)}
                    data-toggle="tooltip"
                    data-placement="top"
                    type="button"
                    className="attachment"
                >
                    <i className="icon-delete fa fa-times" />
                    <i className={`icon-file fa fa-${fileTypeToIcon(forwardAttachment.type)}`} />
                </button>
            </HoverTooltip>
        );
    };

    renderParent = children => {
        const { showMimeTypes } = this.props;
        if (!showMimeTypes) {
            return <>{children}</>;
        }

        return <HoverTooltip title={this.supportedExtensions()}>{children}</HoverTooltip>;
    };

    render() {
        const { attachments, forwardAttachments, onAttach } = this.props;

        return (
            <div className="respond-attatchments">
                {this.renderParent(
                    <button type="button" onClick={this.onAttach} className="expand-options">
                        <i className="fas fa-paperclip" />
                        <input
                            ref={this.inputAttachment}
                            onChange={onAttach}
                            id="file"
                            multiple
                            type="file"
                            name="attach"
                            accept={this.acceptableMimeTypes()}
                            className="d-none"
                        />
                    </button>
                )}

                {(attachments.length > 0 || forwardAttachments.length > 0) && (
                    <div className="extra-options attachments">
                        {attachments.length > 0 && attachments.map(this.renderAttachment)}
                        {forwardAttachments.length > 0 &&
                            forwardAttachments.map(this.renderForwardAttachment)}
                    </div>
                )}
            </div>
        );
    }
}

RespondAttachment.propTypes = {
    forwardAttachments: PropTypes.array,
    attachments: PropTypes.array,
    onAttach: PropTypes.func,
    onDeleteAttachment: PropTypes.func,
    onDeleteForwardAttachment: PropTypes.func,
    mimeTypes: PropTypes.array,
    showMimeTypes: PropTypes.bool,
};

RespondAttachment.defaultProps = {
    forwardAttachments: [],
    attachments: [],
    onAttach: () => {},
    onDeleteAttachment: () => {},
    onDeleteForwardAttachment: () => {},
    mimeTypes: null,
    showMimeTypes: true,
};
