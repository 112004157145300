import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import URL from './URL';

const FormTextButton = props => {
    const { text, type, to, onClick, hidden } = props;

    return type === 'link' ? (
        <div className="dc-form-text-button align-items-center">
            <Link to={to} onClick={onClick}>
                {text}
            </Link>
        </div>
    ) : (
        <div className="dc-form-text-button align-items-center">
            <URL className="dc-form-text-btn" onClick={onClick} hidden={hidden}>
                {text}
            </URL>
        </div>
    );
};

export default FormTextButton;

FormTextButton.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
    hidden: PropTypes.bool,
};

FormTextButton.defaultProps = {
    type: 'link',
    to: null,
    onClick: () => {},
    hidden: false,
};
