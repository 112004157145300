import cx from 'classnames';
import React from 'react';

export default class MapperService {
    static mapAutoComplete(x) {
        return {
            id: x.id,
            name: x.name,
            value: x.name,
            label: (
                <div
                    className={cx(
                        `list-group-item list-group-item-action active font-weight-normal py-1 pl-4 cursor-pointer`
                    )}
                >
                    {x.name}
                </div>
            ),
        };
    }
}
