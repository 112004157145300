import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { errorEmail } from '../../assets/images';
import { Api, AuthService } from '../../services';
import { AuthCardView } from '../../components/auth';
import authService from '../../services/AuthService';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';
import ApiService from '../../services/ApiService';

class VerifyPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            checking: true,
        };
    }

    componentDidMount() {
        this.tryToVerify();
    }

    tryToVerify() {
        const query = ApiService.formatSearch(this.props);

        const { history } = this.props;

        Api.request({
            method: 'GET',
            url: `auth/verify?${query}`,
        })
            .then(() => {
                if (authService.user()) {
                    const user = authService.user();
                    user.email_verified = true;
                    AuthService.setUser(user);
                }

                history.push('/');
            })
            .catch(() => {
                this.setState({
                    checking: false,
                });
            });
    }

    render() {
        const { checking } = this.state;

        return (
            <AuthLayout isLoading={checking}>
                <AuthCardView
                    title={trans('you_are_all_ready_to_go')}
                    subtitle={trans('you_are_all_ready_to_go_subtitle')}
                    titleSmall={trans('verify_your_email')}
                    centerHeadingSmall
                >
                    <div hidden={checking}>
                        <div className="auth-email-sent text-center pt-5">
                            <img
                                src={errorEmail}
                                alt={trans('verification_failed')}
                                className="auth-email-sent-image img-fluid mb-5 mt-2"
                            />

                            <h2 className="auth-email-sent-text text-center font-weight-bold">
                                {trans('something_went_wrong')}
                            </h2>
                        </div>

                        <div className="auth-email-sent-message">
                            <p className="mb-0 mt-4 pt-2 text-center text-muted">
                                {trans('we_will_email_you_about_setup')}
                            </p>
                        </div>
                    </div>
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(VerifyPage);

VerifyPage.propTypes = {
    history: PropTypes.object.isRequired,
};
