import React from 'react';
import PropTypes from 'prop-types';

const TicketIcon = props => {
    const { ticket, className } = props;
    const image = ticket.source && ticket.source.image;
    const alt = ticket.source && ticket.source.name ? ticket.source.name : 'channel logo';

    if (image) {
        return <img className="circle-icon" src={image} alt={alt} />;
    }

    const icon = ticket.source && ticket.source.icon ? ticket.source.icon : 'envelope';

    return (
        <div className={`circle-icon bg-color-${ticket.source.color} ${className}`}>
            <i className={`fas fa-${icon}`} />
        </div>
    );
};

export default TicketIcon;

TicketIcon.propTypes = {
    ticket: PropTypes.object,
    className: PropTypes.string,
};

TicketIcon.defaultProps = {
    ticket: null,
    className: '',
};
