import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import fileTypeToIcon from '../../../../utils/fileTypeToIcon';
import trans from '../../../../localization';
import { DateTime, HoverTooltip } from '../../../index';
import { Api } from '../../../../services';
import notify from '../../../../utils/notify';
import dialog from '../../../../utils/dialog';

export default class TicketAttachments extends Component {
    constructor(props) {
        super(props);

        const { attachment, attachments } = props;

        this.state = {
            attachments,
            isShowing: !!attachment,
            attachment,
            next: null,
            prev: null,
            index: -1,
        };

        this.handleClose = this.handleClose.bind(this);
        this.goToNext = this.goToNext.bind(this);
        this.goToPrev = this.goToPrev.bind(this);
        this.forward = this.forward.bind(this);
        this.delete = this.delete.bind(this);
    }

    openPreview(index) {
        const { attachments } = this.state;

        this.setState({
            index,
            isShowing: true,
            attachment: attachments[index],
            next: index + 1 < attachments.length ? index + 1 : null,
            prev: index > 0 ? index - 1 : null,
        });
    }

    delete() {
        const { attachment } = this.state;
        dialog.deleteModelConfirm(attachment.name).then(() => {
            const { attachments, index } = this.state;

            Api.request({
                url: `ticketAttachments/${attachment.id}`,
                method: 'DELETE',
            }).then(() => {
                attachments.splice(index, 1);

                this.setState(
                    {
                        attachments,
                    },
                    () => {
                        notify.deleted();
                        this.handleClose();
                    }
                );
            });
        });
    }

    forward() {
        const { attachment } = this.state;
        const { onForward } = this.props;

        onForward(attachment);
        this.handleClose();
    }

    handleClose() {
        this.setState(
            {
                isShowing: false,
                attachment: null,
                next: null,
                prev: null,
                index: -1,
            },
            () => {
                const { onHide } = this.props;
                onHide();
            }
        );
    }

    goToNext() {
        const { next } = this.state;
        if (next !== null) {
            this.openPreview(next);
        }
    }

    goToPrev() {
        const { prev } = this.state;
        if (prev !== null) {
            this.openPreview(prev);
        }
    }

    renderCloseButton() {
        return (
            <button
                onClick={this.handleClose}
                type="button"
                className="mt-2 btn text-white file-close-button"
            >
                <i className="fa fa-times fa-fw" />
                {trans('close')}
            </button>
        );
    }

    renderExtraInfo() {
        const { attachment } = this.state;

        // noinspection JSUnresolvedVariable
        return (
            <div>
                <p className="m-0 file-info-extra">
                    {trans('uploaded_by_x', {
                        name: attachment.uploader,
                    })}
                </p>
                <p className="m-0 file-info-extra">
                    <DateTime datetime={attachment.created_at} />
                </p>
            </div>
        );
    }

    static renderPreviewImage(attachment) {
        // noinspection JSUnresolvedVariable
        if (attachment.preview_url) {
            // noinspection JSUnresolvedVariable
            return (
                <img
                    src={attachment.preview_url}
                    alt={`${attachment.name} Preview`}
                    className="w-100 h-100"
                />
            );
        }

        return <div />;
    }

    renderPreview() {
        const { attachment } = this.state;

        return (
            <Col className="d-flex flex-column align-items-center justify-content-center">
                <img src={attachment.url} alt={attachment.name} className="img-fluid" />
            </Col>
        );
    }

    renderInformation() {
        const { attachment } = this.state;

        return (
            <Col className="d-flex flex-column align-items-center justify-content-center file-info">
                <i className={`file-big-icon fa-7x fa fa-${fileTypeToIcon(attachment.type)}`} />
                <h3 className="file-info-title text-white">{trans('file_cannot_viewed')}</h3>
                <p className="file-info-text">{trans('need_to_download_file')}</p>
                <p className="file-info-name">{attachment.name}</p>
                <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary file-info-button"
                >
                    <i className="fa-download fas mr-2" />
                    {trans('download_file')}
                </a>

                {this.renderExtraInfo()}
                {this.renderCloseButton()}
            </Col>
        );
    }

    renderModal() {
        const { attachment, next, prev } = this.state;

        // noinspection JSUnresolvedVariable
        return (
            <Container fluid>
                <Row>
                    <Col sm={1} className="d-flex justify-content-end align-items-center">
                        <button
                            onClick={this.goToPrev}
                            disabled={prev === null}
                            type="button"
                            className="btn btn-lg text-white"
                        >
                            <i className="fa fa-chevron-left" />
                        </button>
                    </Col>
                    <Col>
                        <Row noGutters className="flex-column justify-content-around min-h-90vh">
                            <Col bsPrefix="col-auto" className="mb-1 w-100">
                                <Row>
                                    {attachment.preview_url && (
                                        <Col className="text-white small">
                                            {this.renderExtraInfo()}
                                        </Col>
                                    )}
                                    <Col className="d-flex justify-content-end align-items-end">
                                        <a
                                            href={attachment.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn text-white"
                                            title={trans('download')}
                                        >
                                            <i className="fa-download fas" />
                                        </a>

                                        <button
                                            type="button"
                                            title={trans('forward')}
                                            onClick={this.forward}
                                            className="btn text-white"
                                        >
                                            <i className="fa-share fas" />
                                        </button>

                                        <button
                                            type="button"
                                            title={trans('delete_attachment')}
                                            onClick={this.delete}
                                            className="btn text-white"
                                        >
                                            <i className="fa-trash fas" />
                                        </button>
                                    </Col>
                                </Row>
                            </Col>

                            {attachment.preview_url
                                ? this.renderPreview()
                                : this.renderInformation()}

                            {attachment.preview_url && (
                                <Col bsPrefix="col-auto" className="d-flex justify-content-center">
                                    {this.renderCloseButton()}
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col sm={1} className="d-flex justify-content-start align-items-center">
                        <button
                            onClick={this.goToNext}
                            disabled={next === null}
                            type="button"
                            className="btn btn-lg text-white"
                        >
                            <i className="fa fa-chevron-right" />
                        </button>
                    </Col>
                </Row>
            </Container>
        );
    }

    render() {
        const { isShowing } = this.state;
        const { attachments } = this.props;

        return (
            <div className="attachment-list">
                {attachments.map((attachment, index) => (
                    <HoverTooltip key={attachment.id} title={attachment.name}>
                        <button
                            type="button"
                            className="file"
                            onClick={() => this.openPreview(index)}
                        >
                            <div className="opacity" />
                            {TicketAttachments.renderPreviewImage(attachment)}
                            <i className={`fa fa-${fileTypeToIcon(attachment.type)}`} />
                        </button>
                    </HoverTooltip>
                ))}

                <Modal
                    show={isShowing}
                    centered
                    onHide={this.handleClose}
                    backdrop="static"
                    className="modal-dialog-attachment"
                >
                    {isShowing && this.renderModal()}
                </Modal>
            </div>
        );
    }
}

TicketAttachments.propTypes = {
    attachments: PropTypes.array,
    attachment: PropTypes.object,
    onHide: PropTypes.func,
    onForward: PropTypes.func,
};

TicketAttachments.defaultProps = {
    attachments: [],
    attachment: null,
    onHide: () => {},
    onForward: () => {},
};
