import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthService } from '../services';
import NotFoundPage from '../pages/errors/NotFoundPage';

class Kernel {
    static middlewares = {
        auth: next => Kernel.authRoute(next),
        hasNotPassword: next => Kernel.hasNotPassword(next),
        notVerified: next => Kernel.notVerified(next),
        guest: next => Kernel.guestRoute(next),
        newUser: next => Kernel.newUser(next),
    };

    boot(routes, extra) {
        this.routes = routes;
        return (
            <Switch>
                {this.routes.map(route => Kernel.route(route))}
                {extra || ''}
            </Switch>
        );
    }

    static renderLayout(route) {
        const { component: Component } = route;

        return (
            <Component>
                <Switch>{route.children.map(child => Kernel.route(child))}</Switch>
            </Component>
        );
    }

    static route(route) {
        const exact = route.exact === undefined ? true : route.exact;

        if (route.children) {
            return (
                <Route
                    key={route.path}
                    path={route.path}
                    exact={exact}
                    render={() => Kernel.renderLayout(route)}
                />
            );
        }

        return (
            <Route
                key={route.path}
                path={route.path}
                exact={exact}
                render={props => Kernel.handle(route, props)}
            />
        );
    }

    static handle(route, props) {
        const routeMiddlewares = route.middleware ? route.middleware : [];
        let response = null;
        try {
            if (routeMiddlewares.length === 0) {
                response = Kernel.render(route);
            }

            for (let i = 0; i < routeMiddlewares.length; i += 1) {
                response = Kernel.middlewares[routeMiddlewares[i]](route);
                if (response.status === false) {
                    break;
                }
            }

            let isValid = true;
            if (route.parameters) {
                const { match } = props;
                window._.each(route.parameters, (regex, name) => {
                    if (!regex.test(match.params[name])) {
                        isValid = false;
                    }
                });
            }

            // noinspection JSUnresolvedVariable
            return isValid ? response.component : <NotFoundPage />;
        } catch (e) {
            // handle error here
        }

        return null;
    }

    static hasNotPassword(route) {
        if (AuthService.hasPassword() === false) {
            return Kernel.render(route);
        }

        // eslint-disable-next-line no-console
        console.warn('Middleware Rejected: _hasNotPassword', route, AuthService.user());

        if (AuthService.guest()) {
            return Kernel.redirectTo('/auth/signIn', route.path);
        }

        return Kernel.redirectTo('/', route.path);
    }

    static notVerified(route) {
        if (AuthService.emailVerified() === false) {
            return Kernel.render(route);
        }

        // eslint-disable-next-line no-console
        console.warn('Middleware Rejected: _notVerified', route, AuthService.user());

        if (AuthService.guest()) {
            return Kernel.redirectTo('/auth/signIn', route.path);
        }

        return Kernel.redirectTo('/', route.path);
    }

    static authRoute(route) {
        if (AuthService.check()) {
            return Kernel.render(route);
        }

        // eslint-disable-next-line no-console
        console.warn('Middleware Rejected: _authRoute', route, AuthService.user());

        const user = AuthService.user();
        if (user) {
            // noinspection JSUnresolvedVariable
            if (!user.has_password) {
                return Kernel.redirectTo('/auth/setPassword', route.path);
            }

            if (!user.email_verified) {
                return Kernel.redirectTo('/auth/verifyEmail', route.path);
            }
        }

        return Kernel.redirectTo('/auth/signIn', route.path);
    }

    static guestRoute(route) {
        if (AuthService.guest()) {
            return Kernel.render(route);
        }

        // eslint-disable-next-line no-console
        console.warn('Middleware Rejected: _guestRoute', route, AuthService.user());
        return Kernel.redirectTo('/', route.path);
    }

    static newUser(route) {
        if (AuthService.guest()) {
            return Kernel.render(route);
        }

        if (!AuthService.hasPassword()) {
            return Kernel.render(route);
        }

        // if (!AuthService.emailVerified()) {
        //     return Kernel.render(route);
        // }

        return Kernel.redirectTo('/', route.path);
    }

    static render({ component: Component }) {
        return {
            status: true,
            component: <Component />,
        };
    }

    static redirectTo(pathname, from = '/') {
        return {
            status: false,
            component: (
                <Redirect
                    to={{
                        pathname,
                        state: { from },
                    }}
                />
            ),
        };
    }
}

export default Kernel;
