import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HoverTooltip } from '../index';
import trans from '../../localization';
import { Api } from '../../services';

export default class ResponseAISuggestion extends Component {
    constructor(props) {
        super(props);

        // noinspection JSUnresolvedVariable
        this.state = {
            loading: false,
        };
    }

    onClick = () => {
        const { ticketId, onGenerated } = this.props;

        this.setState({
            loading: true,
        });

        Api.request({
            method: 'POST',
            url: `tickets/${ticketId}/generateSuggestion`,
        })
            .then(response => {
                if (response.data && onGenerated) {
                    onGenerated(response.data);
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    renderParent = children => {
        return <HoverTooltip title={trans('generate_ai_suggestions')}>{children}</HoverTooltip>;
    };

    render() {
        const { loading } = this.state;

        return (
            <div className="respond-attatchments mr-2">
                {this.renderParent(
                    <button
                        onClick={this.onClick}
                        type="button"
                        className="expand-options bg-color-edge_blue"
                        disabled={loading}
                    >
                        <i className={`fas fa-robot text-white ${loading && 'fa-spin'}`} />
                    </button>
                )}
            </div>
        );
    }
}

ResponseAISuggestion.defaultProps = {
    ticketId: PropTypes.any.isRequired,
    onGenerated: PropTypes.func,
};
