import router from '../utils/router';

export default class Routes {
    static toChannels(history, company) {
        router.toCompany(history, company, 'channels');
    }

    static toEditChannel(history, company, channel) {
        router.toCompany(history, company, `channels/${channel.id}/edit/${channel.type}`);
    }
}
