import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import definitions from '../../definitions';
import { Api } from '../../services';
import StepCarousel from '../../components/StepCarousel';
import { AppLayout } from '../../layouts';
import { CarouselPane } from '../../components';
import router from '../../utils/router';

class EbayCallbackPage extends PureComponent {
    componentDidMount() {
        const channelId = localStorage.getItem(definitions.CALLBACK_EBAY_CHANNEL_ID);
        if (channelId) {
            this.fetchChannel(channelId);
        } else {
            const { history } = this.props;
            history.push(`/`);
        }
    }

    fetchChannel(channelId) {
        const { history, declined } = this.props;
        return Api.request({
            method: 'GET',
            url: `channels/${channelId}`,
            params: {
                company: true,
            },
        })
            .then(response => {
                const channel = response.data.data;

                if (declined) {
                    router.toCompany(
                        history,
                        channel.company,
                        `channels/${channel.id}/setup/ebay/error`
                    );
                } else {
                    router.toCompany(
                        history,
                        channel.company,
                        `channels/${channel.id}/setup/ebay/connect`
                    );
                }
            })
            .catch(() => {
                history.push(`/`);
            });
    }

    render() {
        return (
            <AppLayout isLoading>
                <StepCarousel step={0}>
                    <CarouselPane />
                </StepCarousel>
            </AppLayout>
        );
    }
}

export default withRouter(EbayCallbackPage);

EbayCallbackPage.propTypes = {
    history: PropTypes.object.isRequired,
    declined: PropTypes.bool,
};

EbayCallbackPage.defaultProps = {
    declined: false,
};
