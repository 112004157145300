import React from 'react';
import PropTypes from 'prop-types';

const ManageLayoutIllustrationTR = props => {
    const { theme } = props;
    const themeColors = {
        purple: {
            lightColor: '#cc88e8',
            darkColor: '#9b59b6',
        },
        green: {
            lightColor: '#8ed7a5',
            darkColor: '#1abc9c',
        },
        orange: {
            lightColor: '#FFCB78',
            darkColor: '#D9A24A',
        },
        blue: {
            lightColor: '#25BBF3',
            darkColor: '#3596D4',
        },
        red: {
            lightColor: '#ff7878',
            darkColor: '#d94a51',
        },
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="297.988"
            height="210.511"
            viewBox="0 0 297.988 210.511"
        >
            <g transform="translate(-679.287 -450.067)">
                <path
                    d="M39.5,0,79,67.142H0Z"
                    transform="matrix(0.966, -0.259, 0.259, 0.966, 679.287, 470.514)"
                    fill={themeColors[theme].lightColor}
                />
                <g
                    transform="translate(867.275 595.578)"
                    fill="none"
                    stroke={themeColors[theme].darkColor}
                    strokeWidth="6"
                >
                    <circle cx="32.5" cy="32.5" r="32.5" stroke="none" />
                    <circle cx="32.5" cy="32.5" r="29.5" fill="none" />
                </g>
                <g transform="translate(716.33 626.184) rotate(30)" fill="none">
                    <path d="M10.666,0,21.332,18.285H0Z" stroke="none" />
                    <path
                        d="M 10.66618251800537 3.969272613525391 L 3.482076644897461 16.28488159179688 L 12.25791263580322 16.28488159179688 L 17.85028839111328 16.28488159179688 L 10.66618251800537 3.969272613525391 M 10.66618251800537 0 L 21.33236312866211 18.28488159179688 L 12.25791263580322 18.28488159179688 L 1.9073486328125e-06 18.28488159179688 L 10.66618251800537 0 Z"
                        stroke="none"
                        fill={themeColors[theme].lightColor}
                    />
                </g>
                <circle
                    cx="16.5"
                    cy="16.5"
                    r="16.5"
                    transform="translate(944.275 460.578)"
                    fill={themeColors[theme].darkColor}
                />
            </g>
        </svg>
    );
};

export default ManageLayoutIllustrationTR;

ManageLayoutIllustrationTR.propTypes = {
    theme: PropTypes.string.isRequired,
};
