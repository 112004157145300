import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DateTime, HoverTooltip } from '../../index';
import Ticket from '../ticket/Ticket';
import TicketIcon from '../ticket/TicketIcon';
import TicketId from '../ticket/TicketId';
import TicketPriority from '../ticket/TicketPriority';
import TicketOverdue from '../ticket/TicketOverdue';
import TicketResolution from '../ticket/TicketResolution';
import MultipleUserIcon from '../MultipleUserIcon';
import router from '../../../utils/router';
import TicketRepliedAt from '../ticket/TicketRepliedAt';
import trans from '../../../localization';

const DOUBLE_CLICK_DELAY = 250;

class TicketListItem extends PureComponent {
    constructor(props) {
        super(props);

        this.clickTimeout = null;
    }

    handleClicks = () => {
        if (this.clickTimeout !== null) {
            this.expandTicket();
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        } else {
            this.clickTimeout = setTimeout(() => {
                const { onOpenTicket } = this.props;
                onOpenTicket();
                clearTimeout(this.clickTimeout);
                this.clickTimeout = null;
            }, DOUBLE_CLICK_DELAY);
        }
    };

    expandTicket() {
        const { ticket, company, history } = this.props;
        router.toCompany(history, company, `tickets/${ticket.id}`);
        return false;
    }

    render() {
        const { ticket, company, active, onSelectTicket, selected } = this.props;
        const hasTicketViewers = ticket.viewers && ticket.viewers.length > 0;
        const ticketViewClass = hasTicketViewers ? 'ticket-container-view' : '';
        const names = hasTicketViewers
            ? ticket.viewers.map(viewer => viewer.user.name).join(',')
            : '';

        return (
            <div key={ticket.id} className="ticket-wrapper">
                <div className="custom-checkbox">
                    <input
                        type="checkbox"
                        className="checkbox"
                        name={`${ticket.id}_checkbox`}
                        id={`select-${ticket.id}`}
                        value={ticket.id}
                        checked={selected}
                        onChange={() => onSelectTicket(ticket.id)}
                    />
                    <div className="cont">
                        <label htmlFor={`select-${ticket.id}`}>
                            <i className="fas fa-check" />
                        </label>
                    </div>
                </div>

                <div
                    className={`ticket-container ${ticketViewClass}`}
                    role="button"
                    tabIndex={0}
                    onClick={this.handleClicks}
                >
                    <Ticket ticket={ticket} active={active}>
                        <div className="text-truncate ticket-ref">
                            <TicketIcon ticket={ticket} />
                            <div className="flex-column sender">
                                <h3>{ticket.customer.name}</h3>
                                <p className="text-truncate d-flex align-items-center">
                                    <span className="mxw-210 overflow-hidden text-truncate">
                                        {ticket.source.name}
                                    </span>
                                    <TicketId ticket={ticket} company={company} />
                                </p>
                            </div>
                        </div>
                        <div className="split-message">{ticket.subject}</div>
                        <div className="text-truncate ml-auto d-flex">
                            {hasTicketViewers && (
                                <HoverTooltip title={trans('viewing_by', { names })}>
                                    <div className="mr-2">
                                        <i className="fa fa-eye text-primary" />
                                    </div>
                                </HoverTooltip>
                            )}
                            {!!ticket.resolution_at && (
                                <div className="ticket-resolution">
                                    <TicketResolution
                                        datetime={ticket.resolution_at}
                                        format="MMM D YYYY, hh:mm A"
                                    />
                                </div>
                            )}
                            <TicketPriority ticket={ticket} className="ml-4" />
                        </div>
                        <div className="text-truncate ticket-time ml-4 d-flex flex-column">
                            <DateTime datetime={ticket.last_message_at} diff />
                            <div className="ticket-overdue d-flex justify-content-end pt-1 text-xsmall">
                                <TicketRepliedAt
                                    isReplied={ticket.is_replied}
                                    lastUnRepliedMessageAt={ticket.last_unreplied_message_at}
                                />
                            </div>
                            {!!ticket.due_at && (
                                <div className="ticket-overdue d-flex justify-content-end pt-1 text-xsmall">
                                    <TicketOverdue
                                        format="MMM D YYYY, hh:mm A"
                                        datetime={ticket.due_at}
                                        isOverdue={ticket.is_overdue}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="text-right ticket-agent ml-4">
                            <MultipleUserIcon users={ticket.users} />
                        </div>
                    </Ticket>
                </div>
            </div>
        );
    }
}

export default withRouter(TicketListItem);

TicketListItem.propTypes = {
    ticket: PropTypes.object,
    company: PropTypes.object,
    onOpenTicket: PropTypes.func,
    onSelectTicket: PropTypes.func,
    active: PropTypes.bool,
    selected: PropTypes.bool,
    history: PropTypes.any.isRequired,
};

TicketListItem.defaultProps = {
    ticket: null,
    company: null,
    onOpenTicket: () => {},
    onSelectTicket: () => {},
    active: null,
    selected: false,
};
