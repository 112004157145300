import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

const FormInput = props => {
    const {
        labelText,
        id,
        type,
        name,
        validationError,
        rightLink,
        rightLinkTo,
        className,
        ...others
    } = props;

    const inputId = id || `input${name}` || undefined;

    return (
        <div className={`form-group ${className}`}>
            <label className="dc-form-label" htmlFor={inputId}>
                {labelText}
            </label>
            <div className={classNames({ 'input-group': rightLink })}>
                <input
                    className={classNames(
                        'form-control',
                        'dc-form-input',
                        {
                            'is-invalid': validationError,
                        },
                        {
                            'dc-input-with-right-link': rightLink,
                        }
                    )}
                    type={type}
                    name={name || id}
                    id={inputId}
                    aria-describedby="basic-addon2"
                    {...others}
                />
                {validationError ? <div className="invalid-feedback">{validationError}</div> : ''}
                {rightLink ? (
                    <div className="input-group-append d-flex align-items-center dc-input-right-link">
                        <Link
                            className="dc-dark-link dc-gray-link"
                            to={rightLinkTo}
                        >{`${rightLink} `}</Link>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FormInput;

FormInput.propTypes = {
    labelText: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    type: PropTypes.string,
    validationError: PropTypes.string,
    rightLink: PropTypes.string,
    rightLinkTo: PropTypes.string,
    className: PropTypes.string,
};

FormInput.defaultProps = {
    placeholder: null,
    id: null,
    type: 'text',
    validationError: null,
    rightLink: null,
    rightLinkTo: null,
    className: '',
};
