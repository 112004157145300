import React from 'react';
import PropTypes from 'prop-types';

const ManageField = ({ title, children, className, ...others }) => {
    return (
        <div className="manage-field" {...others}>
            {title && <div className={`manage-field-label ${className}`}>{title}</div>}
            <div className="manage-field-content">{children}</div>
        </div>
    );
};

export default ManageField;

ManageField.propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.any,
    className: PropTypes.string,
};

ManageField.defaultProps = {
    title: null,
    className: '',
};
