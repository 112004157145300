import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { AppLayout } from '../../layouts';
import { AuthService } from '../../services';
import trans from '../../localization';

const SignOutPage = props => {
    const { history } = props;

    AuthService.logout().then(() => {
        history.push('/auth/signIn');
    });

    return <AppLayout isLoading>{trans('terminating_session')}</AppLayout>;
};

export default withRouter(SignOutPage);

SignOutPage.propTypes = {
    history: PropTypes.object.isRequired,
};
