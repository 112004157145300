import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import { CarouselPane, StepCarousel } from '../../../components';
import trans from '../../../localization';
import CheckboxBorderContainer from '../../../components/helpdesk/CheckboxBorderContainer';
import { ManageField, ManageInput } from '../../../elements';
import router from '../../../utils/router';

class ListPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            companyId: match.params.companyId,
            company: null,
            user: AuthService.user(),
            ticketRules: {
                active: [
                    {
                        id: 1,
                        title: 'Apply all tickets to spam',
                        description: 'This removes all tickets from inbox and puts to spam',
                    },
                    {
                        id: 2,
                        title: 'Apply all tickets to spam',
                        description: 'This removes all tickets from inbox and puts to spam',
                    },
                    {
                        id: 3,
                        title: 'Apply all tickets to spam',
                        description: 'This removes all tickets from inbox and puts to spam',
                    },
                    {
                        id: 4,
                        title: 'Apply all tickets to spam',
                        description: 'This removes all tickets from inbox and puts to spam',
                    },
                    {
                        id: 5,
                        title: 'Apply all tickets to spam',
                        description: 'Used for any new costumers coming into the helpdesk.',
                    },
                    {
                        id: 6,
                        title: 'Apply all tickets to spam',
                        description: 'Used for any new costumers coming into the helpdesk.',
                    },
                ],

                inactive: [
                    {
                        id: 7,
                        title: 'Apply all tickets to spam',
                        description: 'Used for any new costumers coming into the helpdesk.',
                    },
                ],
            },
            selectedRules: [],
            isLoading: true,
            query: '',
        };
    }

    componentDidMount() {
        const promises = [this.fetchCompany(), this.fetchTicketRules()];
        const fetchPromises = Promise.all(promises);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany = () => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    };

    fetchTicketRules = (params = {}) => {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: 'ticketRules',
            params: {
                company_id: companyId,
                ...params,
            },
        }).then(response => {
            const active = [];
            const inactive = [];

            response.data.data.forEach(x => (x.is_active ? active.push(x) : inactive.push(x)));

            this.setState({
                ticketRules: {
                    active,
                    inactive,
                },
            });
        });
    };

    deleteSelectedRules = () => {
        const { selectedRules, ticketRules } = this.state;

        const setLoading = (isLoading = false) => {
            this.setState({ isLoading });
        };

        setLoading(true);

        Api.request({
            method: 'DELETE',
            url: `ticketRules/bulk/delete`,
            data: { ids: selectedRules },
        })
            .then(() => {
                this.setState({
                    selectedRules: [],
                    ticketRules: {
                        ...ticketRules,
                        inactive: ticketRules.inactive.filter(
                            x => selectedRules.indexOf(x.id) === -1
                        ),
                    },
                });
            })
            .finally(setLoading);
    };

    onSearch = e => {
        const query = e.target.value;
        const setLoading = (isLoading = false) => {
            this.setState({ isLoading });
        };

        setLoading(true);
        this.setState({ query }, () => this.fetchTicketRules({ query }).finally(setLoading));
    };

    clearSearch = () => {
        this.onSearch({ target: { value: '' } });
    };

    onCreateClick = () => {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company, 'ticketRules/create');
    };

    openRule = rule => {
        const { history } = this.props;
        const { company } = this.state;
        router.toCompany(history, company, `ticketRules/${rule.id}`);
    };

    selectRule = rule => {
        const { selectedRules } = this.state;
        const index = selectedRules.indexOf(rule.id);
        this.setState({
            selectedRules:
                index > -1
                    ? selectedRules.filter(x => x.id !== rule.id)
                    : [...selectedRules, rule.id],
        });
    };

    goBack = () => {
        const { history } = this.props;
        const { company } = this.state;

        router.toCompany(history, company);
    };

    renderTicketRules = type => {
        const { ticketRules } = this.state;

        return (
            <div>
                <div className="pb-4 d-flex justify-content-space-between align-items-center flex-wrap">
                    <span className="font-weight-bold text-large">
                        {trans('rules_title', { type: trans(`rule_types.${type}`) })}
                    </span>
                    {type === 'inactive' && ticketRules[type].length > 0 && (
                        <button
                            onClick={this.deleteSelectedRules}
                            type="button"
                            className="btn p-0 text-red text-xsmall ml-auto no-outline-text-btn"
                        >
                            <i className="fas fa-trash-alt pr-1" />
                            {trans('delete_rules')}
                        </button>
                    )}
                </div>
                <div className="d-flex flex-wrap">
                    {type === 'active' && (
                        <div
                            role="button"
                            tabIndex="0"
                            onClick={this.onCreateClick}
                            className="pr-3 pb-4"
                            key="create-ticket-rule"
                        >
                            <div className="checkbox-border-container dashed-db-border align-items-center">
                                <span className="text-light-gray">{trans('new_rule')}</span>
                            </div>
                        </div>
                    )}

                    {ticketRules[type].map(x => (
                        <div className="pr-3 pb-4" key={`ticket-rule-${x.id}`}>
                            <CheckboxBorderContainer
                                id={`${x.id}`}
                                onSelect={() => this.selectRule(x)}
                                onClick={() => this.openRule(x)}
                            >
                                <span className="font-weight-bold text-small text-dark">
                                    {x.name}
                                </span>
                                <span className="text-2xsmall text-offblack">{x.description}</span>
                            </CheckboxBorderContainer>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    render() {
        const { user, company, query, isLoading, ticketRules } = this.state;

        return (
            <ManageLayout
                theme="purple"
                user={user}
                company={company}
                isLoading={isLoading}
                hideLBImg
            >
                <StepCarousel>
                    <CarouselPane
                        onPrev={this.goBack}
                        className="justify-content-start pt-2"
                        scroll
                        fluid
                    >
                        <div style={{ maxWidth: 600 }}>
                            <div className="d-flex flex-column pt-3 pb-5">
                                <span className="font-weight-bold text-3xlarge pb-3">
                                    {trans('ticket_rules')}
                                </span>
                                <span className="font-weight-light text-darker-gray">
                                    {trans('ticket_rules_text')}
                                </span>
                            </div>
                            <div className="pb-5">
                                <ManageField>
                                    <ManageInput
                                        placeholder={trans('ticket_rules_search')}
                                        value={query}
                                        onChange={this.onSearch}
                                        type="text"
                                    />
                                </ManageField>
                                <div
                                    className={`d-flex align-items-center ${
                                        query ? '' : 'invisible'
                                    }`}
                                >
                                    <button
                                        type="button"
                                        onClick={this.clearSearch}
                                        className="btn ml-auto text-xsmall text-light-gray px-2 py-1"
                                    >
                                        <i className="fas fa-times mr-2" />
                                        <span>{trans('clear_search')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-4">
                            {this.renderTicketRules('active')}
                        </div>
                        {ticketRules.inactive.length > 0 && (
                            <div className="d-flex flex-column mb-4">
                                {this.renderTicketRules('inactive')}
                            </div>
                        )}
                    </CarouselPane>
                </StepCarousel>
            </ManageLayout>
        );
    }
}

ListPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(ListPage);
