import React from 'react';
import PropTypes from 'prop-types';
import TicketInfo from './TicketInfo';

const TicketReply = props => {
    const { activity } = props;

    const { ticket, company } = activity;

    return (
        <div className="activity-ticket-reply d-flex flex-column p-3">
            <TicketInfo ticket={ticket} company={company} />
            <p className="text-dark text-small mb-0 break-word">{activity.new}</p>
        </div>
    );
};

export default TicketReply;

TicketReply.propTypes = {
    activity: PropTypes.object,
};

TicketReply.defaultProps = {
    activity: null,
};
