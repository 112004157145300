import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FilterGroupItem from './FilterGroupItem';
import trans from '../../localization';

export default class FilterGroup extends Component {
    constructor(props) {
        super(props);

        const { defaultSelectedItems } = props;

        this.state = {
            selectedItems: defaultSelectedItems.map(x => x.id || x) || [],
        };

        this.getCount = this.getCount.bind(this);
    }

    getCount(name) {
        const { itemCounts } = this.props;

        if (itemCounts && itemCounts[name]) {
            return itemCounts[name];
        }

        return 0;
    }

    clickItem(id) {
        const { selectedItems } = this.state;
        const { onChange } = this.props;

        const index = selectedItems.indexOf(id);
        if (index > -1) {
            selectedItems.splice(index, 1);
        } else {
            selectedItems.push(id);
        }

        this.setState(
            {
                selectedItems,
            },
            () => {
                onChange(selectedItems);
            }
        );
    }

    render() {
        const { selectedItems } = this.state;
        const { onShowOrHide, title, totalCount, itemTransPrefix, items, isShowing } = this.props;

        return (
            <div className="filter-option mx-4">
                <div className="list-group">
                    <div className="list-group-title">
                        {onShowOrHide ? (
                            <button
                                className="align-items-center bg-white border-0 d-flex font-weight-bold justify-content-between m-0 mb-2 p-0 small text-muted w-100"
                                type="button"
                                onClick={onShowOrHide}
                            >
                                <span>{title}</span>
                                <i
                                    className={cx('fas text-muted', {
                                        'fa-eye-slash': isShowing,
                                        'fa-eye': !isShowing,
                                    })}
                                />
                            </button>
                        ) : (
                            <h5>{title}</h5>
                        )}

                        {totalCount && (
                            <span className="badge badge-pill badge-secondary py-1 px-2">
                                {totalCount}
                            </span>
                        )}
                    </div>
                    {isShowing &&
                        items.map(item => (
                            <FilterGroupItem
                                key={`filter-${item && (item.id || item)}`}
                                text={
                                    item &&
                                    (item.name ||
                                        trans(
                                            itemTransPrefix ? `${itemTransPrefix}.${item}` : item
                                        ))
                                }
                                color={item && (item.color || item)}
                                count={this.getCount(item && (item.id || item))}
                                isSelected={selectedItems.indexOf(item.id || item) > -1}
                                onClick={() => this.clickItem(item.id || item)}
                                isDeleted={item.is_deleted}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

FilterGroup.propTypes = {
    title: PropTypes.string,
    totalCount: PropTypes.number,
    itemCounts: PropTypes.object,
    itemTransPrefix: PropTypes.string,
    items: PropTypes.array,
    defaultSelectedItems: PropTypes.array,
    onChange: PropTypes.func,
    onShowOrHide: PropTypes.func,
    isShowing: PropTypes.bool,
};

FilterGroup.defaultProps = {
    title: null,
    totalCount: null,
    itemCounts: null,
    itemTransPrefix: null,
    items: [],
    defaultSelectedItems: [],
    onChange: () => {},
    onShowOrHide: null,
    isShowing: true,
};
