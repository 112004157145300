import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';
import definitions from '../../definitions';
import StepCarousel from '../../components/StepCarousel';
import { AppLayout } from '../../layouts';
import { CarouselPane } from '../../components';
import { Api } from '../../services';
import router from '../../utils/router';
import notify from '../../utils/notify';

class OutlookCallbackPage extends PureComponent {
    componentDidMount() {
        const channelId = localStorage.getItem(definitions.CALLBACK_OUTLOOK_CHANNEL_ID);
        if (channelId) {
            this.fetchChannel(channelId);
        } else {
            const { history } = this.props;
            history.push(`/`);
        }
    }

    fetchChannel(channelId) {
        try {
            const { location } = this.props;
            const { code } = queryString.parse(location.search);

            Api.request({
                method: 'POST',
                url: `callback/outlook`,
                data: {
                    code,
                    channelId,
                },
            }).then(({ data }) => {
                const { history } = this.props;
                const channel = data.data;

                router.toCompany(history, channel.company, `channels/${channel.id}/edit/email`);
                notify.success('you_have_connected_your_outlook_account_successfully');
            });
        } catch (e) {
            //
        }
    }

    render() {
        return (
            <AppLayout isLoading>
                <StepCarousel step={0}>
                    <CarouselPane />
                </StepCarousel>
            </AppLayout>
        );
    }
}

export default withRouter(OutlookCallbackPage);

OutlookCallbackPage.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
