import React from 'react';
import PropTypes from 'prop-types';
import Heading from './Heading';
import Button from './Button';

const Container = props => {
    const { activity, className, children, onMoreClick, buttonText, onButtonClick } = props;
    const { user, customer, date } = activity;

    return (
        <div className={`activity d-flex flex-column ${className}`}>
            <Heading user={customer || user} date={date} onMoreClick={onMoreClick} />
            <div className="content flex-grow-1 p-3 flex-wrap">{children}</div>
            {!!buttonText && <Button onClick={onButtonClick} text={buttonText} />}
        </div>
    );
};

export default Container;

Container.propTypes = {
    activity: PropTypes.object.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    onMoreClick: PropTypes.func,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func,
};

Container.defaultProps = {
    className: '',
    children: null,
    onMoreClick: null,
    buttonText: null,
    onButtonClick: () => {},
};
