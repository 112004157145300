import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Ticket = ({ ticket, children, className, active, ...rest }) => {
    const activeClass = active || active === null ? 'active' : 'inactive';

    return (
        <div
            className={classNames(
                'ticket',
                `border-status-${ticket.status}`,
                className,
                activeClass
            )}
            {...rest}
        >
            {children}
        </div>
    );
};

export default Ticket;

Ticket.propTypes = {
    ticket: PropTypes.object,
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    active: PropTypes.bool,
};

Ticket.defaultProps = {
    ticket: null,
    className: '',
    active: null,
};
