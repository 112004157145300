import React from 'react';
import PropTypes from 'prop-types';
import definitions from '../../../definitions';
import ActivityTicketReply from './ActivityTicketReply';
import ActivityInvitationReceived from './ActivityInvitationReceived';
import ActivityPrivateNote from './ActivityPrivateNote';
import Container from './Container';
import ActivityTicketChanged from './ActivityTicketChanged';
import ActivityTicketAssign from './ActivityTicketAssign';

const Activity = props => {
    const { activity, containerClassName, seeTicket, acceptInvitation, onMoreClick } = props;

    const { type } = activity;

    const {
        ACTIVITY_TICKET_RESOLVED,
        ACTIVITY_TICKET_REOPEN,
        ACTIVITY_TICKET_STATUS_CHANGED,
        ACTIVITY_TICKET_PRIORITY_CHANGED,
        ACTIVITY_TICKET_ASSIGNED_TO_YOU,
        ACTIVITY_TICKET_UNASSIGNED_TO_YOU,
        ACTIVITY_TICKET_REPLIED,
        // ACTIVITY_TICKET_MESSAGE_RECEIVED,
        ACTIVITY_INVITATION_RECEIVED,
        // ACTIVITY_INVITED,
        ACTIVITY_PRIVATE_NOTE_ADDED,
    } = definitions.activities;

    const getComponent = () => {
        switch (type) {
            case ACTIVITY_INVITATION_RECEIVED:
                return (
                    <ActivityInvitationReceived
                        containerClassName={containerClassName}
                        activity={activity}
                        acceptInvitation={acceptInvitation}
                        onMoreClick={onMoreClick}
                    />
                );
            case ACTIVITY_PRIVATE_NOTE_ADDED:
                return (
                    <ActivityPrivateNote
                        activity={activity}
                        seeTicket={seeTicket}
                        containerClassName={containerClassName}
                        onMoreClick={onMoreClick}
                    />
                );
            case ACTIVITY_TICKET_REPLIED:
                return (
                    <ActivityTicketReply
                        activity={activity}
                        seeTicket={seeTicket}
                        containerClassName={containerClassName}
                        onMoreClick={onMoreClick}
                    />
                );
            case ACTIVITY_TICKET_STATUS_CHANGED:
                return (
                    <ActivityTicketChanged
                        activity={activity}
                        seeTicket={seeTicket}
                        containerClassName={containerClassName}
                        onMoreClick={onMoreClick}
                        status
                    />
                );
            case ACTIVITY_TICKET_RESOLVED:
                return (
                    <ActivityTicketChanged
                        activity={activity}
                        seeTicket={seeTicket}
                        containerClassName={containerClassName}
                        onMoreClick={onMoreClick}
                        resolve
                    />
                );
            case ACTIVITY_TICKET_REOPEN:
                return (
                    <ActivityTicketChanged
                        activity={activity}
                        seeTicket={seeTicket}
                        containerClassName={containerClassName}
                        onMoreClick={onMoreClick}
                        reopen
                    />
                );
            case ACTIVITY_TICKET_PRIORITY_CHANGED:
                return (
                    <ActivityTicketChanged
                        activity={activity}
                        seeTicket={seeTicket}
                        containerClassName={containerClassName}
                        onMoreClick={onMoreClick}
                        priority
                    />
                );
            case ACTIVITY_TICKET_ASSIGNED_TO_YOU:
                return (
                    <ActivityTicketAssign
                        activity={activity}
                        seeTicket={seeTicket}
                        containerClassName={containerClassName}
                        onMoreClick={onMoreClick}
                        assigned
                    />
                );
            case ACTIVITY_TICKET_UNASSIGNED_TO_YOU:
                return (
                    <ActivityTicketAssign
                        activity={activity}
                        seeTicket={seeTicket}
                        containerClassName={containerClassName}
                        onMoreClick={onMoreClick}
                    />
                );
            default:
                return (
                    <Container
                        activity={activity}
                        className={containerClassName}
                        onMoreClick={onMoreClick}
                    >
                        <p className="text-small text-dark activity-text">{activity.new}</p>
                    </Container>
                );
        }
    };

    return getComponent();
};

export default Activity;

Activity.propTypes = {
    activity: PropTypes.object,
    containerClassName: PropTypes.string,
    seeTicket: PropTypes.func,
    acceptInvitation: PropTypes.func,
    onMoreClick: PropTypes.func,
};

Activity.defaultProps = {
    activity: null,
    containerClassName: '',
    seeTicket: () => {},
    acceptInvitation: () => {},
    onMoreClick: null,
};
