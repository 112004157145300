import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { emptyMessage } from '../../assets/images';
import trans from '../../localization';
import TicketIcon from './ticket/TicketIcon';
import TicketId from './ticket/TicketId';
import Message from './messages/Message';
import Audit from './messages/Audit';
import PaginationScrollbar from '../PaginationScrollbar';
import { DateTime, HoverTooltip, Popover } from '../index';
import TicketStatus from './ticket/TicketStatus';
import Ticket from './ticket/Ticket';
import TicketPriority from './ticket/TicketPriority';
import TicketResolution from './ticket/TicketResolution';
import URL from '../../elements/URL';
import definitions from '../../definitions';
import TicketService from '../../services/TicketService';
import helpers from '../../helpers';
import Statuslist from './StatusList';
import LabeledCheckbox from './LabeledCheckbox';
import MultipleUserIcon from './MultipleUserIcon';
import MultipleEmailSelect from '../MultipleEmailSelect';
import QuickUserAssign from './QuickUserAssign';
import CannedResponses from './CannedResponses';
import UserIcon from './UserIcon';
import RespondAttachment from './RespondAttachment';
import { Api, AuthService } from '../../services';
import eventService from '../../services/EventService';
import { AppContext } from '../../utils/app-context';
import dialog from '../../utils/dialog';
import router from '../../utils/router';
import TicketTypeList from './TicketTypeList';
import ResponseAISuggestion from './ResponseAISuggestion';

export default class QuickRespond extends Component {
    constructor(props, context) {
        super(props, context);

        this.lastTextareaHeight = 168;

        // noinspection JSUnresolvedVariable
        this.state = {
            user: AuthService.user(),
            type: null,
            afterSend: null,
            showCannedResponses: false,
            cannedResponses: [],
            smallerControls: false,
            showResponseSuggestions: false,
            // eslint-disable-next-line react/no-unused-state
            isDirty: false,
        };

        this.textarea = React.createRef();
        this.scrollbarContainer = null;
    }

    componentDidMount() {
        const { single } = this.props;

        if (single) {
            this.scrollMessages(1000);
        }

        this.setTextareaHeight();
    }

    componentDidUpdate() {
        const { messages, ticket, lastAIResponseId, lastAIResponse } = this.props;
        const { messageBody } = this.getFromContext();
        const { isDirty } = this.state;
        const { lastMessage, ticketId } = this;

        const newMessage = messages && messages[0];
        const newTicketId = ticket && ticket.id;

        const newMessageReceived =
            (newMessage && newMessage.type) !== 'audit' &&
            (newMessage && newMessage.id) !== (lastMessage && lastMessage.id);

        const shouldScroll = ticketId !== newTicketId || newMessageReceived;

        if (shouldScroll) {
            this.lastMessage = newMessage;
            this.ticketId = newTicketId;

            this.scrollMessages(500);

            this.clear();
        }

        if (
            !isDirty &&
            newTicketId === lastAIResponseId &&
            ticket &&
            ticket.status === definitions.STATUS_OPEN &&
            (!messageBody || messageBody.length === 0) &&
            lastAIResponse &&
            lastAIResponse.length > 0
        ) {
            console.log('Setting content from AI', lastAIResponse);

            this.setToContext({
                messageBody: lastAIResponse,
            });
        }

        this.setTextareaHeight();
    }

    searchUser = (query, setItems) => {
        const { company, ticket } = this.props;

        return Api.request({
            method: 'GET',
            url: `companies/${company.slug}/users`,
            params: {
                search: query,
                ticket_id: ticket.id,
            },
        }).then(res => {
            setItems(res.data.data);
        });
    };

    assignUser = user => {
        const { ticket } = this.props;

        return Api.request({
            method: 'POST',
            url: `tickets/${ticket.id}/user`,
            data: { user_id: user.id },
        }).then(res => {
            eventService.emit(definitions.EVENT_TICKET_UPDATED, res.data.data);
        });
    };

    removeAssignedUser = user => {
        const { ticket } = this.props;

        return Api.request({
            method: 'DELETE',
            url: `tickets/${ticket.id}/user`,
            data: { user_id: user.id },
        }).then(res => {
            eventService.emit(definitions.EVENT_TICKET_UPDATED, res.data.data);
        });
    };

    send = (e, afterSend = null) => {
        if (e) {
            e.preventDefault();
        }

        this.setState(
            {
                afterSend,
            },
            this.onSubmit
        );
    };

    sendByType = (e, type, afterSend = null) => {
        if (e) {
            e.preventDefault();
        }

        this.setState(
            {
                type,
                afterSend,
            },
            this.onSubmit
        );
    };

    onSubmit = e => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { type, afterSend } = this.state;
        const { sendMessage, ticket, messages, updateTicket, updateMessages } = this.props;

        const {
            messageBody: body = '',
            attachments = [],
            forwardAttachments = [],
            cc = [],
            bcc = [],
            fwd = [],
            signature = AuthService.user().use_signature,
            cannedResponse = null,
        } = this.getFromContext();

        // eslint-disable-next-line no-undef
        const formData = new FormData();

        formData.append('body', body);
        formData.append('type', type);
        formData.append('signature', signature ? '1' : '0');
        if (afterSend) {
            formData.append('after_send', afterSend);
        }

        const appendValues = (arr, name) => arr.forEach(x => formData.append(`${name}[]`, x.value));

        appendValues(fwd, 'fwd');
        appendValues(cc, 'cc');
        appendValues(bcc, 'bcc');

        if (cannedResponse) {
            formData.append('canned_response_id', cannedResponse.id);
        }

        attachments.forEach(x => formData.append('files[]', x, x.name));
        forwardAttachments.map(x => formData.append('forward_attachment_ids[]', x.id));

        sendMessage(ticket, formData).then(response => {
            const message = response.data.data;

            // noinspection JSUnresolvedVariable
            ticket.last_message_at = message.created_at;
            messages.splice(0, 0, message);

            updateTicket(ticket);
            updateMessages(messages).then(() => {
                setTimeout(() => {
                    this.scrollToBottom();
                }, 500);
            });

            this.clear();
            this.clearContext();
            if (this.textarea && this.scrollbarContainer) {
                this.textarea.current.value = '';

                // textarea height that given in css
                const minHeight = 33;

                // reset scroll and textarea height
                this.textarea.current.style.height = `${minHeight}px`;
                this.scrollbarContainer.style.height = '100%';
                this.lastTextareaHeight = minHeight;
            }
        });
    };

    onTryAgain = ticketMessage => {
        const currentTicketMessage = ticketMessage;
        if (currentTicketMessage.is_failed) {
            const { ticket } = this.props;
            Api.request({
                method: 'POST',
                url: `tickets/${ticket.id}/messages/${ticketMessage.id}/retry`,
            });

            currentTicketMessage.is_failed = false;
            eventService.emit(definitions.EVENT_TICKET_MESSAGE_SENT, ticketMessage);
        }
    };

    onCancelSendingMessage = message => {
        const { ticket } = this.props;
        TicketService.cancelSendingMessage(ticket.id, message.id).then(res => {
            this.setToContext({
                messageBody: res.data.data.text,
            });

            eventService.emit(definitions.EVENT_TICKET_MESSAGE_DELETED, message);
        });
    };

    setTextareaHeight = () => {
        if (this.textarea && this.textarea.current && this.scrollbarContainer) {
            // textarea height that given in css
            const minHeight = 168;

            // reset scroll height
            this.textarea.current.style.height = `${minHeight}px`;

            // get current messages scroll height
            const scrollContainerHeight = this.scrollbarContainer.clientHeight;

            // get full height of textarea
            const height =
                this.textarea.current.scrollHeight > minHeight
                    ? this.textarea.current.scrollHeight + 21
                    : minHeight;

            // expand textarea if it is below half of messages scroll height
            // else keep height same as before
            if (height < scrollContainerHeight) {
                this.textarea.current.style.height = `${height}px`;
                this.lastTextareaHeight = height;
            } else {
                this.textarea.current.style.height = `${this.lastTextareaHeight}px`;
            }
        }
    };

    focusOnMessages = (focus = true) => {
        this.setState({
            smallerControls: focus,
        });
    };

    onForwardAttachment = attachment => {
        const { forwardAttachments = [] } = this.getFromContext();
        const index = forwardAttachments.indexOf(attachment);

        if (index > -1) {
            return;
        }

        forwardAttachments.push(attachment);

        this.setToContext({ forwardAttachments }, this.setTextareaHeight);
    };

    attachFile = e => {
        const { attachments = [] } = this.getFromContext();

        attachments.push(...e.target.files);

        this.setToContext({ attachments }, this.setTextareaHeight);
    };

    deleteFromArray = (key, value) => {
        const { [key]: array = [] } = this.getFromContext();
        const index = array.indexOf(value);

        if (index !== -1) {
            array.splice(index, 1);
        }

        this.setToContext({ array });
    };

    setToContext = (state = {}, callback) => {
        const { setObjectWithId } = this.context;
        const { ticket } = this.props;

        if (!ticket) {
            return;
        }

        setObjectWithId('quickRespond', ticket.id, state, callback);
    };

    getFromContext = () => {
        const { getObjectWithId } = this.context;
        const { ticket } = this.props;

        if (!ticket) {
            return {};
        }

        return getObjectWithId('quickRespond', ticket.id);
    };

    clearContext = () => {
        this.setToContext({
            messageBody: '',
            attachments: [],
            forwardAttachments: [],
            receiverAdditions: {},
            fwd: [],
            cc: [],
            bcc: [],
            signature: AuthService.user().use_signature,
            cannedResponse: null,
        });
    };

    clear = () => {
        this.setState({
            user: AuthService.user(),
            type: null,
            showCannedResponses: false,
            cannedResponses: [],
            isDirty: false,
        });
    };

    scrollMessages = timeout => {
        const setScrolling = () => {
            const scrollTimeout = setTimeout(() => {
                const { messages } = this.props;
                const speed = messages.length > 100 ? 2000 : messages.length * 10 + 1000;

                this.scrollToBottom(speed);
            }, timeout);

            this.scrollTimeout = scrollTimeout;
        };

        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }

        setScrolling();
    };

    scrollToBottom = speed => {
        if (this.scrollbarContainer) {
            // eslint-disable-next-line no-undef
            window.$(this.scrollbarContainer).animate(
                {
                    scrollTop: this.scrollbarContainer.scrollHeight,
                },
                speed || 'slow'
            );
        }
    };

    closeTicket = () => {
        const { ticket, updateTicket } = this.props;

        TicketService.updateStatus(ticket.id, definitions.STATUS_RESOLVED).then(response => {
            updateTicket(response.data.data);
        });
    };

    toggleShowResponseSuggestions = () => {
        const { showResponseSuggestions } = this.state;
        const { getCannedResponses } = this.props;

        if (showResponseSuggestions === true) {
            this.setState({
                cannedResponses: [],
                showResponseSuggestions: false,
                showCannedResponses: false,
            });
            return;
        }

        if (getCannedResponses instanceof Function) {
            getCannedResponses()
                .then(response => {
                    this.setState({
                        showResponseSuggestions: !showResponseSuggestions,
                        cannedResponses: response.data.data,
                    });
                })
                .catch(() => {
                    this.setState({
                        showResponseSuggestions: false,
                        showCannedResponses: false,
                        cannedResponses: [],
                    });
                });
        }
    };

    toggleCannedResponses = () => {
        const { showCannedResponses } = this.state;
        const { getCannedResponses } = this.props;

        if (showCannedResponses === true) {
            this.setState({
                showCannedResponses: false,
                showResponseSuggestions: false,
                cannedResponses: [],
            });

            return;
        }

        if (getCannedResponses instanceof Function) {
            getCannedResponses()
                .then(response => {
                    this.setState({
                        showCannedResponses: !showCannedResponses,
                        cannedResponses: response.data.data,
                    });
                })
                .catch(() => {
                    this.setState({
                        showCannedResponses: false,
                        showResponseSuggestions: false,
                        cannedResponses: [],
                    });
                });
        }
    };

    setReceiverAdditions = key => {
        const { receiverAdditions = {} } = this.getFromContext();

        this.setToContext({
            receiverAdditions: {
                ...receiverAdditions,
                [key]: !receiverAdditions[key],
            },
            [key]: [],
        });
    };

    handleSearch = (value, setItems) => {
        const { company } = this.props;
        return Api.request({
            method: 'GET',
            url: `search/${company.slug}/persons?search=${value}`,
        })
            .then(res => setItems(res.data.data))
            .catch(() => setItems([]));
    };

    handleSelect = (list, prop) => item => {
        this.setToContext({
            [prop]: [
                ...list,
                {
                    value: item.key,
                    label: item.email,
                },
            ],
        });
    };

    handleRemove = (list, prop) => item => {
        this.setToContext({
            [prop]: list.filter(x => x.value !== item.value),
        });
    };

    createMessage = type => {
        const { useQuickRespondPopup, single, openQuickRespondPopup } = this.props;

        if (useQuickRespondPopup && !single && openQuickRespondPopup) {
            openQuickRespondPopup(type);
            return;
        }

        this.setState({ type });
    };

    checkCommand = e => {
        const { type, showResponseSuggestions } = this.state;
        const { single, useQuickRespondPopup } = this.props;

        const sendingAsMessage = type === definitions.TYPE_MESSAGE;
        const showMessageInputSection = !!type;
        const showInQuickMode = !single && !useQuickRespondPopup;

        if ((sendingAsMessage || !single) && (showInQuickMode || showMessageInputSection)) {
            if (/^\/c/.test(e.target.value)) {
                if (showResponseSuggestions === false) {
                    this.toggleShowResponseSuggestions();
                }
            } else if (showResponseSuggestions === true) {
                this.toggleShowResponseSuggestions();
            }
        }
    };

    static renderEmpty() {
        return (
            <div className="empty text-center">
                <div className="empty-graphic">
                    <h2>{trans('its_empty_over_here')}</h2>
                    <img src={emptyMessage} alt="Empty message" />
                    <span id="random_message">{trans('try_clicking_on_a_ticket')}</span>
                </div>
            </div>
        );
    }

    renderTopTicket() {
        const {
            ticket,
            company,
            onOpenFullScreen,
            changeStatus,
            changePriority,
            changeTicketType,
        } = this.props;
        const statuses = helpers.getStatuses();
        const priorities = helpers.getPriorities();
        const ticketTypes = company ? company.ticketTypes : [];

        // noinspection JSUnresolvedVariable
        return (
            <div>
                <div className="top pb-2">
                    <div className="recipients">
                        <div className="p-3 item">
                            <TicketIcon ticket={ticket} />
                            <div className="ml-2">
                                <h2>{ticket.customer.name}</h2>
                            </div>
                        </div>
                        <div className="quick-user-assign">
                            <QuickUserAssign
                                onChange={this.searchUser}
                                onSelect={this.assignUser}
                                onRemove={this.removeAssignedUser}
                                users={ticket.users}
                                label={
                                    <div className="p-3 item">
                                        <div className="mr-2 agent">
                                            {trans('assigned_to')}
                                            {ticket.user && (
                                                <span className="d-block font-weight-bold agent">
                                                    {ticket.user.name}
                                                </span>
                                            )}
                                        </div>
                                        <MultipleUserIcon users={ticket.users} />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className="subject">
                        <p className="px-3 text-left">
                            <span id="ticket_subject">{ticket.subject}</span>
                            <TicketId ticket={ticket} company={company} />
                        </p>
                    </div>
                    <div className="date">
                        <p className="px-3 text-right">
                            {`${trans('created')}: `} <DateTime datetime={ticket.created_at} />
                        </p>
                    </div>
                </div>
                <div className="bottom px-2 py-2">
                    <div className="expand unselectable">
                        <URL onClick={() => onOpenFullScreen(ticket)}>
                            <i className="fas fa-expand-wide mr-1" />
                            {trans('expand_ticket')}
                        </URL>
                    </div>
                    <div className="statuses unselectable">
                        <Popover
                            align="right"
                            label={<TicketStatus className="cursor-pointer" ticket={ticket} />}
                        >
                            <Statuslist
                                items={statuses}
                                activeStatus={ticket.status}
                                footer={trans('select_a_status')}
                                onClick={changeStatus}
                            />
                        </Popover>
                        <div className="spacer mx-1" />
                        <Popover
                            align="right"
                            label={
                                <TicketStatus className="cursor-pointer" ticket={ticket} priority />
                            }
                        >
                            <Statuslist
                                items={priorities}
                                activeStatus={ticket.priority}
                                footer={trans('select_a_priority')}
                                onClick={changePriority}
                            />
                        </Popover>
                        {ticketTypes.length > 0 && (
                            <>
                                <div className="spacer mx-1" />
                                <Popover
                                    align="right"
                                    label={
                                        ticket.ticketType ? (
                                            <div
                                                className={`status ticket-status-${ticket.ticketType.color}`}
                                            >
                                                {ticket.ticketType.name}
                                            </div>
                                        ) : (
                                            <div className="status ticket-status-archive">
                                                {trans('none')}
                                            </div>
                                        )
                                    }
                                >
                                    <TicketTypeList
                                        items={ticketTypes}
                                        activeTicketType={
                                            ticket.ticketType ? ticket.ticketType.id : null
                                        }
                                        footer={trans('select_ticket_type')}
                                        onClick={changeTicketType}
                                    />
                                </Popover>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderTopSingleTicket() {
        const { ticket, company, onCloseTicket } = this.props;
        const hasTicketViewers = ticket.viewers && ticket.viewers.length > 0;
        const ticketViewClass = hasTicketViewers ? 'full-message-view' : '';
        const names = hasTicketViewers
            ? ticket.viewers.map(viewer => viewer.user.name).join(',')
            : '';

        // noinspection JSUnresolvedVariable
        return (
            <div className={`top full-message py-0 ${ticketViewClass}`}>
                <div className="back text-center">
                    <URL onClick={() => onCloseTicket()}>
                        <i className="fa fa-chevron-left" style={{ textIndent: -2 }} />
                    </URL>
                </div>
                <Ticket
                    active
                    ticket={ticket}
                    className="inside cursor-initial quickrespond-expanded flex-nowrap"
                >
                    <div className="ticket-ref">
                        <TicketIcon ticket={ticket} />
                        <div className="sender white-space-nowrap">
                            <span className="text-dark font-weight-bold text-normal">
                                {ticket.customer.name}
                            </span>
                            <p>
                                <span className="text-small text-dark">{ticket.source.name}</span>
                                <TicketId ticket={ticket} company={company} />
                            </p>
                        </div>
                    </div>
                    <div className="px-4 text-dc-light-gray text-small subject flex-grow-1 text-align-center">
                        <span>{ticket.subject}</span>
                    </div>
                    <TicketPriority ticket={ticket} className="text-small white-space-nowrap" />

                    {hasTicketViewers && (
                        <HoverTooltip title={trans('viewing_by', { names })}>
                            <div className="ml-4">
                                <i className="fa fa-eye text-primary" />
                            </div>
                        </HoverTooltip>
                    )}

                    {!!ticket.resolution_at && (
                        <div className="pl-4">
                            <TicketResolution
                                datetime={ticket.resolution_at}
                                isOverdue={ticket.is_resolution}
                                format="DD MMMM YYYY, hh:mm"
                            />
                        </div>
                    )}
                    <div className="py-0 pr-0 pl-2 text-right">
                        <QuickUserAssign
                            onChange={this.searchUser}
                            onSelect={this.assignUser}
                            onRemove={this.removeAssignedUser}
                            users={ticket.users}
                            label={
                                <div className="p-3 item">
                                    {ticket.user ? (
                                        <div className="mr-2 agent">
                                            <span className="d-block font-weight-bold agent">
                                                {ticket.user.name}
                                            </span>
                                        </div>
                                    ) : (
                                        <MultipleUserIcon users={ticket.users} />
                                    )}
                                </div>
                            }
                        />
                    </div>
                </Ticket>
            </div>
        );
    }

    renderTicket() {
        const {
            ticket,
            messages,
            single,
            onClickCustomer,
            onNextPage,
            isLastPage,
            company,
            canFetchOrders,
            onFetchOrders,
            useQuickRespondPopup,
            isQuickRespondPopupOpen,
            mimeTypes,
            hasSignature,
            history,
        } = this.props;

        const {
            type,
            showCannedResponses,
            cannedResponses,
            user,
            smallerControls,
            showResponseSuggestions,
        } = this.state;

        const {
            messageBody = '',
            attachments = [],
            forwardAttachments = [],
            receiverAdditions = {},
            cc = [],
            bcc = [],
            fwd = [],
            signature = AuthService.user().use_signature,
            cannedResponse = null,
        } = this.getFromContext();

        const sendingAsMessage = type === definitions.TYPE_MESSAGE;
        const showMessageInputSection = !!type;
        const showInQuickMode = !single && !useQuickRespondPopup;
        const hideInQuickPopupOpenMode = !single && useQuickRespondPopup && isQuickRespondPopupOpen;

        return (
            <div className="loaded">
                {single ? this.renderTopSingleTicket() : this.renderTopTicket()}
                <div
                    className={classNames('conversation', { single })}
                    role="button"
                    tabIndex="0"
                    onClick={this.focusOnMessages}
                >
                    <PaginationScrollbar
                        containerRef={ref => {
                            this.scrollbarContainer = ref;
                        }}
                        direction="up"
                        onNextPage={onNextPage}
                        isLastPage={isLastPage}
                    >
                        <div className="d-flex flex-column flex-column-reverse">
                            {messages.map((message, i) =>
                                message.type === 'audit' ? (
                                    <Audit key={`log-${message.id}`} message={message} />
                                ) : (
                                    <Message
                                        key={message.id}
                                        message={message}
                                        isFirstMessage={i === 0}
                                        onTryAgain={this.onTryAgain}
                                        onCancelSending={() => this.onCancelSendingMessage(message)}
                                        onClickCustomer={customer => onClickCustomer(customer)}
                                        canFetchOrders={canFetchOrders}
                                        onFetchOrders={onFetchOrders}
                                        onForwardAttachment={attachment =>
                                            this.onForwardAttachment(attachment)
                                        }
                                    />
                                )
                            )}
                        </div>
                    </PaginationScrollbar>
                </div>
                {ticket.locked_by ? (
                    <div className="align-items-center controls justify-content-center min-h-auto text-center text-muted text-small">
                        {trans('you_can_not_reply_this_ticket_because_x', {
                            reason: trans(`locked_bys.${ticket.locked_by}`),
                        })}
                    </div>
                ) : (
                    <>
                        <div
                            className={classNames(
                                'controls-wrapper',
                                {
                                    show: showInQuickMode || showMessageInputSection,
                                },
                                {
                                    smaller:
                                        smallerControls &&
                                        (showInQuickMode || showMessageInputSection),
                                }
                            )}
                            tabIndex="0"
                            role="button"
                            onClick={() => this.focusOnMessages(false)}
                        >
                            <div
                                className={classNames('controls single', {
                                    show: showInQuickMode || showMessageInputSection,
                                })}
                            >
                                <div className="receiver-controls d-flex justify-content-between w-100">
                                    <div className="d-flex align-items-center">
                                        <UserIcon user={user} />
                                        <span className="text-dark text-small font-weight-bold ml-1">
                                            {user.name}
                                        </span>
                                    </div>
                                    <div
                                        className={classNames(
                                            'receiver-controls-buttons d-flex align-items-center',
                                            {
                                                hide: showInQuickMode || !sendingAsMessage,
                                            }
                                        )}
                                    >
                                        <div
                                            role="button"
                                            tabIndex="0"
                                            className="control-button border-right"
                                            onClick={() => this.setReceiverAdditions('cc')}
                                        >
                                            {trans('cc')}
                                        </div>

                                        <div
                                            role="button"
                                            tabIndex="0"
                                            className="control-button border-right"
                                            onClick={() => this.setReceiverAdditions('bcc')}
                                        >
                                            {trans('bcc')}
                                        </div>

                                        <div
                                            role="button"
                                            tabIndex="0"
                                            className="control-button"
                                            onClick={() => this.setReceiverAdditions('fwd')}
                                        >
                                            <i className="fad fa-share rotate-y-180 mr-2" />
                                            {trans('forward')}
                                        </div>
                                    </div>
                                </div>

                                {single && sendingAsMessage && (
                                    <div
                                        className={classNames('receiver-inputs-wrapper', {
                                            show: Object.values(receiverAdditions).includes(true),
                                        })}
                                    >
                                        <div className="receiver-inputs">
                                            {receiverAdditions.cc && (
                                                <MultipleEmailSelect
                                                    placeholder="Start typing here"
                                                    onSearch={this.handleSearch}
                                                    onSelect={this.handleSelect(cc, 'cc')}
                                                    onRemove={this.handleRemove(cc, 'cc')}
                                                    items={cc}
                                                    label="CC"
                                                />
                                            )}

                                            {receiverAdditions.bcc && (
                                                <MultipleEmailSelect
                                                    placeholder="Start typing here"
                                                    onSearch={this.handleSearch}
                                                    onSelect={this.handleSelect(bcc, 'bcc')}
                                                    onRemove={this.handleRemove(bcc, 'bcc')}
                                                    items={bcc}
                                                    label="BCC"
                                                />
                                            )}

                                            {receiverAdditions.fwd && (
                                                <MultipleEmailSelect
                                                    placeholder="Start typing here"
                                                    onSearch={this.handleSearch}
                                                    onSelect={this.handleSelect(fwd, 'fwd')}
                                                    onRemove={this.handleRemove(fwd, 'fwd')}
                                                    items={fwd}
                                                    label="FWD"
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}

                                {(showInQuickMode || sendingAsMessage) && (
                                    <CannedResponses
                                        show={showCannedResponses}
                                        onClose={() => this.toggleCannedResponses()}
                                        single={single}
                                        activeCannedResponseId={cannedResponse && cannedResponse.id}
                                        onSelectCannedResponse={newCannedResponse =>
                                            this.setToContext(
                                                {
                                                    cannedResponse:
                                                        cannedResponse &&
                                                        cannedResponse.id === newCannedResponse.id
                                                            ? null
                                                            : newCannedResponse,
                                                    messageBody:
                                                        cannedResponse &&
                                                        cannedResponse.id === newCannedResponse.id
                                                            ? ''
                                                            : newCannedResponse.body,
                                                },
                                                (cannedResponse &&
                                                    cannedResponse.id !== newCannedResponse.id) ||
                                                    !cannedResponse
                                                    ? () => {
                                                          this.toggleCannedResponses();
                                                          this.setTextareaHeight();
                                                      }
                                                    : this.setTextareaHeight
                                            )
                                        }
                                        items={cannedResponses}
                                        company={company}
                                    />
                                )}

                                <div
                                    className={classNames(
                                        'd-flex justify-content-start flex-grow-1 w-100 controlled-popover-wrapper',
                                        { 'p-2': !showCannedResponses }
                                    )}
                                >
                                    <textarea
                                        className="message-textarea"
                                        hidden={showCannedResponses}
                                        name="body"
                                        value={messageBody}
                                        onFocus={this.checkCommand}
                                        onChange={e => {
                                            // eslint-disable-next-line react/no-unused-state
                                            this.setState({ isDirty: true });
                                            this.checkCommand(e);
                                            this.setToContext(
                                                { messageBody: e.target.value },
                                                this.setTextareaHeight
                                            );
                                        }}
                                        placeholder={trans('type_your_message_here')}
                                        ref={this.textarea}
                                    />
                                    <div
                                        className={classNames('controlled-popover-content', {
                                            show:
                                                /^\/c/.test(messageBody) &&
                                                showResponseSuggestions &&
                                                cannedResponses.length > 0 &&
                                                (sendingAsMessage || !single) &&
                                                (showInQuickMode || showMessageInputSection),
                                        })}
                                    >
                                        <div className="overflow-auto max-height-280">
                                            {cannedResponses.map(x => (
                                                <div
                                                    onClick={() =>
                                                        this.setToContext(
                                                            {
                                                                cannedResponse: x,
                                                                messageBody: x.body,
                                                            },
                                                            () => {
                                                                this.toggleShowResponseSuggestions();
                                                                this.setTextareaHeight();
                                                            }
                                                        )
                                                    }
                                                    role="button"
                                                    tabIndex="-1"
                                                    className="p-3 hover-item-effect cursor-pointer border-radius-8"
                                                >
                                                    {x.title}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <form
                                    onSubmit={this.onSubmit}
                                    className={classNames('message-controls', {
                                        hide: showCannedResponses,
                                    })}
                                >
                                    <div className="d-flex">
                                        {company.is_ai_enabled && (
                                            <ResponseAISuggestion
                                                ticketId={ticket.id}
                                                onGenerated={message =>
                                                    this.setToContext(
                                                        {
                                                            messageBody: message,
                                                        },
                                                        () => {
                                                            this.setTextareaHeight();
                                                        }
                                                    )
                                                }
                                            />
                                        )}
                                        <RespondAttachment
                                            mimeTypes={mimeTypes}
                                            attachments={attachments}
                                            forwardAttachments={forwardAttachments}
                                            onAttach={this.attachFile}
                                            onDeleteAttachment={attachment =>
                                                this.deleteFromArray('attachments', attachment)
                                            }
                                            onDeleteForwardAttachment={attachment =>
                                                this.deleteFromArray(
                                                    'forwardAttachments',
                                                    attachment
                                                )
                                            }
                                        />
                                        {(showInQuickMode || sendingAsMessage) && (
                                            <div
                                                role="button"
                                                tabIndex="0"
                                                onClick={() => this.toggleCannedResponses()}
                                                className="canned-responses-button"
                                            >
                                                <span className="text text-truncate">
                                                    {trans('canned_responses')}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="ml-auto" />
                                    {(showInQuickMode || sendingAsMessage) && hasSignature ? (
                                        <div className="d-flex align-items-center text-nowrap">
                                            <LabeledCheckbox
                                                item={{
                                                    name: trans('signature'),
                                                    id: 'signature',
                                                }}
                                                checked={signature}
                                                onChange={e =>
                                                    this.setToContext({
                                                        signature: e.target.checked,
                                                    })
                                                }
                                                containerClassName={classNames(
                                                    'pl-2 pr-5',
                                                    { 'theme-primary': signature },
                                                    { 'theme-lightgray': !signature }
                                                )}
                                            />
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center text-nowrap">
                                            <LabeledCheckbox
                                                item={{
                                                    name: trans('signature'),
                                                    id: 'signature',
                                                }}
                                                checked={false}
                                                onChange={() =>
                                                    dialog
                                                        .confirm(
                                                            trans('you_do_not_have_any_signature'),
                                                            trans(
                                                                'you_do_not_have_any_signature_text'
                                                            ),
                                                            trans('go_to_signatures'),
                                                            trans('cancel')
                                                        )
                                                        .catch(() => {
                                                            router.to(
                                                                history,
                                                                '/myAccount/signatures',
                                                                {
                                                                    company,
                                                                }
                                                            );
                                                        })
                                                }
                                                containerClassName="pl-2 pr-5 theme-lightgray"
                                            />
                                        </div>
                                    )}
                                    {single || (!showInQuickMode && !!type) ? (
                                        <div className="pl-1 d-flex text-nowrap">
                                            {type === definitions.TYPE_NOTE ? (
                                                <button
                                                    type="submit"
                                                    onClick={e => this.send(e)}
                                                    className={classNames('ml-2 send-message', {
                                                        'private-note':
                                                            type === definitions.TYPE_NOTE,
                                                    })}
                                                >
                                                    {trans('add_note')}
                                                </button>
                                            ) : (
                                                <Dropdown className="send-message-button-dropdown">
                                                    <Dropdown.Toggle className="send-message">
                                                        {trans('send')}
                                                        <i className="fa fa-chevron-up pl-2" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            as="button"
                                                            type="submit"
                                                            onClick={e => this.send(e)}
                                                            name="type"
                                                            value="message"
                                                        >
                                                            {trans('send')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            as="button"
                                                            type="submit"
                                                            onClick={e =>
                                                                this.send(
                                                                    e,
                                                                    definitions.AFTER_SEND_CLOSE
                                                                )
                                                            }
                                                            name="type"
                                                            value="note"
                                                        >
                                                            {trans('send_and_close')}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )}

                                            <button
                                                type="button"
                                                onClick={() => this.createMessage(null)}
                                                className="ml-2 close-message"
                                            >
                                                {trans('close')}
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <button
                                                type="button"
                                                onClick={e =>
                                                    this.sendByType(e, definitions.TYPE_NOTE)
                                                }
                                                className="mx-2 send-message private-note"
                                            >
                                                {trans('add_note')}
                                            </button>
                                            <Dropdown className="send-message-button-dropdown">
                                                <Dropdown.Toggle className="send-message">
                                                    {trans('reply')}
                                                    <i className="fa fa-chevron-up pl-2" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        as="button"
                                                        type="submit"
                                                        onClick={e =>
                                                            this.sendByType(
                                                                e,
                                                                definitions.TYPE_MESSAGE
                                                            )
                                                        }
                                                        name="type"
                                                        value="message"
                                                    >
                                                        {trans('reply')}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        as="button"
                                                        type="submit"
                                                        onClick={e =>
                                                            this.sendByType(
                                                                e,
                                                                definitions.TYPE_MESSAGE,
                                                                definitions.AFTER_SEND_CLOSE
                                                            )
                                                        }
                                                        name="type"
                                                        value="note"
                                                    >
                                                        {trans('reply_and_close')}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {ticket &&
                                                ticket.status !== definitions.STATUS_RESOLVED && (
                                                    <button
                                                        type="button"
                                                        onClick={this.closeTicket}
                                                        className="ml-2 close-message text-nowrap"
                                                    >
                                                        {trans('close_ticket')}
                                                    </button>
                                                )}
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>
                        <div
                            className={classNames('controls-wrapper', {
                                show:
                                    !showInQuickMode &&
                                    !showMessageInputSection &&
                                    !hideInQuickPopupOpenMode,
                            })}
                        >
                            <div className="controls control-bar px-4">
                                <button
                                    type="button"
                                    onClick={() => this.createMessage(definitions.TYPE_NOTE)}
                                    className="ml-2 send-message private-note"
                                >
                                    {trans('add_note')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => this.createMessage(definitions.TYPE_MESSAGE)}
                                    className="ml-2 send-message"
                                >
                                    {trans('reply')}
                                </button>
                                {ticket && ticket.status !== definitions.STATUS_RESOLVED && (
                                    <button
                                        type="button"
                                        onClick={this.closeTicket}
                                        className="ml-2 close-message"
                                    >
                                        {trans('close_ticket')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    render() {
        const { ticket, single } = this.props;

        return (
            <div className={`quickrespond ${single ? 'd-block' : 'd-none d-lg-block'}`}>
                {ticket ? this.renderTicket() : QuickRespond.renderEmpty()}
            </div>
        );
    }
}

QuickRespond.contextType = AppContext;

QuickRespond.propTypes = {
    ticket: PropTypes.object,
    company: PropTypes.object,
    messages: PropTypes.array,
    onCloseTicket: PropTypes.func,
    onOpenFullScreen: PropTypes.func,
    onClickCustomer: PropTypes.func,
    sendMessage: PropTypes.func,
    updateTicket: PropTypes.func,
    updateMessages: PropTypes.func,
    single: PropTypes.bool,
    onNextPage: PropTypes.func,
    isLastPage: PropTypes.bool,
    changeStatus: PropTypes.func,
    changePriority: PropTypes.func,
    changeTicketType: PropTypes.func,
    getCannedResponses: PropTypes.func,
    mimeTypes: PropTypes.array,
    canFetchOrders: PropTypes.bool,
    onFetchOrders: PropTypes.func,
    useQuickRespondPopup: PropTypes.bool,
    openQuickRespondPopup: PropTypes.func,
    isQuickRespondPopupOpen: PropTypes.bool,
    hasSignature: PropTypes.bool,
    history: PropTypes.object.isRequired,
    lastAIResponse: PropTypes.string,
    lastAIResponseId: PropTypes.number,
};

QuickRespond.defaultProps = {
    ticket: null,
    company: null,
    messages: [],
    onCloseTicket: () => {},
    onOpenFullScreen: () => {},
    onClickCustomer: () => {},
    sendMessage: () => {},
    updateTicket: () => {},
    updateMessages: () => {},
    single: false,
    onNextPage: () => Promise.resolve(),
    isLastPage: false,
    changeStatus: () => {},
    changePriority: () => {},
    changeTicketType: () => {},
    getCannedResponses: () => Promise.resolve(),
    mimeTypes: [],
    canFetchOrders: false,
    onFetchOrders: () => {},
    useQuickRespondPopup: false,
    openQuickRespondPopup: () => {},
    isQuickRespondPopupOpen: false,
    hasSignature: false,
    lastAIResponse: '',
    lastAIResponseId: null,
};
