import React from 'react';
import PropTypes from 'prop-types';
import { ColorPicker } from '../manage';
import { ManageField } from '../../../elements';
import trans from '../../../localization';
import { HoverTooltip } from '../../index';
import IconLoaderPicker from '../manage/IconLoaderPicker';
import helpers from '../../../helpers';
import IconPreviewUpload from '../manage/IconPreviewUpload';

const EditChannelSidebar = ({ channel, changeIcon, changeColor, changeImage }) => {
    const inputRef = React.createRef();

    const onClickUpload = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
            inputRef.current.click();
        }
    };

    return (
        <div className="col-lg-3 mt-4">
            <div className="d-flex align-items-center py-3 px-4 bg-light-gray border-radius-8">
                <IconPreviewUpload
                    onClick={onClickUpload}
                    color={channel.color}
                    icon={channel.icon}
                    image={channel.image}
                    title={trans('click_to_upload_your_own_logo')}
                    large
                />
                <input ref={inputRef} type="file" onChange={changeImage} hidden />
                <div className="ml-4">
                    <div className="font-weight-bold">{channel.name}</div>
                    <div className="text-capitalize">{helpers.providerName(channel)}</div>
                </div>
            </div>

            <ManageField
                title={
                    <>
                        {trans('channel_icon')}{' '}
                        <span className="text-small text-light-gray">{trans('or')}</span>{' '}
                        <span
                            className="cursor-pointer text-primary"
                            role="button"
                            tabIndex="0"
                            onClick={onClickUpload}
                        >
                            {trans('upload_your_own_icon')}
                        </span>
                    </>
                }
                className="mt-4 font-weight-light"
            >
                <IconLoaderPicker
                    color={channel.color}
                    value={channel.icon}
                    onChange={c => changeIcon(c)}
                />
            </ManageField>

            <ManageField
                title={
                    <div>
                        {trans('channel_color')}
                        <HoverTooltip title={trans('this_color_will_use_in_theme')}>
                            <i className="fa-fw fa-question-circle fas ml-1 text-primary" />
                        </HoverTooltip>
                    </div>
                }
                className="mt-4 font-weight-light"
            >
                <ColorPicker value={channel.color} onChange={c => changeColor(c)} />
            </ManageField>
        </div>
    );
};

EditChannelSidebar.propTypes = {
    channel: PropTypes.object.isRequired,
    changeIcon: PropTypes.func.isRequired,
    changeColor: PropTypes.func.isRequired,
    changeImage: PropTypes.func.isRequired,
};

export default EditChannelSidebar;
