import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api } from '../../services';
import { AuthCardView } from '../../components/auth';
import AutoLoadCompleteInput from '../../components/helpdesk/AutoLoadCompleteInput';
import { FormButton, FormInput, FormTextButton } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';
import router from '../../utils/router';

class CreateCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                name: '',
                timezone: '',
            },
            isSending: false,
        };
    }

    onSubmit = event => {
        event.preventDefault();
        const { isSending, data } = this.state;
        const { history } = this.props;

        if (isSending) {
            return false;
        }

        this.setState({
            isSending: true,
        });

        return Api.request({
            method: 'POST',
            url: 'companies/create',
            data,
        })
            .then(res => {
                router.toCompany(history, res.data.data);
            })
            .finally(() => {
                this.setState({
                    isSending: false,
                });
            });
    };

    onChange = event => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                [event.target.name]: event.target.value,
            },
        });
    };

    searchTimezone = query => {
        if (!query) return Promise.resolve([]);

        return Api.request({
            method: 'GET',
            url: `timezones?name=${query}`,
        })
            .then(response => {
                return response.data.data;
            })
            .catch(() => {
                return [];
            });
    };

    selectTimezone = timezone => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                timezone: timezone.timezone,
            },
        });

        return Promise.resolve();
    };

    render() {
        const { isSending, data } = this.state;
        const { name } = data;

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView centerHeading title={trans('creating_new_helpdesk')}>
                    <form onSubmit={this.onSubmit}>
                        <FormInput
                            labelText={trans('attributes.company_name')}
                            onChange={this.onChange}
                            type="text"
                            name="name"
                            value={name}
                            placeholder={trans('placeholders.company_name')}
                        />

                        <div className="form-group pt-1">
                            <label className="dc-form-label">{trans('timezone')}</label>
                            <div className="auth-autoload-theme">
                                <AutoLoadCompleteInput
                                    canCreate={false}
                                    onSearch={this.searchTimezone}
                                    onClick={this.selectTimezone}
                                    itemMapper={x => ({
                                        ...x,
                                        value: x.timezone,
                                        label: (
                                            <div className="font-weight-normal p-1 cursor-pointer hover-effect">
                                                {x.name}
                                            </div>
                                        ),
                                    })}
                                    placeholder={trans('placeholders.type_to_search_timezone')}
                                    scrollbarClassnames="timezone-scrollbar slim-gray"
                                    scroll
                                    showSelectedValue
                                    suggestion
                                    fullWidth
                                />
                            </div>
                        </div>

                        <div className="mt-4 d-flex align-items-center">
                            <FormTextButton
                                type="link"
                                to="/"
                                text={trans('back_to_company_list')}
                            />
                            <div className="ml-auto">
                                <FormButton
                                    text={trans('create_helpdesk')}
                                    disabled={isSending}
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(CreateCompany);

CreateCompany.propTypes = {
    history: PropTypes.object.isRequired,
};
