import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Api, AuthService } from '../../services';
import helpers from '../../helpers';
import trans from '../../localization';
import { ManageLayout } from '../../layouts';
import { CarouselPane, StepCarousel } from '../../components';
import { ManageCheckbox, ManageSelectbox, ManageTimeInput } from '../../elements';
import ManageHeading from '../../components/helpdesk/heading/ManageHeading';
import CancelButton from '../../components/helpdesk/manage/CancelButton';
import router from '../../utils/router';

class SLATargetsPage extends Component {
    constructor(props) {
        super(props);

        const { match } = this.props;
        const user = AuthService.user();

        this.state = {
            user,
            companyId: match.params && match.params.companyId,
            company: null,
            isLoading: true,
            data: {
                urgent: {
                    resolve_interval: 'i',
                    resolve_time: '',
                    respond_interval: 'i',
                    respond_time: '',
                    operational_hours: 'calendar',
                    is_active: false,
                },
                high: {
                    resolve_interval: 'i',
                    resolve_time: '',
                    respond_interval: 'i',
                    respond_time: '',
                    operational_hours: 'calendar',
                    is_active: false,
                },
                medium: {
                    resolve_interval: 'i',
                    resolve_time: '',
                    respond_interval: 'i',
                    respond_time: '',
                    operational_hours: 'calendar',
                    is_active: false,
                },
                low: {
                    resolve_interval: 'i',
                    resolve_time: '',
                    respond_interval: 'i',
                    respond_time: '',
                    operational_hours: 'calendar',
                    is_active: false,
                },
            },
        };
    }

    componentDidMount() {
        const fetchPromises = Promise.all([this.fetchCompany(), this.fetchSLATargets()]);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchSLATargets() {
        const { companyId } = this.state;

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}/slaTargets`,
        }).then(response => {
            this.setState({
                data: response.data.data,
            });
        });
    }

    handleSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            isLoading: true,
        });

        const { data, companyId } = this.state;

        // / handle submitt

        Api.request({
            method: 'PUT',
            url: `companies/${companyId}/slaTargets`,
            data,
        })
            .then(this.goBack)
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    onChange = (priority, value, field) => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                [priority]: {
                    ...data[priority],
                    [field]: value,
                },
            },
        });
    };

    render() {
        const { isLoading, user, company, companyId, data } = this.state;
        const priorities = helpers.getPriorities();
        const intervals = helpers.getIntervals();
        const operationalHours = helpers.getOperationalHours();

        return (
            <ManageLayout isLoading={isLoading} theme="red" user={user} company={company}>
                <form onSubmit={this.handleSubmit}>
                    <StepCarousel step={0}>
                        <CarouselPane onPrev={this.goBack} center middle scroll>
                            <div>
                                <ManageHeading
                                    title={trans('sla_targets')}
                                    subtitle={trans('sla_targets_text')}
                                />
                                <table className="table-spacing-1 sla-targets-table">
                                    <thead>
                                        <tr>
                                            <th className="text-dark text-align-center">
                                                {trans('priority')}
                                            </th>
                                            <th className="text-dark text-align-center">
                                                {trans('respond_within')}
                                            </th>
                                            <th className="text-dark text-align-center">
                                                {trans('resolve_within')}
                                            </th>
                                            <th className="text-dark text-align-center">
                                                {trans('operational_hours')}
                                            </th>
                                            <th className="text-dark text-align-center">
                                                {trans('active')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {priorities.map(x => (
                                            <tr key={x.value}>
                                                <td>
                                                    <span className="text-dark text-nowrap">
                                                        {x.title}
                                                    </span>
                                                </td>
                                                <td>
                                                    <ManageTimeInput
                                                        units={intervals}
                                                        timeInputProps={{
                                                            value: data[x.value].respond_time,
                                                            min: 0,
                                                            placeholder: '0',
                                                        }}
                                                        unitSelectProps={{
                                                            value: data[x.value].respond_interval,
                                                        }}
                                                        onTimeChange={e =>
                                                            this.onChange(
                                                                x.value,
                                                                e.target.value,
                                                                'respond_time'
                                                            )
                                                        }
                                                        onUnitChange={e =>
                                                            this.onChange(
                                                                x.value,
                                                                e.target.value,
                                                                'respond_interval'
                                                            )
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <ManageTimeInput
                                                        units={intervals}
                                                        timeInputProps={{
                                                            value: data[x.value].resolve_time,
                                                            min: 0,
                                                            placeholder: '0',
                                                        }}
                                                        unitSelectProps={{
                                                            value: data[x.value].resolve_interval,
                                                        }}
                                                        onTimeChange={e =>
                                                            this.onChange(
                                                                x.value,
                                                                e.target.value,
                                                                'resolve_time'
                                                            )
                                                        }
                                                        onUnitChange={e =>
                                                            this.onChange(
                                                                x.value,
                                                                e.target.value,
                                                                'resolve_interval'
                                                            )
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <ManageSelectbox
                                                        items={operationalHours}
                                                        value={data[x.value].operational_hours}
                                                        onChange={e =>
                                                            this.onChange(
                                                                x.value,
                                                                e.target.value,
                                                                'operational_hours'
                                                            )
                                                        }
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <ManageCheckbox
                                                        className="d-flex justify-content-center align-items-center mb-0"
                                                        checked={data[x.value].is_active}
                                                        onChange={e =>
                                                            this.onChange(
                                                                x.value,
                                                                e.target.checked,
                                                                'is_active'
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex align-items-center px-4 py-2">
                                    <span className="text-dark text-small ml-auto">
                                        {trans('your_business_hours_come_from_x', {
                                            name: (
                                                <Link
                                                    to={router.pathCompany(
                                                        companyId,
                                                        'worktimeCalendar'
                                                    )}
                                                >
                                                    {trans('work_time_settings')}
                                                </Link>
                                            ),
                                        })}
                                    </span>
                                </div>
                            </div>
                        </CarouselPane>
                    </StepCarousel>

                    <div className="float-bottom-container d-flex justify-content-end">
                        <CancelButton onClick={this.goBack} />
                        <button type="submit" className="btn btn-primary btn-manage save-btn">
                            {trans('save_changes')}
                        </button>
                    </div>
                </form>
            </ManageLayout>
        );
    }
}

export default withRouter(SLATargetsPage);

SLATargetsPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
