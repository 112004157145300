import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api, ApiService, AuthService } from '../../services';
import { SocialLogin } from '../../components';
import { AuthCardView, SignUpLink } from '../../components/auth';
import { FormButton, FormInput } from '../../elements';
import { AuthLayout } from '../../layouts';
import trans from '../../localization';

class SignInPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: {
                email: '',
                password: '',
            },
            errors: {},
            isSending: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        const { isSending, data } = this.state;

        if (isSending) {
            return;
        }

        this.setState({
            isSending: true,
        });

        Api.request(
            {
                method: 'POST',
                url: 'auth/signIn',
                data,
            },
            true
        )
            .then(response => {
                const { token } = response.data.data;
                const { history } = this.props;
                AuthService.setTokenAndFetchThenRedirect(token, history);
            })
            .catch(error => {
                const errors = ApiService.parseErrors(error);
                this.setState({
                    errors,
                    isSending: false,
                });
            });
    }

    onChange(event) {
        const { data } = this.state;
        data[event.target.name] = event.target.value;

        this.setState({
            data,
        });
    }

    render() {
        const { isSending, errors, data } = this.state;
        const { email, password } = data;

        return (
            <AuthLayout isLoading={isSending}>
                <AuthCardView
                    title={trans('login_to_your_account')}
                    titleSmall={trans('login')}
                    subtitle={trans('login_to_your_account_subtitle')}
                >
                    <div className="">
                        
                        <form onSubmit={this.onSubmit} className="mb-4">
                            <FormInput
                                labelText={trans('attributes.email')}
                                validationError={errors.email}
                                onChange={this.onChange}
                                value={email}
                                type="email"
                                name="email"
                                placeholder={trans('placeholders.sample_email')}
                            />

                            <FormInput
                                labelText={trans('attributes.password')}
                                validationError={errors.password}
                                onChange={this.onChange}
                                value={password}
                                placeholder="●●●●●●●●●●"
                                type="password"
                                name="password"
                                rightLink={trans('feeling_forgetful')}
                                rightLinkTo="/auth/recoverPassword"
                            />

                            <FormButton
                                className="mt-4 text-uppercase"
                                isBlock
                                text={trans('login_to_my_account')}
                                disabled={isSending}
                                type="submit"
                            />
                        </form>
                        <SignUpLink />
                    </div>
                </AuthCardView>
            </AuthLayout>
        );
    }
}

export default withRouter(SignInPage);

SignInPage.propTypes = {
    history: PropTypes.object.isRequired,
};
