import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ColorListItem = ({ color, children, right, className, ...props }) => {
    return (
        <div className={cx('color-list-item', `text-color-${color}`, className)} {...props}>
            <div className="color-list-item-label">{children}</div>
            {right}
        </div>
    );
};

ColorListItem.propTypes = {
    children: PropTypes.any,
    right: PropTypes.any,
    color: PropTypes.string,
    className: PropTypes.any,
};

ColorListItem.defaultProps = {
    children: null,
    right: null,
    color: '',
    className: null,
};

export default ColorListItem;
