import HomePage from './HomePage';
import TicketPage from './TicketPage';
import CreateTicketPage from './CreateTicketPage';
import SLATargetsPage from './SLATargetsPage';
import { HelpdeskLayout } from '../../layouts';
import agentRoutes from './agents';
import channelRoutes from './channels';
import integrations from './integrations';
import WorktimeCalendarPage from './WorktimeCalendarPage';
import TicketLogsPage from './TicketLogsPage';
import parameters from '../../routes/parameters';
import ticketTypeRoutes from './ticketTypes';
import ticketRuleRoutes from './ticketRules';
import statisticsRoutes from './statistics';

export default [
    {
        path: '/companies/:companyId',
        component: HelpdeskLayout,
        exact: false,
        children: [
            {
                path: '/companies/:companyId',
                middleware: ['auth'],
                component: HomePage,
                ...parameters.companyIdParameters,
            },
            {
                path: '/companies/:companyId/tickets/:ticketId',
                middleware: ['auth'],
                component: TicketPage,
                ...parameters.companyIdParameters,
            },
            {
                path: '/companies/:companyId/createTicket',
                component: CreateTicketPage,
                ...parameters.companyIdParameters,
            },
            {
                path: '/companies/:companyId/SLATargets',
                component: SLATargetsPage,
                ...parameters.companyIdParameters,
            },
            {
                path: '/companies/:companyId/worktimeCalendar',
                component: WorktimeCalendarPage,
                ...parameters.companyIdParameters,
            },
            {
                path: '/companies/:companyId/tickets/:ticketId/logs',
                component: TicketLogsPage,
                ...parameters.companyIdParameters,
            },
            ...agentRoutes,
            ...channelRoutes,
            ...integrations,
            ...ticketTypeRoutes,
            ...ticketRuleRoutes,
            ...statisticsRoutes,
        ],
    },
];
