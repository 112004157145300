const slugRegex = /^.+\.([0-9]+)$/;

export default {
    slugRegex,
    companyIdParameters: {
        parameters: {
            companyId: slugRegex,
        },
    },
    companyParameters: {
        parameters: {
            company: slugRegex,
        },
    },
};
