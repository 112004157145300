import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AuthService } from '../../../services';
import CompanyService from '../../../services/CompanyService';
import ChannelService from '../../../services/ChannelService';
import notify from '../../../utils/notify';
import Routes from '../../../services/Routes';
import helpers from '../../../helpers';
import trans from '../../../localization';
import { ManageLayout } from '../../../layouts';
import { CarouselPane, StepCarousel } from '../../../components';
import EditChannelHeader from '../../../components/helpdesk/channel/EditChannelHeader';
import EditChannelFields from '../../../components/helpdesk/channel/EditChannelFields';
import EditChannelSidebar from '../../../components/helpdesk/channel/EditChannelSidebar';
import CancelAndSaveFooter from '../../../components/helpdesk/carousel/CancelAndSaveFooter';
import CropperModal from '../../../components/helpdesk/CropperModal';
import dialog from '../../../utils/dialog';

export default class BaseEditPage extends Component {
    constructor(props, states = {}) {
        super(props);

        this.state = {
            // eslint-disable-next-line
            user: AuthService.user(),
            company: null,
            // eslint-disable-next-line
            isLoading: true,
            channel: {
                id: props.match.params.channelId,
                is_active: false,
                is_enabled_auto_ai_response: false,
                icon: '',
                name: '',
                color: '',
                image: '',
            },
            ...states,
            showCropper: false,
            channelImage: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.changeIcon = this.changeIcon.bind(this);
        this.onChangeChannelSettings = this.onChangeChannelSettings.bind(this);
        this.goToBack = this.goToBack.bind(this);
    }

    onMount(promises = []) {
        return Promise.all([this.fetchCompany(), this.fetchChannel(), ...promises]).finally(() => {
            this.setState({
                // eslint-disable-next-line
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { match } = this.props;

        return CompanyService.show(match.params.companyId).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    fetchChannel() {
        const { match } = this.props;

        return ChannelService.show(match.params.channelId).then(response =>
            this.setState({ channel: response.data.data })
        );
    }

    handleSubmit(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { channel, company } = this.state;
        this.setState({
            // eslint-disable-next-line
            isLoading: true,
        });

        ChannelService.update(channel, this.getData())
            .then(() => {
                notify.saved();
                const { history } = this.props;
                Routes.toChannels(history, company);
            })
            .finally(() => {
                this.setState({
                    // eslint-disable-next-line
                    isLoading: false,
                });
            });
    }

    handleDelete(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { channel, company } = this.state;
        this.setState({
            // eslint-disable-next-line
            isLoading: true,
        });

        ChannelService.delete(channel.id)
            .then(() => {
                notify.deleted();
                const { history } = this.props;
                Routes.toChannels(history, company);
            })
            .finally(() => {
                this.setState({
                    // eslint-disable-next-line
                    isLoading: false,
                });
            });
    }

    archiveTickets = () => {
        const { channel } = this.state;
        dialog
            .confirm(
                trans('confirm'),
                trans('are_you_sure_archive_channel', {
                    name: channel.name,
                })
            )
            .then(() => {
                ChannelService.archiveAllTickets(channel.id)
                    .then(() => {
                        notify.successMessage(
                            trans('all_tickets_in_this_channel_archive_successfull')
                        );
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            });
    };

    getData() {
        const { channel } = this.state;

        return {
            is_active: helpers.parseBoolean(channel.is_active),
            is_enabled_auto_ai_response: helpers.parseBoolean(channel.is_enabled_auto_ai_response),
            name: channel.name,
            icon: channel.icon,
            color: channel.color,
            image: channel.image,
        };
    }

    changeIcon(icon) {
        const { channel } = this.state;

        this.setState({
            channel: {
                ...channel,
                icon,
                image: null,
            },
        });
    }

    changeColor(color) {
        const { channel } = this.state;

        this.setState({
            channel: {
                ...channel,
                color,
            },
        });
    }

    changeImage = e => {
        const file = e && e.target && e.target.files[0];

        if (!file) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        const reader = new FileReader();
        reader.onload = () => {
            this.setState({
                isLoading: false,
                showCropper: true,
                channelImage: reader.result,
            });
        };
        reader.readAsDataURL(file);
    };

    onCloseCrop = () => {
        this.setState({
            showCropper: false,
        });
    };

    onCrop = image => {
        const { channel } = this.state;

        this.setState({
            channelImage: null,
            channel: {
                ...channel,
                image,
                icon: null,
            },
            showCropper: false,
        });
    };

    onChangeChannelSettings(field, e) {
        const { channel } = this.state;

        this.setState({
            channel: {
                ...channel,
                [field]: e.target.value,
            },
        });
    }

    goToBack() {
        const { history } = this.props;
        const { company } = this.state;

        Routes.toChannels(history, company);
    }

    layout(children = null) {
        const { isLoading, user, company, channel, showCropper, channelImage } = this.state;

        return (
            <ManageLayout isLoading={isLoading} theme="green" user={user} company={company}>
                <StepCarousel>
                    <CarouselPane onPrev={this.goToBack} fluid scroll>
                        <EditChannelHeader channel={channel} />
                        <div className="row mt-4">
                            <div className="col-lg-9">
                                <EditChannelFields
                                    company={company}
                                    channel={channel}
                                    onChangeChannelSettings={this.onChangeChannelSettings}
                                    onDeleteChannel={this.handleDelete}
                                />
                                {children}
                            </div>
                            <EditChannelSidebar
                                changeIcon={this.changeIcon}
                                channel={channel}
                                changeColor={this.changeColor}
                                changeImage={this.changeImage}
                            />
                        </div>
                    </CarouselPane>
                </StepCarousel>

                <CropperModal
                    onClose={this.onCloseCrop}
                    onCrop={this.onCrop}
                    show={showCropper}
                    src={channelImage}
                    width={128}
                    height={128}
                />

                <CancelAndSaveFooter
                    onSubmit={this.handleSubmit}
                    onCancel={this.goToBack}
                    onArchive={this.archiveTickets}
                />
            </ManageLayout>
        );
    }
}

BaseEditPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
