import { Api } from './index';
import notify from '../utils/notify';
import Routes from './Routes';

export default class ChannelService {
    static delete(channelId) {
        return Api.request({
            method: 'DELETE',
            url: `channels/${channelId}/delete`,
        });
    }

    static update(channel, data) {
        return Api.request({
            method: 'PUT',
            url: `channels/${channel.id}/${channel.type}/edit`,
            data,
        });
    }

    static show(channelId) {
        return Api.request({
            method: 'GET',
            url: `channels/${channelId}`,
        });
    }

    static archiveAllTickets(channelId) {
        return Api.request({
            method: 'PUT',
            url: `channels/${channelId}/archive`,
        });
    }

    static editPageHandleSubmit(channel, company, data, history, setState) {
        setState({
            isLoading: true,
        });

        ChannelService.update(channel, data)
            .then(() => {
                notify.saved();
                Routes.toChannels(history, company);
            })
            .finally(() => {
                setState({
                    isLoading: false,
                });
            });
    }
}
