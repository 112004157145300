import errorEmail from './error-email.svg';
import successEmail from './green-email.svg';
import logo from './logo.svg';
import logoCry from './logo-cry.svg';
import authLeftImg from './auth-left-img.svg';
import carouselImg1 from './carousel-img-1.png';
import signInWithGoogle from './sign-in-with-google.svg';
import signInWithAmazon from './sign-in-with-amazon.svg';
import signInWithFacebook from './sign-in-with-facebook.svg';
import brandLogo from './brand-logo.svg';
import emptyMessage from './empty-message.png';
import menu from './menu.svg';
import menuClose from './menu-close.svg';
// import agents from './agents.svg';
// import channels from './channels.svg';
import comingSoon from './coming-soon.svg';
// import productivity from './producticity.svg';
// import rulesEngine from './rules-engine.svg';
import ticketTemplate from './ticket-template.svg';
import statistics from './statistics.svg';
import questionMarkDuotone from './question-circle-duotone.svg';
import coloredUsers from './colored-users.svg';
import profileCover from './profile-cover.png';
import activityPrivateNote from './activity-private-note.svg';
import activityInvite from './activity-invite.svg';
import cannedResponsesIcon from './canned-responses-icon.svg';

import integrations from './settings/integrations.svg';
import channels from './settings/channels.svg';
import agents from './settings/agents.svg';
import responses from './settings/responses.svg';
import targets from './settings/targets.svg';
import ticketTypes from './ticket-type.svg';
import ticketRules from './ticket-rules.svg';

const socialAuthImages = [
    {
        name: 'google',
        image: signInWithGoogle,
    },
    {
        name: 'facebook',
        image: signInWithFacebook,
    },
    {
        name: 'amazon',
        image: signInWithAmazon,
    },
];

const settingImages = [
    {
        name: 'agents',
        image: agents,
        adminOnly: true,
    },
    {
        name: 'channels',
        image: channels,
        adminOnly: true,
    },
    {
        name: 'responses',
        image: responses,
    },
    {
        name: 'sla_targets',
        image: targets,
        adminOnly: true,
    },
    {
        name: 'integrations',
        image: integrations,
        adminOnly: true,
    },
    {
        name: 'ticket_types',
        image: ticketTypes,
        adminOnly: true,
    },
    {
        name: 'ticket_rules',
        image: ticketRules,
        adminOnly: true,
    },
    /*
        {
            name: 'worktime_calendar',
            image: calendar,
            adminOnly: true,
        },
        {
            name: 'productivity',
            image: productivity,
            notImplemented: true,
        },
        {
            name: 'rules_engine',
            image: rulesEngine,
            notImplemented: true,
        },
    */
];

export {
    logo,
    errorEmail,
    successEmail,
    authLeftImg,
    carouselImg1,
    socialAuthImages,
    brandLogo,
    emptyMessage,
    menu,
    menuClose,
    ticketTemplate,
    settingImages,
    comingSoon,
    statistics,
    questionMarkDuotone,
    coloredUsers,
    profileCover,
    activityPrivateNote,
    activityInvite,
    cannedResponsesIcon,
    logoCry,
};
