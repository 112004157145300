import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Api, AuthService } from '../../../services';
import { ManageLayout } from '../../../layouts';
import { CarouselPane, StepCarousel } from '../../../components';
import ManageHeading from '../../../components/helpdesk/heading/ManageHeading';
import AutoLoadCompleteInput from '../../../components/helpdesk/AutoLoadCompleteInput';
import trans from '../../../localization';
import CancelAndSaveFooter from '../../../components/helpdesk/carousel/CancelAndSaveFooter';

class ChangeTimezone extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;
        const user = AuthService.user();

        this.state = {
            user,
            companyId: location.state && location.state.companyId,
            company: null,
            isLoading: true,
            data: {
                timezone: (user && user.timezone) || '',
            },
        };
    }

    componentDidMount() {
        const fetchPromises = Promise.all([this.fetchCompany()]);

        fetchPromises.finally(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    fetchCompany() {
        const { companyId } = this.state;

        if (!companyId) return Promise.resolve();

        return Api.request({
            method: 'GET',
            url: `companies/${companyId}`,
        }).then(response => {
            this.setState({
                company: response.data.data,
            });
        });
    }

    handleSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            isLoading: true,
        });

        const { data } = this.state;
        Api.request({
            method: 'POST',
            url: 'myAccount/changeTimezone',
            data,
        })
            .then(this.goBack)
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    searchTimezone = query => {
        if (!query) return Promise.resolve([]);

        return Api.request({
            method: 'GET',
            url: `timezones?name=${query}`,
        })
            .then(response => {
                return response.data.data;
            })
            .catch(() => {
                return [];
            });
    };

    selectTimezone = timezone => {
        const { data } = this.state;

        this.setState({
            data: {
                ...data,
                timezone: timezone.timezone,
            },
        });

        return Promise.resolve();
    };

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { isLoading, user, company, data } = this.state;

        return (
            <ManageLayout
                isLoading={isLoading}
                theme="purple"
                user={user}
                company={company}
                hideMyAccountLink
            >
                <form onSubmit={this.handleSubmit}>
                    <StepCarousel step={0}>
                        <CarouselPane onPrev={this.goBack} center middle scroll>
                            <div>
                                <ManageHeading
                                    title={trans('change_x', {
                                        name: <strong>{trans('timezone')}</strong>,
                                    })}
                                    subtitle={trans('change_timezone_text')}
                                />

                                <div>
                                    <p className="title text-color-dark text-size-18px">
                                        {trans('timezone')}
                                    </p>
                                    <div className="manage-autoload-theme">
                                        <AutoLoadCompleteInput
                                            value={data.timezone}
                                            canCreate={false}
                                            onSearch={this.searchTimezone}
                                            onClick={this.selectTimezone}
                                            itemMapper={x => ({
                                                ...x,
                                                value: x.timezone,
                                                label: (
                                                    <div className="font-weight-normal p-1 cursor-pointer hover-effect">
                                                        {x.name}
                                                    </div>
                                                ),
                                            })}
                                            placeholder={trans(
                                                'placeholders.type_to_search_timezone'
                                            )}
                                            scrollbarClassnames="timezone-scrollbar slim-gray"
                                            scroll
                                            showSelectedValue
                                            suggestion
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                        </CarouselPane>
                    </StepCarousel>

                    <CancelAndSaveFooter onCancel={this.goBack} />
                </form>
            </ManageLayout>
        );
    }
}

export default withRouter(ChangeTimezone);

ChangeTimezone.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
