import React from 'react';
import { Link } from 'react-router-dom';
import trans from '../../localization';

const SignUpLink = () => {
    return (
        <div className="auth-signuplink">
            <Link to="/auth/signUp" className="text-dark auth-signuplink-md">
                <div className="align-items-center d-flex flex-column free-trial-link">
                    <p className="text-center free-trial-link-top-text">
                        {trans('i_do_not_have_an_account')}
                    </p>
                    <p>
                        {trans('start_my_7_day_x_trial', {
                            type: <strong className="text-uppercase">{trans('free')}</strong>,
                        })}
                    </p>
                </div>
            </Link>
            <Link to="/auth/signUp" className="auth-signuplink-sm">
                {trans('i_need_an_account')}
            </Link>
        </div>
    );
};

export default SignUpLink;
