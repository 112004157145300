import React from 'react';
import PropTypes from 'prop-types';

const ManageCheckbox = ({ label, className, ...rest }) => {
    return (
        <label className={`checkbox ${className}`}>
            <input type="checkbox" className="checkbox-element" {...rest} />
            <span className="checkbox-handle">
                <span className="checkbox-sign">
                    <i className="fa fas fa-check" />
                </span>
            </span>
            {label && <span className="checkbox-label">{label}</span>}
        </label>
    );
};

ManageCheckbox.propTypes = {
    label: PropTypes.any,
    className: PropTypes.string,
};

ManageCheckbox.defaultProps = {
    label: '',
    className: '',
};

export default ManageCheckbox;
